
import { Switch } from 'antd';
import GlobalDrawer from 'components/GlobalModal/GlobalDrawer';
import React, { useEffect, useState } from 'react'
import { SubHeadingLabel, TrackGridTwo } from 'statics/styles/StyledComponents';
import styled from 'styled-components';
import EventCollapse from './EventCollapse';
import GoogleMap from 'google-map-react';
import styles from '../../../../../statics/styles/GoogleMapThemes/GoogleMapStylesPurple.json';
import { ReactComponent as SourceMarker } from '../../../../../statics/images/sorceMapIcon.svg';
import { ReactComponent as DestinationMarker } from '../../../../../statics/images/destinationMapIcon.svg';
import { DotMarker, ScanDetails } from '../../styles';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import LogIssueModal from '../../Issues/LogIssueModal';
import { toLatitudeAndLongitude } from '../../Utils/functions';
import { BUSINESS_STEP } from '../../Utils/CONSTANTS';
import { useAuthStore } from 'zustand-stores';
import { AuthStore } from 'zustand-stores/useAuthStore';

type Props = {
  open: boolean;
  onClose: Function;
  selectedEvent: any;
}

const YOUR_LATITUDE = -37.840935;
const YOUR_LONGITUDE = 144.946457;


const EventInfoModal = (props: Props) => {
  const { open, onClose, selectedEvent } = props
  const isVctReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.vct.isReadOnly);
  const [destinationMarkerList, setDestinationMarkerList] = useState<any[]>([])

  const isNotCurrentLocationVisible = BUSINESS_STEP.includes(selectedEvent?.biz_step)

  const [toggleSelection, setToggleSelection] = React.useState<any>({
    display_epcis_standard: false,
    display_map: false,
  });
  const [map, setMap] = useState<any>(null);
  const [logIssueModal, setLogIssueModal] = useState<any>({
    open: false,
    selectedEvent: null,
    event_id: '',
  });

  useEffect(() => {
    if (selectedEvent) {
      console.log('selectedEvent', selectedEvent)
      if (selectedEvent.destinationList) {
        const destinationMarkerList = selectedEvent.destinationList.map((item: any, index: number) => {
          const { lat, long } = toLatitudeAndLongitude(item?.geo);
          const destinationData =
            selectedEvent.ilmd?.['itrazo:extension']?.productInfo && selectedEvent.ilmd?.['itrazo:extension']?.productInfo?.destinationbusinessLocation && selectedEvent.ilmd?.['itrazo:extension']?.productInfo?.destinationbusinessLocation[index];
          const receiver = `${destinationData?.businessName}, ${destinationData?.address}` || 'Destination';

          return {
            lat,
            lng: long,
            name: receiver,
            isSource: false,
          };
        });

        setDestinationMarkerList(destinationMarkerList);
      }
    }

  }, [selectedEvent])

  const handleDisplayMap = () => {
    setToggleSelection({
      ...toggleSelection,
      display_map: !toggleSelection.display_map,
    });
  };

  const getSourceAndDestinationList = (sourceList: any[], destinationList: any[]) => {

    const sourceAndDestinationPath: any[] = []
    const source = sourceList[0]

    Array.isArray(destinationList) && destinationList.map((item: any) => {
      const { lat: sourceLat, long: sourceLon } = toLatitudeAndLongitude(source?.geo);
      const { lat: destinationLat, long: destinationLon } = toLatitudeAndLongitude(item?.geo);


      const sourceGeo = {
        lat: parseFloat(sourceLat),
        lng: parseFloat(sourceLon),
      }

      const destinationGeo = {
        lat: parseFloat(destinationLat),
        lng: parseFloat(destinationLon),
      }

      sourceAndDestinationPath.push({
        sourceGeo,
        destinationGeo,
      })

    })

    return sourceAndDestinationPath

  };

  const showLine = () => {
    if (!isNotCurrentLocationVisible) return null
    if (!selectedEvent?.sourceList || !selectedEvent?.destinationList) return null

    const sourceAndDestinationPath = getSourceAndDestinationList(selectedEvent?.sourceList, selectedEvent?.destinationList)

    if (sourceAndDestinationPath.length > 0) {
      const paths: any[] = []
      sourceAndDestinationPath.map((item: any) => {
        const path = [item.sourceGeo, item.destinationGeo]
        paths.push(path[0])
        paths.push(path[1])
        return new window.google.maps.Polyline({
          path: path,
          strokeColor: '#3E7BFA',
          strokeWeight: 2,
          map: map,
          icons: [{
            icon: {
              path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            },
            offset: '50%',  // Appear at the middle of the line
          }]
        });

      })
    }
  };

  const PointsNew = ({ name, isSource }: any) => {
    return (
      <DotMarker newScan>
        {/* <Marker /> */}
        {isSource === false ? <DestinationMarker /> : <SourceMarker />}
        {showDetails(name)}
      </DotMarker>
    );
  }


  const showDetails = (name: string) => {
    return (
      <ScanDetails>
        {`${name}`}
      </ScanDetails>
    );
  };

  const displaySourceLocation = () => {
    let locationInfo;
    if (isNotCurrentLocationVisible) {
      locationInfo = selectedEvent?.ilmd?.['itrazo:extension']?.productInfo?.sourcebusinessLocation[0]
    } else {
      locationInfo = selectedEvent?.ilmd?.['itrazo:extension']?.productInfo?.currentbusinessLocation
    }

    const geoInfo = isNotCurrentLocationVisible ? selectedEvent?.sourceList[0]?.geo : locationInfo?.geo

    try {
      const { lat, long } = toLatitudeAndLongitude(geoInfo)
      const sender = `${locationInfo?.businessName}, ${locationInfo?.address}` || 'Source'
      if (!lat || !long) return null
      return (
        <PointsNew
          lat={lat}
          lng={long}
          name={sender || 'Source'}
          isSource={true}
        />
      );
    } catch (error) {
      console.log('error getting latitude and longitude')
      return null
    }
  }

  const displayDestinationLocation = () => {

    if (!isNotCurrentLocationVisible) return null
    try {
      if (!selectedEvent?.destinationList[0]?.geo) return null

      return Array.isArray(destinationMarkerList) && destinationMarkerList.length > 0 && destinationMarkerList.map((item: any, index: number) => {
        return (
          <PointsNew
            Key={index}
            lat={parseFloat(item.lat)}
            lng={parseFloat(item.lng)}
            name={item.name}
            isSource={false}
          />
        )
      })

    } catch (error) {
      console.log('error getting latitude and longitude')
      return null
    }
  }

  const renderGoogleMap = () => {
    return (
      <div style={{
        height: '400px',
        marginBottom: '20px',
      }}>
        <GoogleMap
          bootstrapURLKeys={{
            key: `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
            libraries: 'geometry',
          }}
          options={{
            mapTypeId: 'roadmap',
            minZoomOverride: true,
            styles,
          }}
          defaultZoom={3}
          defaultCenter={{
            lat: YOUR_LATITUDE,
            lng: YOUR_LONGITUDE,
          }}
          center={{
            lat: YOUR_LATITUDE,
            lng: YOUR_LONGITUDE,
          }}
          onGoogleApiLoaded={({ map }) => setMap(map)}
        >
          {displaySourceLocation()}
          {displayDestinationLocation()}
          {showLine()}
        </GoogleMap>
      </div>
    )
  };

  const modalClose = () => {
    onClose();
    setToggleSelection({
      display_epcis_standard: false,
      display_map: false,
    });
  }

  return (
    <GlobalDrawer
      open={open}
      onClose={modalClose}
      title={`Event Information - ${selectedEvent?.intEventID}`}
    >
      <>
        <TrackGridTwo>
          <div style={{ display: 'flex', gap: '20px' }}>
            <SubHeadingLabel>Display EPCIS Standard</SubHeadingLabel>
            <SwitchDiv>
              <SwitchContainer color={toggleSelection.display_epcis_standard}
                onChange={(checked: boolean) => {
                  setToggleSelection({
                    ...toggleSelection,
                    display_epcis_standard: checked
                  })
                }}
              />
            </SwitchDiv>
          </div>
          <div style={{ display: 'flex', gap: '20px' }}>
            <SubHeadingLabel>Display Map</SubHeadingLabel>
            <SwitchDiv>
              <SwitchContainer color={toggleSelection.display_map}
                onChange={handleDisplayMap}
              />
            </SwitchDiv>
          </div>

        </TrackGridTwo>
        {toggleSelection.display_map && (
          renderGoogleMap()
        )}
        {/* Collapse */}
        <EventCollapse selectedEvent={selectedEvent} toggleSelection={toggleSelection} eventModalClose={modalClose} />
        {
          !isVctReadOnly &&
          <SimpleButton
            text='Log Issue'
            onClick={(e: any) => {
              e.preventDefault()
              e.stopPropagation()
              setLogIssueModal({
                open: true,
                selectedEvent: selectedEvent,
                event_id: selectedEvent?.intEventID,
              })
            }}
          />
        }


        <LogIssueModal
          isModalOpen={logIssueModal.open}
          isEdit={'add'}
          eventID={logIssueModal.event_id}
          handleModalClose={() => setLogIssueModal({ ...logIssueModal, open: false, selectedEvent: null })}
        />

      </>
    </GlobalDrawer>
  )
}
export default EventInfoModal
type switchProps = {
  color?: boolean;
}

const SwitchDiv = styled.div`
  .ant-switch {
    background-color: #E4E4EB !important;
    border: 1px solid #E4E4EB !important;
  }
`;
const SwitchContainer = styled(Switch)`
  .ant-switch-handle::before {
    background-color: ${(props: switchProps) => {
    return props.color ? `green` : `red`;
  }}!important;
  }
`;