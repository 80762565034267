/**
 * This file defines all api functions associated with Order API
 */


import { logError } from "../utilities/functions";
import { OSM_API, CRM_API } from "utilities/AdiApi";

import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";

// Error handling is in the component that calls the api function
const GeneratePicklistAPI = {
  getId: async () => {

    try {
      const res = await OSM_API.get(`generate-picklist/picklist-id`)
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }

  },
  getAllPicklist: async (orderID: string, picklist_status: string) => {
    console.log('payload createPicklist') // order_ID only be "acg_product || 3pl_product || ecommerce_product"

    try {
      const res = await OSM_API.get(`generate-picklist?order_ID=${orderID}&picklist_status=${picklist_status}`)
      return res.data.body.Items
    } catch (e) {
      logError(e)
      throw e
    }

  },
  createPicklist: async (payload: any) => {
    console.log('payload createPicklist')

    try {
      const res = await OSM_API({
        method: 'POST',
        url: `generate-picklist`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  },
  updatePicklist: async (payload: any) => {
    console.log('payload updatePicklist')

    try {
      const res = await OSM_API({
        method: 'PUT',
        url: `generate-picklist`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  },
  getPicklistIdByOrderId: async (order_id: string) => {
    try {
      const res = await OSM_API.get(`generate-picklist?order_ID=${order_id}`)
      return res.data.body.Item
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getAcgProductFromPicklistByOrderId: async (order_ID: string) => {
    try {
      const res = await OSM_API.get(`generate-picklist/acg-product-inventory?order_ID=${order_ID}`)
      return res.data.body.Items
    } catch (e) {
      logError(e)
      throw e
    }
  }

}

export default GeneratePicklistAPI