import React from 'react'
import styled from 'styled-components';
import { Descriptions } from 'antd';
import 'antd/dist/antd.css';
import GlobalModal from 'components/GlobalModal';
import { FlexWrapper } from 'statics/styles/StyledComponents';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import { VCT_ROOT_PATH } from '../../VCTRoutes';
import { useNavigate } from 'react-router-dom';


type OriginalProps = {
  open: boolean;
  onClose: Function;
  name: string;
  info: any[];
  isButtonVisible?: boolean;
  eventModalClose?: Function;
  itemData?: any;
}

const EventInfoModalInfo = (props: OriginalProps) => {
  const { open, onClose, info, name, isButtonVisible, itemData } = props
  const navigate = useNavigate();

  const bothModalClose = () => {
    onClose()
    props.eventModalClose && props.eventModalClose()
  }

  return (
    <GlobalModal
      open={open}
      onCancel={onClose}
      title={`${name}`}
      hideFooter
    >
      <div style={{
        border: '1px solid #383A56',
      }}>
        <StyledDescriptions
          column={1}
          layout="horizontal"
          bordered
        >
          {info?.map((data: any, index: any) => {
            return (
              <React.Fragment key={index}>
                <Descriptions.Item label={data.label}>{data.value}</Descriptions.Item>
              </React.Fragment>
            )
          })}
        </StyledDescriptions>

      </div>
      {isButtonVisible && itemData?.epc && (
        <FlexWrapper gap='15px' style={{
          marginTop: '20px',
        }}>
          <SimpleButton
            text="Origin"
            onClick={
              () => {
                navigate(`${VCT_ROOT_PATH}/origin`, {
                  state: {
                    epc: itemData?.epc,
                  },
                });
                bothModalClose();
              }
            }
          />
          <SimpleButton
            text="Trace"
            onClick={
              () => {
                navigate(`${VCT_ROOT_PATH}/trace`, {
                  state: {
                    epc: itemData?.epc,
                  },
                });
                bothModalClose();
              }
            }
          />
        </FlexWrapper>
      )}
    </GlobalModal>
  )
}

export default EventInfoModalInfo;

export const StyledDescriptions = styled(Descriptions)`
  padding-bottom: 10px;
    .ant-descriptions-title {
      color: var(--white-text-color) !important;
    }
    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      background-color: transparent !important;
      padding: 6px 12px !important;
    }
    .ant-descriptions-bordered,
    .ant-descriptions-view {
      border: unset !important;
    }
    .ant-descriptions-bordered,
    .ant-descriptions-item-label {
      border-right: unset !important;
      color: var(--dark-dark-4, #C7C9D9) !important;
      font-size: 12px !important;
      padding: 6px 15px !important;
      width: 200px !important;
    }
    .ant-descriptions-bordered,
    .ant-descriptions-item-content {
      color: var(--light-light-2, #F2F2F5) !important;
      font-size: 12px !important;
    }
    .ant-descriptions-bordered,
     .ant-descriptions-row {
      border-bottom: 2px dashed #383A56 !important;
    }
  
   `;
