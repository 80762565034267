import { DeleteOutlined, LeftOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import { Col, Divider, Form, Switch, Row, Steps, message, Radio } from 'antd';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import Input from 'components/Input';
import { InventoryItemAPI, SupplierAPI } from 'containers/Pages/InventoryManagement/apis';
import React, { useEffect, useMemo, useState } from 'react'
import { FlexWrapper, theme } from 'statics/styles/StyledComponents';
import styled from 'styled-components';
import { UomList } from '../../../statics/constants'
import { convertStrBool, getBizCode } from 'utilities/Functions/GlobalHelperFunctions';
import { CRM_API } from 'utilities/AdiApi';


import { getCategoryData } from 'containers/Pages/InventoryManagement/utilities/functions';
import PageBreadcrumb from 'components/PageBreadcrumb';
import { CreateProductPriceTable } from 'containers/Pages/InventoryManagement/components';
import GlobalModal from 'components/GlobalModal';
import { PRODUCT_TYPE } from '../../../statics/constants'
import Section from './Section';
import PriceBreakdownModal from './PriceBreakdownModal';
import { FormInstance } from 'antd/es/form/Form';
//import { prototype } from 'react-bootstrap-daterangepicker';


// Unit of Time
const UOT_OPTIONS = [
  { label: 'Month(s)', value: 'months' },
  { label: 'Week(s)', value: 'weeks' },
  { label: 'Day(s)', value: 'days' },
]


const FOR_SUPPLIER_PRODUCT_TYPES = [PRODUCT_TYPE.generalProduct.value]
const PRICE_VALIDATION_ERROR_TEXT = 'You have already defined a range for the order quantity.';

type Props = {
  goBack: () => void
  form: FormInstance
  priceBreakdownValues: any[]
  setPriceBreakdownValues: Function
  ecomStdPriceBreakdownValues?: any[]
  setEcomStdPriceBreakdownValues?: Function
  viewOnly: boolean
  isEdit: boolean
  productData: any
  formName: string
  isForSupplier?: boolean
  isLastStep?: boolean
}

type PriceModalType = {
  open: boolean,
  priceData: any,
  isEdit: boolean,
  roleIndex: number | undefined,
  isEcomStdPrice: boolean,
}


const AssignSupplierAndCustomer = (props: Props) => {
  const { goBack, form, priceBreakdownValues, setPriceBreakdownValues, viewOnly, isEdit, productData, formName, isForSupplier, isLastStep, ecomStdPriceBreakdownValues, setEcomStdPriceBreakdownValues } = props

  console.log('ecomStdPriceBreakdownValues', ecomStdPriceBreakdownValues)
  const productType = form.getFieldValue(`mark_finished_product`)

  const forSupplier = useMemo(() => {
    if (isForSupplier) {
      return true
    }

    return FOR_SUPPLIER_PRODUCT_TYPES.includes(productType)
  }, [form, formName, isForSupplier])

  const isEcommProduct = useMemo(() => {
    return productType === PRODUCT_TYPE.eCommerceProduct.value
  }, [form, formName]);

  const is3PLProduct = useMemo(() => {
    return productType === PRODUCT_TYPE.xplProduct.value
  }, [form, formName]);

  const isAcgProduct = useMemo(() => {
    return productType === PRODUCT_TYPE.acgProduct.value
  }, [form, formName])

  const isGeneralProduct = useMemo(() => {
    return productType === PRODUCT_TYPE.generalProduct.value
  }, [form, formName])


  const contentRenderer = useContentRenderer(form, forSupplier, viewOnly, isEdit, productData)
  const formListName = forSupplier ? 'supplier_details' : 'customer_details'
  // get existing supplier ids or customer ids for edit mode
  const existingRoleIds = useMemo(() => {
    if (isEdit) {
      if (forSupplier) {
        return productData?.supplier_details?.map((supplier: any) => supplier.supplier_id)
      } else {
        return productData?.customer_details?.map((customer: any) => customer.customer_ID)
      }
    }
    return []
  }, [isEdit, productData])


  const [priceBreakdownModal, setPriceBreakdownModal] = useState<PriceModalType>({
    open: false,
    priceData: null,
    isEdit: false,
    roleIndex: 0,
    isEcomStdPrice: false,
  })

  const validateQuantityRanges = (arr: any) => {
    console.log('validateQuantityRanges', arr, arr.length, priceBreakdownValues[0], priceBreakdownValues)
    for (let i = 0; i < arr.length; i++) {
      console.log('indide for', arr)
      const obj = arr[i];
      const min = parseInt(obj.min_moq);
      const max = parseInt(obj.max_moq);

      // Validate the min and max values for one row
      if (isNaN(min) || isNaN(max) || min > max) {
        return false;
      }
    }

    // each min should be equal to the previous max + 1
    // for (let i = 1; i < arr.length; i++) {
    //   const obj = arr[i];
    //   const min = parseInt(obj.min_moq);
    //   const prevObj = arr[i - 1];
    //   const prevMax = parseInt(prevObj.max_moq);
    //   if (min !== prevMax + 1) {
    //     return false;
    //   }
    // }

    return true;
  }

  // modal on finish
  const handlePriceUpdate = (values: any) => {
    console.log('handlePriceUpdate', values)
    const roleIndex = priceBreakdownModal.roleIndex || 0
    if (priceBreakdownModal.isEdit) {
      const key = priceBreakdownModal.priceData?.key
      console.log('handlePriceUpdate isEdit', priceBreakdownModal.isEcomStdPrice, ecomStdPriceBreakdownValues, setEcomStdPriceBreakdownValues)
      if (priceBreakdownModal.isEcomStdPrice && ecomStdPriceBreakdownValues && setEcomStdPriceBreakdownValues) {
        const newPriceBreakdownValues = [...ecomStdPriceBreakdownValues]
        delete values.key
        newPriceBreakdownValues[key] = values
        setEcomStdPriceBreakdownValues([...newPriceBreakdownValues])
      } else {
        const newPriceBreakdownValues = [...priceBreakdownValues]
        delete values.key
        newPriceBreakdownValues[roleIndex][key] = values
        setPriceBreakdownValues([...newPriceBreakdownValues])
      }

    } else {
      // add
      console.log('roleIndex newPriceBreakdownValues', roleIndex, priceBreakdownValues, priceBreakdownValues.length)

      if (priceBreakdownModal.isEcomStdPrice && ecomStdPriceBreakdownValues && setEcomStdPriceBreakdownValues) {
        // ecom std price
        let newPriceBreakdownValues = [...ecomStdPriceBreakdownValues, values]
        let isValid = validateQuantityRanges(newPriceBreakdownValues);
        if (!isValid) {
          message.warning(PRICE_VALIDATION_ERROR_TEXT)
          return;
        }
        setEcomStdPriceBreakdownValues([...newPriceBreakdownValues])

      } else {

        let newPriceBreakdownValues = [...priceBreakdownValues]
        let isValid = true

        if (roleIndex === priceBreakdownValues.length) {
          newPriceBreakdownValues.push([values])

        } else {
          const tempArr = [...newPriceBreakdownValues[roleIndex]]
          tempArr.push(values)
          newPriceBreakdownValues[roleIndex] = tempArr
        }
        isValid = validateQuantityRanges(newPriceBreakdownValues[0]);
        console.log('isValid', isValid)
        if (!isValid) {
          message.warning(PRICE_VALIDATION_ERROR_TEXT)
          return; // Exit the function if ranges are invalid
        }

        console.log('pass validation', newPriceBreakdownValues)
        setPriceBreakdownValues([...newPriceBreakdownValues])



      }

    }
    setPriceBreakdownModal((prev: any) => {
      return {
        ...prev,
        open: false,
        priceData: null,
        isEdit: false,
      }
    })

    console.log('handlePriceUpdate setPriceBreakdownModal')

  }

  console.log('setPriceBreakdownModal', priceBreakdownModal)

  console.log('priceBreakdownValues', priceBreakdownValues)

  const openAddPriceModal = (index?: number, isEcomStdPrice?: boolean) => {
    setPriceBreakdownModal({
      ...priceBreakdownModal,
      open: true,
      priceData: { measurement_unit: form.getFieldValue('measurement_unit') },
      roleIndex: index,
      isEcomStdPrice: Boolean(isEcomStdPrice)
    })
  }

  const openEditPriceModal = (record: any, roleIndex?: number, isEcomStdPrice?: boolean) => {
    setPriceBreakdownModal({
      ...priceBreakdownModal,
      open: true,
      priceData: record,
      roleIndex: roleIndex,
      isEdit: true,
      isEcomStdPrice: Boolean(isEcomStdPrice)
    })
  }

  const deletePriceRow = (record: any, roleIndex?: number) => {
    console.log('deletePriceRow', record, roleIndex)
    if (typeof roleIndex === 'number' && roleIndex >= 0) {
      setPriceBreakdownValues((prev: any[]) => {
        const newPriceBreakdownValues = [...prev]
        const key = record.key
        newPriceBreakdownValues[roleIndex].splice(key, 1)
        return newPriceBreakdownValues
      })
    } else if (setEcomStdPriceBreakdownValues) {
      // ecom std price
      setEcomStdPriceBreakdownValues((prev: any[]) => {
        const newPriceBreakdownValues = [...prev]
        const key = record.key
        newPriceBreakdownValues.splice(key, 1)
        return newPriceBreakdownValues
      })
    }

  }

  const deleteRole = (roleIndex: number) => {
    // update price break down values
    setPriceBreakdownValues((prev: any[]) => {
      const newPriceBreakdownValues = [...prev]
      newPriceBreakdownValues.splice(roleIndex, 1)
      return newPriceBreakdownValues
    })
  }

  // function to check, delete icon need to show or not
  const isAllowDelete = (fields: any[]) => {
    let allowDelete = false
    // by default - not delete
    // for ecomm, there must be at least one supplier
    // for 3pl, there must be at least one customer


    if (viewOnly) {
      allowDelete = false
    } else {
      if (isForSupplier) {
        if (isEcommProduct) {
          allowDelete = true
        } else if (is3PLProduct) {
          allowDelete = fields.length > 1
        } else if (isAcgProduct) {
          allowDelete = fields.length > 1
        } else if (isGeneralProduct) {
          allowDelete = fields.length > 1
        }
      } else {
        if (is3PLProduct) {
          allowDelete = fields.length > 1
        } else if (isEcommProduct) {
          allowDelete = true
        } else if (isAcgProduct) {
          allowDelete = fields.length > 1
        } else if (isGeneralProduct) {
          allowDelete = fields.length > 1
        }
      }
    }
    return allowDelete

  };

  return (
    <>
      <Form name={formName} form={form}>
        <div>
          <Form.List name={formListName}>
            {(fields, { add, remove }) => (
              <>
                <Row gutter={[15, 15]} style={{ marginBottom: '24px' }}>
                  <Col md={{ span: '4' }} sm={{ span: '4' }} xs={{ span: '4' }}>
                    <div style={{ fontSize: '20px', color: '#CCDDFF' }}>
                      {contentRenderer.renderRoleActionText()}
                    </div>
                  </Col>
                  <Col md={{ span: '20' }} sm={{ span: '20' }} xs={{ span: '20' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div style={{ fontSize: '14px' }}>
                        {contentRenderer.renderBasicInfo()}
                      </div>
                      {
                        !viewOnly &&
                        <SimpleButton
                          text={<BtnText><PlusOutlined />&nbsp;&nbsp; {contentRenderer.renderRoleActionText()}</BtnText>}
                          onClick={() => {
                            // add empty price breakdown
                            setPriceBreakdownValues((prev: any[]) => {
                              const newPriceBreakdownValues = [...prev]
                              newPriceBreakdownValues.push([])
                              return newPriceBreakdownValues
                            })
                            add()
                          }}
                        />
                      }

                    </div>
                  </Col>
                </Row>
                <hr />
                <ScrollableContainer>
                  <div style={{ background: '#29293d', padding: '20px 40px 20px 20px', marginBottom: '20px', }}>
                    {
                      isEcommProduct && !forSupplier && (
                        <Section label={`Standard Selling Price`}>
                          {
                            !viewOnly &&
                            <FlexWrapper flexEnd style={{ marginBottom: '10px' }}>
                              <SimpleButton
                                text={<BtnText><PlusOutlined />&nbsp;&nbsp; Add Price Breakdown</BtnText>}
                                onClick={() => openAddPriceModal(undefined, true)}
                                outlined
                              />
                            </FlexWrapper>
                          }
                          <CreateProductPriceTable
                            tableData={ecomStdPriceBreakdownValues || []}
                            onEdit={(record: any, rowIndex: number) => openEditPriceModal(record, undefined, true)}
                            onDelete={(record: any, rowIndex: number) => deletePriceRow(record)}
                            viewOnly={viewOnly}
                          />
                        </Section>
                      )
                    }
                    {
                      fields.map((field, index) => (
                        <div key={field.key} style={{ position: 'relative' }}>
                          <Section label={`${forSupplier ? 'Supplier' : 'Customer'} ${index + 1}`}>
                            {contentRenderer.renderRoleInputRow(formListName, field.name)}
                          </Section>
                          {
                            isAllowDelete(fields) &&
                            <DeleteOutlined
                              style={{ position: 'absolute', top: '40px', right: '10px', color: 'white', fontSize: '16px', cursor: 'pointer' }}
                              onClick={() => {
                                deleteRole(index)
                                remove(field.name)
                              }}
                            />
                          }

                          <Section label={`${forSupplier ? 'Cost Price' : 'Selling Price'}`}>
                            {
                              !viewOnly &&
                              <FlexWrapper flexEnd style={{ marginBottom: '10px' }}>
                                <SimpleButton
                                  text={<BtnText><PlusOutlined />&nbsp;&nbsp; Add Price Breakdown</BtnText>}
                                  onClick={() => openAddPriceModal(index)}
                                  outlined
                                />
                              </FlexWrapper>
                            }
                            <CreateProductPriceTable
                              tableData={priceBreakdownValues[index] || []}
                              onEdit={(record: any, rowIndex: number) => openEditPriceModal(record, index)}
                              onDelete={(record: any, rowIndex: number) => deletePriceRow(record, index)}
                              viewOnly={viewOnly}
                            />
                          </Section>
                        </div>
                      ))
                    }
                  </div>
                </ScrollableContainer>

              </>

            )}
          </Form.List>
          <Row gutter={[15, 15]} style={{ marginBottom: '24px', marginTop: '50px', justifyContent: 'end', marginRight: '1px' }}>
            <div
              style={{ display: 'flex', alignItems: 'center', fontSize: '16px', fontWeight: '500', cursor: 'pointer' }}
              onClick={goBack}
            >
              <LeftOutlined style={{ color: "#4E89FF" }} />
              <p style={{ marginBottom: '0px', marginLeft: '10px', color: 'var(--primary-color)' }}>Previous</p>
            </div>
            {
              (!viewOnly || !isLastStep) &&
              <SimpleButton
                style={{ marginLeft: '20px', fontSize: '16px' }}
                text={!isLastStep ? (
                  <>
                    <div style={{ display: 'flex' }}>
                      Next
                      <span style={{ marginTop: '2px', marginLeft: '10px' }}><RightOutlined /></span>
                    </div>
                  </>
                ) : (isEdit ? 'Save' : 'Create')}
                id='modal-btn-width-regular'
                htmlType='submit'
                form={formName}
              />
            }

          </Row>
        </div>
      </Form>
      <PriceBreakdownModal
        open={priceBreakdownModal.open}
        onClose={() => setPriceBreakdownModal({ ...priceBreakdownModal, open: false })}
        priceData={priceBreakdownModal.priceData}
        isEdit={priceBreakdownModal.isEdit}
        onFinish={(values: any) => handlePriceUpdate(values)}
        productType={productType}
        isForSupplier={isForSupplier}
      />
    </>
  )
}

// render content based on customer or supplier
const useContentRenderer = (form: any, forSupplier: boolean, viewOnly: boolean, isEdit: boolean, productData: any) => {
  const BIZ_CODE = getBizCode();
  const [roleOptions, setRoleOptions] = useState<any>({
    suppliers: [],
    customers: []
  })

  const [_, setRerender] = useState(false)

  // const isEcommProduct = useMemo(() => {
  //   return productType === PRODUCT_TYPE.eCommerceProduct.value
  // }, [form])

  const triggerRerender = () => {
    setRerender(prev => !prev)
  }

  useEffect(() => {
    getSupplierOptions()
    getCustomerOptions()
  }, [])

  const getSupplierOptions = () => {
    SupplierAPI.getAllSuppliers().then((res: any) => {
      if (Array.isArray(res)) {
        const suppliers = res.map((supplier: any) => {
          return { label: supplier.bus_name, value: supplier.supplier_ID }
        })
        setRoleOptions((prev: any) => ({
          ...prev,
          suppliers
        }))
      }
    }).catch(e => {
      console.log(e)
      // message.error('Failed to get supplier data.')
    })
  }

  const getCustomerOptions = () => {
    const url = `${BIZ_CODE}/Customer`
    CRM_API.get(url).then(res => {
      const result = res.data.Items
      const customers = result.map((customer: any) => {
        return { label: customer.bus_name, value: customer.customer_ID }
      })
      setRoleOptions((prev: any) => ({
        ...prev,
        customers
      }))
    }).catch(e => {
      console.log(e)
      // message.error('Failed to get customer data.')
    })
  }

  const renderRoleActionText = () => {
    if (forSupplier) return "Add Supplier"
    return "Assign Customer"
  }

  const getIndividualSupplierOptions = (formListName: string, fieldName: number) => {
    // remove the selected supplier from the list
    let selectedSuppliers = form.getFieldValue(formListName)?.map((item: any, index: number) => {
      if (index === fieldName) return null
      return item?.supplier_id
    })
    // remove null values
    selectedSuppliers = selectedSuppliers.filter((item: any) => item !== null)
    const filteredOptions = roleOptions.suppliers.filter((option: any) => {
      return !selectedSuppliers.includes(option.value)
    })
    return filteredOptions
  }

  const getIndividualCustomerOptions = (formListName: string, fieldName: number) => {
    // remove the selected customer from the list
    let selectedCustomers = form.getFieldValue(formListName)?.map((item: any, index: number) => {
      if (index === fieldName) return null
      return item?.customer_ID
    })
    // remove null values
    selectedCustomers = selectedCustomers.filter((item: any) => item !== null)
    const filteredOptions = roleOptions.customers.filter((option: any) => {
      return !selectedCustomers.includes(option.value)
    })
    return filteredOptions
  }

  const renderBasicInfo = () => {
    const DescriptionText = styled.p`
      margin-bottom: 0px;
      color: var(--white-text-color);
    `
    if (forSupplier) {
      return (
        <>
          <DescriptionText>Compare and analyze multiple supplier offerings, including delivery lead time,</DescriptionText>
          <DescriptionText>minimum order quantity and unit costs, all in one place to make informed decisions.</DescriptionText>
        </>
      )
    } else {
      return (
        <>
          <DescriptionText>Set desired unit price for each customer(Pharmacy/Distributor/Sponsor)</DescriptionText>
          <DescriptionText>for specific order quantities.</DescriptionText>
        </>
      )
    }
  }



  const renderRoleInputRow = (formListName: string, filedName: any) => {
    // only disable when view only
    let disabled = viewOnly

    if (forSupplier) {
      return (
        <Row gutter={[15, 15]} style={{ marginBottom: '24px' }}>
          <Col md={{ span: '6' }} sm={{ span: '24' }} xs={{ span: '24' }}>
            <Form.Item
              name={[filedName, 'supplier_id']}
              rules={[{ required: true, message: 'Please select supplier.' }]}
            >
              <Input
                type='select'
                showSearch
                label="Supplier"
                // options={roleOptions.suppliers} 
                options={getIndividualSupplierOptions(formListName, filedName)}
                placeholder={'Select Supplier'}
                disabled={disabled}
                onChange={triggerRerender}
                isGetPopupContainer={false}
                required
              />
            </Form.Item>
          </Col>
          <Col md={{ span: '6' }} sm={{ span: '24' }} xs={{ span: '24' }}>
            <Form.Item
              name={[filedName, 'supplier_id']}
            >
              <Input type='text' label="Supplier ID" placeholder={'Supplier ID'} disabled />
            </Form.Item>
          </Col>
          <Col md={{ span: '6' }} sm={{ span: '24' }} xs={{ span: '24' }}>
            <Form.Item
              name={[filedName, 'lead_delivery_time']}
              rules={[{ required: true, message: 'Please enter lead delivery time.' }]}
            >
              <Input type='number' label="Lead Delivery Time" placeholder={'Lead Delivery Time'} disabled={disabled} required />
            </Form.Item>
          </Col>
          <Col md={{ span: '6' }} sm={{ span: '24' }} xs={{ span: '24' }}>
            <Form.Item
              name={[filedName, 'lead_delivery_time_unit']}
              initialValue={UOT_OPTIONS[0].value}
            >
              <Input type='select' label="Lead Period" options={UOT_OPTIONS} disabled={disabled} required />
            </Form.Item>
          </Col>

        </Row>
      )
    } else {
      return (
        <Row gutter={[15, 15]} style={{ marginBottom: '24px' }}>
          <Col md={{ span: '6' }} sm={{ span: '24' }} xs={{ span: '24' }}>
            <Form.Item
              name={[filedName, 'customer_ID']}
              rules={[{ required: true, message: 'Please select customer.' }]}
            >
              <Input
                type='select'
                showSearch
                label="Customer"
                // options={roleOptions.customers} 
                options={getIndividualCustomerOptions(formListName, filedName)}
                onChange={triggerRerender}
                placeholder={'Select Customer'}
                disabled={disabled}
                // required={!isEcommProduct}
                required
              />
            </Form.Item>
          </Col>
        </Row>
      )
    }
  }



  return {
    renderRoleActionText,
    renderBasicInfo,
    renderRoleInputRow,
  }

}

export default AssignSupplierAndCustomer

const BtnText = styled.span`
  color: var(--white-text-color);
  display: flex;
  align-items: center;
`

const ScrollableContainer = styled.div`
  ${theme.myScrollbar}
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
  // Space for the scrollbar
  margin-right: -20px;
  padding-right: 20px;
`