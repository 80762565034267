import { DeleteOutlined } from '@ant-design/icons'
import { Col, Divider, Form, Row, message } from 'antd'
import SimpleButton from 'components/GlobalButtons/SimpleButton'
import Input from 'components/Input'
import { is } from 'date-fns/locale'
//import { update } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { FlexWrapper, SupplierPriceTable } from 'statics/styles/StyledComponents'
import styled from 'styled-components'
import { getSelectorOptions } from 'utilities/Functions/GlobalHelperFunctions'

type Props = {
  form: any
  initialValues?: any
  roleType: string
  setOrderPrice: any
  orderPrice: any
  selectedProdCatData: any
  setSelectedProdCatData: Function
  allProdCatData: any
  initialSelectedProdCatData: any
  isEdit: boolean
  isView: boolean
}

const ProductInfoPage = (props: Props) => {
  console.log('ProductInfoPage props', props.selectedProdCatData, props.isEdit, props.isView, props.setOrderPrice)
  const { form, roleType, orderPrice, setOrderPrice, selectedProdCatData, setSelectedProdCatData, allProdCatData, initialSelectedProdCatData, isEdit, isView } = props
  var detailValue = roleType === 'supplier' ? 'cost' : 'selling'

  useEffect(() => {
    const formValuesList = form.getFieldValue('product_info')
    console.log('formValuesList==>', formValuesList)
    if (!formValuesList || formValuesList.length === 0) {
      form.setFieldsValue({
        product_info: [
          {
            item_category: undefined,
            item_sub_category: undefined,
            item_name: undefined,
            quantity: undefined,
            sku: undefined,
            measurement_unit: undefined,
            cost_price: undefined,
          }
        ]
      })
    } else {
      if (isEdit || isView) {
        updateProductInfo(selectedProdCatData)
      }
    }

  }, [roleType])

  const updateHandlerChange = (itemIndex: number, type: 'category' | 'sub-category' | 'product') => {
    switch (type) {
      case 'category':
        const newResult = Array.isArray(selectedProdCatData) && selectedProdCatData.map((item: any, index: number) => {
          console.log('itemIndex==>', itemIndex, index)
          if (index === itemIndex) {
            return {
              ...item,
              selectedCat: '',
              subCatOpt: [],
              selectedSubCat: '',
              prodOpt: [],
              selectedProd: '',
              prodQuantity: '',
              prodSku: '',
              prodName: '',
              measurement_unit: '',
              prodCostPrice: '',
              prodRange: [
                {
                  min_moq: '',
                  max_moq: '',
                  cost_price: '',
                }
              ],
              minrange: '',
              maxrange: '',
              dataBasedproductSelection: {},
            }
          }
          return item
        })
        console.log('newResult==>', newResult)
        setSelectedProdCatData(newResult)
        updateProductInfo(newResult)
        break;
      case 'sub-category':
        const newResult1 = Array.isArray(selectedProdCatData) && selectedProdCatData.map((item: any, index: number) => {
          console.log('itemIndex==>', itemIndex, index)
          if (index === itemIndex) {
            return {
              ...item,
              selectedSubCat: '',
              prodOpt: [],
              selectedProd: '',
              prodQuantity: '',
              prodSku: '',
              prodName: '',
              measurement_unit: '',
              prodCostPrice: '',
              prodRange: [
                {
                  min_moq: '',
                  max_moq: '',
                  cost_price: '',
                }
              ],
              minrange: '',
              maxrange: '',
              dataBasedproductSelection: {},
            }
          }
          return item
        })
        console.log('newResult==>', newResult1)
        setSelectedProdCatData(newResult1)
        updateProductInfo(newResult1)
        break;
      case 'product':
        const newResult2 = Array.isArray(selectedProdCatData) && selectedProdCatData.map((item: any, index: number) => {
          console.log('itemIndex==>', itemIndex, index)
          if (index === itemIndex) {
            return {
              ...item,
              selectedProd: '',
              prodQuantity: '',
              prodSku: '',
              prodName: '',
              measurement_unit: '',
              prodCostPrice: '',
              prodRange: [
                {
                  min_moq: '',
                  max_moq: '',
                  cost_price: '',
                }
              ],
              minrange: '',
              maxrange: '',
              dataBasedproductSelection: {},
            }
          }
          return item
        }
        )
        console.log('newResult==>', newResult2)
        setSelectedProdCatData(newResult2)
        updateProductInfo(newResult2)
        break;
      default:
        break;
    }
  }

  const updateProductInfo = (newResult: any) => {
    let productInfo: any = []
    Array.isArray(newResult) && newResult.map((item: any, index: number) => {
      productInfo.push({
        item_category: item.selectedCat ? item.selectedCat : undefined,
        item_sub_category: item.selectedSubCat ? item.selectedSubCat : undefined,
        item_name: item.prodName ? item.prodName : undefined,
        sku: item.prodSku ? item.prodSku : undefined,
        measurement_unit: item.measurement_unit ? item.measurement_unit : undefined,
        quantity: item.prodQuantity ? item.prodQuantity : '',
        cost_price: item.prodCostPrice ? item.prodCostPrice : undefined,
      })
    })
    console.log('updateProductInfo productInfo==>', productInfo)

    form.setFieldsValue({
      product_info: [
        ...productInfo,
      ],
    })

    // calculate price and quantity
    calculatePriceQuantity(productInfo)

  }

  const calculatePriceQuantity = (productInfo: any) => {
    let pricesArray: any = []
    // set the product details and calculate the sub total price, gst, total price

    pricesArray = productInfo.map((item: any) => {
      return {
        price: item.cost_price ? item.cost_price : 0,
        quantity: item.quantity ? item.quantity : 1,
      }

    })
    console.log('pricesArray==>', pricesArray)

    const totalPrice = pricesArray.reduce((acc: any, item: any) => {
      return acc + (item.price * item.quantity)
    }
      , 0)

    console.log('totalPrice==>', totalPrice)
    // calculate the sub total price, gst, total price
    let gst: any = 0
    let mrpPrice: any = 0
    let gstprice: any = 0
    let finalTotalPrice: any = 0
    let finalPriceWithFixedDecimal: any = 0
    gst = totalPrice * 0.1

    // gstprice = gst.toFixed(2)
    finalTotalPrice = totalPrice * 1.1

    console.log('gst==>', gst, totalPrice, finalTotalPrice, orderPrice)

    setOrderPrice((prev: any) => {
      return {
        ...prev,
        prices: pricesArray,
        subTotalPrice: totalPrice.toFixed(2),
        gst: gst.toFixed(2),
        finalTotalPrice: finalTotalPrice.toFixed(2),
      }
    })
  }

  const categoryhandler = (e: any, itemIndex: number) => {
    console.log('categoryhandler', e, itemIndex, selectedProdCatData)
    const categoryValue = e;
    if (categoryValue != '') {
      // get the sub category list from allProdCatData if categoryValue is not empty, and match with the category
      const filteredCategoryListing = categoryValue != '' ? allProdCatData[categoryValue] : []
      console.log('filteredCategoryListing==>', filteredCategoryListing)
      // make a list of sub category from filteredCategoryListing
      let subCategoryList: any = []
      Array.isArray(filteredCategoryListing) && filteredCategoryListing.length > 0 && filteredCategoryListing.map((item: any) => {
        subCategoryList.push({
          value: item.item_sub_category,
          label: item.item_sub_category,
        })
      })
      console.log('subCategoryList==>', subCategoryList)
      // remove duplicacy of sub category list, if the sub category label and value already exist in subCategoryList
      subCategoryList = Array.isArray(subCategoryList) && subCategoryList.filter((item: any, index: number) => {
        const _item = JSON.stringify(item)
        return index === subCategoryList.findIndex((obj: any) => {
          return JSON.stringify(obj) === _item
        })
      })
      console.log('subCategoryList after filter==>', subCategoryList)

      const newResult = Array.isArray(selectedProdCatData) && selectedProdCatData.map((item: any, index: number) => {
        console.log('itemIndex==>', itemIndex, index)
        if (index === itemIndex) {
          return {
            ...item,
            selectedCat: categoryValue,
            selectedSubCat: '',
            subCatOpt: subCategoryList,
            dataBasedCategorySelection: filteredCategoryListing,
            prodOpt: [],
            selectedProd: '',
            prodQuantity: '',
            prodSku: '',
            prodName: '',
            measurement_unit: '',
            prodCostPrice: '',
            prodRange: [
              {
                min_moq: '',
                max_moq: '',
                cost_price: '',
              }
            ],
            minrange: '',
            maxrange: '',
            dataBasedproductSelection: {},
          }
        }
        return item
      })
      console.log('category newResult==>', newResult)
      setSelectedProdCatData(newResult)
      updateProductInfo(newResult)
    } else {
      console.log('categoryValue is empty')
      updateHandlerChange(itemIndex, 'category')
    }

  }

  const handleProductOpt = (subCategoryValue: string, type: 'add' | 'remove' | 'existing', itemIndex: number, itemsku?: string, categoryValue?: string) => {
    console.log('handleProductOpt==>', subCategoryValue, type, itemIndex, itemsku, selectedProdCatData)
    // get the values from the form.get values
    const values = form.getFieldValue('product_info');
    console.log('values==>', values)

    let selectedProdDataList = Array.isArray(values) && values.map((item: any) => {
      if (item.item_category === values[itemIndex]?.item_category && item.item_sub_category === subCategoryValue) {
        return item.sku
      }
    }) || []
    selectedProdDataList = selectedProdDataList.filter((item: any) => item)
    if (itemsku) {
      // if itemname is not present in the selectedProdDataList, then push it
      !selectedProdDataList.includes(itemsku) && selectedProdDataList.push(itemsku)
    }
    console.log('selectedProdDataList==>', selectedProdDataList)
    let productItemsList: any[] = []
    switch (type) {
      case 'add':
        Array.isArray(allProdCatData[selectedProdCatData[itemIndex].selectedCat]) && allProdCatData[selectedProdCatData[itemIndex].selectedCat].map((item: any) => {
          if (subCategoryValue === item.item_sub_category && !selectedProdDataList.includes(item.sku)) {
            productItemsList.push({
              value: item.sku,
              label: item.item_name,
            })
          }
        })
        console.log('productItemsList==>', productItemsList)
        const newResult = Array.isArray(selectedProdCatData) && selectedProdCatData.map((item: any, index: number) => {
          if (index === itemIndex) {
            return {
              ...item,
              prodOpt: productItemsList,
              selectedSubCat: subCategoryValue,
              selectedProd: '',
              prodQuantity: '',
              prodSku: '',
              prodName: '',
              measurement_unit: '',
              prodCostPrice: '',
              minrange: '',
              maxrange: '',
            }
          }
          return item
        })
        console.log('sub category newResult==>', newResult)
        setSelectedProdCatData(newResult)
        updateProductInfo(newResult)
        break;
      case 'remove':
        const newResultRemove = Array.isArray(selectedProdCatData) && selectedProdCatData.filter((item: any, index: number) => index !== itemIndex)
        console.log('new==>', newResultRemove, selectedProdCatData, itemsku, categoryValue)
        const itemName: string = itemsku && allProdCatData[categoryValue || selectedProdCatData[itemIndex].selectedCat].find((item: any) => item.sku === itemsku)?.item_name || ''
        if (itemsku) {
          console.log('remove==>', itemIndex, selectedProdCatData, itemName)
          Array.isArray(newResultRemove) && newResultRemove.forEach((item: any) => {
            if (item.selectedCat === selectedProdCatData[itemIndex].selectedCat && item.selectedSubCat === selectedProdCatData[itemIndex].selectedSubCat) {
              console.log('item==>', item)
              item.prodOpt.push({
                value: itemsku,
                label: itemName,
              })
            }
          })
          console.log('newResultRemove==>', newResultRemove)
        }
        setSelectedProdCatData(() => newResultRemove)
        updateProductInfo(newResultRemove)

        break;
      case 'existing':
        const productOptFromMasterData = Array.isArray(allProdCatData[selectedProdCatData[itemIndex].selectedCat]) && allProdCatData[selectedProdCatData[itemIndex].selectedCat].map((item: any) => {
          // return the item.value, if the item.item_sub_category is equal to subCategoryValue
          if (subCategoryValue === item.item_sub_category) {
            return {
              value: item.sku,
              label: item.item_name,
            }
          }
        })
        const filteredProductOptFromMasterData = Array.isArray(productOptFromMasterData) && productOptFromMasterData.filter((item: any) => item !== undefined)
        console.log('filteredProductOptFromMasterData==>', filteredProductOptFromMasterData)

        setSelectedProdCatData((prevState: any) => {
          const correctItems: any = Array.isArray(prevState) && prevState.filter((correctItem: any) => correctItem.selectedCat === prevState[itemIndex].selectedCat && correctItem.selectedSubCat === prevState[itemIndex].selectedSubCat)
          console.log('correctItems==>', correctItems)
          console.log('prevState==>', prevState)
          const newResults = Array.isArray(prevState) && prevState.map((prevStateItem: any, index: number) => {
            console.log('index==>', index, itemIndex, prevStateItem.selectedCat, prevStateItem.selectedSubCat, prevState[itemIndex].selectedCat, prevState[itemIndex].selectedSubCat, prevStateItem.selectedCat === prevState[index].selectedCat && prevStateItem.selectedSubCat === prevState[index].selectedSubCat)
            if (index === itemIndex || !(prevStateItem.selectedCat === prevState[itemIndex].selectedCat && prevStateItem.selectedSubCat === prevState[itemIndex].selectedSubCat)) {
              return {
                ...prevStateItem,
              }
            } else {
              // remove the selected selectedProdDataList options from the productOptFromMasterData
              const newOptions = Array.isArray(filteredProductOptFromMasterData) && filteredProductOptFromMasterData.filter((item: any) => {
                console.log('selectedProdDataList==>', selectedProdDataList, itemIndex)
                var itemValue = prevStateItem.selectedProd

                return !selectedProdDataList.includes(item.value) || item.value === correctItems[selectedProdDataList.indexOf(itemValue)]?.selectedProd
              })
              console.log('newOptions==>', newOptions)
              return { ...prevStateItem, prodOpt: newOptions }
            }
          })
          console.log('prevState newResults==>', newResults)
          return newResults
        })
        break;
      default:
        break;
    }
  }

  const subCategoryHandler = (e: any, itemIndex: number) => {
    console.log('subCategoryHandler', e, itemIndex, selectedProdCatData)
    const subCategoryValue = e;
    if (subCategoryValue != '') {
      handleProductOpt(subCategoryValue, 'add', itemIndex)
    } else {
      console.log('sub-category is empty')
      updateHandlerChange(itemIndex, 'sub-category')
    }
  }

  const productItemHandler = (e: any, itemIndex: number) => {
    console.log('productItemHandler', e, itemIndex, selectedProdCatData)

    const productValue = e;
    console.log('productValue==>', productValue)
    if (productValue != '') {
      handleProductOpt(selectedProdCatData[itemIndex].selectedSubCat, 'existing', itemIndex, productValue)
      setSelectedProdCatData((prev: any) => {
        let productDetails: any = {};
        Array.isArray(prev) && prev.map((item: any, index: number) => {
          if (itemIndex === index) {
            Array.isArray(item.dataBasedCategorySelection) && item.dataBasedCategorySelection.map((categoryItem: any) => {
              // if sub category and product value match with the item, then get the item details
              if (item.selectedSubCat === categoryItem.item_sub_category && productValue === categoryItem.sku) {
                productDetails = categoryItem
              }
            })
          }
        })
        console.log('productDetails==>', productDetails)
        const newResult = Array.isArray(prev) && prev.map((item: any, index: number) => {
          if (index === itemIndex) {
            return {
              ...item,
              selectedProd: productDetails?.sku,
              dataBasedproductSelection: productDetails,
              measurement_unit: productDetails?.measurement_unit,
              prodSku: productDetails?.sku,
              prodName: productDetails?.item_name,
              prodQuantity: '',
              prodCostPrice: '',
              prodRange: productDetails?.[`${roleType}_details`].map((item: any) => {
                const productRange = Array.isArray(item?.[`${detailValue}_price`]) && item?.[`${detailValue}_price`].map((item: any) => {
                  return {
                    min_moq: item.min_moq,
                    max_moq: item.max_moq,
                    cost_price: item?.cost_price,
                  }
                })
                return productRange
              }).flat(),
            }
          }
          return item
        })
        console.log('newResult==>', newResult)
        updateProductInfo(newResult)
        return newResult
      })

    } else {
      console.log('product is empty')
      updateHandlerChange(itemIndex, 'product')
    }

  }

  const quantityHandler = (e: any, itemIndex: number, type: string) => {
    const quantityValue = e.target.value;
    console.log('quantityHandler', quantityValue, itemIndex, selectedProdCatData)
    checkQuanityFallrange(quantityValue, itemIndex)
  }

  const checkQuanityFallrange = (quantityValue: any, itemIndex: number) => {
    console.log('checkQuanityFallrange', quantityValue, itemIndex, selectedProdCatData)
    let minMinMoq = Number.MAX_SAFE_INTEGER;
    let maxMaxMoq = Number.MIN_SAFE_INTEGER;
    let costPrice: any = '';
    let noRangeExist = false;

    const updateResult = () => {
      const newResult = Array.isArray(selectedProdCatData) && selectedProdCatData.map((item: any, index: number) => {
        if (index === itemIndex) {
          return {
            ...item,
            prodQuantity: quantityValue,
            prodCostPrice: costPrice.toString(),
            minrange: minMinMoq,
            maxrange: maxMaxMoq,
          }
        }
        return item;
      })
      console.log('updateResult newResult==>', newResult)
      setSelectedProdCatData(newResult)
      updateProductInfo(newResult)
    }

    if (quantityValue) {
      let breakfalse = false;
      for (let i = 0; i < selectedProdCatData.length; i++) {
        if (breakfalse) {
          break;
        }
        if (i === itemIndex) {
          for (let j = 0; j < selectedProdCatData[i].prodRange.length; j++) {
            const minMoq = parseInt(selectedProdCatData[i].prodRange[j].min_moq);
            const maxMoq = parseInt(selectedProdCatData[i].prodRange[j].max_moq);

            if (minMoq < minMinMoq) {
              minMinMoq = minMoq;
            }

            if (maxMoq > maxMaxMoq) {
              maxMaxMoq = maxMoq;
            }

            if (quantityValue >= minMoq && quantityValue <= maxMoq) {
              costPrice = selectedProdCatData[i].prodRange[j].cost_price;
              breakfalse = true;
              break;
            } else {
              console.log('eeeee', minMoq, maxMoq, quantityValue)
              costPrice = '';
              noRangeExist = true;
            }
          }
        }
      }

      console.log('values==>', minMinMoq, maxMaxMoq, costPrice, noRangeExist)

      if (costPrice !== '') {
        console.log('Cost price:', costPrice);
        updateResult()
      } else {
        if (noRangeExist) {
          message.warning({
            content: `Please enter a quantity within the range min: ${minMinMoq} and max: ${maxMaxMoq} allowed by the supplier`,
            duration: 5,
          });
          updateResult()
        }
      }
    } else {
      updateResult()
    }

  }

  const updateProductInfoOptions = (type: 'add' | 'remove', selctedItemIndex: number, selected?: any) => {
    console.log('selected', selected, selctedItemIndex)
    switch (type) {
      case 'add':
        // keep the existing selectedProdCatData and add new object
        const newResult = Array.isArray(selectedProdCatData) && selectedProdCatData.concat({ ...initialSelectedProdCatData })
        console.log('add newResult==>', newResult)

        setSelectedProdCatData(newResult)
        updateProductInfo(newResult)

        const newPrices = [...orderPrice.prices];
        newPrices.push({
          price: 0,
          quantity: 1,
        });

        setOrderPrice((prev: any) => {
          return {
            ...prev,
            prices: newPrices,
          }
        }
        )

        break;
      case 'remove':
        console.log('remove==>', selected?.item_sub_category, selctedItemIndex, selected, selectedProdCatData)
        if (!selected?.item_sub_category || !selected?.item_name || !selected?.item_category) {
          const newResultRemove = Array.isArray(selectedProdCatData) && selectedProdCatData.filter((item: any, index: number) => index !== selctedItemIndex)
          console.log('remove newResult==>', newResultRemove)
          setSelectedProdCatData(() => newResultRemove)
          updateProductInfo(newResultRemove)
          return;
        } else {
          handleProductOpt(selected?.item_sub_category, 'remove', selctedItemIndex, selected?.sku, selected?.category)
        }
        break;
      default:
        break;
    }
  }

  const renderOptions = (type: string, index?: any) => {
    if (type == 'category') {
      // keys of allProdCatData is the category list
      let categoryKeys = Object.keys(allProdCatData)

      // create value and label for categoryKeys
      let Categories: any = []
      Array.isArray(categoryKeys) && categoryKeys.length > 0 && categoryKeys.map((item: any) => {
        Categories.push({
          value: item,
          label: item,
        })
      })
      console.log('Categories==>', Categories, selectedProdCatData)
      return getSelectorOptions(Categories, 'Select Category', 'value', 'label')
    }
    else if (type == 'subCategory') {
      const subCategories = Array.isArray(selectedProdCatData) && selectedProdCatData.length > 0 ? selectedProdCatData[index].subCatOpt : []
      console.log('subCategories==>', subCategories, selectedProdCatData[index])
      return getSelectorOptions(subCategories, '', 'value', 'label')
    } else if (type == 'productItem') {
      const productList = Array.isArray(selectedProdCatData) && selectedProdCatData.length > 0 ? selectedProdCatData[index].prodOpt : []
      console.log('productList==>', productList)
      return getSelectorOptions(productList, '', 'value', 'label')
    }
    else {
      return getSelectorOptions([], 'Select')
    }
  }

  // console.log("formmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm", form);

  return (
    <>
      <Form.List name="product_info">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index: number) => (
              <div key={key} style={{ marginBottom: '10px' }} >
                <Divider orientation="left" orientationMargin="0">
                  <div style={{ width: '200px', textAlign: 'left', fontSize: '14px' }}>
                    Product {name + 1}
                  </div>
                </Divider>
                {
                  name > 0 &&
                  <DisabledForm isDisabled={isView ? true : false}>
                    <div style={{ float: 'right', marginTop: '5px', marginRight: '5px', zIndex: 1, position: 'relative' }}>
                      <DeleteOutlined
                        style={{ cursor: 'pointer', color: '#c6c6c6', fontSize: '15px' }}
                        onClick={(e: any) => {
                          e.preventDefault();
                          const removedProduct = form.getFieldValue('product_info')[name];
                          remove(name)
                          updateProductInfoOptions('remove', name, removedProduct);
                        }}
                      />
                    </div>
                  </DisabledForm>
                }
                <FlexWrapper flexEnd >
                  <DivWidthForModal>
                    <Row gutter={[15, 0]}>
                      <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                        <Form.Item
                          {...restField}
                          name={[name, 'item_category']}
                          rules={[
                            { required: true, message: 'Please select the Inventory Category.' },
                          ]}
                        // getValueFromEvent={(e: any) => {
                        //   console.log('e==>', e, name)
                        //   categoryhandler(e, name)
                        // }}
                        >
                          <Input
                            placeholder="Select Inventory Category"
                            label="Inventory Category"
                            name="item_category"
                            type="select"
                            options={renderOptions('category', name)}
                            onChange={(e: any) => {
                              console.log('e==>', e, name)
                              categoryhandler(e, name)
                            }}
                            disabled={isView ? true : false}
                            required
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                        <Form.Item
                          {...restField}
                          name={[name, "item_sub_category"]}
                          rules={[
                            { required: true, message: 'Please select the Inventory Subcategory.' },
                          ]}
                        // getValueFromEvent={(e: any) => {
                        //   console.log('e==>', e, name)
                        //   subCategoryHandler(e, name)
                        // }}
                        >
                          <Input
                            placeholder={"Select Inventory Subcategory"}
                            label="Inventory Subcategory"
                            type="select"
                            options={renderOptions('subCategory', name)}
                            onChange={(e: any) =>
                              subCategoryHandler(e, name)}
                            disabled={isView ? true : false}
                            required
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                        <Form.Item
                          {...restField}
                          name={[name, "item_name"]}
                          rules={[
                            { required: true, message: 'Please select the Inventory ID.' },
                          ]}
                        // getValueFromEvent={(e: any) => {
                        //   console.log('e==>', e, name)
                        //   productItemHandler(e, name)
                        // }}
                        >
                          <Input
                            label="Product Name"
                            placeholder={"Product Name"}
                            type="select"
                            options={renderOptions('productItem', name)}
                            onChange={(e: any) => productItemHandler(e, name)}
                            disabled={isView ? true : false}
                            required
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                        <Form.Item
                          {...restField}
                          name={[name, "sku"]}
                          initialValue={''}
                        >
                          <Input
                            label="Inventory ID"
                            placeholder={"Inventory ID"}
                            type="text"
                            disabled={true}
                          />

                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[15, 0]}>
                      <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                        <Form.Item
                          {...restField}
                          name={[name, "quantity"]}
                          initialValue={''}
                          rules={[
                            { required: true, message: 'Please enter the Quantity.' },
                          ]}
                        >
                          <Input
                            label="Quantity"
                            isGreaterthanOneRequired
                            type="number"
                            placeholder={'Enter Quantity'}
                            onChange={(e: any) => quantityHandler(e, name, 'quantity')}
                            disabled={isView || 
                              (!form.getFieldsValue(true).product_info[index].item_category ||
                              !form.getFieldsValue(true).product_info[index].item_sub_category ||
                              !form.getFieldsValue(true).product_info[index].item_name) ? true : false}
                            required
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                        <Form.Item
                          {...restField}
                          name={[name, "measurement_unit"]}
                          initialValue={''}
                        >
                          <Input
                            label="Unit of Measurement"
                            name="measurement_unit"
                            placeholder={'Select Unit of Measurement'}
                            type="text"
                            disabled={true}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={{ span: '4' }} md={{ span: '5' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                        <Form.Item
                          {...restField}
                          name={[name, "cost_price"]}
                          initialValue={''}
                          rules={[
                            { required: true, message: 'Please enter the Cost Price.' },
                          ]}
                        >
                          <Input
                            label="Cost Price"
                            type="text"
                            placeholder={'Cost Price'}
                            disabled={true}
                            required
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </DivWidthForModal>
                </FlexWrapper>
              </div>
            ))}
            <DisabledForm isDisabled={isView ? true : false}>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <SimpleButton
                  outlined
                  text="+ Add More"
                  onClick={(e: MouseEvent) => {
                    e.preventDefault();
                    const getProductItem = form.getFieldValue('product_info');
                    // items of product_info should not be empty
                    const isProductInfoEmpty = getProductItem.find((item: any) => {
                      return item.item_name == '' || item.cost_price == '' || item.quantity == ''
                    })
                    console.log('isProductInfoEmpty', isProductInfoEmpty)

                    if (isProductInfoEmpty) {
                      message.warning('Please complete product details before adding another item to your order');
                      return;
                    } else {
                      console.log('getProductItem', getProductItem)
                      add();
                      updateProductInfoOptions('add', fields.length, null);
                    }
                  }}
                />
              </div>
            </DisabledForm>
          </>
        )
        }
      </Form.List >
      <Divider orientation="left" orientationMargin="0">
        <div style={{ width: '200px', textAlign: 'left', fontSize: '14px' }}>{`Price Summary`}</div>
      </Divider>
      <FlexWrapper flexEnd >
        <DivWidthForModal>
          <PriceDiv>
            <div style={{ width: '200px', textAlign: 'left', fontSize: '14px', marginBottom: '15px' }}>{`Price Breakdown`}</div>
            <div>
              <SupplierPriceTable>
                <tbody>
                  {orderPrice?.prices?.map((product: any, index: number) => (
                    <tr key={index}>
                      <td>{`Product ${index + 1}`}</td>
                      <td><span>{product.price ? `$ ${(Number(product.price)).toFixed(2)}` : '$ 0.00'}</span></td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td>Subtotal</td>
                    <td><span>{`$ ${(Number(orderPrice.subTotalPrice)).toFixed(2)}`}</span></td>
                  </tr>
                  <tr>
                    <td>GST <span style={{ color: '#8F90A6' }}> (10%)</span></td>
                    <td><span>{`$ ${(Number(orderPrice.gst)).toFixed(2)}`}</span></td>
                  </tr>
                  <tr>
                    <td>Total</td>
                    <td><span>{`$ ${Number(orderPrice.finalTotalPrice).toFixed(2)}`}</span></td>
                  </tr>
                </tfoot>
              </SupplierPriceTable>
            </div>
          </PriceDiv>
        </DivWidthForModal>
      </FlexWrapper>
    </>
  )
}

export default ProductInfoPage

type purchaseCss = {
  isDisabled?: boolean;
};

const DivWidthForModal = styled.div`
          width: calc(100% - 212px);
          `;
const PriceDiv = styled.div`
          background-color: var(--modal-body-color);
          padding: 10px 36px 26px;
          color: var(--white-text-color);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          overflow-y: hindden;
          overflow-x: auto;
          ${(props) => props.theme.myScrollbar}
          `;
const DisabledForm = styled.div`
          pointer-events: ${(props: purchaseCss) => {
    return props.isDisabled ? 'none !important' : 'auto !important';
  }};
          `;