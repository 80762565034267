import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import InventoryManagement from "containers/Pages/InventoryManagement";
import { OSM_ROOT_PATH, OSM_ROUTES } from "containers/Pages/InventoryManagement/OSMRoutes";
import { Dashboard, Inventory, Purchase, QualityManagement } from "containers/Pages/InventoryManagement/pages";
import Category from "containers/Pages/InventoryManagement/pages/admin/Inventory/Category";
import CreateProduct from "containers/Pages/InventoryManagement/pages/admin/Inventory/CreateProduct";

const Right = styled.div`
  height: 100%;
  padding: 0 0.5em 0.5em 0.5em;
  border-radius: 5px;
  margin-right: 1em;
  margin-left: 1em;
`;


const PageLayout = ({ children }: { children: React.ReactNode }) => (
  <>
    <InventoryManagement />
    <Right>{children}</Right>
  </>
);

const OSMRouting = () => {
  return (
    <Routes>
      <Route
        path={OSM_ROOT_PATH}
        element={<Navigate to={`${OSM_ROOT_PATH}/dashboard`} />}
      />
      <Route
        path={OSM_ROUTES.dashboard}
        element={<PageLayout><Dashboard /></PageLayout>}
      />
      <Route
        path={OSM_ROUTES.procurement}
        element={<PageLayout><Purchase /></PageLayout>}
      />
      <Route
        path={OSM_ROUTES.qualityManagement}
        element={<PageLayout><QualityManagement /></PageLayout>}
      />
      <Route
        path={OSM_ROUTES.inventory}
        element={<PageLayout><Inventory /></PageLayout>}
      />
      <Route
        path={OSM_ROUTES.inventoryProductsCategory}
        element={<PageLayout><Category /></PageLayout>}
      />
      <Route
        path={OSM_ROUTES.inventoryCreateProduct}
        element={<PageLayout><CreateProduct /></PageLayout>}
      />

    </Routes>
  );
};

export default OSMRouting;