import React from "react";
import { DetailsPageContainer } from "../../components";
import { DetailsPageContext } from "../../context";
import { useNavigate } from "react-router-dom";
import { CRM_ROUTES } from "../../CRMRoutes";

const DetailsView = () => {
  const navigate = useNavigate();

  const switchToTableView = () => {
    navigate(CRM_ROUTES.CONTACT);
  };

  return (
    <DetailsPageContext>
      <DetailsPageContainer
      // onBackClick={switchToTableView}
      />
    </DetailsPageContext>
  );
};

export default DetailsView;
