import React, { useEffect, useState } from 'react'
import AdiTable from 'components/AdiTable'
import { ActionButton, DisplayEmptyText } from 'statics/styles/StyledComponents'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { TABLE_CELL_CONFIG, TABLE_ACTION_WIDTH, TABLE_PHONE_WIDTH, TABLE_DATE_WIDTH, TABLE_ACTIVE_STATUS_WIDTH } from 'utilities/CONSTANTS'
import moment from 'moment'
import { checkWindowSelectedText, includeColumn } from '../../utilities/functions'
import { formatTitle, initialCellValue } from 'utilities/Functions/FormatFunctions'
import { useAuthStore } from 'zustand-stores'
import { AuthStore } from 'zustand-stores/useAuthStore'

type Props = {
  data: any;
  actions: { edit: any, delete: any },
  onRowClick: Function,
  selectedTableColumns: any[]
  isLoading: boolean
}




const ContactPageTable = (props: Props) => {
  const { data, onRowClick, actions, isLoading } = props
  const isCrmReadonly = useAuthStore((state: AuthStore) => state.moduleAccess.crm.isReadOnly)
  const [tableData, setTableData] = useState<any>([])


  const fixedColumns = [
    {
      title: formatTitle("Full Name"),
      dataIndex: "full_name",
      key: "full_name",
      ...TABLE_CELL_CONFIG,
      sorter: (a: any, b: any) => a.full_name?.localeCompare(b.full_name),
      render: initialCellValue
    },

  ]

  const actionColumn = isCrmReadonly ? [] : [
    {
      title: formatTitle('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      align: 'center',
      width: 150,
      // render: (text: any, record: any, index: number) => (
      //   <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'center' }}>
      //     <ActionButton
      //       title="Edit"
      //       onClick={(e: any) => {
      //         e.preventDefault()
      //         e.stopPropagation()
      //         actions.edit(record)
      //       }}
      //     >
      //       <EditOutlined />
      //     </ActionButton>
      //     <ActionButton
      //       title="Delete"
      //       onClick={(e: any) => {
      //         e.preventDefault()
      //         e.stopPropagation()
      //         actions.delete(record)
      //       }}
      //     >
      //       <DeleteOutlined />
      //     </ActionButton>
      //   </div>
      // ),
      render: (text: any, record: any) => {
        const items = [
          {
            label: 'Convert',
            icon: <EditOutlined />,
            onClick: () => actions.edit(record),
          },
          {
            label: 'Delete',
            icon: <DeleteOutlined />,
            onClick: () => actions.delete(record),
          },
        ];

        console.log('itemsaaaa', items);

        return <AdiTable.ActionDropdown menuItems={items} />;
      },
    }
  ]

  const flexibleColumns = [
    {
      title: formatTitle("Job Title"),
      dataIndex: "job_title",
      key: "job_title",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue
    },
    {
      title: formatTitle("Company Name"),
      dataIndex: "bus_name",
      key: "bus_name",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue
    },
    {
      // this is fixed column
      title: formatTitle('Email'),
      dataIndex: 'email',
      key: 'email',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue
    },
    {
      title: formatTitle('Phone Number'),
      dataIndex: 'phone',
      key: 'phone',
      width: TABLE_PHONE_WIDTH,
      ellipsis: true,
      render: initialCellValue
    },
    {
      title: formatTitle('Created On'),
      dataIndex: 'creation_date',
      key: 'creation_date',
      width: TABLE_DATE_WIDTH,
      ellipsis: true,
      // align: 'center',
      sorter: (a: any, b: any) => a.creation_date?.localeCompare(b.creation_date),
      render: (text: string) => text ? moment(text).format('DD/MM/YYYY') : initialCellValue(text)
    },
    {
      title: formatTitle('Status'),
      dataIndex: 'active',
      key: 'active',
      width: TABLE_ACTIVE_STATUS_WIDTH,
      ellipsis: true,
      // align: 'center',
      filters: [
        { text: 'Active', value: true },
        { text: 'Inactive', value: false },
      ],
      onFilter: (value: string | number | boolean, record: any) => record.active === value,
      render: (status: boolean) => status === false ? 'Inactive' : 'Active'
    },

  ]

  const newColArray = flexibleColumns.filter((item: any) => {
    if (includeColumn(props.selectedTableColumns, item.dataIndex)) {
      return item
    }
    if (item.key === 'email') {
      // email is fixed, but it shouldn't be next to the name
      return item
    }
  })

  const finalTableColumns = [
    ...fixedColumns,
    ...newColArray,
    ...actionColumn
  ]

  // convert to table data
  useEffect(() => {
    // add key to each item
    let tableData: any = Array.isArray(data) ? data.map((item: any, index: number) => {
      return {
        ...item,
        key: index
      }
    }) : []

    // default sort - by creation date
    tableData.sort((a: any, b: any) => {
      return new Date(b.creation_date).getTime() - new Date(a.creation_date).getTime()
    })

    setTableData(tableData)
  }, [data])



  return (
    !isLoading && tableData.length === 0 ?
      <DisplayEmptyText>
        No Contact data to display.
      </DisplayEmptyText>
      :
      <AdiTable
        loading={isLoading}
        className=''
        columns={finalTableColumns}
        tableData={tableData}
        fullWidth
        marginTop='20px'
        scroll={{ x: '100%', y: '70vh' }}
        onRowClick={(record: any, rowIndex: any, event: any) => {
          // prevent row click if trying to select text
          if (checkWindowSelectedText()) return
          onRowClick(record)
        }}
        extraPaginationPadding
      // sticky // will break the layout
      />

  )
}



export default ContactPageTable
