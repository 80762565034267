import React, { useState } from 'react'
import styled from 'styled-components'
import SimpleButton from '../SimpleButton'
import { LayoutOutlined } from '@ant-design/icons'
import { Dropdown, Menu } from 'antd'
import { formatTitle } from 'utilities/Functions/FormatFunctions'
import Input from 'components/Input'

export type ColumnOptionsType = {
  text: string;
  valueKey: string;
}[]

type Props = {
  updateSelectedColumns: Function;
  selectedColumns: string[];
  columnOptions: ColumnOptionsType;
}

const ColumnSelectButton = (props: Props) => {
  const { updateSelectedColumns, selectedColumns, columnOptions } = props
  const [dropdownVisible, setDropdownVisible] = useState(false)


  const handleMenuClick = (e: any) => {
    const value = e.key
    let newSelectedColumns = [...selectedColumns]

    if (newSelectedColumns.includes(value)) {
      // remove from selected columns
      newSelectedColumns = newSelectedColumns.filter((item: any) => item !== value)
    } else {
      // add to selected columns based on its index in columnOptions
      const index = columnOptions.findIndex((item: any) => item.valueKey === value)
      newSelectedColumns.splice(index, 0, value)
    }
    console.log('newSelectedColumns', newSelectedColumns)
    // update columns
    updateSelectedColumns(newSelectedColumns)
  };

  const MenuItem = ({ text, valueKey }: { text: string, valueKey: string }) => {
    return (
      <StyledCheckbox>
        <Input
          type='checkbox'
          label={formatTitle(text)}
          style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}
          checked={selectedColumns.includes(valueKey)}
        />
      </StyledCheckbox>
    )
  }


  const renderColumnMenu = (data: { text: string, valueKey: string }[]) => {
    return (
      <Menu onClick={handleMenuClick}>
        {
          data.map((item) => {
            return (
              <Menu.Item key={item.valueKey}><MenuItem text={item.text} valueKey={item.valueKey} /></Menu.Item>
            )
          })
        }
      </Menu>
    )
  }

  return (
    <Dropdown getPopupContainer={trigger => trigger.parentNode as HTMLElement}
      visible={dropdownVisible}
      overlay={renderColumnMenu(columnOptions)}
      trigger={['click']}
      onVisibleChange={(visible: boolean) => {
        // Update the dropdown visibility state, prevent the dropdown from closing
        setDropdownVisible(visible);
      }}
    >
      <StyledButton
        outlined
        text={<BtnText><LayoutOutlined />&nbsp; Edit Columns</BtnText>}
      />
    </Dropdown>
  )
}

export default ColumnSelectButton

const StyledButton = styled(SimpleButton)`
  border-color: var(--input-border-color) !important;
  &:hover {
    span {
      color: var(--white-text-color) !important;
    }
  }
`

const BtnText = styled.span`
  color: var(--white-text-color);
  display: flex;
  align-items: center;
`
const StyledCheckbox = styled.div`
label {
  width: 100% !important;
}
`