import React, { useEffect, useState } from "react";
import PageTabs from "components/PageTabs";
import OSMRoutes, {
  OSM_ROUTES,
  InventoryRoutes,
} from "containers/Pages/InventoryManagement/OSMRoutes";
import AllInventory from "./AllInventory";
import BatchTracking from "./BatchTracking";
import Items from "./Items";
import { useLocation, useNavigate } from "react-router-dom";
import { SPM_ROUTES } from "containers/Pages/StockAndProductionManagement/SPMRoutes";

export const TABS = {
  productListing: "Products",
  batchTracking: "Batch Tracking",
  stockOverview: "Stock Overview",
};

const Inventory = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [subPageTab, setSubPageTab] = useState("");
  const isTableView = location.pathname === OSM_ROUTES.inventory || location.pathname === SPM_ROUTES.inventory;

  console.log("subPageTab", subPageTab, isTableView, location);

  // initialize the active tab
  useEffect(() => {
    if (location.pathname === OSM_ROUTES.inventoryCreateProduct || location.pathname === SPM_ROUTES.inventoryProductsCategory) {
      // handle navigate to create product page from CMS module
      setSubPageTab(TABS.productListing);
    } else {
      setSubPageTab(TABS.productListing);
    }
  }, []);

  // navigate back from sub-pages under product listing, update the the route
  useEffect(() => {
    if (
      !isTableView &&
      (subPageTab === TABS.stockOverview || subPageTab === TABS.batchTracking)
    ) {

      navigate({
        pathname: OSM_ROUTES.inventory || SPM_ROUTES.inventory,
      })
    }
  }, [isTableView, subPageTab]);

  const tabs = Object.values(TABS).map((tab: any) => {
    return {
      text: tab,
      onClick: () => setSubPageTab(tab),
      active: subPageTab === tab,
    };
  });

  // Only table view pages
  const renderTabPages = () => {
    const pages = [
      {
        tab: TABS.productListing,
        component: <Items active={TABS.productListing === subPageTab} />,
      },
      {
        tab: TABS.batchTracking,
        component: <BatchTracking active={TABS.batchTracking === subPageTab} />,
      },
      {
        tab: TABS.stockOverview,
        component: <AllInventory active={TABS.stockOverview === subPageTab} />,
      },
    ];

    return pages.map((page, index) => {
      return (
        <div
          key={index}
          style={{ display: page.tab === subPageTab ? "block" : "none" }}
        >
          {page.component}
        </div>
      );
    });
  };

  return (
    <>
      <PageTabs tabs={tabs} isSubTabs />
      {isTableView && renderTabPages()}
      {/* Routes for sub-pages */}
      <InventoryRoutes />
    </>
  );
};

export default Inventory;
