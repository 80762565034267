import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AddressBook, Dashboard, FoB, Logs } from './pages';

export const VISITOR_MANAGEMENT_ROOT_PATH = '/app/visitor-management';

const VisitorManagementRoutes = () => {
  return (
    <Routes>
      <Route path={`${VISITOR_MANAGEMENT_ROOT_PATH}`} element={<Navigate to={`${VISITOR_MANAGEMENT_ROOT_PATH}/dashboard`} />} />
      <Route path={`${VISITOR_MANAGEMENT_ROOT_PATH}/dashboard`} element={<Dashboard />} />
      <Route path={`${VISITOR_MANAGEMENT_ROOT_PATH}/logs`} element={<Logs />} />
      <Route path={`${VISITOR_MANAGEMENT_ROOT_PATH}/address-book`} element={<AddressBook />} />
      <Route path={`${VISITOR_MANAGEMENT_ROOT_PATH}/fob`} element={<FoB />} />
    </Routes>
  );
};

export default VisitorManagementRoutes;
