import { InfoCircleFilled } from "@ant-design/icons";
import { Descriptions, Form, message } from "antd";
import AdiTable from "components/AdiTable";
import SimpleButton from "components/GlobalButtons/SimpleButton";
import GlobalModal from "components/GlobalModal";
import Input from "components/Input";
import SimpleDescriptions from "components/SimpleDescriptions";
import { UserAPI } from "containers/Pages/RelationshipManagement/apis";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";

import { FlexWrapper } from "statics/styles/StyledComponents";

import styled from "styled-components";
import {
  DATE_FORMAT,
  SESSION_STORAGE_KEYS,
  TABLE_CELL_CONFIG,
} from "utilities/CONSTANTS";
import { formatTitle } from "utilities/Functions/FormatFunctions";
import {
  convertDateAndTime,
  getSessionStorage,
} from "utilities/Functions/GlobalHelperFunctions";
import { PRODUCT_TYPE } from "../../statics/constants";
import GeneratePicklistAPI from "../../apis/GeneratePicklistAPI";
import { useGeneralStore } from "zustand-stores";
import { GeneralStore } from "zustand-stores/useGeneralStore";
import { OrderAPI } from "../../apis";

type Props = {
  open: boolean;
  onClose: () => void;
  viewOnly?: boolean;
  picklistData: any;
  onSuccess: () => void;
  productType?: string;
};

const PicklistDetailsModal = (props: Props) => {
  const { open, onClose, viewOnly, picklistData, onSuccess, productType } =
    props;
  console.log("PicklistDetailsModal picklistData", picklistData);

  const setIsGlobalLoading = useGeneralStore(
    (state: GeneralStore) => state.setIsGlobalLoading
  );
  const [form] = Form.useForm();
  const username = getSessionStorage(SESSION_STORAGE_KEYS.USER_NAME);
  const isAcgProduct = productType === PRODUCT_TYPE.acgProduct.value;
  const [userOptions, setUserOptions] = useState<any[]>([]);

  const [toggle, setToggle] = useState(false);

  const rerender = () => {
    setToggle((prev: boolean) => !prev);
  };

  useEffect(() => {
    // get user options
    getUserOptions();
  }, []);

  const getUserOptions = async () => {
    setIsGlobalLoading(true);
    try {
      const users = await UserAPI.getAllUsers();
      const usernameOptions = users.map((item: any) => {
        return {
          value: item.user_ID,
          label: item.full_name,
        };
      });

      console.log("usernameOptions ==> ", users, usernameOptions);

      setUserOptions(usernameOptions);
    } catch (e) {
      console.log("getUserOptions error ==> ", e);
    } finally {
      setIsGlobalLoading(false);
    }
  };

  const displayTableData = useMemo(() => {
    const orders = picklistData?.orders_for_picklist_generation || [];

    const data = orders.map((order: any) => {
      // for order table
      const orderInfo = {
        orderId: order.order_ID,
        orderTableData:
          order?.allocated_picklist?.map(
            (allocatedPicklist: any, index: number) => {
              return {
                key: index,
                item_name: allocatedPicklist.item_name,
                sku: allocatedPicklist.sku,
                quantity_ordered: allocatedPicklist.quantity_ordered,
                measurement_unit: allocatedPicklist.measurement_unit,
                expiry_date: moment(allocatedPicklist?.expiry_date),
              };
            }
          ) || [],
      };
      return orderInfo;
    });
    return data;
  }, [picklistData]);

  const closeModal = () => {
    onClose();
  };

  const processTablePayload = (values: any) => {
    console.log("values", values);
    const dates = values?.expiry_date_collection || [];
    let orders = picklistData?.orders_for_picklist_generation ? [...picklistData.orders_for_picklist_generation] : [];
    // let orders = [...picklistData?.orders_for_picklist_generation] || [];

    // for each product in each order, assign corresponding expiry date
    orders.forEach((order: any, orderIndex: number) => {
      const orderTableData = order?.allocated_picklist || [];
      orderTableData.forEach((product: any, productIndex: number) => {
        const expiryDate = dates[orderIndex][productIndex];
        product.expiry_date = convertDateAndTime(expiryDate);
      });
    });

    return orders;
  };

  const formOnFinish = async (values: any) => {
    console.log("formOnFinish", values, picklistData);
    const {
      assigned_to,
      picked_by,
      approver_one,
      approver_two,
      batch_number,
      processing_line,
    } = values;
    let payload = {};
    if (isAcgProduct) {
      const newOrders = processTablePayload(values);
      payload = {
        ...payload,
        picklist_ID: picklistData?.picklist_ID,
        orders_for_picklist_generation: newOrders,
        picklist_status: "picked",
        assigned_to,
        picked_by,
        approver_one,
        approver_two,
        batch_number,
        processing_line,
        picked_date: convertDateAndTime(moment()), // should be today
        manufacturing_date: convertDateAndTime(values.manufacturing_date),
      };
    } else {
      payload = {
        ...picklistData,
        // orders_for_picklist_generation: newOrders,
        picklist_status: "picked",
        assigned_to,
        picked_by,
        approver_one,
        approver_two,

        picked_date: convertDateAndTime(moment()), // should be today
      };
    }
    // const newOrders = processTablePayload(values)
    // const payload = {
    //   ...picklistData,
    //   orders_for_picklist_generation: newOrders,
    //   picklist_status: 'picked',
    //   assigned_to,
    //   picked_by,
    //   approver_one,
    //   approver_two,
    //   batch_number,
    //   processing_line,
    //   picked_date: convertDateAndTime(moment()),  // should be today
    //   manufacturing_date: convertDateAndTime(values.manufacturing_date),
    // }

    console.log("formOnFinish payload", payload);

    try {
      setIsGlobalLoading(true);
      const res = await GeneratePicklistAPI.updatePicklist(payload);
      if (res?.data?.statusCode === "401") {
        throw {
          message: res?.data?.body?.status,
          statusCode: 401,
        };
      }
      message.success({
        content: "Picklist updated successfully!",
        onClose: onSuccess,
        duration: 1,
      });
    } catch (e) {
      const err: any = e;
      console.log("formOnFinish error", e);
      if (err?.statusCode === 401 && err?.message) {
        message.error(err?.message);
      } else {
        message.error("Failed to update picklist.");
      }
    } finally {
      setIsGlobalLoading(false);
    }
  };

  const renderPicklistDetails = () => {
    return (
      <div>
        <StyledDescriptions title="Picklist Details" bordered column={1}>
          <Descriptions.Item label="PICKLIST NO.">
            {picklistData?.picklist_ID}
          </Descriptions.Item>
          <Descriptions.Item label="PICKLIST TYPE">
            Production Work Order
          </Descriptions.Item>
          <Descriptions.Item label="CREATED ON">
            {picklistData?.created_on
              ? moment(picklistData?.created_on).format(DATE_FORMAT)
              : ""}
          </Descriptions.Item>
          <Descriptions.Item label="CREATED BY">
            {picklistData?.created_by}
          </Descriptions.Item>
          <Descriptions.Item label="ASSIGNED TO">
            <Form.Item
              name="assigned_to"
              style={{ margin: 0 }}
              rules={[{ required: true, message: "" }]}
            >
              <Input type="select" options={userOptions} />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="TOTAL ORDERS">
            {picklistData?.total_orders}
          </Descriptions.Item>
        </StyledDescriptions>
      </div>
    );
  };

  const renderActionDetails = () => {
    return (
      <div>
        <StyledDescriptions title="Picklist Details" bordered column={1}>
          <Descriptions.Item label="PICKED BY">
            <Form.Item
              name="picked_by"
              style={{ margin: 0, minWidth: "150px" }}
              rules={[{ required: true, message: "" }]}
            >
              <Input type="select" options={userOptions} />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="PICKED ON">
            {moment(picklistData?.picked_date || undefined).format(DATE_FORMAT)}
          </Descriptions.Item>
          <Descriptions.Item label="APPROVER 1">
            <Form.Item
              name="approver_one"
              style={{ margin: 0 }}
              rules={[{ required: true, message: "Please select approver 1" }]}
              getValueFromEvent={(value: string) => {
                // force re-render to update the options of the other approver
                rerender();
                return value;
              }}
            >
              <Input
                type="select"
                options={userOptions.filter(
                  (item: any) =>
                    item.value !== form.getFieldValue("approver_two")
                )}
              />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="APPROVER 2">
            <Form.Item
              name="approver_two"
              style={{ margin: 0 }}
              rules={[{ required: true, message: "Please select approver 2" }]}
              getValueFromEvent={(value: string) => {
                // force re-render to update the options of the other approver
                rerender();
                return value;
              }}
            >
              <Input
                type="select"
                options={userOptions.filter(
                  (item: any) =>
                    item.value !== form.getFieldValue("approver_one")
                )}
              />
            </Form.Item>
          </Descriptions.Item>
        </StyledDescriptions>
      </div>
    );
  };

  const renderFooter = () => (
    <FlexWrapper style={{ justifyContent: "flex-end" }} key={1}>
      <SimpleButton
        id="modal-btn-width-regular"
        text="Cancel"
        isCancel
        onClick={closeModal}
      />
      {!viewOnly && (
        <SimpleButton
          id="modal-btn-width-regular"
          text="Save"
          htmlType="submit"
          form={`picklist-order-form`}
        />
      )}
    </FlexWrapper>
  );

  return (
    <GlobalModal
      title={`Picklist - ${picklistData?.picklist_ID || ""}`}
      open={open}
      onCancel={closeModal}
      footer={[renderFooter()]}
      xlarge
    // width='800px'
    >
      <Form
        form={form}
        name={"picklist-order-form"}
        onFinish={formOnFinish}
        initialValues={{
          ...picklistData,
          assigned_to: picklistData?.assigned_to || username,
          picked_by: picklistData?.picked_by || username,
          picked_date: moment(picklistData?.picked_date || undefined).format(
            DATE_FORMAT
          ),
          manufacturing_date: moment(
            picklistData?.manufacturing_date || undefined
          ),
        }}
      >
        <FlexWrapper gap="40px" flexStart alignStart>
          {renderPicklistDetails()}
          {renderActionDetails()}
        </FlexWrapper>
        {isAcgProduct && <BatchDetails form={form} />}
        {Array.isArray(displayTableData) &&
          [...displayTableData].map((order: any, orderIndex: number) => {
            const tableColumns = [
              ...columns,
              {
                title: formatTitle("Expiry Date"),
                dataIndex: "expiry_date",
                key: "expiry_date",
                ...TABLE_CELL_CONFIG,
                render: (text: any, record: any, index: number) => {
                  return isAcgProduct ? (
                    <Form.Item
                      style={{ margin: 0 }}
                      name={["expiry_date_collection", orderIndex, index]}
                      rules={[
                        {
                          required: true,
                          message: "Please select Expiry Date.",
                        },
                      ]}
                      initialValue={moment(record.expiry_date)}
                    >
                      <Input type="date" />
                    </Form.Item>
                  ) : (
                    moment(record?.expiry_date || "").format(DATE_FORMAT)
                  );
                },
              },
            ];

            return (
              <div>
                <LabelHeading>
                  Order {orderIndex + 1}: {order?.orderId}
                </LabelHeading>
                <StyledTable
                  columns={tableColumns}
                  tableData={order?.orderTableData || []}
                  fullWidth
                  noPagination
                  marginTop="0"
                />
              </div>
            );
          })}
      </Form>
    </GlobalModal>
  );
};

export default PicklistDetailsModal;

const BatchDetails = ({ form }: { form: any }) => {

  // Function to fetch the batch number from the API
  const fetchBatchNumber = async () => {
    try {
      const res = await OrderAPI.getBatchNumberIncrement();
      console.log('Batch Number response:', res);
      return res || ''; // Fallback to an empty string if the response is falsy
    } catch (error) {
      console.error('Error fetching Batch Number:', error);
      return ''; // Return empty string on error
    }
  };

  // Fetch the batch number and set it to the form field
  useEffect(() => {
    const initializeBatchNumber = async () => {
      const batchNumber = await fetchBatchNumber();
      form.setFieldsValue({ batch_number: batchNumber }); // Set batch_number field
    };

    initializeBatchNumber();
  }, [form]);

  return (
    <div>
      <LabelHeading>Add Batch Details</LabelHeading>
      <BatchDetailsContainer>
        <FlexWrapper gap="40px" alignStart>
          <FlexWrapper gap="5px" alignStart style={{ maxWidth: "50%" }}>
            <InfoCircleFilled className="info-icon" />
            <ul>
              <li>
                All the products from each order of the picklist will share same
                Manufacturing date, Batch No and Processing line.{" "}
              </li>
              <li>
                Please add Expiry Date for each Individual Inventory ID from all
                the orders in the below Data table.{" "}
              </li>
              <li>Prepopulated Expiry dates are system predicted. </li>
            </ul>
          </FlexWrapper>
          <FlexWrapper gap="10px">
            <Form.Item
              name="batch_number"
              rules={[{ required: true, message: "Please enter Batch No." }]}
            >
              <Input label="Batch No." type="text" placeholder="Batch No." />
            </Form.Item>
            <Form.Item
              name="processing_line"
              rules={[
                { required: true, message: "Please enter Processing Line." },
              ]}
            >
              <Input
                label="Processing Line"
                type="text"
                placeholder="Processing Line"
              />
            </Form.Item>
            <Form.Item
              name="manufacturing_date"
              rules={[
                {
                  required: true,
                  message: "Please select Manufacturing Date.",
                },
              ]}
            >
              <Input label="Manufacturing Date" type="date" />
            </Form.Item>
          </FlexWrapper>
        </FlexWrapper>
      </BatchDetailsContainer>
    </div>
  );
};

const columns = [
  {
    title: formatTitle("S. No."),
    dataIndex: "key",
    key: "key",
    width: 100,
    render: (text: any, record: any, index: number) => index + 1,
  },
  {
    title: formatTitle("Product Name"),
    dataIndex: "item_name",
    key: "item_name",
    ...TABLE_CELL_CONFIG,
  },
  {
    title: formatTitle("Inventory ID"),
    dataIndex: "sku",
    key: "sku",
    ...TABLE_CELL_CONFIG,
    width: 160,
  },
  {
    title: formatTitle("Quantity"),
    dataIndex: "quantity_ordered",
    key: "quantity_ordered",
    ...TABLE_CELL_CONFIG,
    width: 100,
  },
  {
    title: formatTitle("UoM"),
    dataIndex: "measurement_unit",
    key: "measurement_unit",
    ...TABLE_CELL_CONFIG,
    width: 100,
  },
];

const BatchDetailsContainer = styled.div`
  padding: 20px 10px;
  background-color: #31324a;

  .info-icon {
    font-size: 18px;
    color: #577cfb;
  }

  ul,
  li {
    color: var(--white-text-color);
  }
`;

const StyledDescriptions = styled(SimpleDescriptions)`
  .ant-descriptions-title {
    font-size: 14px !important;
  }
  .ant-descriptions-item-label {
    font-size: 12px !important;
  }
  .ant-descriptions-item-content {
    font-size: 12px !important;
  }
`;

const LabelHeading = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: var(--white-text-color);

  margin: 20px 0;
`;
const DESCRIPTION_BORDER_STYLE = "1px solid rgba(85, 87, 112, 0.60) !important";
const StyledTable = styled(AdiTable)`
  border: ${DESCRIPTION_BORDER_STYLE};
  .ant-table-thead > tr > .ant-table-cell {
    border-top: ${DESCRIPTION_BORDER_STYLE};
    border-bottom: ${DESCRIPTION_BORDER_STYLE};
    border-bottom: none;
    background-color: #29293d !important;
  }
  .ant-table-tbody > tr > td {
    border-top: ${DESCRIPTION_BORDER_STYLE};
    border-bottom: ${DESCRIPTION_BORDER_STYLE};
    background-color: #29293d !important;
  }
  .ant-table-tbody > tr {
    :hover {
      td {
        background-color: #29293d !important;
      }
    }
  }
`;
