import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom'; // Import Navigate
import ProductsPartners from '../Products&partners';
import KeyManagement from '../KeyManagement';
import UserManagement from './UserManagement';

export const ADMIN_ROOT_PATH = '/app/admin';

const AdminRoutes = () => {
  return (
    <Routes>
      <Route
        path={ADMIN_ROOT_PATH}
        element={<Navigate to={`${ADMIN_ROOT_PATH}/user-management`} />} // Use Navigate instead of Redirect
      />
      <Route path={`${ADMIN_ROOT_PATH}/user-management`} element={<UserManagement />} />
      <Route path={`${ADMIN_ROOT_PATH}/product-and-partners`} element={<ProductsPartners />} />
      <Route path={`${ADMIN_ROOT_PATH}/adi-key-management`} element={<KeyManagement />} />
    </Routes>
  );
};

export default AdminRoutes;
