import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ADMIN_ROOT_PATH } from "containers/Pages/Admin/AdminRoutes";
import Admin from "containers/Pages/Admin";
import UserManagement from "containers/Pages/Admin/UserManagement";
import ProductsPartners from "containers/Pages/Products&partners";
import KeyManagement from "containers/Pages/KeyManagement";
import styled from "styled-components";

const Right = styled.div`
  height: 100%;
  padding: 0 0.5em 0.5em 0.5em;
  border-radius: 5px;
  margin-right: 1em;
  margin-left: 1em;
`;

const AdminRouting = () => {
  return (
    <Routes>
      <Route
        path={ADMIN_ROOT_PATH}
        element={<Navigate to={`${ADMIN_ROOT_PATH}/user-management`} />}
      />
      <Route
        path={`${ADMIN_ROOT_PATH}/user-management`}
        element={
          <>
            <Admin />
            <Right>
              <UserManagement />
            </Right>
          </>
        }
      />
      <Route
        path={`${ADMIN_ROOT_PATH}/product-and-partners`}
        element={
          <>
            <Admin />
            <Right>
              <ProductsPartners />
            </Right>
          </>
        }
      />

      <Route
        path={`${ADMIN_ROOT_PATH}/adi-key-management`}
        element={
          <>
            <Admin />
            <Right>
              <KeyManagement />
            </Right>
          </>
        }
      />
    </Routes>
  );
};

export default AdminRouting;