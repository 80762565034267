import React from "react";
import { Button, Tooltip } from 'antd';
import styled from 'styled-components';

export interface IconButtonProps {
  title?: string;
  icon: React.ReactNode;
  onClick: React.MouseEventHandler;
  disabled?: boolean;
  loading?: boolean;
}

const IconButton = ({ title, icon, onClick, disabled, loading }: IconButtonProps) => {
  console.log('abc', title, icon, onClick, disabled, loading);
  return (
    <StyledTooltip title={title}>
      <StyledButton
        shape="circle"
        disabled={disabled}
        loading={loading}
        onClick={onClick}
      >
        {icon}
      </StyledButton>
    </StyledTooltip>
  );
};

const StyledButton = styled(Button)`
  background-color: rgba(0, 0, 0, 0);
  border: none;
`;

const StyledTooltip = styled(Tooltip)`
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-color: rgba(0, 0, 0, 0);
`;

export default IconButton;
