/**
 * The layout to render on mobile devices (portrait orientation)
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React from 'react';
import styled from 'styled-components';
import { Route, Routes, Navigate } from 'react-router-dom';
import Header from '../../../Headers/MobileHeader';
import Navigation from '../../../Navigations/MobileNavigation';
import { Page } from '../../../../utilities/types';

type Props = {
  pages: Page[];
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 4rem;
`;

const FooterWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: 5rem;
`;

const PageDisplay = styled.div`
  position: fixed;
  top: 4rem;
  bottom: 5rem;
  right: 0;
  left: 0;
  padding: 1rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
`;

const MobileLayout = (props: Props) => {
  const { pages } = props;

  return (
    <Wrapper>
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>
      <PageDisplay>
        <Routes>
          {pages.map((page) => (
            <Route
              key={page.key}
              path={page.path}
              element={<page.component apis={page.apis} />}
            />
          ))}
          {pages.map((page) =>
            page.subNav?.map((pagerec: any) => (
              <Route
                key={pagerec.key}
                path={pagerec.path}
                element={<pagerec.component apis={pagerec.apis} />}
              />
            ))
          )}
          <Route
            path="/"
            element={<Navigate to={pages[0].path} replace />}
          />
        </Routes>
      </PageDisplay>
      <FooterWrapper>
        <Navigation pages={pages} />
      </FooterWrapper>
    </Wrapper>
  );
};

export default MobileLayout;
