/**
 * @description Renders the form for creating and editing a contact
 * @version 1.0.0
 * @author Bruce Zhu 
 * @date 2023-02-23
 */

import React, { useState, useEffect } from 'react'
import GlobalModal from 'components/GlobalModal'
import { FlexWrapper, FormRequireText } from 'statics/styles/StyledComponents';
import { Form, Row, Col } from 'antd';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import Input from 'components/Input';
import { VALIDATION_RULES } from 'utilities/inputValidation';
import { AssignAddressFormDataType } from '../../statics/types';
import { CountryInfo, getSelectorOptions } from 'utilities/Functions/GlobalHelperFunctions';
import { formatNameString } from 'utilities/Functions/FormatFunctions';
import { ACTIVE_INACTIVE_STATUS, ADDRESS_TYPES } from 'utilities/CONSTANTS';
import AddressSearchInput from 'components/AddressSearchInput';
import set from 'lodash/set';
import styled from 'styled-components';
import AddressSearchInputFormItem from 'components/AddressSearchInput/AddressSearchInputFormItem';

type FormValues = {
  unit: string;
  street: string;
  suburb: string;
  state: string;
  country: string;
  postcode: string;
  type: string;
  is_manual_input?: string;
}

type Props = {
  open: boolean;
  onCancel: () => void;
  isEdit: boolean;
  hasNoBillingAddress: boolean;
  selectedAddress: AssignAddressFormDataType;
  handleDataSave: (data: AssignAddressFormDataType, action: 'edit' | 'add') => void;
  bizId?: string;
}



const AssignAddressForm = (props: Props) => {
  const { open, onCancel, isEdit, selectedAddress, handleDataSave, bizId } = props;

  // const coutryOptions = CountryInfo.getCountryOptions();

  // const [stateOptions, setStateOptions] = useState<any>(CountryInfo.getStateOptions('Australia'));
  // const [suburbOptions, setSuburbOptions] = useState<any>([]);
  const [contactPage, setContactPage] = useState<boolean>(false);
  // const [addressState, setAddressState] = useState<any>({ ...defaultAddressState });
  // const [error, setError] = useState<any>(null);

  const [form] = Form.useForm();


  const getContactpage = () => {
    if (bizId) {
      const splitBizId: any = bizId?.split('-')
      if (splitBizId[0] == 'CON') {
        setContactPage(true);
      } else {
        setContactPage(false);
      }
    }

  };

  useEffect(() => {
    if (isEdit && selectedAddress) {
      console.log('selectedAddress===>', selectedAddress)
      // full address is combination of unit, street, suburb, postcode, state, country
      const fullAddress = `${selectedAddress.unit ? selectedAddress.unit + '/' : ''} ${selectedAddress.street ? selectedAddress.street + ',' : ''} ${selectedAddress.suburb ? selectedAddress.suburb + ',' : ''}  ${selectedAddress.country ? selectedAddress.country + ',' : ''} ${selectedAddress.state ? selectedAddress.state + ',' : ''} ${selectedAddress.postcode ? selectedAddress.postcode : ''}`
      // setAddressState({
      //   unit: selectedAddress.unit,
      //   street: selectedAddress.street,
      //   suburb: selectedAddress.suburb,
      //   postcode: selectedAddress.postcode,
      //   state: selectedAddress.state,
      //   country: selectedAddress.country,
      //   full_address: fullAddress,
      // })
      // load data
      form.setFieldsValue({
        full_address: fullAddress,
        ...selectedAddress,
      })
    }
    getContactpage()
  }, [isEdit])

  const closeModal = () => {
    // empty payload
    form.resetFields()
    // setAddressState({ ...defaultAddressState })
    // setError(null)
    onCancel()
  }

  const readyToConfirm = (values: FormValues) => {
    console.log('values===>', values)

    const addressId = (selectedAddress && selectedAddress.address_ID) || ''

    var newValues: any = {
      address_ID: addressId,
      unit: values?.unit,
      street: values?.street,
      suburb: values?.suburb,
      postcode: values?.postcode,
      state: values?.state,
      country: values?.country,
      type: values?.type,
      is_manual_input: values?.is_manual_input?.toString() || 'false',
    }

    console.log('allnewValues===>', newValues)

    handleDataSave(newValues, isEdit ? 'edit' : 'add')
    closeModal()

  }

  const onFinishFailed = (errorInfo: any) => {
  };

  const renderFooterBtns = () => (
    <FlexWrapper flexEnd key='0'>
      <Form.Item style={{ marginBottom: '0' }}>
        <SimpleButton
          text='Cancel'
          id='modal-btn-width-regular'
          onClick={closeModal}
          isCancel
        />
        <SimpleButton
          text={isEdit ? 'Save' : 'Add'}
          form='address-form'
          id='modal-btn-width-regular'
          htmlType="submit"
        />
      </Form.Item>
    </FlexWrapper>
  )


  const renderOptions = (type: string) => {
    if (type == 'address_type') {
      const keys = Object.keys(ADDRESS_TYPES).map(item => ({
        label: ADDRESS_TYPES[item],
        value: ADDRESS_TYPES[item]
      }))
      // if already has billing address, remove the billing option
      if (!props.hasNoBillingAddress) {
        keys.splice(1, 1)
      }
      return getSelectorOptions(keys, '', 'value', 'label')
    } else {

      return getSelectorOptions([], 'Select')
    }
  }

  return (
    <GlobalModal
      small
      title={isEdit ? 'Edit an Address' : 'Add an Address'}
      open={open}
      onCancel={closeModal}
      footer={[renderFooterBtns()]}
    >
      <FormRequireText>
        <span>*</span>
        &nbsp;Fields marked with (*) are required.
      </FormRequireText>
      <Form
        form={form}
        name='address-form'
        onFinish={readyToConfirm}
        onFinishFailed={onFinishFailed}
        initialValues={{ country: 'Australia' }}
      >
        {contactPage ? (
          <>
            {isEdit ? null :
              (<Form.Item
                name='type'
                initialValue={'Location'}
              >
                <Input label="Address Type" type="text" disabled={true} />
              </Form.Item>)
            }
          </>
        ) : (
          <>
            {isEdit ? null : (
              <Form.Item
                name='type'
                rules={[
                  { required: true, message: 'Please select the Address Type.' },
                ]}
              >
                <Input
                  label="Address Type"
                  type="select"
                  options={renderOptions('address_type')}
                  required
                  disabled={contactPage ? true : false}
                /></Form.Item>
            )}
          </>
        )}

        {/* <Row gutter={[30, 15]}>
          <Col sm={{ span: 24 }} xl={{ span: 24 }}>
            <AddressLabel>Address&nbsp;<span style={{ color: `var(--itrazo-red-color)` }}>*</span></AddressLabel>
            <AddressSearchInput
              handleAddressChange={(address: any) => {
                setAddressState({
                  unit: address.unit,
                  street: address.street,
                  suburb: address.city,
                  state: address.state,
                  postcode: address.postcode,
                  country: address.country,
                  full_address: address.full_address,
                })
                setError(null)
                // form.setFieldsValue({
                //   unit: address.unit,
                //   street: address.street,
                //   suburb: address.city,
                //   state: address.state,
                //   postcode: address.postcode,
                //   country: address.country,
                // })
              }}
              addressValue={addressState?.full_address}// full address
            />
            <span style={{ color: `var(--itrazo-red-color)` }}>{error}</span>
          </Col>
        </Row> */}

        <Row>
          <Col span='24'>
            <AddressSearchInputFormItem
              form={form}
              inputProps={{
                placeholder: 'Enter Address',
                label: 'Address',
                required: true
              }}
              formItemProps={{
                name: 'full_address',
                rules: [{ required: true, message: 'Please enter the Address.' }]
              }}
              fromAddressFieldNames={'default'}
              isManualInput={selectedAddress?.is_manual_input}
            />
          </Col>
        </Row>

        {/* // old code */}
        {/* <Row gutter={[30, 15]}>
          <Col sm={{ span: 24 }} xl={{ span: 12 }}>
            <Form.Item
              name='unit'
              rules={[
                // { required: true, message: 'Please input unit' },
                VALIDATION_RULES.MAX.STREET
              ]}
            >
              <Input label="Unit/Suite" type="text" />
            </Form.Item>
          </Col>
          <Col sm={{ span: 24 }} xl={{ span: 12 }}>
            <Form.Item
              name='street'
              rules={[
                { required: true, message: 'Please enter the Street.' },
                VALIDATION_RULES.MAX.STREET
              ]}
            >
              <Input label="Street Address" type="text" required />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[30, 15]}>
          <Col sm={{ span: 24 }} xl={{ span: 12 }}>
            <Form.Item
              name='country'
              rules={[
                { required: true, message: 'Please select a Country.' },
              ]}
            >
              <Input
                label="Country"
                type="select"
                options={coutryOptions}
                onChange={(value: any) => {
                  const stateOptions = CountryInfo.getStateOptions(value)
                  setStateOptions(stateOptions)
                  setSuburbOptions([])
                  form.setFieldsValue({ state: '', suburb: '' })
                }}
                showSearch
                allowClear
                required
              />
            </Form.Item>
          </Col>
          <Col sm={{ span: 24 }} xl={{ span: 12 }}>
            <Form.Item
              name='state'
            >
              <Input
                label="State"
                type="select"
                options={stateOptions}
                onChange={(value: any) => {
                  const suburbOptions = CountryInfo.getCityOptions(value, form.getFieldValue('country'))
                  setSuburbOptions(suburbOptions)
                  form.setFieldsValue({ suburb: '' })
                }}
                showSearch
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[30, 15]}>
          <Col sm={{ span: 24 }} xl={{ span: 12 }}>
            <Form.Item
              name='suburb'
            >
              <Input label="Suburb/City" type="select" options={suburbOptions} showSearch allowClear />
            </Form.Item>
          </Col>
          <Col sm={{ span: 24 }} xl={{ span: 12 }}>
            <Form.Item
              name='postcode'
              rules={[
                { required: true, message: 'Please enter the Postcode.' },
                VALIDATION_RULES.MAX.POSTCODE
              ]}
            >
              <Input label="Postcode" type="text" required />
            </Form.Item>
          </Col>
        </Row> */}
      </Form>
    </GlobalModal>
  )
}

export default AssignAddressForm

const AddressLabel = styled.label`
  margin-top: 5px;
  font-size: 12px !important;
  margin-bottom: 8px;
  color: var(--white-text-color);
`;