/**
 * @description Task creation form, handles creation and edit for tasks; 
 * @version 2.0.0
 */

import React, { useState, useEffect } from 'react';
import GlobalModal from '../../../../../components/GlobalModal';
import Input from '../../../../../components/Input';
import { ActionButton, FlexWrapper, FormRequireText } from 'statics/styles/StyledComponents';
import styled from 'styled-components';
import { TaskAPI, LeadAPI, CompanyAPI, ContactAPI, UserAPI } from '../../apis';
import { message } from 'antd'
import moment from 'moment'
import { IContactPayload, ITaskPayload } from '../../statics/types';
import { Row, Col, Form, Popconfirm, Tooltip } from 'antd'
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import { ADI_CONFIRM, getSelectorOptions, combineDateAndTime, convertDateAndTime } from 'utilities/Functions/GlobalHelperFunctions';
import { titleOptions, taskTypeOptions, taskPriorityOptions, reminderOptions } from '../../statics/constants'
import infoIcon from '../../statics/images/infoIcon.svg'


import set from 'lodash/set';
import { useGeneralStore, useUserStore } from 'zustand-stores';
import { UserStore } from 'zustand-stores/useUserStore';
import { GeneralStore } from 'zustand-stores/useGeneralStore';


const IconLabel = styled.span`
  color: var(--white-text-color);
  Font-family: var(--font-normal);
  Font-size: 12px;`


type Props = {
  open: boolean;
  onClose: Function;
  selectedTask: any;
  isEdit: boolean;
  setReload: () => void;
  viewOnly?: boolean;
};
type formTypes = {
  assigned_to: String;
  assigned_to_name: String;
  business_ID: String;
  subject: String;
  priority: String;
  task_type: String;
  due_date: String;
  created_by: String;
  details: String;
  reminders: String;
  comments: String;
  time: String;
  business_type: String;
}
const CreateTask = (props: Props) => {
  const { open, onClose, isEdit, selectedTask, setReload, viewOnly } = props;
  console.log('selectedTask==>', selectedTask)
  const username = useUserStore((state: UserStore) => state.username)
  const [completedTask, setCompletedTask] = useState(false);
  const [businessID, setBusinessID] = useState([]);
  const [selectedAssociate, setSelectedAssociate] = useState('')

  const [form] = Form.useForm();

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  const emptyTaskData: formTypes = {
    assigned_to: '',
    assigned_to_name: '',
    business_ID: '',
    subject: '',
    priority: '',
    task_type: '',
    due_date: '',
    created_by: '',
    details: '',
    reminders: '',
    comments: '',
    time: '',
    business_type: ''
  }

  // initialise edit data
  useEffect(() => {
    if (isEdit) {
      // initialize the form
      if (isEdit && selectedTask) {
        // initialize the form
        if (selectedTask?.end_datetime) {
          setCompletedTask(true);
        }
        let associateTo: any = '';
        let businessId: any = '';
        if (selectedTask.business_ID && selectedTask.business_ID.lead_ID) {
          associateTo = 'Lead';
          businessId = selectedTask.business_ID.lead_ID
          businessData('Lead')
        }
        else if (selectedTask.business_ID && selectedTask.business_ID.company_ID) {
          associateTo = 'Company';
          businessData('Company')
          businessId = selectedTask.business_ID.company_ID

        }
        else if (selectedTask.business_ID && selectedTask.business_ID.customer_ID) {
          associateTo = 'Customer';
          businessData('Customer');
          businessId = selectedTask.business_ID.customer_ID


        }
        else if (selectedTask.business_ID && selectedTask.business_ID.contact_ID) {
          associateTo = 'Contact';
          businessData('Contact');
          businessId = selectedTask.business_ID.contact_ID

        }
        else if (selectedTask.business_ID && selectedTask.business_ID.supplier_ID) {
          associateTo = 'Supplier';
          businessData('Supplier');
          businessId = selectedTask.business_ID.supplier_ID

        }
        form.setFieldsValue({
          ...selectedTask,
          due_date: new Date(selectedTask.due_date),
          time: moment(selectedTask.due_date).format(),
          business_type: associateTo,
          business_ID: { value: businessId, label: selectedTask.business_ID.bus_name }
        })
      }
    }
  }, [isEdit])


  // empty the form and payload when close modal
  const onCloseModal = () => {
    onClose();
    // form.setFieldsValue({ ...emptyTaskData })
    form.resetFields()
    setSelectedAssociate('')
    setCompletedTask(false)
  }

  const readyToConfirm = (values: any) => {
    console.log('values==>', values)
    const dateDue = combineDateAndTime(values.due_date, values.time)
    const completed_date = convertDateAndTime(moment(), 'datetime')
    delete values.time;

    let payload: any = {}

    if (isEdit) {
      payload = {
        ...values,
        "due_date": dateDue,
        "created_by": username,
        "end_datetime": completedTask ? true : "",
        task_ID: selectedTask.task_ID,
        business_ID: values.business_ID.value ? values.business_ID.value : values.business_ID,
        comments: completedTask ? values.comments : "",
      }
    }
    else {
      payload = {
        ...values,
        "due_date": dateDue,
        "created_by": username,
        "end_datetime": "",
      }
    }

    delete payload.mark_completed;

    console.log('payload==>', payload)
    // return 
    // ADI_CONFIRM({
    //   actionTitle: isEdit ? 'update the task' : 'add a task',
    //   onConfirm: () => isEdit ? updateTask(payload) : createTask(payload)
    // })

    if (isEdit) {
      updateTask(payload)
    } else {
      createTask(payload)
    }

  }
  const createTask = (payload: ITaskPayload) => {
    const key = 'creating'
    // message.loading({ content: 'Creating Task...', key })
    setIsGlobalLoading(true)

    TaskAPI.createTask(payload)
      .then(res => {
        message.success({
          content: 'Task created successfully!',
          duration: 1,
          key,
          onClose: () => {
            onCloseModal()
            setReload()
          }
        })
      }).catch(e => {
        message.error(`Failed to create Task`)
      }).finally(() => {
        setIsGlobalLoading(false)
      })
  }
  const updateTask = (payload: ITaskPayload) => {
    const key = 'updatinging'
    // message.loading({ content: 'Updating Task...', key })
    setIsGlobalLoading(true)

    TaskAPI.updateTask(payload)
      .then((res: any) => {
        message.success({
          content: 'Task updated successfully!',
          duration: 1,
          key,
          onClose: () => {
            onCloseModal()
            setReload()
          }
        })
      }).catch(e => {
        message.error(`Failed to update Task`)
      }).finally(() => {
        setIsGlobalLoading(false)
      })
  }

  const onFinishFailed = (errorInfo: any) => {
    // console.log('Failed:', errorInfo);
  };
  const [assignedOptions, setAssignedOptions] = useState([]);

  useEffect(() => {
    UserAPI.getAllUsers().then((data) => {
      const options = data
      let optiondata: any = [];
      options.map((rec: any) => {
        optiondata.push({ value: rec.username, label: rec.full_name })
      })
      setAssignedOptions(optiondata)

    }).catch(e => {
      // message.error('Failed to get all user data')
      console.log(e)

    })
  }, [])

  const [associateWith, setAssociateWith] = useState([
    // {
    //   label: 'Unassigned Company', value: 'Company',
    // },
    {
      label: 'Contact', value: 'Contact',
    },
    {
      label: 'Lead', value: 'Lead',
    },
    {
      label: 'Supplier', value: 'Supplier',
    },
    {
      label: 'Customer', value: 'Customer',
    }
  ]);



  const businessData = (e: any) => {
    form.setFieldsValue({ business_ID: '' })
    setSelectedAssociate(e)
    switch (e) {
      case 'Lead':
        return LeadAPI.getAllLeads().then(async (data) => {
          const options = data
          let optiondata: any = [];
          await options.map((rec: any) => {
            optiondata.push({ value: rec.lead_ID, label: rec.bus_name })
          })
          setBusinessID(optiondata)
        }).catch(e => {
          message.error('Failed to get all lead data')
          // console.log(e)
        })
      case 'Company':
        return CompanyAPI.getAllCompanies().then(async (data) => {
          const options = data.Companies
          let optiondata: any = [];
          await options.map((rec: any) => {
            optiondata.push({ value: rec.company_ID, label: rec.bus_name })
          })
          setBusinessID(optiondata)
        }).catch(e => {
          message.error('Failed to get all companies data')
          // console.log(e)

        })
      case 'Contact':
        return ContactAPI.getAllContacts().then(async (data) => {
          const options = data
          let optiondata: any = [];
          await options.map((rec: any) => {
            optiondata.push({ value: rec.contact_ID, label: rec.full_name })
          })
          setBusinessID(optiondata)
        }).catch(e => {
          message.error('Failed to get all contact data')
          // console.log(e)

        })
      case 'Supplier':
        return CompanyAPI.getAllCompanies().then(async (data) => {
          const options = data.Suppliers
          let optiondata: any = [];
          await options.map((rec: any) => {
            optiondata.push({ value: rec.supplier_ID, label: rec.bus_name })
          })
          setBusinessID(optiondata)
        }).catch(e => {
          message.error('Failed to get all supplier data')
          // console.log(e)
        })
      case 'Customer':
        return CompanyAPI.getAllCompanies().then(async (data) => {
          const options = data.Customers
          let optiondata: any = [];
          await options.map((rec: any) => {
            optiondata.push({ value: rec.customer_ID, label: rec.bus_name })
          })
          setBusinessID(optiondata)
        }).catch(e => {
          message.error('Failed to get all customers data')
          // console.log(e)

        })
    }

  }

  const renderOptions = (type?: string) => {
    if (type == 'busID') {
      return getSelectorOptions(businessID, 'Select', 'value', 'label')
    } else {
      return getSelectorOptions([], 'Select')
    }
  }

  const renderFooterBtns = () => {
    return (
      <FlexWrapper flexEnd key='0'>
        <Form.Item style={{ marginBottom: '0' }}>
          <SimpleButton
            text='Cancel'
            id='modal-btn-width-regular'
            onClick={onCloseModal}
            isCancel
          />
          {
            !viewOnly &&
            <SimpleButton
              text={`${isEdit ? 'Update' : 'Create'}`}
              id='modal-btn-width-regular'
              htmlType='submit'
              form='create-task'
            />
          }
        </Form.Item>

      </FlexWrapper>
    )
  }

  return (
    <GlobalModal
      title={`${isEdit ? viewOnly ? 'View' : 'Edit' : 'Create'} Task`}
      open={open}
      onCancel={onCloseModal}
      footer={[renderFooterBtns()]}
    >
      <FormRequireText style={{ marginTop: '0px' }}>
        <span>*</span>
        &nbsp;Fields marked with (*) are required.
      </FormRequireText>
      <Form
        form={form}
        name='create-task'
        onFinish={readyToConfirm}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name='subject'
          initialValue={''}
          rules={[
            { required: true, message: 'Please enter the Title.' },
            // VALIDATION_RULES.MAX.ID,
            // VALIDATION_RULES.ID_SPECIAL_CHAR
          ]}
        >
          <Input label="Title" type="text" allowClear required={true} disabled={viewOnly ? true : false}

          />
        </Form.Item>
        <Row gutter={[15, 15]}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              name='task_type'
              rules={[
                { required: true, message: 'Please select Task Type.' },
                // VALIDATION_RULES.MAX.ID,
                // VALIDATION_RULES.ID_SPECIAL_CHAR
              ]}
            >
              <Input label="Task Type" type="select" options={taskTypeOptions} disabled={viewOnly ? true : false} allowClear
                placeholder='Select a Task Type' required={true}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              name='priority'
              rules={[
                { required: true, message: 'Please select Priority.' },
                // VALIDATION_RULES.MAX.ID,
                // VALIDATION_RULES.ID_SPECIAL_CHAR
              ]}
            >
              <Input label="Priority" type="select" options={taskPriorityOptions} disabled={viewOnly ? true : false}
                placeholder='Select Priority' required={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[15, 15]}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              name='due_date'
              rules={[
                { required: true, message: 'Please select Date.' },
                // VALIDATION_RULES.MAX.ID,
                // VALIDATION_RULES.ID_SPECIAL_CHAR
              ]}
            >
              <Input label="Due Date" type="date" required={true} disabled={viewOnly ? true : false} allowClear />
            </Form.Item>

          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              name='time'
              rules={[
                { required: true, message: 'Please select Time.' },
                // VALIDATION_RULES.MAX.ID,
                // VALIDATION_RULES.ID_SPECIAL_CHAR
              ]}
            >
              <Input
                label="Time"
                type="time"
                required={true}
                disabled={viewOnly ? true : false}
                options={titleOptions}
                format='HH:mm'
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[15, 15]}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>

            <Row style={{ margin: '3px 0px 5px 0px' }}>
              <Col>
                <IconLabel>Associate with Records<span style={{ color: 'red', marginLeft: '2px' }}>*</span></IconLabel>
              </Col>
              <Col style={{ paddingLeft: '10px' }}>
                <Tooltip title='Associate this task with any existing records
                    (Unassigned Company, Contact, Lead, Supplier or Customer).'>
                  <img src={infoIcon} />
                </Tooltip>
              </Col>
            </Row>
            <Form.Item
              name='business_type'
              rules={[
                { required: true, message: 'Please select Associate To.' },
                // VALIDATION_RULES.MAX.ID,
                // VALIDATION_RULES.ID_SPECIAL_CHAR
              ]}
            >
              <Input type="select" options={associateWith} disabled={viewOnly ? true : false} onChange={businessData} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              name='business_ID'
              rules={[
                { required: true, message: `Please select a ${selectedAssociate ? selectedAssociate : 'Name'}.` },
                // VALIDATION_RULES.MAX.ID,
                // VALIDATION_RULES.ID_SPECIAL_CHAR
              ]}
            >
              {/* <Input label="Select" type="datalist" required={true} disabled={viewOnly ? true : false} options={businessID} allowClear /> */}
              <Input
                label={selectedAssociate ? `Select ${selectedAssociate}` : 'Select'}
                type="datalist"
                placeholder='Select'
                required={true}
                disabled={viewOnly ? true : false} options={renderOptions('busID')} allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name='assigned_to'
          rules={[
            { required: true, message: 'Please select Name.' },
            // VALIDATION_RULES.MAX.ID,
            // VALIDATION_RULES.ID_SPECIAL_CHAR
          ]}
        >
          <Input label="Assigned To" type="datalist" required={true} disabled={viewOnly ? true : false} options={assignedOptions} allowClear />
        </Form.Item>
        {/* <Form.Item
          name='reminders'
        >

          <Input label="Reminder" type="select" options={reminderOptions} disabled={viewOnly ? true : false} allowClear />
        </Form.Item> */}

        <Form.Item
          name='details'
        >
          <Input label="Notes" type="text" disabled={viewOnly ? true : false} allowClear />
        </Form.Item>
        {/* For due task */}
        {isEdit && selectedTask.end_datetime === '' ? (
          <>
            <Form.Item
              name='mark_completed'
              initialValue={completedTask}
            >
              <Input
                margin="8px 0"
                type='checkbox'
                label="Mark as completed"
                checked={completedTask}
                disabled={viewOnly ? true : false}
                onChange={(e: any) => {
                  console.log('e.target.checked', e, e.target.checked)
                  setCompletedTask(e.target.checked)
                }}
              />
            </Form.Item>
            {completedTask ? (
              <Form.Item
                name='comments'
              >
                <Input
                  margin="8px 0"
                  type='textarea'
                  label="Comments"
                  disabled={viewOnly ? true : false}
                />
              </Form.Item>
            ) : null}</>
        ) : (
          <>
            {isEdit && selectedTask.end_datetime != '' ? (
              <>
                {console.log('selectedTask.end_datetime !=', selectedTask.end_datetime != '', completedTask)}
                <Form.Item
                  name='mark_completed'
                  initialValue={completedTask}
                >
                  <Input
                    margin="8px 0"
                    type='checkbox'
                    label="Mark as completed"
                    checked={completedTask}
                    disabled={viewOnly ? true : false}
                    onChange={(e: any) => {
                      setCompletedTask(e.target.checked)
                    }}
                  />
                </Form.Item>
                {completedTask ? (
                  <Form.Item
                    name='comments'
                  >
                    <Input
                      margin="8px 0"
                      type='textarea'
                      label="Comments"
                      disabled={viewOnly ? true : false}
                    />
                  </Form.Item>
                ) : null}
              </>
            ) : null}
          </>
        )}

      </Form>
    </GlobalModal >
  );
};

export default CreateTask;



