import { Col, Divider, Form, Row, message } from 'antd'
import SimpleButton from 'components/GlobalButtons/SimpleButton'
import GlobalModal from 'components/GlobalModal'
import Input from 'components/Input'
import DragFileInput from 'components/Input/DragFileInput'
import AdiLabel from 'components/Label'
import React, { useEffect, useState } from 'react'
import { FlexBox, FlexWrapper } from 'statics/styles/StyledComponents'
import styled from 'styled-components'
import { useFileUpload } from 'utilities/hooks'
import VALIDATION_RULES from 'utilities/inputValidation'
import AddressBookAPI from '../apis/AddressBookAPI'


import { useGeneralStore } from 'zustand-stores'
import { GeneralStore } from 'zustand-stores/useGeneralStore'

const { ModalLayout } = GlobalModal;


type Props = {
  open: boolean
  selectedItem: any
  setSelectedProfile: any
  onClose: () => void
  reload: () => void
}
const EditProfileModal = (props: Props) => {
  const { open, selectedItem, onClose, reload, setSelectedProfile } = props

  console.log('EditProfileModalselectedItem==>', selectedItem)

  const [form] = Form.useForm()
  const [isResetImage, setIsResetImage] = useState<boolean>(false)
  const [isOldImage, setIsOldImage] = useState<boolean>(selectedItem?.image != '' && false)

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  const [fileHandler] = useFileUpload()

  useEffect(() => {
    if (!open) return;

    initializeEditProfile()

    return () => form.resetFields()

  }, [open])


  const initializeEditProfile = () => {

    if (!selectedItem) return;
    let values = {
      ...selectedItem,
    }
    setIsOldImage(selectedItem?.image != '' && false)
    console.log('initializeEditProfilevalues==>', values)
    form.setFieldsValue(values)

  }

  const getEditAddressBookPayloadFormat = async (values: any, selectedItem: any) => {

    let oneFullRecord = {};

    try {
      const Id = selectedItem?.id
      const oneRecord = await AddressBookAPI.getAddressBooksItemByID(Id)
      console.log('oneRecord', oneRecord)
      if (!oneRecord) return null
      /// if the record.email === values.email, then update invitee details
      let invitee_details = oneRecord?.invitee_details?.map((item: any) => {
        if (item?.email === values?.email) {
          return {
            ...item,
            phone_no: values?.phone_no,
            background_check: values?.background_check,
            job_title: values?.job_title,
          }
        }
        return {
          ...item,
        }
      })

      console.log('invitee_details==>', invitee_details)

      oneFullRecord = {
        ...oneRecord,
        invitee_details: invitee_details,
        mnda_status: values?.mnda_status,
      }

      console.log('oneFullRecord==>', oneFullRecord)

      return oneFullRecord
    } catch (e) {
      console.log('error', e)
      message.error('Failed to get Record.')
    }
    return null

  }

  const formOnFinish = async (values: any) => {
    console.log('formOnFinish==>', values, selectedItem)
    const { image } = values
    let finalPayload: any = {};

    let payload = await getEditAddressBookPayloadFormat(values, selectedItem)

    finalPayload = {
      ...payload,
    }
    console.log('formOnFinishpayload==>', payload)

    try {
      setIsGlobalLoading(true)
      if (image) {
        const path = `site-visitor/${finalPayload?.purpose_of_visit}/profile-image/${image.name}`
        const imgS3Key = fileHandler.constructS3Key(fileHandler.S3_MODULE_CODE.osm, path)
        const res = await fileHandler.uploadFileToS3(image, imgS3Key)
        if (res) {
          finalPayload = {
            ...finalPayload,
            image: imgS3Key
          }

          console.log('finalFormFinishwithimgS3Key==>', finalPayload)
          await AddressBookAPI.updateAddressBook(finalPayload).then((res) => {
            console.log('res', res)
            if (!res) return message.error('Failed to update record.')
            if (res) {
              console.log('selectedItemafter update', { ...selectedItem, ...values, image: imgS3Key })
              setSelectedProfile({ ...selectedItem, ...values, image: imgS3Key })
            }
          })
        }
      } else {
        await AddressBookAPI.updateAddressBook(finalPayload).then((res) => {
          console.log('selectedItemafter update', { ...selectedItem, ...values, image: '' })
          if (!res) return message.error('Failed to update record.')
          setSelectedProfile({ ...selectedItem, ...values, image: '' })
        })
      }
      message.success('Record updated successfully!')
      reload()
      closeForm()
    } catch (e) {
      message.error('Failed to update record.')
    } finally {
      setIsGlobalLoading(false)
    }

  }

  const closeForm = () => {
    onClose()
    form.resetFields()
    setIsResetImage(true)
  }

  return (
    <GlobalModal
      title='Edit Profile'
      open={open}
      onCancel={onClose}
      width='700px'
      footer={[
        <FlexBox style={{ justifyContent: 'flex-end', margin: '0' }}>
          <SimpleButton
            id='modal-btn-width-regular'
            isCancel={true}
            text="Cancel"
            onClick={onClose}
            style={{ marginRight: '1rem' }}
          />
          <SimpleButton
            id='modal-btn-width-regular'
            htmlType="submit"
            text="Save"
            form='edit-form'
          />
        </FlexBox>
      ]}
    >
      <Form
        form={form}
        name="edit-form"
        onFinish={(values: any) => formOnFinish(values)}
      >
        {/* <ModalLayout>
          <ModalLayout.ModalSection title="Add Picture">
            <Row gutter={[15, 0]}>
              <ImageContainer
                inputName={'image'}
                selectedItem={selectedItem}
                isReset={isResetImage}
                setIsReset={setIsResetImage}
                setIsOldImage={setIsOldImage}
                isOldImage={isOldImage}
              />
            </Row>
          </ModalLayout.ModalSection>
        </ModalLayout> */}
        <ModalLayout>
          <ModalLayout.ModalSection title='Personal Details'>
            <Row gutter={[15, 0]}>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter First Name',
                    }
                  ]}
                >
                  <Input
                    type='text'
                    label='First Name'
                    placeholder="First Name"
                    required
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="last_name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Last Name',
                    }
                  ]}
                >
                  <Input
                    type='text'
                    label='Last Name'
                    placeholder="Last Name"
                    required
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="email"
                  rules={[
                    VALIDATION_RULES.TYPE.EMAIL,
                    {
                      required: true,
                      message: 'Please enter Valid Email',
                    }
                  ]}
                >
                  <Input
                    type='email'
                    label='Email'
                    placeholder="Email"
                    required
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="phone_no"
                >
                  <Input
                    type='phone'
                    label='Phone No.'
                    placeholder="Phone No."

                  />
                </Form.Item>
              </Col>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="job_title"
                >
                  <Input
                    type='text'
                    label='Job Title'
                    placeholder="Job Title"
                  />
                </Form.Item>
              </Col>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="background_check"
                  rules={[
                    {
                      required: true,
                      message: 'Please select Background Check Status',
                    }
                  ]}
                >
                  <Input
                    label='Background Check Status'
                    placeholder="Background Check Status"
                    type="select"
                    options={
                      [
                        { value: 'verified', label: 'Verified' },
                        { value: 'not_verified', label: 'Not Verified' },
                      ]
                    }
                    required
                  />
                </Form.Item>
              </Col>
            </Row>
          </ModalLayout.ModalSection>

        </ModalLayout>

        <ModalLayout>

          <ModalLayout.ModalSection title="Company">
            <Row gutter={[15, 0]}>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="company"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a Company',
                    }
                  ]}
                >
                  <Input
                    type='text'
                    label='Company'
                    placeholder="Company"
                    disabled
                    required
                  />
                </Form.Item>
              </Col>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="mnda_status"
                  rules={[
                    {
                      required: true,
                      message: 'Please select MNDA Status',
                    }
                  ]}
                >
                  <Input
                    type='select'
                    label='MNDA Status'
                    placeholder="MNDA Status"
                    options={
                      [
                        { value: 'signed', label: 'Signed' },
                        { value: 'not_signed', label: 'Not Signed' },
                      ]
                    }
                    required
                  />
                </Form.Item>
              </Col>
            </Row>
          </ModalLayout.ModalSection>

        </ModalLayout>
      </Form>
    </GlobalModal>
  )
}

export default EditProfileModal

const getBase64 = (file: any): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

const ImageContainer = (props: { inputName: string, selectedItem: any, isReset: boolean, setIsReset: Function, setIsOldImage: Function, isOldImage: boolean }) => {
  const { inputName, selectedItem, isReset, setIsReset, isOldImage, setIsOldImage } = props;

  const [previewImage, setPreviewImage] = useState('')

  const [file, setFile] = useState<any>(null)

  const [fileHandler] = useFileUpload()

  const Wrapper = styled.div`
  background: #28293D;
  border-radius: var(--border-radius);
  // padding: 24px 36px 24px 12px;
  padding: 2px 5px 2px 1px;
`
  const NoImage = styled.div`
  // background: #D9D9D9;
  width: 156px;
  height: 137px;
  border: 1px solid var(--input-border-color);
`
  const Image = styled.img`
  width: 156px;
  height: 137px;
  object-fit: contain;
`

  const handlePreview = (file: any) => {
    getBase64(file).then(preview => {
      console.log('preview', preview)
      setPreviewImage(preview || '')
    })

  }

  useEffect(() => {
    if (isOldImage) {
      console.log('isOldImage', file, isOldImage)
      if (file) {
        handlePreview(file)
      } else {
        setPreviewImage('')
      }
    }

  }, [file])

  useEffect(() => {
    // show image if selected category has image
    console.log('selectedItemFor Image', selectedItem, inputName, selectedItem?.[inputName])
    const s3Key = selectedItem?.image
    console.log('s3Key', s3Key)
    if (s3Key) {
      const src = fileHandler.constructS3Url(s3Key)
      console.log('src', src)
      setPreviewImage(src)
      const fileObj = {
        uid: '-1',
        name: fileHandler.getFileNameFromS3Key(s3Key),
        url: src
      }
      console.log('fileObj', fileObj)
      setFile(fileObj)
    } else {
      setPreviewImage('')
      setFile(null)
    }

  }, [selectedItem])

  useEffect(() => {
    if (isReset) {
      setPreviewImage('')
      setFile(null)
      setIsReset(false)
    }
  }, [isReset])

  console.log('isOldImage', isOldImage, isReset)


  return (
    <div style={{ marginBottom: '10px' }}>
      <Wrapper>
        <FlexWrapper gap='20px' flexStart>
          {
            previewImage ? <Image src={previewImage} alt='Preview' /> : <NoImage />
          }
          <div style={{ maxWidth: '290px', width: '290px' }}>
            <DragFileInput
              useFormItem
              fileList={file ? [file] : []}
              onRemove={() => {
                console.log('onRemove', file, isOldImage)
                setIsOldImage(true)
                setFile(null)
              }}
              beforeUpload={(file: any) => {
                console.log('beforeUpload', file, isOldImage)
                setIsOldImage(true)
                setFile(file)
                return false
              }}
              formItemProps={{
                name: inputName,
              }}
              accept='image/*'
            />
          </div>
        </FlexWrapper>
      </Wrapper>
    </div>
  )
}
