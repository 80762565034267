/**
 * This is the user creation component
 *
 * @author Ricardo Enriquez
 * @version 1.0.0
 * @Date 25 Dec 2023
 */

import React, { useState, useMemo, useEffect } from "react";
import DisplayStats from "components/Statistics/DisplayStats";
import { Toaster } from "react-hot-toast";
import Loading from "../../../../components/Loading";
import { Button, Col, Row } from 'antd';
import {
  WhiteButton as MyButton,
  Body,
} from "../../../../statics/styles/StyledComponents";
import AdiTable from 'components/AdiTable'
import SimpleButton from "../../../../components/GlobalButtons/SimpleButton";
import styled from "styled-components";
import bulkAdd from '../../../../statics/images/bulkAdd.svg';
import singleAdd from '../../../../statics/images/singleAdd.svg';
import AddGS1Key from "./AddGS1Key";
import ImportGS1Key from "./ImportGS1Key";
import AddGS1KeyAPI from "../apis/Gs1Key";
import QRCode from 'qrcode';
import { formatTitle, initialCellValue } from "utilities/Functions/FormatFunctions";
import { SESSION_STORAGE_KEYS, TABLE_CELL_CONFIG } from "utilities/CONSTANTS";
import { PRODUCT_TYPE } from "containers/Pages/InventoryManagement/statics/constants";
import { getSessionStorage } from "utilities/Functions/GlobalHelperFunctions";
import { useAuthStore } from "zustand-stores";
import { AuthStore } from "zustand-stores/useAuthStore";
import TableCard from "components/AdiTable/TableCard";
type Props = {
  active: boolean;
}


const GS1A1KeysTab = (props: Props) => {
  const isAdminModuleReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.admin.isReadOnly);
  const CLIENT_CODE = getSessionStorage(SESSION_STORAGE_KEYS.BIZ_CODE);

  const isACGCustomer = CLIENT_CODE === 'acg';

  const [modals, setModals] = useState({
    open: false,
    isEdit: false,
    onClose: false,
    selectedRecord: null,
    disabled: false,
  })
  const [importModals, setImportModals] = useState({
    open: false,
    isEdit: false,
    onClose: false,
    selectedRecord: null,
    disabled: false,
  })
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [showAddBtns, setShowAddBtns] = useState<boolean>(false)


  const onSearch = () => {

  };
  const [qrCodeData, setQRCodeData] = useState<string | null>(null);


  const generateAndDownloadQRCode = async (data: any) => {
    try {
      // Replace this with the data you want to encode in the QR code
      const dataToEncode = data.gs1_company + "" + data.code + "" + data.check_digit;

      // Generate the QR code as a data URL
      const qrCodeDataURL = await QRCode.toDataURL(dataToEncode);

      // Create a temporary anchor element to trigger the download
      const downloadLink = document.createElement('a');
      downloadLink.href = qrCodeDataURL;
      downloadLink.download = 'qrcode.png';
      downloadLink.click();

      // Set the QR code data for display
      setQRCodeData(qrCodeDataURL);
    } catch (error) {
      console.error('Error generating QR code:', error);
    }
  };

  const fixedColumns = [
    {
      title: formatTitle('Key Type'),
      dataIndex: 'key_type',
      key: 'key_type',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: formatTitle('Code'),
      dataIndex: 'code',
      key: 'code',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: formatTitle('GS1 Company Prefix'),
      dataIndex: 'gs1_company',
      key: 'gs1_company',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: formatTitle('Check Digit'),
      dataIndex: 'check_digit',
      key: 'check_digit',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
  ];

  const flexibleColumns = [
    {
      title: formatTitle('Inventory Type'),
      dataIndex: 'inventory_type',
      key: 'inventory_type',
      ...TABLE_CELL_CONFIG,
      filters: [
        { text: PRODUCT_TYPE.acgProduct.label, value: PRODUCT_TYPE.acgProduct.value },
        { text: PRODUCT_TYPE.eCommerceProduct.label, value: PRODUCT_TYPE.eCommerceProduct.value },
        { text: PRODUCT_TYPE.xplProduct.label, value: PRODUCT_TYPE.xplProduct.value }
      ],
      onFilter: (value: string, record: any) => record.inventory_type === value,
      render: (text: any, record: any) => (
        PRODUCT_TYPE.acgProduct.value === text ? PRODUCT_TYPE.acgProduct.label :
          PRODUCT_TYPE.eCommerceProduct.value === text ? PRODUCT_TYPE.eCommerceProduct.label :
            PRODUCT_TYPE.xplProduct.value === text ? PRODUCT_TYPE.xplProduct.label : initialCellValue(text)
      )
    },
    {
      title: formatTitle('Inventory ID'),
      dataIndex: 'sku',
      key: 'sku',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
  ];

  const restColumns = [
    {
      title: formatTitle('Product Name'),
      dataIndex: 'item_name',
      key: 'item_name',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: formatTitle('Barcode Category'),
      dataIndex: 'barcode_category',
      key: 'barcode_category',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: formatTitle('Barcode Type'),
      dataIndex: 'barcode_type',
      key: 'barcode_type',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    // {
    //   title: formatTitle('QR code'),
    //   dataIndex: 'qr_code',
    //   key: 'qr_code',
    //   ...TABLE_CELL_CONFIG,
    //   render: (text: any, record: any) => {
    //     if (record.inventory_type != 'acg_product') {
    //       return <Button type='primary' onClick={() => { generateAndDownloadQRCode(record) }} >  Download QR Code</Button >
    //     }
    //   }
    // },
  ]

  const columnData = useMemo(() => {
    // if isACGCustomer, add flexible columns, else not
    console.log('inside columnData', isACGCustomer, [...fixedColumns, ...flexibleColumns, ...restColumns])
    return [...fixedColumns, ...(isACGCustomer ? flexibleColumns : []), ...restColumns]
  }, [fixedColumns, flexibleColumns, isACGCustomer])

  const userModal = () => {
    setShowAddBtns(false)
    setModals({ ...modals, open: true })
  }

  const closeModal = () => {
    setModals({ ...modals, open: false })
  }

  const closeImportModal = () => {
    setImportModals({ ...importModals, open: false })
  }

  const openImportModal = () => {
    setShowAddBtns(false)
    setImportModals({ ...importModals, open: true })
  }


  const getTableData = () => {
    try {
      AddGS1KeyAPI.getGS1Key().then((response) => {
        console.log(response, 'response::')
        if (Array.isArray(response) && response.length > 0) {
          setData(response)
        } else {
          if (response.body?.includes('ResourceNotFoundException')) {
            setData([])
          }
        }

      })

    }
    catch {
      console.log('ok')
    }

  }
  useEffect(() => {
    getTableData()
  }, [])

  const statsData = useMemo(() => {
    console.log('inside stats', data);
    const totalKeys = data && data?.length || 0;
    const GTINKeys = data && data?.filter((rec: any) => rec.key_type === "GTIN") || 0;
    const GLNKeys = data && data?.filter((rec: any) => rec.key_type === "GLN") || 0;
    return [
      {
        text: 'Total Keys',
        number: totalKeys,
      },
      {
        text: 'GTIN',
        number: GTINKeys.length,
      },
      {
        text: 'GLN',
        number: GLNKeys.length,
      },

    ]
  }, [getTableData])
  return (
    <>
      <Loading show={loading} />
      <Toaster />
      <DisplayStats data={statsData} />
      <div style={{
        marginTop: '20px',
      }}>
        <TableCard>
          <Row gutter={[0, 15]} align="middle" justify="end" style={{ margin: '0 10px' }}>
            {
              !isAdminModuleReadOnly &&
              <Col >
                <SimpleButton text="+ Add GS1 Key(s)" onClick={userModal} />
                {/* {showAddBtns && (
            <ButtonsPanel>
              <SingleBulkButton onClick={userModal} >
                <img style={{ marginRight: '1rem' }} src={singleAdd} /> Add Manually
              </SingleBulkButton>
              <SingleBulkButton onClick={openImportModal} >
                <img style={{ marginRight: '1rem' }} src={bulkAdd} /> Import XLS file
              </SingleBulkButton>
            </ButtonsPanel>
          )} */}
              </Col>
            }

          </Row>
          <AdiTable
            columns={columnData}
            tableData={data}
            fullWidth
            marginTop='20px'
            extraPaginationPadding
          />
        </TableCard>
      </div>
      <AddGS1Key
        open={modals.open}
        onClose={closeModal}
        getTableData={getTableData}
      />
      <ImportGS1Key
        setData={setData}
        setColumns={setColumns}
        getTableData={getTableData}
        open={importModals.open}
        onClose={closeImportModal}
      />
    </>
  );
};

const ButtonsPanel = styled.div`
  position: absolute;
  top:100%;
  right:0;
  width: 20rem;
  z-index: 401;
`
const SingleBulkButton = styled.div`
  background-color: var(--dropdown-bg-color);
  color: white;
  font-size: 14px;
  padding: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`
export default GS1A1KeysTab;
