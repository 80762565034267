import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import styles from 'statics/styles/GoogleMapThemes/GoogleMapStylesPurple.json';
import { DotMarker } from "statics/styles/StyledMapComponents";
import { ReactComponent as Marker } from 'statics/images/map-marker.svg';

type Props = {
  modalData: any;
  isGraphOpen: boolean;
};

const AssetMapSection = (props: Props) => {
  const { modalData, isGraphOpen } = props;

  const [map, setMap] = useState<google.maps.Map | null>(null);

  const minimumZoom = 3;
  const defaultCenter = { lat: 0, lng: 0 };

  const PointsNew = ({ lat, lng }: { lat: number, lng: number }) => (
    <DotMarker>
      <Marker />
    </DotMarker>
  );

  const displayMarkers = () => {
    if (modalData.device_data && !modalData.device_data.error && modalData.device_data.length > 0) {
      return modalData.device_data.map((item: any, index: number) => {
        const { lat, long } = item.Location;
        return (
          <PointsNew
            key={index}
            lat={parseFloat(lat)}
            lng={parseFloat(long)}
          />
        );
      });
    } else {
      return null;
    }
  };

  const displayPolyline = () => {
    if (modalData.device_data && !modalData.device_data.error && modalData.device_data.length > 0) {
      const path: google.maps.LatLngLiteral[] = [];
      modalData.device_data.forEach((item: any) => {
        const { lat, long } = item.Location;
        if (lat && long) {
          path.push({ lat: parseFloat(lat), lng: parseFloat(long) });
        }
      });

      new window.google.maps.Polyline({
        path,
        strokeColor: 'red',
        strokeWeight: 2,
        map,
      });

      if (map) {
        const bounds = new window.google.maps.LatLngBounds();
        path.forEach(({ lat, lng }) => {
          bounds.extend(new window.google.maps.LatLng(lat, lng));
        });
        map.fitBounds(bounds);
      }
    }
  };

  return isGraphOpen ? (
    <div style={{ height: '400px', marginBottom: '20px', marginTop: '20px' }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAP_API_KEY || "",
          libraries: ['places', 'geometry', 'drawing', 'visualization'],
        }}
        defaultZoom={minimumZoom}
        defaultCenter={defaultCenter}
        options={{
          mapTypeId: 'roadmap',
          minZoom: minimumZoom,
          styles,
        }}
        onGoogleApiLoaded={({ map }) => {
          setMap(map);
          displayPolyline(); // Ensure polyline is displayed after map load
        }}
        yesIWantToUseGoogleMapApiInternals
      >
        {displayMarkers()}
      </GoogleMapReact>
    </div>
  ) : (
    <div>Map failed to load. Check API key and network connection.</div>
  );
};

export default AssetMapSection;
