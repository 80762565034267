import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Dashboard, Inventory, Purchase, QualityManagement } from './pages'
import Category from './pages/admin/Inventory/Category'
import CreateProduct from './pages/admin/Inventory/CreateProduct'

export const OSM_ROOT_PATH = "/app/inventory-management";
export const OSM_ROUTES = {
  dashboard: `${OSM_ROOT_PATH}/dashboard`,
  procurement: `${OSM_ROOT_PATH}/procurement`,
  qualityManagement: `${OSM_ROOT_PATH}/quality-management`,
  inventory: `${OSM_ROOT_PATH}/inventory`,
  inventoryProductsCategory: `${OSM_ROOT_PATH}/inventory/products/category`,
  inventoryCreateProduct: `${OSM_ROOT_PATH}/inventory/products/create-product`,
};

const OSMRoutes = () => {
  return (
    <Routes>
      <Route path={OSM_ROOT_PATH} element={<Navigate to={`${OSM_ROOT_PATH}/procurement`} />} />
      <Route path={OSM_ROUTES.dashboard} element={<Dashboard />} />
      <Route path={OSM_ROUTES.procurement} element={<Purchase />} />
      <Route path={OSM_ROUTES.qualityManagement} element={<QualityManagement />} />
      <Route path={OSM_ROUTES.inventory} element={<Inventory />} />
    </Routes>
  )
}

const InventoryRoutes = () => {
  return (
    <Routes>
      <Route
        path={OSM_ROUTES.inventoryProductsCategory}
        element={<Category />}
      />
      <Route
        path={OSM_ROUTES.inventoryCreateProduct}
        element={<CreateProduct />}
      />
    </Routes>
  );
};

export default OSMRoutes;
export { InventoryRoutes };
