import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { CMS_ROOT_PATH } from "containers/Pages/ContentManagementSystem/CMSRoutes";
import ContentManagementSystem from "containers/Pages/ContentManagementSystem";
import { EditProduct, Products } from "containers/Pages/ContentManagementSystem/pages";

const Right = styled.div`
  height: 100%;
  padding: 0 0.5em 0.5em 0.5em;
  border-radius: 5px;
  margin-right: 1em;
  margin-left: 1em;
`;

const PageLayout = ({ children }: { children: React.ReactNode }) => (
  <>
    <ContentManagementSystem />
    <Right>{children}</Right>
  </>
);

const ContentManagementRouting = () => {
  return (
    <Routes>
      <Route
        path={CMS_ROOT_PATH}
        element={<Navigate to={`${CMS_ROOT_PATH}/products`} />}
      />
      <Route
        path={`${CMS_ROOT_PATH}/products`}
        element={<PageLayout><Products /></PageLayout>}
      />
      <Route
        path={`${CMS_ROOT_PATH}/products/edit-product`}
        element={<PageLayout><EditProduct /></PageLayout>}
      />
    </Routes>
  );
};

export default ContentManagementRouting;