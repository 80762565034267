import Input from 'components/Input'
import React from 'react'
import { FlexWrapper } from 'statics/styles/StyledComponents'
import SearchButton from 'components/SearchButton'
import SimpleButton from 'components/GlobalButtons/SimpleButton'
import useAuthStore, { AuthStore } from 'zustand-stores/useAuthStore'
import { useResponsive } from 'utilities/hooks'



type InputConfig = {
  type: string;
  placeholder?: string;
  options?: { [key: string]: any }[];
  onChange: Function;
  onSearch?: Function;
  allowClear?: boolean;
  inputStyle?: any;
}

type ButtonConfig = {
  text: React.ReactNode;
  onClick: Function;
}

type Props = {
  showSearchBtn?: boolean;
  inputsConfig?: InputConfig[];
  onSearchBtnClick?: Function;
  buttonsConfig?: ButtonConfig[];
  flexConfig?: {};
  showClear?: boolean;
  clearResults?: Function;
}

/**
 * 
 * This component is used to render the filter header in the value chain tracking module
 */
const FilterHeader = (props: Props) => {
  const { showSearchBtn, inputsConfig, onSearchBtnClick, buttonsConfig, showClear, flexConfig, clearResults } = props
  const isVctReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.vct.isReadOnly);
  const screen = useResponsive();
  const flexOptions = screen.md ? {} : flexConfig;

  return (
    <FlexWrapper gap='15px' flexBetween alignEnd style={{
      margin: '0 10px'
    }}>
      <FlexWrapper gap='10px' {...flexOptions}>
        {
          Array.isArray(inputsConfig) && inputsConfig.map((inputProps, index) => {
            return (
              <Input {...inputProps} key={index} style={{ ...inputProps.inputStyle }} />
            )
          })
        }
        {
          showSearchBtn &&
          <SearchButton
            onSearchClick={() => onSearchBtnClick ? onSearchBtnClick() : null}
            style={{ padding: '5px' }}
          />
        }
      </FlexWrapper>
      <FlexWrapper gap='10px'>
        {
          showClear &&
          <p
            style={{ color: '#7799fb', fontSize: '14px', cursor: 'pointer', marginBottom: '5px' }}
            onClick={() => clearResults ? clearResults() : null}
          >
            Clear Search Results
          </p>
        }
        {
          !isVctReadOnly && Array.isArray(buttonsConfig) && buttonsConfig.map((buttonProps, index) => {
            return (
              <SimpleButton {...buttonProps} key={index} />
            )
          })
        }

      </FlexWrapper>

    </FlexWrapper>
  )
}

export default FilterHeader
