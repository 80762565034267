import { Card, CardProps } from 'antd';
import React from 'react'
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
  style?: React.CSSProperties;
  noPadding?: boolean;
  padding?: string;
  bodyHeight?: string;
} & CardProps;

const TableCard = (props: Props) => {
  const { children, style, noPadding, padding, bodyHeight, ...rest } = props;
  return (
    <StyledCard {...rest} style={style} noPadding={noPadding} padding={padding} bodyHeight={bodyHeight}>
      {children}
    </StyledCard>
  );
}

export default TableCard

const padding = '16px';
const StyledCard = styled(Card) <{ noPadding?: boolean, padding?: string, bodyHeight?: string }>`
  width: 100%;
  background-color: var(--table-card-header-color) !important;
  border: 1px solid var(--table-card-header-color) !important;
  border-radius: 8px !important;


  .ant-card-body {
    padding: ${(props) => (props.noPadding ? `${padding} 0 ${padding} 0 ` : props.padding ? `${props.padding}` : `${padding} 0 0 0`)};
    height: ${(props) => (props.bodyHeight ? props.bodyHeight : 'auto')};
  }
`;