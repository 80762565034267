/**
 * This is the user creation component
 *
 * @author Preet Kaur
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import { Col, Divider, Form, Row, message } from 'antd';
import Input from 'components/Input';
import { FlexWrapper, } from 'statics/styles/StyledComponents';
import GlobalDrawer from "components/GlobalModal/GlobalDrawer";

import { getSessionStorage } from "utilities/Functions/GlobalHelperFunctions";
import { SESSION_STORAGE_KEYS } from "utilities/CONSTANTS";

import ProductPartnerAPI from "../KeyManagement/apis/ProductPartnerAPI";
import { SUPPLY_CHAIN_ROLES } from "./Partners";
import { useGeneralStore } from "zustand-stores";
import { GeneralStore } from "zustand-stores/useGeneralStore";

const { ModalLayout } = GlobalDrawer;

type Props = {
  open: boolean;
  onClose: Function;
  reload: Function; // onSuccess callback
  isEdit?: boolean;
  selectedPartner?: any;
};

const SupplyChainPartner = (props: Props) => {
  const { open, onClose, reload, isEdit, selectedPartner } = props;
  const [form] = Form.useForm();
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  const BIZ_CODE = getSessionStorage(SESSION_STORAGE_KEYS.BIZ_CODE);

  console.log('BIZ_CODE::', BIZ_CODE)

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue({
        ...selectedPartner,
      })
    }
  }, [isEdit, selectedPartner])

  const formOnFinish = async (values: any) => {
    const payload = {
      ...values,
      client_code: BIZ_CODE,
    }
    console.log('payload::', payload, BIZ_CODE)

    const key = isEdit ? 'updated' : 'added'

    setIsGlobalLoading(true)

    const response = isEdit ? await ProductPartnerAPI.updateSupplyChainPartner(payload) : await ProductPartnerAPI.addSupplyChainPartner(payload)

    console.log('response::', response)

    if (!response) {
      message.error(`Failed to ${key} Partner`)
      setIsGlobalLoading(false)
      return
    }

    if (response) {
      message.success(`Partner ${key} Successfully`)
      setIsGlobalLoading(false)
      reload()
      closeForm()
    }

  };

  const closeForm = () => {
    onClose()
    form.resetFields()
  };

  const renderFooter = () => (
    <FlexWrapper flexEnd key='0' gap='10px'>
      <SimpleButton
        text='Cancel'
        id='modal-btn-width-regular'
        onClick={closeForm}
        isCancel
      />
      <SimpleButton
        text={`${isEdit ? 'Save' : 'Add'}`}
        form={'addPartner'}
        id='modal-btn-width-regular'
        htmlType="submit"
      />
    </FlexWrapper>
  );

  return (
    <GlobalDrawer
      title={`Add Partner`}
      open={open}
      onClose={closeForm}
      width={700}
      footer={renderFooter()}
    >
      <Form
        form={form}
        name={'addPartner'}
        onFinish={formOnFinish}
      >
        <ModalLayout>

          <ModalLayout.ModalSection title="Supply Chain Partner">
            <Row gutter={[15, 0]}>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="role"
                  rules={[
                    {
                      required: true,
                      message: 'Please select Supply Chain Function',
                    }
                  ]}
                >
                  <Input
                    type='select'
                    label='Supply Chain Function'
                    placeholder="Select Supply Chain Function"
                    options={SUPPLY_CHAIN_ROLES.map((role: string) => ({ text: role, value: role }))}
                    required
                  />
                </Form.Item>
              </Col>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="business_name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Business Name',
                    }
                  ]}
                >
                  <Input
                    type='text'
                    label='Business Name'
                    placeholder="Business Name"
                    required
                    disabled={isEdit}
                  />
                </Form.Item>
              </Col>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="business_email"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Email Address',
                    },
                    {
                      type: 'email',
                      message: 'Please enter valid Email Address'
                    }
                  ]}
                >
                  <Input
                    type='email'
                    label='Email Address'
                    placeholder="Enter Email Address"
                    required
                  />
                </Form.Item>
              </Col>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="business_phone"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Phone No',
                    }
                  ]}
                >
                  <Input
                    type='phone'
                    label='Phone No.'
                    placeholder="Phone No"
                    required
                  />
                </Form.Item>
              </Col>
              <Col md={{ span: '24' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="business_address"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Business Address',
                    }
                  ]}
                >
                  <Input
                    type='text'
                    label='Business Address'
                    placeholder="Business address"
                    required
                  />
                </Form.Item>
              </Col>
              <Col md={{ span: '24' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Description',
                    }
                  ]}
                >
                  <Input
                    type='textarea'
                    label='Product/Service Description'
                    placeholder="A brief description of the products or services provided by the partner"
                    required
                  />
                </Form.Item>
              </Col>
            </Row>
          </ModalLayout.ModalSection>

        </ModalLayout>
        <ModalLayout>

          <ModalLayout.ModalSection title="Primary Contact">
            <Row gutter={[15, 0]}>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="primary_full_name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Full Name',
                    }
                  ]}
                >
                  <Input
                    type='text'
                    label='Full Name'
                    placeholder="Full Name"
                    required
                  />
                </Form.Item>
              </Col>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="primary_role"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Role',
                    }
                  ]}
                >
                  <Input
                    type='text'
                    label='Role'
                    placeholder="Role"
                    required
                  />
                </Form.Item>
              </Col>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="primary_email"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Email  Address',
                    },
                    {
                      type: 'email',
                      message: 'Please enter valid Email Address'
                    }
                  ]}
                >
                  <Input
                    type='text'
                    label='Email Address'
                    placeholder="Email  Address"
                    required
                  />
                </Form.Item>
              </Col>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="primary_phone_no"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Phone No.',
                    }
                  ]}
                >
                  <Input
                    type='phone'
                    label='Phone No'
                    placeholder="Phone No"
                    required
                  />
                </Form.Item>
              </Col>
            </Row>
          </ModalLayout.ModalSection>

        </ModalLayout>
      </Form>
    </GlobalDrawer>
  )
};


export default SupplyChainPartner;
