import { MoreOutlined } from '@ant-design/icons'
import React from 'react'
import { Dropdown, Menu } from 'antd';
import { TableActionItem } from '..';
import styled from 'styled-components';
import { ActionIcon } from 'components/AdiTable/ActionDropdown';

const TableAction = ({ items, record }: { items: TableActionItem[], record: any }) => {
  const menu = (
    <Menu>
      {
        items.map((item: TableActionItem) => {
          return (
            <Menu.Item
              key={item.key}
              onClick={(e) => {
                e.domEvent.stopPropagation()
                item.onClick(record)
              }}>
              {item.label}
            </Menu.Item>
          )
        })
      }
    </Menu>
  );

  return (
    <div>
      <Dropdown overlay={menu} placement="bottomRight">
        <IconWrapper
          onClick={(e) => {
            e.stopPropagation()
          }}>
          <ActionIcon />
        </IconWrapper>
      </Dropdown>
    </div>
  );
};

export default TableAction

const IconWrapper = styled.div`
	width: fit-content;
	// margin: 0 auto;
	padding: 5px;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out, border-radius 0.2s ease-in-out;

	&:hover {
		background-color: #80808060;
		border-radius: var(--border-radius);
	}
`;