/**
 * The Header to render for mobile devices.
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import LogOut from '../../../components/GlobalButtons/LogOutBotton';
import { formatRoute } from '../../../utilities/Functions/FormatWords';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: var(--side-bar-color);
  display: flex;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
`;

const Item = styled.div`
  flex: 0 0 33.33%;
  text-align: center;
  margin: auto;
  
  *{
    color: white;
    font-size: 2rem;
    margin: auto;
  }
  
  a{
    text-decoration: none;
    
    &:hover{
        text-decoration: none;
    }

    &:focus{
      color: $text-color-light;
    }
  }
`;

const Title = styled.div`
  font-size: 1.2rem;
  white-space: nowrap;
`;

const MobileHeader = () => {
  const location = useLocation();
  const { pathname } = location;
  return (
    <Wrapper>
      {/* <Item>
        <Alert />
      </Item> */}
      <Item>
        <Title>
          {
            pathname
              ? formatRoute(pathname)
              : 'iTrazo'
          }
        </Title>
      </Item>
      <Item>
        <LogOut />
      </Item>
    </Wrapper>
  );
};

export default MobileHeader;
