/**
 * Utility functions for Relationship Management
 */

import { IContactPayload, IAddressPayload, IContactTableData, IDeliveryTableData, IMeetingAction } from '../statics/types'
import CryptoJS from "crypto-js";
import moment from 'moment';
import { AllBusinessTypes, ENCRYPTION_KEY } from '../statics/constants'



// decrypt text data
export const decryptData = (text: string): string => {
  const bytes = CryptoJS.AES.decrypt(text, ENCRYPTION_KEY);
  var res = JSON.parse(JSON.stringify(bytes.toString(CryptoJS.enc.Utf8)));
  if (res.length > 2) {
    // get rid of quotes
    res = res.slice(1, res.length - 1)
  }
  return res
};


// encrypt text data
export const encryptData = (text: string): string => {
  const res = CryptoJS.AES.encrypt(
    JSON.stringify(text),
    ENCRYPTION_KEY
  ).toString();
  return res;
}

// sort objects by time, descending
export const sortByTimeDesc = (items: any): any => {
  return items.sort((a: any, b: any) => {
    return new Date(b.datetime).getTime() - new Date(a.datetime).getTime()
  })
}

// get all month from sortedData
export const getMonths = (items: any): string[] => {
  var monthTitles: string[] = items.map((item: any) => moment(new Date(item.datetime)).format('MMMM YYYY'))
  if (monthTitles.length > 0) {
    // remove duplicate month title
    monthTitles = Array.from(new Set(monthTitles))
  }
  return monthTitles
}



// log errors
export const logError = (e: any) => {
  // console.log('Error: ')
  console.log("API error: ", e)
  if (e.response) console.log(e.response)
}

// check if the month title should be displayed in logs
export const monthDisplayCheck = (month: string, monthIdx: number, time: string, dataIndex: number) => {
  var dateDisplay = false
  // if the month title is different from the previous one, display the month title
  if (month !== moment(new Date(time)).format('MMMM YYYY')) {
    monthIdx += 1;
    dateDisplay = true
  } else {
    // make sure the first month title is displayed
    if (dataIndex === 0) {
      dateDisplay = true
    }
  }
  return { dateDisplay, monthIdx } as const
}



// Get the abbreviation of the business name
export const getAbbreviation = (name: string) => {
  const nameArray = name.split(' ');
  if (nameArray.length === 1) {
    return nameArray[0].charAt(0).toUpperCase();
  }
  // return nameArray[0].charAt(0).toUpperCase() + '-' + nameArray[1].charAt(0).toUpperCase();
  return nameArray[0].charAt(0).toUpperCase() + nameArray[1].charAt(0).toUpperCase();
};



// determine business id
export const getBusinessIdKey = (accountType: 'unassigned' | 'company' | 'supplier' | 'customer' | 'lead') => {
  switch (accountType) {
    case 'supplier':
      return 'supplier_ID'
    case 'customer':
      return 'customer_ID'
    case 'lead':
      return 'lead_ID'
    default:
      return 'company_ID'
  }
}


export const getTableMenuList = (businessType: string) => {

  let menuList: any = []
  console.log('abc businessType', businessType)

  switch (businessType) {

    case AllBusinessTypes.CUSTOMER:
      menuList = [
        { label: 'Duplicate as Supplier' }
      ]
      break
    case AllBusinessTypes.SUPPLIER:
      menuList = [
        { label: 'Duplicate as Customer' }
      ]
      break
    case AllBusinessTypes.LEAD:
      menuList = [
        { label: 'Convert to Customer' },
        { label: 'Convert to Supplier' },
      ]
      break

    default:
      menuList = [
        { label: 'Convert to Lead' },
        { label: 'Convert to Supplier' },
        { label: 'Convert to Customer' },
      ]
      break
  }

  return menuList
};

export const includeColumn = (selectedColumnList: any[], dataIndex: string) => {
  const result = selectedColumnList.some((item) => item == dataIndex)
  return result
}

// check if the cursor has selected any text on the window
export const checkWindowSelectedText = () => {
  let text = '';
  if (window.getSelection) {
    text = window?.getSelection()?.toString() || '';
  }
  if (text.length > 0) {
    return true
  } else {
    return false
  }
}

export const getBusinessType = (businessObject: any) => {
  if (businessObject?.customer_ID) {
    return 'customer'
  } else if (businessObject?.supplier_ID) {
    return 'supplier'
  } else if (businessObject?.lead_ID) {
    return 'lead'
  } else {
    return 'company'
  }
}
