import React, { useState } from 'react'
import { FlexWrapper } from 'statics/styles/StyledComponents'
import Input from 'components/Input'
import SimpleButton from 'components/GlobalButtons/SimpleButton'
import CategoryIcon from '../../statics/images/CategoryIcon.svg'
import { useAuthStore } from 'zustand-stores'
import { AuthStore } from 'zustand-stores/useAuthStore'


type Props = {
  createOnClick: Function;
  onSearch: Function;
  searchPlaceholder: string;
  createBtnText: string;
}


const Header = (props: Props) => {
  const { createOnClick, onSearch, searchPlaceholder, createBtnText } = props
  const isAmReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.am.isReadOnly)

  const [searchText, setSearchText] = useState('')


  return (
    <FlexWrapper flexBetween alignEnd style={
      {
        padding: '0 10px',
      }
    }>
      <Input
        placeholder={searchPlaceholder}
        type="search"
        onChange={(e: any) => setSearchText(e.target.value)}
        onSearch={() => onSearch(searchText)}
        allowClear
      />
      {
        !isAmReadOnly &&
        <SimpleButton
          text={createBtnText}
          onClick={createOnClick}
        />
      }

    </FlexWrapper>
  )
}

export default Header