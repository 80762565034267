import { Col, Divider, Form, Row, message } from 'antd';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import GlobalDrawer from 'components/GlobalModal/GlobalDrawer';
import Input from 'components/Input';
import React, { useEffect, useState } from 'react'
import { FlexWrapper } from 'statics/styles/StyledComponents';
import styled from 'styled-components';
import { FOB_CATEGORY, FOB_DESCRIPTION } from '../statics/constants';
import VALIDATION_RULES from 'utilities/inputValidation';
import { convertDateAndTime, getSessionStorage } from 'utilities/Functions/GlobalHelperFunctions';
import { SESSION_STORAGE_KEYS } from 'utilities/CONSTANTS';


import FoBAPI from '../apis/FoBAPI';
import moment from 'moment';
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';

const { ModalLayout } = GlobalDrawer;

type Props = {
  formName?: string;
  open: boolean;
  onClose: Function;
  reload: Function;  // onSuccess callback
}

const AssignFoB = (props: Props) => {
  const { formName, open, onClose, reload } = props

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  const username = getSessionStorage(SESSION_STORAGE_KEYS.USER_NAME) || ''

  const [fobDescriptionOptions, setFobDescriptionOptions] = useState<any>([])

  const [form] = Form.useForm();

  const disabledDateFunction = (current: any) => {
    return current && current < new Date().setHours(0, 0, 0, 0)
  };

  useEffect(() => {
    if (!open) return;

    initializeAssignFob()

    return () => form.resetFields()

  }, [open])

  const initializeAssignFob = () => {
    const values = {
      assigned_by: username,
      time: moment(new Date),
    }

    form.setFieldsValue(values)

  }

  const renderFobDescription = (selectedCategory: string) => {
    console.log('e==>', selectedCategory, 'FOB_DESCRIPTION==>', FOB_DESCRIPTION)
    let options = [];
    const fobCategory = selectedCategory as keyof typeof FOB_DESCRIPTION

    if (fobCategory) {
      options = FOB_DESCRIPTION[fobCategory]
      setFobDescriptionOptions([...options])
    } else {
      setFobDescriptionOptions([])
    }
  }

  const onFormFinish = (values: any) => {
    console.log('onFormFinish==>', values)

    Object.keys(values).forEach(key => {
      if (values[key] === undefined) values[key] = ''
    })

    const { date, time, ...rest } = values
    const Date = convertDateAndTime(date, 'datetime')
    const Time = time ? convertDateAndTime(time, 'datetime') : ''
    console.log('finalFormFinish==>', Date, Time, rest)

    let finalPayload;
    const notMandatory = { // these fields are not mandatory, but needs to pass in the payload
      role: values?.role || '',
      phone_no: values?.phone_no || '',
      time: Time,
    }

    finalPayload = {
      ...rest,
      ...notMandatory,
      date: Date,
      fob_status: 'assigned',
    }

    console.log('finalPayload==>', finalPayload)

    setIsGlobalLoading(true)
    FoBAPI.addAssignFoB(finalPayload).then((res: any) => {
      message.success('FoB assigned successfully!')
      reload()
      closeForm()
    }).catch((err: any) => {
      message.error('Failed to Assign FoB.')
    }).finally(() => {
      setIsGlobalLoading(false)
    })
  }

  const closeForm = () => {
    onClose()
    form.resetFields()
  }

  const renderFooter = () => (
    <FlexWrapper flexEnd key='0' gap='10px'>
      <SimpleButton
        text='Cancel'
        id='modal-btn-width-regular'
        onClick={closeForm}
        isCancel
      />
      <SimpleButton
        text={'Assign'}
        form={formName || 'item-form'}
        id='modal-btn-width-regular'
        htmlType="submit"
      />
    </FlexWrapper>
  );

  return (
    <GlobalDrawer
      open={open}
      width={700}
      onClose={closeForm}
      title="Assign FOB"
      footer={renderFooter()}
    >
      <Form
        form={form}
        name={formName || 'item-form'}
        onFinish={onFormFinish}
      >
        <>
          <ModalLayout>

            <ModalLayout.ModalSection title="FoB Details">
              <Row gutter={[15, 0]}>
                <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name={'fob_category'}
                    rules={[{ required: true, message: 'Please select FoB Category.' }]}
                    getValueFromEvent={(e: any) => {
                      console.log('e==>', e)
                      renderFobDescription(e)
                      form.setFieldsValue({ fob_description: undefined, fob_number: undefined })
                      return e
                    }}
                  >
                    <Input
                      label={'FoB Category'}
                      type='select'
                      placeholder={'Select FoB Category'}
                      options={FOB_CATEGORY}
                      required
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name={'fob_description'}
                    rules={[{ required: true, message: 'Please select FoB Description.' }]}
                    getValueFromEvent={(e: any) => {
                      Array.isArray(fobDescriptionOptions) && fobDescriptionOptions.find((item: any) => {
                        if (item.value === e) {
                          form.setFieldsValue({ fob_number: item.fob_number })
                        }
                      })
                      return e
                    }}
                  >
                    <Input
                      label={'FoB Description'}
                      type='select'
                      placeholder={'Select FoB Description'}
                      options={fobDescriptionOptions}
                      required
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name={'fob_number'}
                  >
                    <Input
                      label={'FoB Number'}
                      type='text'
                      placeholder={'FoB Number (Auto-populate)'}
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
            </ModalLayout.ModalSection>

          </ModalLayout>
          <ModalLayout>

            <ModalLayout.ModalSection title="Assigned To">
              <Row gutter={[15, 0]}>
                <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name={'first_name'}
                    rules={[{ required: true, message: 'Please enter First Name.' }]}
                  >
                    <Input
                      label={'First Name'}
                      type='text'
                      placeholder={'First Name'}
                      required
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name={'last_name'}
                    rules={[{ required: true, message: 'Please enter Last Name.' }]}
                  >
                    <Input
                      label={'Last Name'}
                      type='text'
                      placeholder={'Last Name'}
                      required
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name="email"
                    rules={[
                      VALIDATION_RULES.TYPE.EMAIL,
                      {
                        required: true,
                        message: 'Please enter Valid Email.',
                      }
                    ]}
                  >
                    <Input
                      type='email'
                      label='Email'
                      placeholder="Email"
                      required
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name="phone_no"
                  >
                    <Input
                      type='phone'
                      label='Phone No.'
                      placeholder="Phone No."

                    />
                  </Form.Item>
                </Col>
              </Row>
            </ModalLayout.ModalSection>

          </ModalLayout>
          <ModalLayout>

            <ModalLayout.ModalSection title="Company">
              <Row gutter={[15, 0]}>
                <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name="company"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Company Name.',
                      }
                    ]}
                  >
                    <Input
                      type='text'
                      label='Company'
                      placeholder="Company"
                      required
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name="role"
                  >
                    <Input
                      type='text'
                      label='Role'
                      placeholder="Enter Role"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </ModalLayout.ModalSection>

          </ModalLayout>
          <ModalLayout>

            <ModalLayout.ModalSection title="Date & Time">
              <Row gutter={[15, 0]}>
                <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name="date"
                    rules={
                      [{
                        required: true,
                        message: 'Please select Date.',
                      }]
                    }
                  >
                    <Input
                      type='date'
                      label='Date'
                      placeholder="Select Date"
                      disabledDate={disabledDateFunction}
                      required
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name="time"
                    rules={
                      [{
                        required: true,
                        message: 'Please select Time.',
                      }]
                    }
                  >
                    <Input
                      type='time'
                      label='Start Time'
                      placeholder="Select Time"
                      format='HH:mm'
                      showNow
                      required
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name="assigned_by"
                    rules={
                      [{
                        required: true,
                        message: 'Please enter Assigned By.',
                      }]
                    }
                  >
                    <Input
                      type='text'
                      label='Assigned By'
                      placeholder="Assignee Name"
                      disabled
                      required
                    />
                  </Form.Item>
                </Col>
              </Row>
            </ModalLayout.ModalSection>

          </ModalLayout>
        </>
      </Form>
    </GlobalDrawer>
  )
}

export default AssignFoB

