import { Steps } from 'antd';
import React from 'react';
import { theme } from 'statics/styles/StyledComponents';
import styled from 'styled-components';
import { useResponsive } from 'utilities/hooks';
import { isMobile } from 'react-device-detect';


const FlowInfoPanel = ({ items, style }: { items: any[]; style?: any }) => {
  const screen = useResponsive();

  if (screen.xs) {
    return null
  } else {
    return (
      <Container >
        {/* <Header>Process Map</Header> */}
        <MainContainer >
          <StepContainer >
            {items.map(({ step, label }, index) => {
              const isFirstStep = index === 0;
              const isLastStep = index === items.length - 1;

              // Define colors for steps and lines
              const stepColor = isFirstStep
                ? '#50A1A5' // First step circle
                : isLastStep
                  ? '#A340B4' // Last step circle
                  : '#6E6BEE'; // Middle steps circle

              const lineColor = index === 1
                ? '#50A1A5' // Line between Step 1 and Step 2
                : '#6E6BEE'; // Default line color

              return (
                <StepWrapper key={index}>
                  {/* Connector Line */}
                  {!isFirstStep && (
                    <StepConnector style={{ backgroundColor: lineColor }} />
                  )}

                  {/* Step Circle */}
                  <StepCircle style={{ borderColor: stepColor }}>
                    <StepNumber style={{
                      color: stepColor,
                    }}>{step}</StepNumber>
                  </StepCircle>

                  {/* Step Label */}
                  <StepLabel>{label}</StepLabel>
                </StepWrapper>
              );
            })}
          </StepContainer>
        </MainContainer>
      </Container>
    );
  }
};

export default FlowInfoPanel;

// Container for the whole panel
const Container = styled.div`
  // display: flex;
  // flex-direction: column;
  // padding: 16px;
  // background-color: #2c2d42;
  // border-radius: 8px;
  // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  // overflow-x: auto;

  min-width: unset !important;
   display: flex;
   flex-direction: column;
   margin-bottom: 20px;
  //  justify-content: flex-start;
   align-items: center;
  //  border-radius: 4px;
  //  background-color: #2C2D42;
   padding: 5px 12px;
  //  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  //  border: 1px solid var(--input-border-color);
     ${theme.myScrollbar}
   overflow-x: auto;
   ::-webkit-scrollbar {
     height: 5px;
   }
`;

const MainContainer = styled.div`
  width: 100%;
  max-width: 1200px;
`;


// Step container (flexbox layout for steps)
const StepContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

// Wrapper for each step
const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;
`;

// Step circle (number container)
const StepCircle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2c2d42;
  z-index: 2; /* Ensure circles are above lines */
`;

// Step number inside the circle
const StepNumber = styled.span`
  font-size: 12px;
  font-weight: bold;
`;

// Step label below the step
const StepLabel = styled.div`
  margin-top: 8px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
`;

// Connector line between steps
const StepConnector = styled.div`
  position: absolute;
  top: 30%; /* Align line to center vertically */
  left: -50%;
  width: 100%;
  height: 3px;
  transform: translateY(-50%);
  z-index: 1; /* Ensure line stays behind circles */
`;






