import React, { useEffect, useState } from "react";
import { Rectangle } from "../CrossDocking";
import SimpleButton from "components/GlobalButtons/SimpleButton";
import Label from "components/Label";
import styled from "styled-components";

//import "./Styles.css";
export const AddRect = ({
  activeInput,
  rectangle,
  parent,
  rectangleMode,
  onSave,
  onClose,
}: {
  activeInput: boolean;
  rectangle: Rectangle;
  parent?: Rectangle;
  rectangleMode: string;
  // eslint-disable-next-line no-unused-vars
  onSave: (updateRectangle: Rectangle) => void;
  onClose: () => void;
}) => {
  const [rectData, setRectData] = useState<Rectangle>(() => ({
    ...rectangle,
  }));
  const [error, setError] = useState("");

  useEffect(() => {
    setRectData({ ...rectangle });
  }, [rectangle]);

  const roundToGrid = (rectData: Rectangle): Rectangle => {
    return Object.fromEntries(
      Object.entries(rectData).map(([key, value]) => [
        key,
        typeof value === "number" && key !== "levels"
          ? Math.round(value / 10) * 10
          : value,
      ])
    ) as Rectangle;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // Validate the input value
    if (rectangleMode !== "hub" && parent && name === "levels") {
      const numericValue = parseInt(value, 10);
      if (numericValue > parent?.levels || numericValue < 1) {
        rectangleMode === "shelf"
          ? setError("The number must be between 1 and max Hub level")
          : setError("The number must be between 1 and max Shelf level");
      } else {
        setError("");
      }
    }
    setRectData((prev) => ({
      ...prev,
      [name]: ["startX", "startY", "width", "length", "levels"].includes(name)
        ? value === "" || isNaN(Number(value)) ? "" : Number(value)
        : value,
    }));
  };

  function onSaveShelf() {
    if (parent) {
      if (rectData.levels <= parent?.levels) {
        const updateRectangle = { ...rectangle, ...roundToGrid(rectData) };
        onSave(updateRectangle);
        onClose();
      }
    }
    else {
      const updateRectangle = { ...rectangle, ...roundToGrid(rectData) };
      onSave(updateRectangle);
      onClose();
    }
  }

  if (!activeInput) return null;

  return (
    <div
      style={{
        position: "absolute",
        width: "25%",
        top: "20%",
        right: "0px",
        overflowY: "auto",
        padding: "10px",
        background: "hsl(168, 90%, 50%, 0.5)",
        border: "1px solid #ccc",
        borderRadius: "4px",
        zIndex: 9999,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div>
        <Label>Name</Label>
        <Input
          name="name"
          placeholder="Name"
          value={rectData.name}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <Label>Start X</Label>
        <Input
          name="startX"
          placeholder="Start X"
          value={rectData.startX}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <Label>Start Y</Label>
        <Input
          name="startY"
          placeholder="Start Y"
          value={rectData.startY}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <Label>Width</Label>
        <Input
          name="width"
          placeholder="Shelf Width"
          value={rectData.width}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <Label>Length</Label>
        <Input
          name="length"
          placeholder="Length"
          value={rectData.length}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <Label>{rectangleMode === "bin" ? "Assigned level" : "Levels"}</Label>
        <Input
          name="levels"
          placeholder="Shelf Depth"
          value={rectData.levels}
          onChange={handleInputChange}
        />
      </div>
      {error && <div style={{ color: "yellow" }}>{error}</div>}
      <div>
        <SimpleButton text="+ Submit" onClick={onSaveShelf} />
      </div>
    </div>
  );
};


const Input = styled.input`
  background: hsla(168, 90%, 50%, 0.8);
  border: 1px solid hsla(168, 90%, 50%, 0.5);
  border-radius: 4px;
  color: #333;
`;

