import React, { useCallback, useEffect, useState, MouseEvent } from 'react'
import { Col, Divider, Form, Row, message } from 'antd';
import { FlexBox } from 'statics/styles/StyledComponents';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import Input from 'components/Input';
import { getSelectorOptions } from 'utilities/Functions/GlobalHelperFunctions';
import { DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import VALIDATION_RULES from 'utilities/inputValidation';


import MandatorySpan from 'components/MandatorySpan';
import GlobalDrawer from 'components/GlobalModal/GlobalDrawer';
import CustomerStoreAPI from '../../apis/CustomerStoreAPI';
import CRM_CustomerAPI from '../../apis/CRM_CustomerAPI';
import SearchFullAddressInputFormListItem from 'components/AddressSearchInput/SearchFullAddressInputFormListItem';
import { CustomerDirectoryDataType } from '../../statics/types';
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';

const INPUT_MARGIN_BOTTOM = '10px'

type Props = {
  getTableData: Function;
  isModalOpen: boolean;
  setIsModalOpen: Function;
  customerDirectoryData: CustomerDirectoryDataType;
  setCustomerDirectoryData: Function;
  // addressState: any;
  // setAddressState: Function;
}

const StoreModal = (props: Props) => {
  const { getTableData, isModalOpen, setIsModalOpen, customerDirectoryData, setCustomerDirectoryData } = props
  const [form] = Form.useForm();
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  const emptyCustomerDirectoryData: CustomerDirectoryDataType = {
    customer_ID: "",
    customer_name: "",
    stores: [{
      store_ID: "",
      store_name: "",
      store_email: "",
      store_phone: "",
      // store_unit: "",
      // store_street: "",
      // store_suburb: "",
      // store_country: "Australia",
      // store_state: "",
      // store_postcode: "",
      full_address: "",
      contact_role: "",
      contact_department: "",
      contact_first_name: "",
      contact_last_name: "",
      contact_phone: "",
      contact_email: "",
    },],
  }


  const [customerList, setCustomerList] = useState<any>([]);

  const initForm = async () => {

    console.log('initForm  ', customerDirectoryData)

    form.setFieldsValue(customerDirectoryData)

    const result = await CRM_CustomerAPI.getAllCustomer()
    setCustomerList(result)

  }

  const initialize = useCallback(
    () => { initForm() },
    [isModalOpen, customerDirectoryData],
  )
  useEffect(initialize, [isModalOpen, customerDirectoryData])

    ;

  const handleModalClose = (e?: MouseEvent) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
    form.resetFields()

    setCustomerDirectoryData(emptyCustomerDirectoryData)
    form.setFieldsValue({ ...emptyCustomerDirectoryData })

  }
  const handleConfirm = async (values: any) => {
    console.log('values', values)
    // console.log('addressState', addressState)

    const newStores = []
    for (let i = 0; i < values.stores.length; i++) {
      const { contact_department, contact_first_name, contact_last_name, contact_phone, contact_email, contact_role } = values.stores[i]
      // const { unit, street, suburb, postcode, state, country, full_address } = addressState[i]
      newStores.push({
        // store_ID,
        // store_name,
        // store_email,
        // store_phone,
        // // unit,
        // // street,
        // // suburb,
        // // postcode,
        // // state,
        // // country,
        // full_address,
        ...values.stores[i],
        contact_department: contact_department || '',
        contact_first_name: contact_first_name || '',
        contact_last_name: contact_last_name || '',
        contact_phone: contact_phone || '',
        contact_email: contact_email || '',
        contact_role: contact_role || '',
      })
    }


    const payload: any = {
      // customer_ID: values.customer_ID,
      // stores: [...newStores]
      ...values
    }
    console.log("handleConfirm stores res payload", payload);

    // return
    setIsGlobalLoading(true)
    const hide = message.loading(`${customerDirectoryData?.customer_ID == '' ? 'Adding' : 'Updating'} store...`,);

    try {
      let res: any
      if (customerDirectoryData?.customer_ID == '') {
        res = await CustomerStoreAPI.addStore(payload)
      } else {
        res = await CustomerStoreAPI.updateStore(payload)
      }

      console.log('handleConfirm sensor res', res)
      hide()
      setIsGlobalLoading(false)
      handleModalClose()
      message.success(`${customerDirectoryData?.customer_ID == '' ? 'Added' : 'Updated'} store successfully, thank you`)
      getTableData()
    } catch (err: any) {
      console.log('Add asset master data fail...', err)
      message.error(err.message)
      setIsGlobalLoading(false)
    }


  }

  const renderOptions = (type?: string) => {
    if (type == 'customer') {
      const customerOptions = Array.isArray(customerList) ? customerList.map((item: any) => ({
        label: item.bus_name,
        value: item.customer_ID,
      })) : []
      return getSelectorOptions(customerOptions, 'Select Customer', 'value', 'label')
    } else {
      return getSelectorOptions([], 'Select')
    }
  }




  const renderFooter = () => {
    return [
      <FlexBox key={'1'} style={{ justifyContent: 'flex-end', margin: '0' }}>
        <SimpleButton
          id='modal-btn-width-regular'
          isCancel={true}
          text="Cancel"
          onClick={() => handleModalClose()}
          style={{ marginRight: '1rem' }}
        />
        <SimpleButton
          id='modal-btn-width-regular'
          htmlType="submit"
          text={customerDirectoryData?.customer_ID == '' ? `Add` : `Save`}
          form='add-store'
        />
      </FlexBox>
    ]
  }



  return (
    <>
      <GlobalDrawer
        title={customerDirectoryData?.customer_ID == '' ? `Add Stores` : `Edit Stores`}
        open={isModalOpen}
        onClose={(e: MouseEvent) => handleModalClose(e)}
        footer={renderFooter()}
      >
        <Form
          form={form}
          name="add-store"
          onFinish={(values: any) => {
            handleConfirm(values)
          }}
        >
          <Row gutter={[15, 0]}>
            <Col xs={{ span: '24' }} sm={{ span: '12' }}>
              <Form.Item
                name="customer_ID"
                rules={[{ required: true, message: 'Please select a Customer ID' }]}
                style={{ marginBottom: INPUT_MARGIN_BOTTOM }}
              >
                <Input
                  label="Customer ID"
                  name="customer_ID"
                  type="select"
                  placeholder="Customer ID"
                  required={true}
                  options={renderOptions('customer')}
                  value={customerDirectoryData.customer_ID}
                  disabled={customerDirectoryData?.customer_ID ? true : false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.List name="stores" initialValue={customerDirectoryData.stores}>
            {(fields, { add, remove }) => (
              <div>
                {fields.map(({ key, name, ...restField }, index) => (
                  <div key={name} style={{ marginBottom: '10px' }} >
                    <Divider orientation="left" orientationMargin="0">
                      Store {name + 1}
                    </Divider>
                    <StoresDiv>
                      {
                        name > 0 &&
                        <div style={{ float: 'right', marginTop: '5px', marginRight: '5px' }}>
                          <DeleteOutlined
                            style={{ cursor: 'pointer', color: '#c6c6c6', fontSize: '15px' }}
                            onClick={() => {
                              remove(name)

                            }}
                          />
                        </div>
                      }
                      <Row gutter={[15, 0]}>
                        <Col xs={{ span: '24' }} sm={{ span: '12' }}>
                          <Form.Item
                            {...restField}
                            name={[name, 'store_ID']}
                            initialValue={''}
                            rules={[VALIDATION_RULES.MAX.ID, { required: true, message: 'Please input your Store ID' }]}
                            style={{ marginBottom: INPUT_MARGIN_BOTTOM }}

                          >
                            <Input
                              label="Store ID"
                              name="store_ID"
                              type="text"
                              placeholder="Store ID"
                              required={true}
                              disabled={customerDirectoryData?.customer_ID ? true : false}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: '24' }} sm={{ span: '12' }}>
                          <Form.Item
                            {...restField}
                            name={[name, 'store_name']}
                            initialValue={''}
                            rules={[VALIDATION_RULES.MAX.BUSINESS_NAME, { required: true, message: 'Please input your Store Name' }]}
                          >
                            <Input
                              label="Store Name"
                              name="store_name"
                              type="text"
                              placeholder="Store Name"
                              required={true}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: '24' }} sm={{ span: '12' }}>
                          <Form.Item
                            {...restField}
                            name={[name, 'store_email']}
                            initialValue={''}
                            rules={[VALIDATION_RULES.TYPE.EMAIL, { required: true, message: 'Please enter the Store Email' }]}
                          >
                            <Input
                              label="Email"
                              // name="store_email"
                              type="email"
                              placeholder="Enter Email"
                              required={true}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: '24' }} sm={{ span: '12' }}>
                          <Form.Item
                            {...restField}
                            name={[name, 'store_phone']}
                            initialValue={''}
                            rules={[VALIDATION_RULES.MAX.PHONE]}
                          >
                            <Input
                              label="Phone No."
                              name="store_phone"
                              type="phone"
                              placeholder="Enter Phone No."
                            // required={true}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <MainLabel marginBottom>Address<MandatorySpan /></MainLabel>
                      <SearchFullAddressInputFormListItem
                        form={form}
                        formListName={'stores'}
                        formItemProps={{
                          name: [name, 'full_address'],
                          rules: [{ required: true, message: 'Please enter the Store Address' }]
                        }}
                      />
                      <MainLabel marginBottom>Contact Person</MainLabel>
                      <Row gutter={[15, 0]}>
                        <Col xs={{ span: '24' }} sm={{ span: '12' }}>
                          <Form.Item
                            {...restField}
                            name={[name, 'contact_role']}
                          >
                            <Input label="Role"
                              type="text"
                              placeholder={'Role'}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: '24' }} sm={{ span: '12' }}>
                          <Form.Item
                            {...restField}
                            name={[name, 'contact_department']}
                          >
                            <Input label="Department"
                              type="text"
                              placeholder={'Department'}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: '24' }} sm={{ span: '12' }}>
                          <Form.Item
                            {...restField}
                            name={[name, 'contact_first_name']}
                          >
                            <Input label="First Name"
                              type="text"
                              placeholder={'First Name'}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: '24' }} sm={{ span: '12' }}>
                          <Form.Item
                            {...restField}
                            name={[name, 'contact_last_name']}
                          >
                            <Input label="Last Name"
                              type="text"
                              placeholder={'Last Name'}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: '24' }} sm={{ span: '12' }}>
                          <Form.Item
                            {...restField}
                            name={[name, 'contact_email']}
                            rules={[VALIDATION_RULES.TYPE.EMAIL]}
                          >
                            <Input label="Email"
                              type="text"
                              placeholder={'Email'}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: '24' }} sm={{ span: '12' }}>
                          <Form.Item
                            {...restField}
                            name={[name, 'contact_phone']}
                          >
                            <Input label="Phone No."
                              type="phone"
                              placeholder={'Phone No.'}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </StoresDiv>
                  </div>
                ))}
                <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
                  {customerDirectoryData?.customer_ID == '' ? (
                    <SimpleButton
                      style={{ width: '36%', alignSelf: 'center' }}
                      outlined
                      text="+ Add More Stores"
                      onClick={() => {
                        add()
                      }}
                    />
                  ) : null}

                </div>
              </div>
            )}
          </Form.List>
        </Form>
      </GlobalDrawer>
    </>
  )
}

type marginStyle = {
  marginBottom: boolean;
};

export default StoreModal
const MainLabel = styled.p`
    font-size: 16px;
    color: white;
    margin-top: 10px !important;
    cursor: pointer;
    font-weight: bold;
    margin-bottom: ${(props: marginStyle) => {
    return props.marginBottom ? "5px !important" : "25px !important";
  }};
`;
const StoresDiv = styled.div`
  background-color: var(--modal-subBody-color);
  padding: 5px 10px 20px 10px;
  border-radius: var(--border-radius);
  margin-left: -10px;
`;




