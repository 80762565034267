import React, { lazy, Suspense, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import styled from "styled-components";
import Navigation from "../../../Navigations/DesktopNavigation";
import { SESSION_STORAGE_KEYS } from "../../../../utilities/CONSTANTS";
import { Page } from "../../../../utilities/types";
import { Button } from "../../../../statics/styles/StyledComponents";
import {
  getOrganization,
  getSessionStorage,
} from "utilities/Functions/GlobalHelperFunctions";
import ValueChainTrackingRoutes from "../ProductTrackingRouting";
import AdminRouting from "../AdminRouting";
import RelationshipManagementRouting from "../RelationshipManagementRouting";
import ContentManagementRouting from "../ContentManagementRouting";
import VisitorManagementRouting from "../VisitorManagementRouting";
import OSMRouting from "../OSMRouting";
import StockAndProductionRouting from "../StockAndProductionRouting";
const Footer = lazy(() => import("../../../../components/Footer"));

type Props = {
  logo: string;
  pages: Page[];
  pagesExternal: Page[];
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
`;

const HeaderWrapper = styled.div`
  flex: 1 1 ${(props) => props.theme.headerHeight};
  height: 100%;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  height: 100%;
`;

const Left = styled.div`
  position: relative;
  height: 100%;
  width: ${({ fold, ...props }: { fold: boolean;[prop: string]: any }) => {
    if (fold) return props.theme.collapseWidth;
    return props.theme.sideWidth;
  }};
  // margin-right: 10px;
`;

const Right = styled.div`
  position: relative;
  height: 100%;
  width: 80%;
  flex: auto;
  padding: 0 0.5em 0.5em 0.5em;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow: auto !important;
`;

const CollapseButton = styled(Button)`
  position: absolute;
  border-radius: 5px;
  left: 1rem;
  top: 1.5rem;
  z-index: 1;
  font-size: 2rem;
  text-align: left;
`;

const PageContentWrapper = styled.div`
  flex: auto;
  height: 95%;
`;

type PureProps = {
  pages: Page[];
};

// not updating page on collapse navigation
class PageDisplay extends React.PureComponent<PureProps> {
  render() {
    const { pages } = this.props;
    return (
      <Routes>
        {pages.map((page) => (
          <Route
            key={page.key}
            path={page.path}
            element={<page.component apis={page.apis} />}
          />
        ))}
        {pages.map((page) =>
          page.subNav?.map((pagerec: Page) => (
            <Route
              key={pagerec.key}
              path={pagerec.path}
              element={<pagerec.component apis={pagerec.apis} />}
            />
          ))
        )}
        <Route path="/" element={<Navigate to={pages[0].path} replace />} />
      </Routes>
    );
  }
}

const DesktopLayout = (props: Props) => {
  const { logo, pages, pagesExternal } = props;

  const [fold, setFold] = useState(true);

  const idToken = getSessionStorage(SESSION_STORAGE_KEYS.IDTOKEN, true);
  let payload: any = null;
  let organization: any = null;
  let name: any = null;
  if (idToken) {
    payload = idToken.payload;
    organization = getOrganization();
    name = getSessionStorage(SESSION_STORAGE_KEYS.USER_NAME) || "";
  }

  return (
    <Wrapper>
      <BodyWrapper>
        <Left fold={fold}>
          <Navigation
            logo={logo}
            pages={pages}
            pagesExternal={pagesExternal}
            fold={fold}
            setFold={setFold}
          />
        </Left>
        <Right>
          <ValueChainTrackingRoutes />
          <AdminRouting />
          <RelationshipManagementRouting />
          <ContentManagementRouting />
          <VisitorManagementRouting />
          <OSMRouting />
          <StockAndProductionRouting />
          <PageContentWrapper>
            <Suspense fallback={<div>Loading...</div>}>
              <PageDisplay pages={pages} />
            </Suspense>
          </PageContentWrapper>
          <Suspense fallback={<div>Loading...</div>}>
            <Footer />
          </Suspense>
        </Right>
      </BodyWrapper>
    </Wrapper>
  );
};

export default DesktopLayout;
