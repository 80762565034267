/**
 * @description Company creation form, handles creation and edit for unassigned company, lead, customer and lead; 
 * The main purpose of this component is to render the form and dispatch actions to the custom hook useCompanyForm
 * @version 1.0.0
 * @author Bruce Zhu 
 */

import React, { useState, useEffect } from 'react';
import GlobalModal from '../../../../../components/GlobalModal';
import Input from '../../../../../components/Input';
import { FlexWrapper, FormRequireText } from 'statics/styles/StyledComponents';
import { Row, Col, Form, Radio, RadioChangeEvent, message, FormInstance, Divider } from 'antd'
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import { ADI_CONFIRM, CountryInfo } from 'utilities/Functions/GlobalHelperFunctions'
import VALIDATION_RULES from 'utilities/inputValidation'
import { Steps } from 'antd';
import { useCompanyForm, useSimilarityCheck } from '../../hooks';
import { ActionType } from '../../hooks/useCompanyForm'
import { INDUSTRY_OPTIONS, titleOptions } from '../../statics/constants'
import AdiLabel from 'components/Label';
import { DeleteOutlined } from '@ant-design/icons';
import { ContactAPI, IDCodeAPI, UserAPI } from '../../apis';
import { LeadStages } from '../../statics/constants'
import { getBusinessIdKey } from '../../utilities/functions';
import { BusinessTypes } from '../../statics/types';
import { useResponsive } from 'utilities/hooks';
import styled from 'styled-components';
import AddressSearchInput from 'components/AddressSearchInput';
//import { add, set } from 'lodash';
import AddressSearchInputFormItem from 'components/AddressSearchInput/AddressSearchInputFormItem';


// customer status
const accountTypeOptions = [
  // { label: 'Unassigned', value: 'company' },
  { label: 'Lead', value: 'lead' },
  { label: 'Customer', value: 'customer' },
  { label: 'Supplier', value: 'supplier' },
]


type Props = {
  open: boolean;
  onClose: Function;
  selectedCompany: any;
  isEdit: boolean;
  setReload: Function;
};

const defaultAddressState = {
  unit: "",
  street: "",
  suburb: "",
  postcode: "",
  state: "",
  country: "",
  full_address: "",
}

const CreateCompany = (props: Props) => {
  const { open, onClose, isEdit, selectedCompany, setReload } = props;
  const [businessID, setBusinessID] = useState<any>(null);

  const [step, setStep] = useState(0)
  const { Step } = Steps;
  const screen = useResponsive()
  const [form] = Form.useForm();

  // custom hook that processes the form payload data
  const [payloadState, formDispatch, getEditData] = useCompanyForm()

  // custom hook that checks for duplicate company
  const similarityCheck = useSimilarityCheck()

  const [contactType, setContactType] = useState<'new' | 'existing'>("existing")
  const [businessType, setBusinessType] = useState<BusinessTypes>('customer')
  const [deliveryAddressState, setDeliveryAddressState] = useState<any>(defaultAddressState)
  const [billingAddressState, setBillingAddressState] = useState<any>(defaultAddressState)
  const [additionalAddresses, setAdditionalAddresses] = useState<any>([])
  const [errorStates, setErrorStates] = useState<any>({
    delivery: '',
    billing: '',
  })
  const [errorAdditional, setErrorAdditional] = useState<any>([])


  console.log('form values', form.getFieldsValue())

  // set the form values when edit
  useEffect(() => {
    if (isEdit) {
      // convert "unassigned" to "company" for the business type option display
      console.log('selectedCompany==>', selectedCompany)

      const newSelectedCompany = { ...selectedCompany }
      if (newSelectedCompany.businessType.toLowerCase() === 'unassigned') newSelectedCompany.businessType = 'company'

      console.log('newSelectedCompany', newSelectedCompany.businessType, newSelectedCompany.customer_ID)

      // initialize the business form
      const businessIds = `${newSelectedCompany.businessType.toLowerCase() == 'customer' ? `${newSelectedCompany.customer_ID}` :
        newSelectedCompany.businessType.toLowerCase() == 'lead' ? `${newSelectedCompany.lead_ID}` :
          newSelectedCompany.businessType.toLowerCase() == 'supplier' ? `${newSelectedCompany.supplier_ID}` : ''}`

      console.log('businessIds', businessIds)

      form.setFieldsValue({
        ...newSelectedCompany,
        businessIDs: businessIds,
      })

      const onSuccess = (data: any) => {
        const busIdKey = getBusinessIdKey(data.businessType)
        setBusinessID(data[busIdKey]);
        console.log('dataaaaaaaaaaaaa', data)
        const physicalAddress = data.additional[0]
        const physical_full_address = `${physicalAddress?.unit ? `${physicalAddress.unit}, ` : ''}${physicalAddress?.street ? `${physicalAddress.street}, ` : ''}${physicalAddress?.suburb ? `${physicalAddress.suburb}, ` : ''}${physicalAddress?.state ? `${physicalAddress.state}, ` : ''}${physicalAddress?.postcode ? `${physicalAddress.postcode}, ` : ''}${physicalAddress?.country ? `${physicalAddress.country}` : ''}`
        const full_address = `${data.unit ? `${data.unit}, ` : ''}${data.street ? `${data.street}, ` : ''}${data.suburb ? `${data.suburb}, ` : ''}${data.state ? `${data.state}, ` : ''}${data.postcode ? `${data.postcode}, ` : ''}${data.delivery_country ? `${data.delivery_country}` : ''}`
        const billing_full_address = `${data.billing_unit ? `${data.billing_unit}, ` : ''}${data.billing_street ? `${data.billing_street}, ` : ''}${data.billing_suburb ? `${data.billing_suburb}, ` : ''}${data.billing_state ? `${data.billing_state}, ` : ''}${data.billing_postcode ? `${data.billing_postcode}, ` : ''}${data.billing_country ? `${data.billing_country}` : ''}`
        console.log('full_address==>', physicalAddress, physical_full_address, full_address, billing_full_address)

        let newFormValues = {
          ...data,
          physical_full_address,
          full_address,
          billing_full_address,
          aditional_unit: physicalAddress?.unit,
          aditional_street: physicalAddress?.street,
          aditional_suburb: physicalAddress?.suburb,
          aditional_state: physicalAddress?.state,
          aditional_postcode: physicalAddress?.postcode,
          aditional_country: physicalAddress?.country,
          isAditional_manual_input: physicalAddress?.isAditional_manual_input || 'false',
        }

        form.setFieldsValue({ ...newFormValues })
        // check the contact type

        if (data.contact_ID) {
          setContactType('existing')
        } else {
          setContactType('new')
        }

      }
      // get data and initialise the rest of the form
      getEditData(newSelectedCompany)
        .then(res => {
          formDispatch({ type: ActionType.INIT_EDIT, payload: { onSuccess } })
        })

    }
  }, [isEdit])

  useEffect(() => {
    setStep(0)
  }, [open])

  // empty the form and payload when close modal
  const onCloseModal = () => {
    onClose()
    formDispatch({ type: ActionType.RESET })
    similarityCheck.resetErrorMessage()
    form.resetFields()
    form.setFieldsValue({ additional: [] })  // need to force reset the additional fields
    // once modal is closed, reset the additionalAddresses state
    setAdditionalAddresses([]) // reset the additional address
    setDeliveryAddressState(defaultAddressState) // reset the delivery address
    setBillingAddressState(defaultAddressState) // reset the billing address
    setErrorStates({
      delivery: '',
      billing: '',
    });
    setErrorAdditional([]);
  }


  const readyToConfirm = async (values: any) => {
    // convert undefined to empty string
    console.log('success values.........', values)
    Object.keys(values).forEach(key => {
      if (values[key] === undefined) values[key] = ''
    })

    if (step === 0) {
      const { bus_name, businessType, abn, bus_email, prefix, code } = values
      // combine prefix and code
      const ID = prefix + code
      const busIdKey = getBusinessIdKey(businessType)
      console.log('ID', ID)
      var payload: any = {
        ...values,
        confirm_similarity: similarityCheck.errorMessage.ignored ? true : false,
        active: isEdit ? values.active : true,
        [busIdKey]: isEdit ? values?.businessIDs : ID,
      };

      if (prefix) {
        delete payload.prefix
        delete payload.code
      }

      if (values.businessIDs) {
        delete payload.businessIDs
      }


      console.log('payload', payload)

      // check business similarity
      await similarityCheck.checkSimilarity(bus_name, businessType, abn, bus_email, businessID, isEdit, () => {
        // check successfully
        if (step < 2) setStep(step + 1)
        formDispatch({ type: ActionType.SET_BUSINESS_PAYLOAD, payload: payload })
      })
      console.log('finalPayload', payload)

    }

    if (step === 1) {

      // check contact similarity
      const { first_name, last_name, email, phone, contact_ID } = values
      if (!values?.skip) {
        values = { ...values, confirm_similarity: similarityCheck.errorMessage.ignored ? true : false }

        await similarityCheck.checkContactSimilarity(first_name, last_name, email, phone, contact_ID, () => {
          // check successfully
          if (step < 2) setStep(step + 1)
          formDispatch({ type: ActionType.SET_CONTACT_PAYLOAD, payload: values })
        })
      } else {
        if (step < 2) setStep(step + 1)
        formDispatch({ type: ActionType.SET_CONTACT_PAYLOAD, payload: values })
      }

    }

    if (step === 2) {
      console.log('adressValues==>', values, additionalAddresses)
      let physicalAddressesAsLocationAdress: any[] = [];

      // physicalAddressesAsLocationAdress construct from the values of additional_unit, additional_street, additional_suburb, additional_state, additional_postcode
      // and push to the physicalAddressesAsLocationAdress array
      physicalAddressesAsLocationAdress.push({
        unit: values.aditional_unit,
        street: values.aditional_street,
        suburb: values.aditional_suburb,
        state: values.aditional_state,
        postcode: values.aditional_postcode,
        type: "Location",
        is_manual_input: values.isAditional_manual_input.toString() || "false",
      });

      // construct the address payload and send to the form reducer
      const newAdditionalAddresses = Array(additionalAddresses) && additionalAddresses.map((address: any) => {
        if (address.full_address) {
          const { full_address, ...rest } = address
          return rest
        }
        return address
      })

      const newValues = {
        ...values,
        additional: physicalAddressesAsLocationAdress,
      }
      console.log('newValues==>', newValues)

      // remove below one from the payload
      delete newValues.aditional_unit;
      delete newValues.aditional_street;
      delete newValues.aditional_suburb;
      delete newValues.aditional_state;
      delete newValues.aditional_postcode;
      delete newValues.aditional_country;
      delete newValues.full_address;
      delete newValues.physical_full_address;
      delete newValues.billing_full_address;

      console.log('newValuesafterdelete==>', newValues)

      formDispatch({ type: ActionType.SET_ADDRESS_PAYLOAD, payload: newValues })
      // return
      if (isEdit) updateCompany()
      else createCompany()
    }

  }

  const createCompany = () => {
    const onSuccess = () => {
      onCloseModal()
      setReload()
    }

    formDispatch({ type: ActionType.CREATE, payload: { onSuccess } })
  }

  const updateCompany = () => {
    const onSuccess = () => {
      onCloseModal()
      setReload()
    }

    formDispatch({ type: ActionType.UPDATE, payload: { onSuccess } })
  }

  // make a function to call api to get ID Code
  const getIDCode = async (businessType: BusinessTypes) => {
    let IDCode: any = '';
    if (businessType === 'lead') {
      IDCode = await IDCodeAPI.getLeadID()
    } else if (businessType === 'supplier') {
      IDCode = await IDCodeAPI.getSupplierID()
    } else {
      IDCode = await IDCodeAPI.getCustomerID()
    }
    if (!IDCode) return message.error('Failed to get ID Code')
    return IDCode
  }


  console.log('open does change', open, step)

  const goBack = () => {
    if (step > 0) setStep(step - 1)
    if (step === 0) {
      similarityCheck.resetErrorMessage()
    }
  }

  const renderFooterBtns = () => {
    return (
      <FlexWrapper flexEnd key='0'>
        <Form.Item style={{ marginBottom: '0' }}>
          {(step === 0 || step === 1) && similarityCheck.errorMessage.similarExist &&
            <SimpleButton
              text='Ignore'
              id='modal-btn-width-regular'
              onClick={(e: any) => {
                e.preventDefault();
                similarityCheck.handleIgnore()
              }}
              isCancel
            />
          }
          {
            step !== 0 && !similarityCheck.errorMessage.similarExist &&
            <SimpleButton
              text='Back'
              id='modal-btn-width-regular'
              onClick={goBack}
              isCancel
            />
          }
          <SimpleButton
            text={step === 2 ? (isEdit ? 'Update' : 'Create') : 'Next'}
            id='modal-btn-width-regular'
            htmlType='submit'
            form={step === 0 ? 'business-info' : step === 1 ? 'primary-contact' : 'address'}
          />
        </Form.Item>

      </FlexWrapper>
    )
  }


  return (
    <GlobalModal
      title={`${isEdit ? 'Edit' : 'Create'} Company`}
      open={open}
      onCancel={onCloseModal}
      footer={[renderFooterBtns()]}
    >
      {/* <div id={screen.xs ? '' : 'crm-steps'} style={{ marginBottom: '35px' }}> */}
      <div style={{ width: screen.xs ? '100%' : 'fit-content', margin: 'auto' }}>
        <Steps current={step} labelPlacement="vertical" size="small">
          <Step title="Company" />
          <Step title="Primary Contact" />
          <Step title="Address" />
        </Steps>
      </div>
      <FormRequireText>
        <span>*</span>
        &nbsp;Fields marked with (*) are required.
      </FormRequireText>
      {
        step === 0 ?
          <BusinessInfoForm
            form={form}
            onFinish={readyToConfirm}
            payloadState={payloadState}
            isEdit={isEdit}
            similarityCheck={similarityCheck}
            businessType={businessType}
            setBusinessType={setBusinessType}
            // open={open}
            getIDCode={getIDCode}
          />
          : step === 1 ?
            <AssignContactForm
              form={form}
              onFinish={readyToConfirm}
              isEdit={isEdit}
              contactType={contactType}
              setContactType={setContactType}
              similarityCheck={similarityCheck}
            />
            : step === 2 &&
            <AddressForm
              form={form}
              onFinish={readyToConfirm}
              payloadState={payloadState}
              formDispatch={formDispatch}
              setDeliveryAddressState={setDeliveryAddressState}
              deliveryAddressState={deliveryAddressState}
              setBillingAddressState={setBillingAddressState}
              billingAddressState={billingAddressState}
              setAdditionalAddresses={setAdditionalAddresses}
              additionalAddresses={additionalAddresses}
              setErrorStates={setErrorStates}
              errorStates={errorStates}
              setErrorAdditional={setErrorAdditional}
              errorAdditional={errorAdditional}
            />
      }

    </GlobalModal >
  );
};

export default CreateCompany;


/* ------------------------------ Local Form Components ------------------------------ */

interface FormProps {
  form: FormInstance<any>;
  onFinish: (values: any) => void;
  payloadState?: any;
  formDispatch?: Function;
  setDeliveryAddressState?: any;
  deliveryAddressState?: any;
  billingAddressState?: any;
  setBillingAddressState?: any;
  setAdditionalAddresses?: any;
  additionalAddresses?: any;
  setErrorStates?: any;
  errorStates?: any;
  setErrorAdditional?: any;
  errorAdditional?: any;
}

interface BusinessFormProps extends FormProps {
  similarityCheck: any;
  setBusinessType: Function;
  isEdit: boolean;
  businessType: BusinessTypes;
  // open: boolean;
  getIDCode: Function;
}

interface AssignContactFormProps extends FormProps {
  isEdit: boolean;
  contactType: any;
  setContactType: Function;
  similarityCheck: any;
}


const BusinessInfoForm = (props: BusinessFormProps) => {
  const { form, onFinish, isEdit, payloadState, similarityCheck, businessType, setBusinessType, getIDCode } = props;

  const [country, setCountry] = useState(payloadState?.country || 'Australia')
  const [abnRequired, setAbnRequired] = useState(false)
  const [userOptions, setUserOptions] = useState([])
  const [leadStage, setLeadStage] = useState(payloadState?.businessPayload?.stage || LeadStages.NEW)
  const [IndustryOptions, setIndustryOptions] = useState([...INDUSTRY_OPTIONS])

  const onFinishFailed = (errorInfo: any) => {
    // console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    if (businessType) {
      // abn is not required for customer and supplier
      setAbnRequired(true)
    } else {
      setAbnRequired(false)
    }
  }, [businessType])

  // need to update initial using use state
  useEffect(() => {
    // const initialBusinessType = payloadState?.businessPayload?.businessType || 'company'
    const initialBusinessType = payloadState?.businessPayload?.businessType || 'customer' // default to customer
    const initialLeadStage = payloadState?.businessPayload?.stage || LeadStages.NEW
    getIDCode(initialBusinessType).then((IDCode: any) => {
      form.setFieldsValue({ code: IDCode })
    })
    setBusinessType(initialBusinessType)
    setLeadStage(initialLeadStage)
    // update business type every time opening the modal
    form.setFieldsValue({ businessType: initialBusinessType, stage: initialLeadStage })
  }, [payloadState])

  // get user options for leads
  useEffect(() => {
    UserAPI.getAllUsers()
      .then(res => {
        const options = res.map((user: any) => ({ label: user.full_name, value: user.username }))
        setUserOptions(options)
      }).catch(e => {
        // console.log(e)
        message.error('Failed to get user options')
      })
  }, [])

  const leadStageDetails: any = payloadState.businessPayload?.details

  // console.log('lead stage', open)
  useEffect(() => {

    getIDCode(businessType).then((IDCode: any) => {
      form.setFieldsValue({ code: IDCode })
    })

  }, [businessType])


  return (
    <Form
      form={form}
      name='business-info'
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={{ country: country }}
    >
      <Form.Item
        name='businessType'
        initialValue={businessType || 'customer'}
      >
        <Input type='select'
          label='Business Relationship'
          placeholder='Customer'
          options={accountTypeOptions}
          onChange={(value: string) => {
            setBusinessType(value)
          }}
          disabled={isEdit}
        />
      </Form.Item>
      <Divider orientation="left" orientationMargin="0">
        <div style={{ textAlign: 'left', fontSize: '14px' }}>{`Company ID`}</div>
      </Divider>
      {isEdit ?
        <>
          <Form.Item
            name='businessIDs'
          >
            <Input
              type='text'
              label='Company ID'
              placeholder={'Business ID'}
              disabled
            />
          </Form.Item>
        </>
        :
        <Row gutter={[15, 15]}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              name='prefix'
              rules={[{ required: true, message: 'Please enter the Prefix.' }]}
            >
              <Input
                type='text'
                label='Prefix'
                placeholder={'Prefix'}
                required
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              name='code'
              rules={[{ required: true, message: 'Code should not be empty.' }]}
            >
              <Input type='text'
                label='Code'
                placeholder='Auto Generated'
                disabled
                required
              />
            </Form.Item>
          </Col>
        </Row>
      }
      <Divider orientation="left" orientationMargin="0">
        <div style={{ textAlign: 'left', fontSize: '14px' }}>{`Company Details`}</div>
      </Divider>
      {/* <TitleHeading>Company Details</TitleHeading> */}
      <Form.Item
        name='bus_name'
        rules={[
          { required: true, message: 'Please enter the Company Name.' },
          VALIDATION_RULES.MAX.BUSINESS_NAME,
          VALIDATION_RULES.SPECIAL_CHAR
        ]}
      >
        <Input
          type='text'
          label='Company Name'
          required
          onChange={() => similarityCheck.resetErrorMessage()}
        />
      </Form.Item>
      <Row gutter={[15, 15]}>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item
            name='country'
          >
            <Input
              type='select'
              label='Country'
              options={CountryInfo.getCountryOptions()}
              onChange={(value: string) => setCountry(value)}
              showSearch
              allowClear
            />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item
            name='abn'

            rules={[
              VALIDATION_RULES.ABN,
              { required: abnRequired, message: `Please enter the ABN.` },
            ]}
          >
            <Input
              type='text'
              label='ABN'
              style={{ width: '100%' }}
              required
              onChange={() => similarityCheck.resetErrorMessage()}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[15, 15]}>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item
            name='bus_email'
            rules={[VALIDATION_RULES.TYPE.EMAIL, { required: true, message: 'Please enter the Business Email.' }]}
            validateTrigger={['onBlur']}
          >
            <Input
              type='text'
              label='Business Email'
              required
              onChange={() => similarityCheck.resetErrorMessage()}

            />
          </Form.Item>

        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item
            name='bus_phone'
            rules={[VALIDATION_RULES.MAX.PHONE, { required: true, message: 'Please enter the Business Phone No.' }]}
          >
            <Input type='phone' label='Business Phone No.' required />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[15, 15]}>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item
            name='industry'
            rules={[VALIDATION_RULES.MAX.INDUSTRY, { required: true, message: 'Please select an Industry.' }]}
          >
            <Input type='select'
              placeholder={'Select Industry'}
              label='Industry'
              options={IndustryOptions}
              required
            />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item
            name='website'
          // rules={[VALIDATION_RULES.TYPE.URL]}
          >
            <Input type='text' label='URL' />
          </Form.Item>
        </Col>
      </Row>
      {
        businessType === 'lead' &&
        <Row gutter={[15, 15]}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              name='stage'
            >
              <Input
                type='select'
                label='Lead Stage'
                options={[
                  { label: LeadStages.NEW, value: LeadStages.NEW },
                  { label: LeadStages.IN_PROGRESS, value: LeadStages.IN_PROGRESS },
                  { label: LeadStages.WON, value: LeadStages.WON },
                  { label: LeadStages.LOST, value: LeadStages.LOST },
                ]}
                disabled={!isEdit}
                onChange={(value: string) => setLeadStage(value)}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              name='assigned_to'
            >
              <Input
                type='select'
                label='Assigned to (Lead Owner)'
                options={userOptions}
              />
            </Form.Item>
          </Col>
        </Row>
      }
      {/* while creating company, user can't change the status, it will be always active, but while editing, user can change the status */}
      {
        isEdit &&
        <Form.Item
          name='active'
          initialValue={true}
        >
          <Input
            type='select'
            label='Status'
            options={[
              { label: 'Active', value: true },
              { label: 'Inactive', value: false }
            ]}
          />
        </Form.Item>
      }
      <Form.Item
        name='comments'
        rules={[VALIDATION_RULES.MAX.COMMENTS]}
      >
        <Input type='textarea' label='Comments' />
      </Form.Item>
      {
        // lead stage notes only in lead edit mode and when lead stage is not new
        (isEdit && businessType === 'lead' && form.getFieldValue('stage') !== LeadStages.NEW) &&
        <>
          {
            // if there is no existing stage notes, show a single input field
            (!leadStageDetails || typeof leadStageDetails !== 'object' || Object.keys(leadStageDetails).length <= 0) ?
              <Form.Item
                name={leadStage.toLowerCase().replace(' ', '_')}
                rules={[VALIDATION_RULES.MAX.COMMENTS]}
              >
                <Input
                  type='textarea'
                  label={`Notes (New to ${leadStage})`}
                />
              </Form.Item>
              :
              <>
                {
                  // if there is existing stage notes, show a input field for each stage
                  // the from stage is the previous stage in the object
                  Object.keys(leadStageDetails).map((item: any, index: number) => (
                    <Form.Item
                      name={item}
                      rules={[VALIDATION_RULES.MAX.COMMENTS]}
                      initialValue={leadStageDetails[item].notes}
                    >
                      <Input
                        type='textarea'
                        label={`Notes (${index > 0 ? leadStageDetails[Object.keys(leadStageDetails)[index - 1]].stage : 'New'} to ${leadStageDetails[item].stage})`}
                      />
                    </Form.Item>
                  ))
                }
                {
                  // if the target stage was not stored in details, show a input field for the target stage
                  !Object.keys(leadStageDetails).includes(leadStage.toLowerCase().replace(' ', '_')) &&
                  <Form.Item
                    name={leadStage.toLowerCase().replace(' ', '_')}
                    rules={[VALIDATION_RULES.MAX.COMMENTS]}
                  >
                    <Input
                      type='textarea'
                      label={`Notes (${Object.keys(leadStageDetails).slice(-1)} to ${leadStage})`}
                    />
                  </Form.Item>
                }
              </>
          }
        </>

      }
      <Row>
        {similarityCheck.renderErrorMessage()}
      </Row>
    </Form>
  )
}


const AssignContactForm = (props: AssignContactFormProps) => {
  const { form, isEdit, onFinish, contactType, setContactType, similarityCheck } = props;

  const [contactOptions, setContactOptions] = useState<any[]>([])
  const [specialFields, setSpecialFields] = useState<any>({
    email: '',
    phone: '',
  })  // at least one of them should be filled
  const [contactId, setContactId] = useState<string>(form.getFieldValue('contact_ID') || '')
  const [requiredField, setRequiredField] = useState(true)
  const [skip, setSkip] = useState<boolean>(false)


  const contactTypeOnChange = (e: RadioChangeEvent) => {
    setContactType(e.target.value)
    form.setFieldsValue({
      contact_ID: '',
      first_name: '',
      last_name: '',
      job_title: '',
      email: '',
      phone: '',
      title: '',
    })
    setContactId('')
    similarityCheck.resetErrorMessage()
  }

  useEffect(() => {
    // get unassigned contact options
    ContactAPI.getAllContacts().then((res: any) => {
      const unassignedContacts = res.filter((contact: any) => {
        if (!isEdit) {
          // git contacts without business id
          return !contact.business_ID
        } else {
          // get contacts without business id or current business id
          return !contact.business_ID || contact.business_ID === form.getFieldValue('business_ID')
        }
      })
      unassignedContacts.sort((a: any, b: any) => {
        return a.full_name.localeCompare(b.full_name)
      })

      const options = unassignedContacts.map((contact: any) => {
        return {
          value: contact.contact_ID,
          label: contact.full_name.trim() || contact.contact_ID
        }
      })
      setContactOptions(options)
    })
  }, [])

  useEffect(() => {
    setSpecialFields({
      ...specialFields,
      email: form.getFieldValue('email'),
      phone: form.getFieldValue('phone'),
    })
  }, [form.getFieldValue('phone'), form.getFieldValue('email')])

  useEffect(() => {
    if (skip) {
      // clear form
      form.setFieldsValue({
        contact_ID: '',
        first_name: '',
        last_name: '',
        job_title: '',
        email: '',
        phone: '',
        title: '',
      })
      // disable reqruied fileds
      setSpecialFields({
        email: '',
        phone: '',
      })
      setRequiredField(false)
    } else {
      // enable required fields
      setRequiredField(true)
    }
  }, [skip])

  // fill the form with selected contact data
  useEffect(() => {
    if (contactId) {
      // get contact data
      ContactAPI.getContactById(contactId).then((res: any) => {
        form.setFieldsValue({
          first_name: res.full_name?.split(' ')[0],
          last_name: res.full_name?.split(' ').slice(1).join(' '),
          job_title: res.job_title,
          email: res.email,
          phone: res.phone,
          title: res.title,
        })
        setSpecialFields({
          email: res.email,
          phone: res.phone,
        })
      })
    }
  }, [contactId])

  useEffect(() => {
    if (isEdit) {
      // if edit mode, check the skip contact by default if there is no contact
      const defaultSkip = form.getFieldValue('contact_ID') ? false : true
      setSkip(defaultSkip)
      form.setFieldsValue({ skip: defaultSkip })
    }
  }, [isEdit])




  return (
    <>
      <Radio.Group value={contactType} onChange={contactTypeOnChange} style={{ marginBottom: '20px' }}>
        <Radio value="existing">Add existing</Radio>
        <Radio value="new">New</Radio>
      </Radio.Group>
      <Form
        form={form}
        name='primary-contact'
        onFinish={onFinish}
      >
        {
          contactType === 'existing' &&
          <Form.Item
            name='contact_ID'
            rules={[{ required: !skip, message: 'Please select a Contact.' }]}
          >
            <Input
              type='select'
              label='Contact'
              placeholder={'Select from existing contacts'}
              options={contactOptions}
              onChange={(value: string) => {
                setContactId(value)
                similarityCheck.resetErrorMessage()
              }}
              showSearch
              allowClear
              disabled={skip}
              required={!skip}
            />
          </Form.Item>
        }
        {
          (contactType === 'new' || contactId) &&
          <>
            <Row gutter={[15, 15]}>
              <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                <Form.Item
                  name='title'
                >
                  <Input type='select' label='Title' options={titleOptions} disabled={skip || contactType === 'existing'} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                <Form.Item
                  name='first_name'
                  rules={[VALIDATION_RULES.MAX.FIRST_NAME, { required: requiredField, message: 'Please enter the First Name.' }]}
                >
                  <Input
                    type='text'
                    label='First name'
                    disabled={skip || contactType === 'existing'}
                    required={requiredField}
                    onChange={() => similarityCheck.resetErrorMessage()}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                <Form.Item
                  name='last_name'
                  rules={[VALIDATION_RULES.MAX.LAST_NAME, { required: requiredField, message: 'Please enter the Last Name.' }]}
                >
                  <Input
                    type='text'
                    label='Last Name'
                    required={requiredField}
                    onChange={() => similarityCheck.resetErrorMessage()}
                    disabled={skip || contactType === 'existing'}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name='job_title'
              rules={[VALIDATION_RULES.MAX.POSITION, { required: requiredField, message: 'Please enter the Job Title.' }]}
            >
              <Input type='text' label='Job Title' disabled={skip || contactType === 'existing'} required={requiredField} />
            </Form.Item>
            <Form.Item
              name='email'
              // rules={[VALIDATION_RULES.TYPE.EMAIL, { required: !skip && !specialFields.email && !specialFields.phone, message: 'Either email or mobile needs to be filled' }]}
              rules={[VALIDATION_RULES.TYPE.EMAIL, { required: !skip, message: 'Please enter an Email.' }]}
              validateTrigger={['onBlur']}
            >
              <Input
                type='text'
                label='Email'
                onChange={(e: any) => {
                  setSpecialFields({ ...specialFields, email: e.target.value })
                  similarityCheck.resetErrorMessage()
                }}
                disabled={skip || contactType === 'existing'}
                required={!skip}

              />
            </Form.Item>
            <Form.Item
              name='phone'
              // rules={[VALIDATION_RULES.MAX.PHONE, { required: !skip && !specialFields.email && !specialFields.phone, message: 'Either email or mobile needs to be filled' }]}
              rules={[VALIDATION_RULES.MAX.PHONE, { required: !skip, message: 'Please enter the Phone Number.' }]}
            >
              <Input
                type='phone'
                label='Mobile No'
                onChange={(value: string) => {
                  setSpecialFields({ ...specialFields, phone: value })
                  similarityCheck.resetErrorMessage()
                }}
                disabled={skip || contactType === 'existing'}
                required={!skip}
              />
            </Form.Item>
          </>
        }



        <Form.Item
          name='skip'
          initialValue={skip}
          getValueFromEvent={(e: any) => e.target.checked}
        >
          <Input
            type='checkbox'
            label='Skip'
            checked={skip}
            onChange={(e: any) => {
              setSkip(e.target.checked)
              similarityCheck.resetErrorMessage()
            }}
          />
        </Form.Item>
        {similarityCheck.renderErrorMessage()}
      </Form>
    </>
  )
}


const AddressForm = (props: FormProps) => {
  const { form, payloadState, onFinish, formDispatch,
    setDeliveryAddressState, deliveryAddressState, billingAddressState, setBillingAddressState,
    setAdditionalAddresses, additionalAddresses, errorStates, setErrorStates,
    errorAdditional, setErrorAdditional } = props;

  console.log('address Form props', payloadState)

  const coutryOptions = CountryInfo.getCountryOptions();
  const ausStateOptions = CountryInfo.getStateOptions('Australia');

  const businessType = payloadState.businessPayload?.businessType   // customer, supplier, lead
  const billingRequired = businessType === 'customer' || businessType === 'supplier' ? true : false

  const [defaultLocationOptions, setDefaultLocationOptions] = useState<any>([
    {
      country: coutryOptions,
      state: ausStateOptions,
      suburb: [],
    },
    {
      country: coutryOptions,
      state: ausStateOptions,
      suburb: [],
    },
  ]);

  const [additionalLocationOptions, setAdditionalLocationOptions] = useState<any>([])

  const [markAsSame, setMarkAsSame] = useState<boolean>(false)


  const copyAddress = () => {
    // billing address could be same as physical address
    form.setFieldsValue({
      billing_unit: form.getFieldValue('aditional_unit'),
      billing_street: form.getFieldValue('aditional_street'),
      billing_suburb: form.getFieldValue('aditional_suburb'),
      billing_state: form.getFieldValue('aditional_state'),
      billing_country: form.getFieldValue('aditional_country'),
      billing_postcode: form.getFieldValue('aditional_postcode'),
      // isBilling_manual_input: 'false',
      isBilling_manual_input: form.getFieldValue('isAditional_manual_input'),
      billing_full_address: form.getFieldValue('physical_full_address'),
    })

    // copy delivery address to billing address
    // setBillingAddressState({ ...deliveryAddressState });
    // setErrorStates({ ...errorStates, billing: '' })

    // form.setFieldsValue({
    //   billing_country: form.getFieldValue('country'),
    //   billing_state: form.getFieldValue('state'),
    //   billing_suburb: form.getFieldValue('suburb'),
    //   billing_unit: form.getFieldValue('unit'),
    //   billing_street: form.getFieldValue('street'),
    //   billing_postcode: form.getFieldValue('postcode'),
    // })
  }

  // sync billing address and delivery address
  const syncAddresses = (name1: string, name2: string) => {
    if (markAsSame) {
      form.setFieldsValue({
        [name2]: form.getFieldValue(name1),
      })
      // if it's country, need to set another state and suburb value to empty
      // if it's state, need to set another suburb value to empty
      if (name1 === 'delivery_country') {
        form.setFieldsValue({
          billing_state: '',
          billing_suburb: '',
        })
      } else if (name1 === 'state') {
        form.setFieldsValue({
          billing_suburb: '',
        })
      } else if (name1 === 'billing_country') {
        form.setFieldsValue({
          state: '',
          suburb: '',
        })
      } else if (name1 === 'billing_state') {
        form.setFieldsValue({
          suburb: '',
        })
      }

    }
  }

  // initalise country and state options
  useEffect(() => {

    const deliveryCountry = payloadState.addressPayload?.delivery?.country || 'Australia'
    const billingCountry = payloadState.addressPayload?.billing?.country || 'Australia'
    // setDefaultLocationOptions([
    //   {
    //     country: coutryOptions,
    //     state: CountryInfo.getStateOptions(deliveryCountry),
    //     suburb: CountryInfo.getCityOptions(payloadState.addressPayload?.delivery?.state, deliveryCountry) || [],
    //   },
    //   {
    //     country: coutryOptions,
    //     state: CountryInfo.getStateOptions(billingCountry),
    //     suburb: CountryInfo.getCityOptions(payloadState.addressPayload?.billing?.state, billingCountry) || [],
    //   },
    // ])

    // const additional = payloadState.addressPayload?.additional
    // if (Array.isArray(additional) && additional.length > 0) {
    //   const additionalOptions = additional.map((item: any, index: number) => {
    //     return {
    //       country: coutryOptions,
    //       state: CountryInfo.getStateOptions(item.country),
    //       suburb: CountryInfo.getCityOptions(item.state, item.country),
    //     }
    //   })
    //   setAdditionalLocationOptions(additionalOptions)
    // }

    // new code added below

    // const fullAddressDelivery = `${payloadState.addressPayload?.delivery?.unit ? payloadState.addressPayload?.delivery?.unit + '/' : ''}${payloadState.addressPayload?.delivery?.street ? payloadState.addressPayload?.delivery?.street + ', ' : ''}${payloadState.addressPayload?.delivery?.suburb ? payloadState.addressPayload?.delivery?.suburb + ', ' : ''}${payloadState.addressPayload?.delivery?.state ? payloadState.addressPayload?.delivery?.state + ', ' : ''}${payloadState.addressPayload?.delivery?.postcode ? payloadState.addressPayload?.delivery?.postcode + ', ' : ''}${payloadState.addressPayload?.delivery?.country ? payloadState.addressPayload?.delivery?.country : ''}`
    // const fullBillingAddress = `${payloadState.addressPayload?.billing?.unit ? payloadState.addressPayload?.billing?.unit + '/' : ''}${payloadState.addressPayload?.billing?.street ? payloadState.addressPayload?.billing?.street + ', ' : ''}${payloadState.addressPayload?.billing?.suburb ? payloadState.addressPayload?.billing?.suburb + ', ' : ''}${payloadState.addressPayload?.billing?.state ? payloadState.addressPayload?.billing?.state + ', ' : ''}${payloadState.addressPayload?.billing?.postcode ? payloadState.addressPayload?.billing?.postcode + ', ' : ''}${payloadState.addressPayload?.billing?.country ? payloadState.addressPayload?.billing?.country : ''}`

    // if (deliveryAddressState.full_address) {
    //   setDeliveryAddressState({
    //     ...deliveryAddressState,
    //   })
    // } else {
    //   setDeliveryAddressState({
    //     unit: payloadState.addressPayload?.delivery?.unit,
    //     street: payloadState.addressPayload?.delivery?.street,
    //     suburb: payloadState.addressPayload?.delivery?.suburb,
    //     state: payloadState.addressPayload?.delivery?.state,
    //     postcode: payloadState.addressPayload?.delivery?.postcode,
    //     country: payloadState.addressPayload?.delivery?.country,
    //     full_address: fullAddressDelivery,
    //   })
    // }

    // if (billingAddressState.full_address) {
    //   setBillingAddressState({
    //     ...billingAddressState,
    //   })
    // } else {
    //   setBillingAddressState({
    //     unit: payloadState.addressPayload?.billing?.unit,
    //     street: payloadState.addressPayload?.billing?.street,
    //     suburb: payloadState.addressPayload?.billing?.suburb,
    //     state: payloadState.addressPayload?.billing?.state,
    //     postcode: payloadState.addressPayload?.billing?.postcode,
    //     country: payloadState.addressPayload?.billing?.country,
    //     full_address: fullBillingAddress,
    //   })
    // }
    // let assignAdditionalAddress;
    // if (Array.isArray(additionalAddresses) && additionalAddresses.length > 0) {
    //   assignAdditionalAddress = additionalAddresses
    // } else {
    //   assignAdditionalAddress = payloadState.addressPayload?.additional
    // }
    // if (Array.isArray(assignAdditionalAddress) && assignAdditionalAddress.length > 0) {
    //   const additionalAddresses = assignAdditionalAddress.map((item: any, index: number) => {
    //     const fullAddress = `${item.unit ? item.unit + '/' : ''}${item.street ? item.street + ', ' : ''}${item.suburb ? item.suburb + ', ' : ''}${item.state ? item.state + ', ' : ''}${item.postcode ? item.postcode + ', ' : ''}${item.country ? item.country : ''}`
    //     return {
    //       unit: item.unit,
    //       street: item.street,
    //       suburb: item.suburb,
    //       state: item.state,
    //       postcode: item.postcode,
    //       country: item.country,
    //       full_address: fullAddress,
    //       type: item.type,
    //       address_ID: item.address_ID,
    //     }
    //   })
    //   setAdditionalAddresses(additionalAddresses)
    // }
  }, [])


  return (
    <Form
      form={form}
      name='address'
      onFinish={onFinish}
    >
      <AddressSearchInputFormItem
        form={form}
        inputProps={{
          placeholder: 'Enter Address',
          label: 'Physical Address',
          required: true,
        }}
        formItemProps={{
          name: 'physical_full_address',
          rules: [{ required: true, message: 'Please enter the Address.' }]
        }}
        fromAddressFieldNames={
          {
            unitFieldName: 'aditional_unit',
            streetFieldName: 'aditional_street',
            suburbFieldName: 'aditional_suburb',
            stateFieldName: 'aditional_state',
            countryFieldName: 'aditional_country',
            postcodeFieldName: 'aditional_postcode',
            isManualFieldName: 'isAditional_manual_input',
          }
        }
        isManualInput={form.getFieldValue('isAditional_manual_input')}
      />

      <div style={{ borderBottom: '2px dashed #383A56', marginBottom: '24px' }}></div>

      <AddressSearchInputFormItem
        form={form}
        inputProps={{
          placeholder: 'Enter Address',
          label: 'Delivery Address',
          required: true,
        }}
        formItemProps={{
          name: 'full_address',
          rules: [{ required: true, message: 'Please enter the Address.' }]
        }}
        fromAddressFieldNames={
          {
            unitFieldName: 'unit',
            streetFieldName: 'street',
            suburbFieldName: 'suburb',
            stateFieldName: 'state',
            countryFieldName: 'delivery_country',
            postcodeFieldName: 'postcode',
            isManualFieldName: 'isDelivery_manual_input',
          }
        }
      />
      <div style={{ borderBottom: '2px dashed #383A56', marginBottom: '24px' }}></div>
      <Input
        margin="8px 0"
        type='checkbox'
        label="Same as Physical Address"
        onChange={() => {
          if (!markAsSame) {
            copyAddress()
          }
          setMarkAsSame(!markAsSame)
        }}
        checked={markAsSame}
      />

      <AddressSearchInputFormItem
        form={form}
        inputProps={{
          placeholder: 'Enter Address',
          label: 'Billing Address',
          required: true,
        }}
        formItemProps={{
          name: 'billing_full_address',
          rules: [{ required: true, message: 'Please enter the Address.' }]
        }}
        fromAddressFieldNames={
          {
            unitFieldName: 'billing_unit',
            streetFieldName: 'billing_street',
            suburbFieldName: 'billing_suburb',
            stateFieldName: 'billing_state',
            countryFieldName: 'billing_country',
            postcodeFieldName: 'billing_postcode',
            isManualFieldName: 'isBilling_manual_input',
          }
        }
        isManualInput={form.getFieldValue('isBilling_manual_input')}
      />

      {/* //old delivery address only google search */}
      {/* <AddressSearchInput
        handleAddressChange={(address: any) => {
          setDeliveryAddressState({
            unit: address.unit,
            street: address.street,
            suburb: address.city,
            state: address.state,
            postcode: address.postcode,
            country: address.country,
            full_address: address.full_address,
          })
          setErrorStates({ ...errorStates, delivery: '' })
        }}
        addressValue={deliveryAddressState?.full_address}// full address
      />
      <span style={{ color: `var(--itrazo-red-color)` }}>{errorStates?.delivery}</span> */}



      {/* <Row gutter={[15, 15]}>
        <Col sm={{ span: 24 }} xl={{ span: 8 }}>
          <Form.Item
            name='unit'
            rules={[VALIDATION_RULES.MAX.STREET]}
          >
            <Input label="Unit/Suite" type="number" style={{ width: '100%' }} onChange={() => syncAddresses('unit', 'billing_unit')} />
          </Form.Item>
        </Col>
        <Col sm={{ span: 24 }} xl={{ span: 8 }}>
          <Form.Item
            name='street'
            rules={[VALIDATION_RULES.MAX.STREET, { required: true, message: 'Please enter the Street.' }]}
          >
            <Input label="Street" type="text" required onChange={() => syncAddresses('street', 'billing_street')} />
          </Form.Item>
        </Col>
        <Col sm={{ span: 24 }} xl={{ span: 8 }}>
          <Form.Item
            name='delivery_country'
            initialValue='Australia'
            rules={[{ required: true, message: 'Please select a Country.' }]}
          >
            <Input
              label="Country"
              type="select"
              options={defaultLocationOptions[0].country}
              onChange={(value: any) => {
                const stateOptions = CountryInfo.getStateOptions(value)
                setDefaultLocationOptions([
                  {
                    ...defaultLocationOptions[0],
                    state: stateOptions,
                    suburb: []
                  },
                  ...defaultLocationOptions.slice(1)
                ])
                // empty state and suburb value
                form.setFieldsValue({
                  state: '',
                  suburb: '',
                  country: value,
                })
                syncAddresses('delivery_country', 'billing_country')
              }}
              showSearch
              allowClear
              required
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[15, 15]}>

        <Col sm={{ span: 24 }} xl={{ span: 8 }}>
          <Form.Item
            name='state'
            rules={[{ required: true, message: 'Please select a State.' }]}
          >
            <Input
              label="State"
              type="select"
              options={defaultLocationOptions[0].state}
              onChange={(value: any) => {
                const suburbOptions = CountryInfo.getCityOptions(value, form.getFieldValue('delivery_country'))
                setDefaultLocationOptions([
                  {
                    ...defaultLocationOptions[0],
                    suburb: suburbOptions
                  },
                  ...defaultLocationOptions.slice(1)
                ])
                // empty suburb value
                form.setFieldsValue({
                  suburb: '',
                })
                syncAddresses('state', 'billing_state')
              }}
              showSearch
              allowClear
              required
            />
          </Form.Item>
        </Col>
        <Col sm={{ span: 24 }} xl={{ span: 8 }}>
          <Form.Item
            name='suburb'
            rules={[{ required: true, message: 'Please select a Suburb.' }]}
          >
            <Input
              label="Suburb/City"
              type="select"
              options={defaultLocationOptions[0].suburb}
              onChange={() => syncAddresses('suburb', 'billing_suburb')}
              showSearch
              allowClear
              required
            />
          </Form.Item>
        </Col>
        <Col sm={{ span: 24 }} xl={{ span: 8 }}>
          <Form.Item
            name='postcode'
            rules={[VALIDATION_RULES.MAX.POSTCODE, { required: true, message: 'Please enter the Postcode.' }]}
          >
            <Input label="Postcode" type="number" style={{ width: '100%' }} required onChange={() => syncAddresses('postcode', 'billing_postcode')} />
          </Form.Item>
        </Col>
      </Row> */}


      {/* <AdiLabel style={{ marginBottom: '20px', marginTop: '20px' }}>Billing Address&nbsp;<span style={{ color: 'red' }}>*</span></AdiLabel> */}
      {/* <Input
        margin="8px 0"
        type='checkbox'
        label="Same as Physical Address"
        onChange={() => {
          if (!markAsSame) {
            copyAddress()
          }
          setMarkAsSame(!markAsSame)
        }}
        checked={markAsSame}
      /> */}

      {/* <AddressSearchInput
        handleAddressChange={(address: any) => {
          setBillingAddressState({
            unit: address.unit,
            street: address.street,
            suburb: address.city,
            state: address.state,
            postcode: address.postcode,
            country: address.country,
            full_address: address.full_address,
          })
          setErrorStates({ ...errorStates, billing: '' })
        }}
        addressValue={billingAddressState?.full_address}// full address
      />
      <span style={{ color: `var(--itrazo-red-color)` }}>{errorStates?.billing}</span> */}

      {/* <Row gutter={[15, 15]}>
        <Col sm={{ span: 24 }} xl={{ span: 8 }}>
          <Form.Item
            name='billing_unit'
            rules={[VALIDATION_RULES.MAX.STREET]}
          >
            <Input label="Unit/Suite" type="number" style={{ width: '100%' }} onChange={() => syncAddresses('billing_unit', 'unit')} />
          </Form.Item>
        </Col>
        <Col sm={{ span: 24 }} xl={{ span: 8 }}>
          <Form.Item
            name='billing_street'
            rules={[VALIDATION_RULES.MAX.STREET, { required: billingRequired, message: 'Please enter the Street.' }]}
          >
            <Input label="Street" type="text" required={billingRequired} onChange={() => syncAddresses('billing_street', 'street')} />
          </Form.Item>
        </Col>
        <Col sm={{ span: 24 }} xl={{ span: 8 }}>
          <Form.Item
            name='billing_country'
            initialValue='Australia'
            rules={[{ required: billingRequired, message: 'Please select a Country.' }]}
          >
            <Input
              label="Country"
              type="select"
              options={defaultLocationOptions[1].country}
              onChange={(value: any) => {
                const stateOptions = CountryInfo.getStateOptions(value)
                setDefaultLocationOptions([
                  defaultLocationOptions[0],
                  {
                    ...defaultLocationOptions[1],
                    state: stateOptions,
                    suburb: []
                  },
                ])
                // empty state and suburb value
                form.setFieldsValue({
                  billing_state: '',
                  billing_suburb: '',
                  billing_country: value,
                })
                syncAddresses('billing_country', 'delivery_country')
              }}
              showSearch
              allowClear
              required={billingRequired}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[15, 15]}>

        <Col sm={{ span: 24 }} xl={{ span: 8 }}>
          <Form.Item
            name='billing_state'
            rules={[{ required: billingRequired, message: 'Please select a State.' }]}
          >
            <Input
              label="State"
              type="select"
              options={defaultLocationOptions[1].state}
              onChange={(value: any) => {
                const suburbOptions = CountryInfo.getCityOptions(value, form.getFieldValue('billing_country'))
                setDefaultLocationOptions([
                  defaultLocationOptions[0],
                  {
                    ...defaultLocationOptions[1],
                    suburb: suburbOptions
                  },
                ])
                // empty suburb value
                form.setFieldsValue({
                  billing_state: value,
                  billing_suburb: '',
                })
                syncAddresses('billing_state', 'state')
              }}
              showSearch
              allowClear
              required={billingRequired}
            />
          </Form.Item>
        </Col>
        <Col sm={{ span: 24 }} xl={{ span: 8 }}>
          <Form.Item
            name='billing_suburb'
            rules={[{ required: billingRequired, message: 'Please select a Suburb/City.' }]}
          >
            <Input
              label="Suburb/City"
              type="select"
              options={defaultLocationOptions[1].suburb}
              onChange={() => syncAddresses('billing_suburb', 'suburb')}
              showSearch
              allowClear
              required={billingRequired}
            />
          </Form.Item>
        </Col>
        <Col sm={{ span: 24 }} xl={{ span: 8 }}>
          <Form.Item
            name='billing_postcode'
            rules={[
              VALIDATION_RULES.MAX.POSTCODE,
              { required: billingRequired, message: 'Please enter the Postcode.' }]}
          >
            <Input label="Postcode" type="number" style={{ width: '100%' }} required={billingRequired} onChange={() => syncAddresses('billing_postcode', 'postcode')} />
          </Form.Item>
        </Col>
      </Row> */}

      {/* <Form.List name='additional'>
        {(fields, { add, remove }) => (
          <>
            {
              fields.length > 0 &&
              <AdiLabel style={{ marginTop: '20px' }}>Additional Addresses</AdiLabel>
            }

            {fields.map((field, index) => (
              <div key={field.key}> */}
      {/* <Row gutter={[15, 10]} align='middle'>
                  <Col span='16'>
                    <Form.Item
                      name={[field.name, 'type']}
                      rules={[{ required: true, message: 'Please select an Address Type.' }]}
                    >
                      <Input
                        type='select'
                        label="Address Type"
                        options={[
                          { label: 'Delivery', value: 'Delivery' },
                          { label: 'Location', value: 'Location' },
                        ]}
                        required
                      />
                    </Form.Item>
                  </Col>
                  <Col span='8' style={{ display: 'flex', justifyContent: 'end' }}>
                    <DeleteOutlined
                      onClick={() => {
                        remove(field.name)
                        setErrorStates({ ...errorStates, additional: '' })
                        // update payload
                        if (formDispatch) {
                          formDispatch({ type: ActionType.SET_DELETE_ADDRESS, payload: { index } })
                        }

                        // remove corresponding location option
                        // const newLocationOptions = [...additionalLocationOptions]
                        // newLocationOptions.splice(index, 1)
                        // setAdditionalLocationOptions(newLocationOptions)

                        const newAddtionalAddress = [...additionalAddresses]
                        newAddtionalAddress.splice(index, 1)
                        setAdditionalAddresses(newAddtionalAddress)

                      }}
                      style={{ color: 'white', fontSize: '20px', cursor: 'pointer' }}
                    />
                  </Col>
                </Row> */}
      {/* <div style={{ marginBottom: '24px' }}>
                  <AdiLabel>Address&nbsp;<span style={{ color: 'red' }}>*</span></AdiLabel>
                  <AddressSearchInput
                    handleAddressChange={(address: any) => {
                      // need to set value on the basis of index setAdditionalAddresses
                      const newAddtionalAddress = [...additionalAddresses]
                      newAddtionalAddress[index] = {
                        country: address.country,
                        full_address: address.full_address,
                        postcode: address.postcode,
                        state: address.state,
                        street: address.street,
                        suburb: address.city,
                        unit: address.unit,
                        type: form.getFieldValue(['additional', index, 'type']),
                      }
                      console.log('newAddtionalAddress', newAddtionalAddress)
                      setAdditionalAddresses(newAddtionalAddress)
                      errorAdditional[index] = ''
                      setErrorAdditional([...errorAdditional])
                    }}
                    addressValue={additionalAddresses[index]?.full_address}// full address
                  />
                  <span style={{ color: `var(--itrazo-red-color)` }}>{errorAdditional[index]}</span>
                </div> */}

      {/* // additional address */}
      {/* <Row gutter={[15, 15]}>
                  <Col sm={{ span: 24 }} xl={{ span: 8 }}>
                    <Form.Item
                      name={[field.name, 'unit']}
                      rules={[VALIDATION_RULES.MAX.STREET]}
                    >
                      <Input label="Unit/Suite" type="number" style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                  <Col sm={{ span: 24 }} xl={{ span: 8 }}>
                    <Form.Item
                      name={[field.name, 'street']}
                      rules={[{ required: true, message: 'Please enter the Street.' }, VALIDATION_RULES.MAX.STREET]}
                    >
                      <Input label="Street" type="text" required />
                    </Form.Item>
                  </Col>
                  <Col sm={{ span: 24 }} xl={{ span: 8 }}>
                    <Form.Item
                      name={[field.name, 'country']}
                      initialValue='Australia'
                      rules={[{ required: true, message: 'Please select a Country.' }]}
                    >
                      <Input
                        label="Country"
                        type="select"
                        options={additionalLocationOptions[index]?.country}
                        onChange={(value: any) => {
                          const stateOptions = CountryInfo.getStateOptions(value)
                          const newLocationOptions = [...additionalLocationOptions]
                          newLocationOptions[index] = {
                            ...newLocationOptions[index],
                            state: stateOptions,
                            suburb: []
                          }
                          setAdditionalLocationOptions(newLocationOptions)
                          // empty state and suburb value
                          const newAdditional = [...form.getFieldValue('additional')]
                          newAdditional[index] = {
                            ...newAdditional[index],
                            state: '',
                            suburb: ''
                          }
                          form.setFieldsValue({
                            additional: newAdditional
                          })
                        }}
                        showSearch
                        allowClear
                        required
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[15, 15]}>

                  <Col sm={{ span: 24 }} xl={{ span: 8 }}>
                    <Form.Item
                      name={[field.name, 'state']}
                      rules={[{ required: true, message: 'Please select a State.' }]}
                    >
                      <Input
                        label="State"
                        type="select"
                        options={additionalLocationOptions[index]?.state}
                        onChange={(value: any) => {
                          const suburbOptions = CountryInfo.getCityOptions(value, form.getFieldValue(['additional', field.name, 'country']))
                          const newLocationOptions = [...additionalLocationOptions]
                          newLocationOptions[index] = {
                            ...newLocationOptions[index],
                            suburb: suburbOptions
                          }
                          setAdditionalLocationOptions(newLocationOptions)
                          // empty suburb value
                          const newAdditional = [...form.getFieldValue('additional')]
                          newAdditional[index] = {
                            ...newAdditional[index],
                            suburb: ''
                          }
                          form.setFieldsValue({
                            additional: newAdditional
                          })
                        }}
                        showSearch
                        allowClear
                        required
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={{ span: 24 }} xl={{ span: 8 }}>
                    <Form.Item
                      name={[field.name, 'suburb']}
                      rules={[{ required: true, message: 'Please select a Suburb/City.' }]}
                    >
                      <Input
                        label="Suburb/City"
                        type="select"
                        options={additionalLocationOptions[index]?.suburb}
                        showSearch
                        allowClear
                        required
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={{ span: 24 }} xl={{ span: 8 }}>
                    <Form.Item
                      name={[field.name, 'postcode']}
                      rules={[{ required: true, message: 'Please enter the Postcode.' }, VALIDATION_RULES.MAX.POSTCODE]}
                    >
                      <Input label="Postcode" type="number" style={{ width: '100%' }} required />
                    </Form.Item>
                  </Col>
                </Row> */}
      {/* </div>
            ))}
            <span style={{ color: `var(--itrazo-red-color)` }}>{errorStates?.additional && 'Please enter the Address'}</span>
            <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
              <SimpleButton text='+ Add' onClick={(e: any) => {
                e.stopPropagation()
                add()
                console.log('additionalAddresses', additionalAddresses)
                // update the additionalAddresses if has values, if not then add empty "" value
                const newAddtionalAddress = [...additionalAddresses]
                newAddtionalAddress.push({
                  country: '',
                  full_address: '',
                  postcode: '',
                  state: '',
                  street: '',
                  suburb: '',
                  unit: '',
                  type: ''
                })
                setAdditionalAddresses(newAddtionalAddress)

                // assign location option
                // setAdditionalLocationOptions([
                //   ...additionalLocationOptions,
                //   {
                //     country: coutryOptions,
                //     state: ausStateOptions,
                //     suburb: []
                //   }
                // ])
              }} />
            </div>

          </>
        )}
      </Form.List> */}



    </Form >
  )
}


const TitleHeading = styled.div`
  color: var(--white-color);
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
`;