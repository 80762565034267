import AdiTable from 'components/AdiTable';
import React from 'react'
import { theme } from 'statics/styles/StyledComponents';
import styled from 'styled-components';

type Props = {
  tableData: any[];
  columns: any[];
  onRowClick?: (record: any) => void;
}

const SimpleStyledTable = (props: Props) => {
  const { tableData, columns, onRowClick } = props;

  return (
    <StyledTable
      tableData={[...tableData]}
      columns={columns}
      onRowClick={onRowClick}
      scroll={{ x: '100%', y: 500 }}
      fullWidth
    />
  )
}

export default SimpleStyledTable

const StyledTable = styled(AdiTable)`
  .ant-table-content, .ant-table-body {
    ${theme.myScrollbar}
    // overflow-y: hidden !important;
  }
  .ant-table-cell-fix-right-first {
      right: 0px !important;
      border-top-right-radius: var(--border-radius) !important;
  }
  .ant-table-cell-scrollbar {
    display: none !important;
  }
  .ant-table-thead > tr > .ant-table-cell {
    border: 1px solid rgba(85, 87, 112, 0.60) !important;
    border-bottom: none;
    background-color: var(--panel-color) !important;
  }
  .ant-table-tbody > tr > td {
    border: 1px solid rgba(85, 87, 112, 0.60) !important;
    background-color: var(--panel-color) !important;
  }
  .ant-table-tbody > tr {
    :hover {
       td {
        background-color: var(--panel-color) !important;
      }
      
    }
  }
`