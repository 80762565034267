import React, { useEffect, useState, useCallback } from 'react'
import { Row, Col, Form, Tag, message, Descriptions, Radio, RadioChangeEvent, Divider } from 'antd'
import styled from 'styled-components';
import GlobalModal from 'components/GlobalModal';
import Input from 'components/Input';
import moment from 'moment';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import GeneratePicklistAPI from 'containers/Pages/InventoryManagement/apis/GeneratePicklistAPI';

import AdiTable from 'components/AdiTable';
import { convertDateAndTime, getBizCode, getSelectorOptions, getSessionStorage, getUploadToS3Promise } from 'utilities/Functions/GlobalHelperFunctions';
import { DATE_FORMAT, SESSION_STORAGE_KEYS, TABLE_CELL_CONFIG } from 'utilities/CONSTANTS';
import { FixedHeightScrollableBox, FlexWrapper, Label, ModalFixedHeightScrollableBox, TrackFlexTwo, TrackGridFour, TrackGridThree, TrackGridTwo } from 'statics/styles/StyledComponents';
import { capitaliseFirst, formatTitle, getFormattedMessage, initialCellValue } from 'utilities/Functions/FormatFunctions';
import { InventoryItemAPI, OrderAPI } from 'containers/Pages/InventoryManagement/apis';
import OrderAllocationAPI from 'containers/Pages/InventoryManagement/apis/OrderAllocationAPI';

import Loading from 'components/Loading';
import DragFileInput from 'components/Input/DragFileInput';
import { RcFile } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { firstLetterUppercase } from 'containers/Pages/InventoryManagement/utilities/functions';
import { DisposalMethodOptions, measurement } from 'containers/Pages/InventoryManagement/statics/constants';
import IncidentReportAPI from 'containers/Pages/InventoryManagement/apis/IncidentReportAPI';

const { ModalLayout } = GlobalModal;

type Props = {
  // selectedPurchaseOrder: any;
  open: boolean;
  onCancel: Function;
  incidentItemData: any;
  setIncidentItemData: Function;
  // getTableData: Function;
  incidentViewMode: boolean;
  setIncidentViewMode: Function;
  initializeAll: Function;
};

type ReportData = {
  "incident_ID": string,
  "reported_on": any,
  "reported_by": string,
  "processing_batch_number": string,
  "item_name": string,
  'inventory_ID': string, // this is the inventory ID of the item
  "quantity_affected": string,
  "measurement_unit": string,
  "comments": string,
  "method_of_disposing": string,
  'disposed_on': any,
  "residue_batch_number": string,
  "location": string,
  "images": any[],
  "itemNameList": any[]
}

const GenerateIncidentReport = (props: Props) => {

  const { open, onCancel, setIncidentItemData, incidentViewMode, setIncidentViewMode } = props;
  const [statusType, setStatusType] = useState<'disposed' | 'reused'>("disposed");
  const username = getSessionStorage(SESSION_STORAGE_KEYS.USER_NAME) || ''


  const defaultReportData = {
    "incident_ID": "",
    "reported_on": '',
    "reported_by": username,
    "processing_batch_number": '',
    "itemNameList": [],
    "item_name": '',
    'inventory_ID': '', // this is the inventory ID of the item
    "quantity_affected": '',
    "measurement_unit": '',
    "comments": '',
    "product_status": {},
    "reused": {},
    "images": [],
    "method_of_disposing": '',
    'disposed_on': '',
    "residue_batch_number": '',
    "location": '',
  }

  const [form] = Form.useForm();
  const [reportData, setReportData] = useState<ReportData>(defaultReportData);
  const [fileListing, setFileListing] = useState<any[]>([]);
  const [viewFileListings, setViewFileListings] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [itemlists, setItemlists] = useState<any[]>([]);


  const getAllItemList = useCallback(
    () => {
      InventoryItemAPI.getAllInventoryItems().then((res: any) => {
        console.log('get inventory items success...', res)
        // get item_names from the response, return listing
        const itemNameListing = Array.isArray(res) && res.map((item: any) => {
          return {
            label: capitaliseFirst(item.item_name),
            value: item.item_name,
            sku: item.sku,
          }
        })
        // if the itemNameListing has similar item_name, remove the duplicate
        const uniqueItemNameListing = Array.isArray(itemNameListing) && itemNameListing.filter((item: any, index: any) => {
          return itemNameListing.findIndex((item2: any) => item2.value === item.value) === index
        })
        console.log('itemNameListing==>', uniqueItemNameListing)

        setItemlists(uniqueItemNameListing as Array<any>)

      }).catch(e => {
        console.log('get generate picklist id fail...', e)
      })
    },
    [],
  )

  const getInventoryID = (value: any) => {
    console.log('getInventoryID', value)
    const item = itemlists.find((item: any) => item.value === value)
    if (item) {
      form.setFieldsValue({
        inventory_ID: item.sku,
      })
    }
  }

  const initialize = useCallback(
    () => {
      setIsLoading(true)
      if (props.incidentItemData && props.incidentItemData.incident_ID != "") {
        let displayStatus: any = ''
        const text = props.incidentItemData.product_status
        if (typeof text === 'object') {
          const key = Object.keys(text)
          if (key[0] != '') {
            displayStatus = key[0]
          }
        }
        console.log('displayStatus==>', displayStatus, itemlists)
        // InventoryItemAPI.getAllInventoryItems().then((res: any) => {
        //   console.log('get inventory items success...', res)
        //   let uniqueItemNameListing: any = []
        //   const itemNameListing = Array.isArray(res) && res.map((item: any) => {
        //     return {
        //       label: capitaliseFirst(item.item_name),
        //       value: item.item_name,
        //       sku: item.sku,
        //     }
        //   })
        //   // if the itemNameListing has similar item_name, remove the duplicate
        //   uniqueItemNameListing = Array.isArray(itemNameListing) && itemNameListing.filter((item: any, index: any) => {
        //     return itemNameListing.findIndex((item2: any) => item2.value === item.value) === index
        //   })
        //   console.log('itemNameListing==>', uniqueItemNameListing)

        const Location = props.incidentItemData.product_status.reused && props.incidentItemData.product_status.reused.location ? props.incidentItemData.product_status.reused.location : ''
        const ResidueBatchNumber = props.incidentItemData.product_status.reused && props.incidentItemData.product_status.reused.residue_batch_number ? props.incidentItemData.product_status.reused.residue_batch_number : ''
        const MethodOfDisposing = props.incidentItemData.product_status.disposed && props.incidentItemData.product_status.disposed.method_of_disposing ? props.incidentItemData.product_status.disposed.method_of_disposing : ''
        const DisposedON = props.incidentItemData.product_status.disposed && props.incidentItemData.product_status.disposed.disposed_on ? moment(props.incidentItemData.product_status.disposed.disposed_on).format(DATE_FORMAT) : ''
        const finalModalData = {
          ...props.incidentItemData,
          reported_on: props.incidentItemData.reported_on ? moment(props.incidentItemData.reported_on) : moment(),
          disposed_on: DisposedON,
          method_of_disposing: MethodOfDisposing,
          residue_batch_number: ResidueBatchNumber,
          location: Location,
          item_name: props.incidentItemData.item_name ? props?.incidentItemData.item_name : '',
          // if the itemNameListing has similar item_name, get sku of that item, and set it to inventory_ID
          // inventory_ID: props.incidentItemData.item_name ? uniqueItemNameListing && uniqueItemNameListing.length > 0 && uniqueItemNameListing?.find((item: any) => item.value === props.incidentItemData.item_name).sku : undefined,
          inventory_ID: props.incidentItemData.item_name ? props.incidentItemData.inventory_ID : '',
        }
        console.log('finalModalData==>', finalModalData)
        setReportData(finalModalData)
        setStatusType(displayStatus)
        setViewFileListings(props.incidentItemData?.images)
        form.setFieldsValue({ ...finalModalData })
        setIsLoading(false)
        // }).catch(e => {
        //   console.log('get generate picklist id fail...', e)
        //   setIsLoading(false)
        // })

        setIsLoading(false)
      } else {
        // get all inventory items
        // setIsLoading(true)
        IncidentReportAPI.getIncidentId().then((res: any) => {
          console.log('get generate picklist id success...', res)
          let incidentID = ''
          if (res && res.length > 0) {
            incidentID = res
          }
          let finalModalData: any = {}
          finalModalData = {
            ...reportData,
            incident_ID: incidentID,
            reported_on: moment(),
          }
          form.setFieldsValue({
            ...finalModalData,
          })
          setReportData(finalModalData)
          setIsLoading(false)
        }).catch(e => {
          console.log('get generate picklist id fail...', e)
          setIsLoading(false)
        })
      }

      getAllItemList()
    },
    [],
  )

  useEffect(initialize, [props.incidentItemData])

  const handleConfirm = (values: any) => {
    console.log('handleConfirm values', values)
    const { comments, incident_ID, item_name, reported_on, residue_batch_number, location, reported_by, measurement_unit, processing_batch_number, quantity_affected, method_of_disposing, disposed_on } = values
    const clientCode = getBizCode()
    const updateDB = (curKeyListing?: any[]) => {
      let productStatus = {}
      const reportedON = convertDateAndTime(reported_on, 'datetime')
      const disposedON = convertDateAndTime(disposed_on, 'datetime')
      if (statusType === 'disposed') {
        productStatus = {
          disposed: {
            method_of_disposing: method_of_disposing,
            disposed_on: disposedON,
          }
        }
      } else {
        productStatus = {
          reused: {
            residue_batch_number: residue_batch_number,
            location: location,
          }
        }
      }
      const payload = {
        comments, incident_ID, item_name, reported_by: username, measurement_unit, processing_batch_number, quantity_affected,
        reported_on: reportedON,
        product_status: productStatus,
        images: curKeyListing ? curKeyListing : [],
      }

      const key = 'create'
      console.log('handleConfirm payload', payload)
      // return

      IncidentReportAPI.createIncidentReport(payload).then((res: any) => {
        message.success({
          content: 'Incident Report created successfully!',
          onClose: () => {
            // props.setPageViewInventory('items')
          },
          duration: 1,
        });
        handleCancel()
        form.setFieldsValue(defaultReportData)
        form.resetFields()
        props.initializeAll()
      }).catch(e => {
        console.log(e)
        message.error({
          content: getFormattedMessage('add', 'fail'), key,
          duration: 1,
        });
      })

    }

    // format fields including: item_image
    const promList = [];
    let curKey = ''
    const fileCurKeyListing: any = [];
    if (fileListing.length > 0) {
      for (let i = 0; i < fileListing.length; i++) {
        curKey = `${clientCode}/osm/incident_report/${incident_ID}/${fileListing[i].name}`
        fileCurKeyListing.push(curKey)
        const fileProm = getUploadToS3Promise(fileListing[i], curKey)
        promList.push(fileProm)
      }
      console.log('promList', promList, fileCurKeyListing)
      Promise.all(promList).then(res => {
        console.log('all files uploaded', res)
        updateDB(fileCurKeyListing)
      }).catch(err => {
        console.log('upload image for item fail...', err)
      })
    } else {
      console.log('no file to upload')
      updateDB()
    }


  }

  const formItemStyle = {
    marginBottom: '0'
  }



  const renderOptions = (type: string, index?: any) => {
    if (type == 'measurement_unit') {
      return getSelectorOptions(measurement, 'Select Measurement Unit', 'value', 'label')
    } else if (type == 'disposal_method') {
      return getSelectorOptions(DisposalMethodOptions, 'Select Disposal Method', 'value', 'label')
    } else if (type == 'item') {
      const itemList = Array.isArray(itemlists) ? itemlists : []
      return getSelectorOptions(itemList, 'Select Product', 'value', 'label')
    }
    else if (type == 'itemValue') {
      const itemList = Array.isArray(itemlists) ? itemlists : []
      return getSelectorOptions(itemList, 'Select Product', 'value', 'label')
    } else {

      return getSelectorOptions([], 'Select')
    }
  }

  const statusTypeOnChange = (e: RadioChangeEvent) => {
    setStatusType(e.target.value)
  }
  const handleCancel = () => {
    onCancel()
    props.setIncidentItemData(defaultReportData)
    form.resetFields()
    setFileListing([]);
    setIncidentViewMode(false)
  }


  const renderFooter = () => (
    <>
      {incidentViewMode ?
        null :
        <Row className='justify-content-end'>
          <Col>
            <Form.Item style={{ marginBottom: '0' }}>
              <SimpleButton
                text='Cancel'
                id='modal-btn-width-regular'
                onClick={() => {
                  // setFileListing([])
                  onCancel()
                }}
                isCancel
              />
              <SimpleButton
                form='report-form'
                id='modal-btn-width-regular'
                className="ms-3"
                htmlType="submit"
                text="Save"
              />
            </Form.Item>
          </Col>
        </Row>
      }
    </>
  )

  const isViewOnly = () => {
    return props.incidentItemData && props.incidentItemData.incident_ID !== ""
  }

  return !isLoading ? (
    <GlobalModal
      // title='Incident Report'
      title={`${isViewOnly() ? '' : 'Create'} Incident Report ${isViewOnly() ? '(View Only)' : ''}`}
      dark
      width='829px'
      open={open}
      onCancel={handleCancel}
      footer={[renderFooter()]}
    >
      <Form
        form={form}
        name='report-form'
        onFinish={(values: any) => handleConfirm(values)}
        initialValues={{ ...reportData }}
      >
        <ModalLayout>

          <ModalLayout.ModalSection title="Incident Details">
            <Row gutter={[15, 15]}>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name='incident_ID'
                  style={{ ...formItemStyle }}
                >
                  <Input
                    label="Incident Report No."
                    type="text"
                    placeholder="IRP2023"
                    disabled
                  // defaultValue={`${orderID}`}
                  />
                </Form.Item>
              </Col>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name='reported_by'
                  style={{ ...formItemStyle }}
                >
                  <Input
                    label="Reported By"
                    type="text"
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name='reported_on'
                  initialValue={moment()}
                  rules={[
                    { required: true, message: 'Please select the Date.' },
                  ]}
                  style={{ ...formItemStyle }}
                >
                  <Input
                    label="Reported On"
                    type="date"
                    placeholder='Select Date'
                    defaultValue={moment()}
                    disabledDate={(current: any) => {
                      return current && current < new Date()
                    }}
                    required
                    disabled={incidentViewMode ? true : false}
                  />
                </Form.Item>
              </Col>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name='processing_batch_number'
                  rules={[
                    { required: true, message: 'Please enter the Internal Lot/Batch No.' },
                  ]}
                >
                  <Input
                    label="Internal Lot/Batch No."
                    type="text"
                    placeholder="Enter Internal Lot/Batch No."
                    required
                  />
                </Form.Item>
              </Col>
            </Row>
          </ModalLayout.ModalSection>

        </ModalLayout>
        <ModalLayout>

          <ModalLayout.ModalSection title="Product Details">
            <DisabledForm isDisabled={incidentViewMode ? true : false}>
              <Row gutter={[15, 15]}>
                <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name='item_name'
                    rules={[
                      { required: true, message: 'Please enter the Product Name.' },
                    ]}
                  // getValueFromEvent={(value: any) => {
                  //   form.setFieldsValue({ inventory_ID: undefined })
                  //   getInventoryID(value)
                  //   return value
                  // }}
                  >
                    <Input
                      label="Product"
                      type="text"
                      // options={renderOptions('item')}
                      placeholder={'Enter Product Name'}
                      required
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name='inventory_ID'
                    style={{ ...formItemStyle }}
                  >
                    <Input
                      label="Inventory ID"
                      placeholder={'Inventory ID'}
                      type="text"
                    // disabled
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name='quantity_affected'
                    rules={[
                      { required: true, message: 'Please enter the Quantity.' },
                    ]}
                  >
                    <Input
                      label="Quantity Affected"
                      type="number"
                      placeholder="Enter Quantity"
                      isGreaterthanOneRequired
                      required
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                  <Form.Item
                    name='measurement_unit'
                    rules={[
                      { required: true, message: 'Please select the Measurement Unit.' },
                    ]}
                  >
                    <Input
                      label="Unit of Measurement"
                      type="select"
                      options={renderOptions('measurement_unit')}
                      placeholder={'Select Measurement Unit'}
                      required
                    />
                  </Form.Item>
                </Col>
              </Row>
            </DisabledForm>
          </ModalLayout.ModalSection>

        </ModalLayout>
        <ModalLayout>

          <ModalLayout.ModalSection title="Incident Information">
            <Row>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item style={{ marginBottom: '15px', marginRight: '20px' }}>
                  <>
                    <label className='text-white' style={{ fontSize: '12px', marginTop: '5px', marginBottom: '4px' }}>Upload Files</label>
                    {/* Check out the component */}
                    <DragFileInput
                      multiple={true}
                      disabledMode={incidentViewMode}
                      beforeUpload={(file: RcFile, FileList?: RcFile[]) => {
                        console.log('dfdfdf', FileList)
                        // push file to fileList
                        fileListing.push(file)
                        // prevent default action of antd upload, so return false
                        return false
                      }}
                      fileList={fileListing}
                      multipleFileDownload={viewFileListings}
                      onRemove={(file: UploadFile) => {
                        console.log('remove file', file)
                        // remove selected file from fileList, return new listing
                        const newFileListing = fileListing.filter((f: UploadFile) => f.uid !== file.uid)
                        setFileListing(newFileListing)
                      }}

                    />
                  </>
                </Form.Item>
              </Col>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <DisabledForm isDisabled={incidentViewMode ? true : false}>
                  <Form.Item
                    name='comments'
                  // rules={[
                  //   { required: true, message: 'Please enter the Comment.' },
                  // ]}
                  >
                    <Input style={{ height: '13.678rem' }}
                      label="Incident Information"
                      type="textarea"
                      placeholder='Describe the incident that has occurred'
                    // required
                    />
                  </Form.Item>
                </DisabledForm>
              </Col>
            </Row>
          </ModalLayout.ModalSection>

        </ModalLayout>

      </Form>
    </GlobalModal >
  ) : <Loading show={isLoading} />
}

export default GenerateIncidentReport;



const Container = styled.div`
  padding: 0 18px;
    `;
type purchaseCss = {
  isDisabled?: boolean;
};

const DisabledForm = styled.div`
        pointer-events: ${(props: purchaseCss) => {
    return props.isDisabled ? 'none !important' : 'auto !important';
  }};
    `;

