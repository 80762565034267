/* eslint-disable react/jsx-props-no-spreading */
/**
 * This is thee authorized app
 * It determines the app layout and configure pages differently according to a user's role
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React, { Component } from "react";
import styled from "styled-components";

import {
  LineChartOutlined as InsightsIcon,
  WifiOutlined as DeviceManagementIcon,
  FileTextOutlined as ContractIcon,
  BellOutlined as Bell,
  GlobalOutlined as ConsumerEngagementIcon,
  ClockCircleFilled as ProductTrackingIcon,
  TeamOutlined as UserManagementIcon,
  KeyOutlined as ItrazokeysIcon,
  FileDoneOutlined as Result,
  ArrowRightOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import AntdIcon from "@ant-design/icons-react";
import { BrowserView, MobileView } from "react-device-detect";
import Logo from "../../../statics/images/Logo.png";
import MobileLayout from "./MobileLayout";
import DesktopLayout from "./DesktopLayout";
import Insights from "../../Pages/Insights";
import BeefBattle from "../../Pages/BeefBattle";
import BeefBattleResult from "../../Pages/BeefBattleResult";
import ConsumerEngagement from "../../Pages/ConsumerEngagement";
import ProductTracking from "../../Pages/ProductTracking";
import InventoryManagement from "../../Pages/InventoryManagement";
import Production from "../../Pages/InventoryManagement/pages/admin/Sales";
import RelationshipManagement from "../../Pages/RelationshipManagement";
// import ContractManagement from '../../Pages/ContractManagement';
import AssetManagemnt from "../../Pages/AssetManagement";
import UserManagement from "../../Pages/UserManagement";
import { AccessModulesShortcut, Page } from "../../../utilities/types";
// import location from "../../../statics/images/location.svg";
import location from "../../../statics/images/newValuechain.svg";
// import insights from "../../../statics/images/insights.svg";
import insights from "../../../statics/images/newInsight.svg";
// import consumer from "../../../statics/images/consumer.svg";
import consumer from "../../../statics/images/newConsumer.svg";
import { USER_ROLES, SESSION_STORAGE_KEYS } from "../../../utilities/CONSTANTS";
import inventory from "../../../statics/images/inventory.svg";
import asset from "../../../statics/images/asset.svg";
// import newAssest from "../../../statics/images/newAssest.svg";
import newAssest from "../../../statics/images/assetManagementIcon.svg";
import bell from "../../../statics/images/belNav.svg";
import user from "../../../statics/images/user.svg";
import farm from "../../../statics/images/farm.svg";
import settings from "../../../statics/images/location.svg";
import Belll from "../../../statics/images/bell.svg";
import BellNav from "../../../statics/images/bellNav.svg";
import User from "../../../statics/images/user1.svg";
import UserIcon from "../../../statics/images/userIcon.svg";
import biUsers from "../../../statics/images/biUsers.svg";

import License from "../../Pages/license";
import Admin from "../../Pages/Admin";
import Farm from "../../Pages/Farm";
import LiveStockManagement from "../../Pages/Farm/LivestockManagement";
import Dashboard from "containers/Pages/Dashboard";
import { decryptData, getSessionStorage } from "utilities/Functions/GlobalHelperFunctions";

import STATIC_IMAGES from "statics/images";
import VisitorManagement from "containers/Pages/VisitorManagement";
import ContentManagementSystem from "containers/Pages/ContentManagementSystem";
import { useAuthStore } from "zustand-stores";
import StockAndProductionManagement from "containers/Pages/StockAndProductionManagement";



const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

type State = {
  logo: string;
  pages: Page[];
  pagesExternal: Page[];
};

// class App extends Component<{}, State> {
class App extends Component<any, State> {
  constructor(props: {}) {
    super(props);
    /* -------------------------------------TO CHANGE ----------------------------------------- */
    // static mapping of organiztion to different mocked data just for demo purpose

    console.log('constructor App')
    console.log('constructor App111')

    const idToken = getSessionStorage(SESSION_STORAGE_KEYS.IDTOKEN, true)
    let payload: any = null;
    let organization: any = null;
    let userRole: any = null;
    let accessLevel: any = null;
    if (idToken) {
      payload = idToken.payload;
      organization = Array.isArray(payload["cognito:groups"])
        ? payload["cognito:groups"][0]
        : null;
      userRole = payload["custom:role"];
      accessLevel = localStorage.getItem("accessLevel");
    }

    const pageInsights = {
      key: "insights",
      path: "/app/insights",
      name: "insights",
      icon: <img src={insights} />,
      component: Insights,
      apis: [
        {
          key: "Daily",
          label: "Daily",
          url: `https://demo-${organization}.s3-ap-southeast-2.amazonaws.com/DAY_CHARTS.json`,
        },
        {
          key: "Weekly",
          label: "Weekly",
          url: `https://demo-${organization}.s3-ap-southeast-2.amazonaws.com/Weekly_Charts.json`,
        },
        {
          key: "Monthly",
          label: "Monthly",
          url: `https://demo-${organization}.s3-ap-southeast-2.amazonaws.com/MONTH_CHARTS.json`,
        },
        {
          key: "Quarterly",
          label: "Quarterly",
          url: `https://demo-${organization}.s3-ap-southeast-2.amazonaws.com/Quarter_Charts.json`,
        },
      ],
    };
    const pageDashboard = {
      key: "insights",
      path: "/app/insights",
      name: "data insights",
      icon: <img src={STATIC_IMAGES.SIDE_BAR_ICONS.insight.inactive} />,
      activeIcon: <img src={STATIC_IMAGES.SIDE_BAR_ICONS.insight.active} />,
      component: Dashboard,
      apis: [],
    };
    const pageBeefBattle = {
      key: "BeefBattle",
      path: "/app/BeefBattle",
      name: "Beef Battle Vote",
      icon: <Result style={{ width: "32px" }} />,
      component: BeefBattle,
      apis: [
        {
          key: "Daily",
          label: "Daily",
          url: `https://demo-${organization}.s3-ap-southeast-2.amazonaws.com/DAY_CHARTS.json`,
        },
        {
          key: "Weekly",
          label: "Weekly",
          url: `https://demo-${organization}.s3-ap-southeast-2.amazonaws.com/Weekly_Charts.json`,
        },
        {
          key: "Monthly",
          label: "Monthly",
          url: `https://demo-${organization}.s3-ap-southeast-2.amazonaws.com/MONTH_CHARTS.json`,
        },
        {
          key: "Quarterly",
          label: "Quarterly",
          url: `https://demo-${organization}.s3-ap-southeast-2.amazonaws.com/Quarter_Charts.json`,
        },
      ],
    };
    const pageBeefResult = {
      key: "BeefBattleResult",
      path: "/app/BeefResult",
      name: "Result",
      icon: <ContractIcon style={{ width: "32px" }} />,
      component: BeefBattleResult,
      apis: [
        {
          key: "Daily",
          label: "Daily",
          url: `https://demo-${organization}.s3-ap-southeast-2.amazonaws.com/DAY_CHARTS.json`,
        },
        {
          key: "Weekly",
          label: "Weekly",
          url: `https://demo-${organization}.s3-ap-southeast-2.amazonaws.com/Weekly_Charts.json`,
        },
        {
          key: "Monthly",
          label: "Monthly",
          url: `https://demo-${organization}.s3-ap-southeast-2.amazonaws.com/MONTH_CHARTS.json`,
        },
        {
          key: "Quarterly",
          label: "Quarterly",
          url: `https://demo-${organization}.s3-ap-southeast-2.amazonaws.com/Quarter_Charts.json`,
        },
      ],
    };

    const pageConsumerEngagement = {
      key: "consumer-engagement",
      path: "/app/consumer-engagement",
      name: "consumer engagement",
      icon: <img src={STATIC_IMAGES.SIDE_BAR_ICONS.consumer.inactive} />,
      activeIcon: <img src={STATIC_IMAGES.SIDE_BAR_ICONS.consumer.active} />,
      component: ConsumerEngagement,
      apis: [
        {
          key: "scan history",
          label: "Scan History",
          url: `${process.env.REACT_APP_API_BASE}itrazo/scan`,
        },
      ],
    };

    const pageProductTracking = {
      key: "value-chain-tracking",
      path: "/app/value-chain-tracking",
      name: "Value Chain Tracking",
      icon: <img src={STATIC_IMAGES.SIDE_BAR_ICONS.valueChain.inactive} />,
      activeIcon: <img src={STATIC_IMAGES.SIDE_BAR_ICONS.valueChain.active} />,
      component: () => <ProductTracking />,
      // apis: [
      //   {
      //     key: "product all",
      //     label: "product all",
      //     url: `${process.env.REACT_APP_API_BASE}product/all`,
      //   },
      //   {
      //     key: "product all",
      //     label: "product all",
      //     url: `${process.env.REACT_APP_API_BASE}event/all/`,
      //   },
      // ],
    };

    const inventoryManagemnt = {
      key: "inventory-management",
      path: "/app/inventory-management",
      name: "Order & Stock Management",
      icon: <img src={STATIC_IMAGES.SIDE_BAR_ICONS.inventory.inactive} />,
      activeIcon: <img src={STATIC_IMAGES.SIDE_BAR_ICONS.inventory.active} />,
      component: InventoryManagement,
    };
    const productionWorkManagement = {
      key: "production work management",
      path: "/app/production-work-management",
      name: "Production Work Management",
      icon: <img src={STATIC_IMAGES.SIDE_BAR_ICONS.inventoryProduction.inactive} />,
      activeIcon: <img src={STATIC_IMAGES.SIDE_BAR_ICONS.inventoryProduction.active} />,
      component: () => <Production />,
    }

    const stockAndProductionManagement = {
      key: "stock-production-management",
      path: "/app/stock-production-management",
      name: "Stock & Production Management",
      icon: <img src={STATIC_IMAGES.SIDE_BAR_ICONS.inventory.inactive} />,
      activeIcon: <img src={STATIC_IMAGES.SIDE_BAR_ICONS.inventory.active} />,
      component: StockAndProductionManagement,
    };

    const contentManagementSystem = {
      key: "content management system",
      path: "/app/content-management-system",
      name: "Content Management System",
      icon: <img src={STATIC_IMAGES.SIDE_BAR_ICONS.cms.inactive} />,
      activeIcon: <img src={STATIC_IMAGES.SIDE_BAR_ICONS.cms.active} />,
      component: ContentManagementSystem,
    }

    const visitorManagement = {
      key: "visitor management",
      path: "/app/visitor-management",
      name: "Visitor Management",
      icon: <img src={STATIC_IMAGES.SIDE_BAR_ICONS.vistorManagement.inactive} />,
      activeIcon: <img src={STATIC_IMAGES.SIDE_BAR_ICONS.vistorManagement.active} />,
      component: () => <VisitorManagement />,
    }

    const customerOrderManagemnt = {
      key: "customer-order-management",
      path: "/app/inventory-management",
      name: "Order Management",
      icon: <img src={STATIC_IMAGES.SIDE_BAR_ICONS.inventory.inactive} />,
      activeIcon: <img src={STATIC_IMAGES.SIDE_BAR_ICONS.inventory.active} />,
      component: InventoryManagement,
    };

    const pageRelationshipManagement = {
      key: "relationship-management",
      path: "/app/relationship-management",
      name: "Relationship Management",
      icon: <img src={STATIC_IMAGES.SIDE_BAR_ICONS.crm.inactive} />,
      activeIcon: <img src={STATIC_IMAGES.SIDE_BAR_ICONS.crm.active} />,
      component: RelationshipManagement,
      apis: [],
    };

    // const pageAlertsAdmin = {
    //   key: "alerts",
    //   path: "/app/alerts",
    //   name: "alerts",
    //   icon: <img src={BellNav} />,
    //   component: (myProps: any) => <Alerts writable {...myProps} />,
    // };

    //// New Asset management
    const assetManagemnt = {
      key: "asset-managemnt",
      path: "/app/asset-managemnt",
      name: "Asset Management",
      icon: <img src={STATIC_IMAGES.SIDE_BAR_ICONS.asset.inactive} />,
      activeIcon: <img src={STATIC_IMAGES.SIDE_BAR_ICONS.asset.active} />,
      component: AssetManagemnt,
    };

    ///old asset management
    // const pageDeviceManagement = {
    //   key: "asset-management",
    //   path: "/app/asset-management",
    //   name: "Asset Management",
    //   icon: (
    //     <img
    //       src={newAssest}
    //     // style={{ width: "31px" }}
    //     />
    //   ),
    //   component: AssetManagement,
    // };
    // const pageAlerts = {
    //   key: "alerts",
    //   path: "/app/alerts",
    //   name: "Alerts",
    //   icon: <img src={BellNav} />,
    //   component: Alerts,
    // };

    const pageFarm = {
      key: "farm",
      path: "/app/farms",
      name: "Future Farm",
      icon: <img src={User} />,
      component: Farm,
      subNav: [
        {
          key: "livestock-management",
          path: "/app/livestock-management",
          name: "Livestock Management",
          icon: <UserManagementIcon />,
          component: (myProps: any) => <LiveStockManagement />,
        },
        {
          key: "farm-operations",
          path: "/app/farm-operations",
          name: "Farm Operations",
          icon: <UserManagementIcon />,
          component: (myProps: any) => <p>Farm operations</p>,
        },
      ],
    };

    // const pageContractManagement = {
    //   key: 'contract-management',
    //   path: '/app/contract-management',
    //   name: 'Contract Management',
    //   icon: <ContractIcon />,
    //   component: ContractManagement,
    //   apis: [
    //     {
    //       url: `${process.env.REACT_APP_API_BASE}file`,
    //       key: 'contract-api',
    //       label: 'contract-api',
    //     },
    //   ],
    // };
    const pageWritableUserManagement = {
      key: "user-management",
      path: "/app/admin",
      name: "Admin",
      icon: <img src={STATIC_IMAGES.SIDE_BAR_ICONS.admin.inactive} />,
      activeIcon: <img src={STATIC_IMAGES.SIDE_BAR_ICONS.admin.active} />,
      component: (myProps: any) => <Admin />,
      apis: [
        {
          url: `${process.env.REACT_APP_API_BASE}user/list`,
          key: "listing-users-api",
          label: "listing-users-api",
        },
        {
          url: `${process.env.REACT_APP_API_BASE}user/create`,
          key: "create-user-api",
          label: "create-user-api",
        },
        {
          url: `${process.env.REACT_APP_API_BASE}user/delete`,
          key: "delete-user-api",
          label: "delete-user-api",
        },
        {
          url: `${process.env.REACT_APP_API_BASE}user/amend`,
          key: "amend-user-api",
          label: "amend-user-api",
        },
      ],
    };


    const pageReadOnlyUserManagement = {
      key: "user-management",
      path: "/app/user-management",
      name: "UserManagement",
      icon: <img src={User} />,
      component: UserManagement,
      apis: [
        {
          url: `${process.env.REACT_APP_API_BASE}user/list`,
          key: "listing-users-api",
          label: "listing-users-api",
        },
        {
          url: `${process.env.REACT_APP_API_BASE}user/create`,
          key: "create-user-api",
          label: "create-user-api",
        },
        {
          url: `${process.env.REACT_APP_API_BASE}user/delete`,
          key: "delete-user-api",
          label: "delete-user-api",
        },
        {
          url: `${process.env.REACT_APP_API_BASE}user/amend`,
          key: "amend-user-api",
          label: "amend-user-api",
        },
      ],
    };

    // const pageWritableItrazokeys = {
    //   key: "iTrazokeys",
    //   path: "/app/iTrazokeys",
    //   name: "ADI Key Management",
    //   icon: <ItrazokeysIcon />,
    //   component: (myProps: any) => <Itrazokeys writable {...myProps} />,
    //   apis: [
    //     {
    //       url: `${process.env.REACT_APP_API_BASE}key`,
    //       key: "requesting-api",
    //       label: "requesting-api",
    //     },
    //     {
    //       url: `${process.env.REACT_APP_API_BASE}key/download`,
    //       key: "key-download-api",
    //       label: "key-download-api",
    //     },
    //   ],
    // };

    // const pageReadonlyItrazokeys = {
    //   key: "iTrazokeys",
    //   path: "/app/iTrazokeys",
    //   name: "iTrazokeys",
    //   icon: <ItrazokeysIcon />,
    //   component: Itrazokeys,
    //   apis: [
    //     {
    //       url: `${process.env.REACT_APP_API_BASE}key`,
    //       key: "requesting-api",
    //       label: "requesting-api",
    //     },
    //     {
    //       url: `${process.env.REACT_APP_API_BASE}key/download`,
    //       key: "key-download-api",
    //       label: "key-download-api",
    //     },
    //   ],
    // };
    // let tierOne;
    // let tierTwo;
    // let tierThree;
    // let tierFour;
    // let tierFive;
    // if (organization === "organicoliveoil") {
    //   tierOne = [
    //     pageInsights,
    //     pageProductTracking,
    //     // pageFarm,
    //     pageConsumerEngagement,
    //     // pageDeviceManagement,
    //     assetManagemnt,
    //     // pageAlertsAdmin,
    //     // pageWritableItrazokeys,
    //     inventoryManagemnt,
    //     productionWorkManagement,
    //     visitorManagement,
    //     pageRelationshipManagement,
    //     pageWritableUserManagement,
    //   ];
    // }
    // // else if (
    // //   organization === "costagroup" &&
    // //   sessionStorage.getItem("USER_NAME") == "admin"
    // // ) {
    // //   tierOne = [pageBeefBattle, pageBeefResult];
    // // }
    // else {
    //   tierOne = [
    //     pageInsights,
    //     assetManagemnt, ////kawal added for temporary to view asset management page
    //     pageProductTracking,
    //     // pageFarm,
    //     pageConsumerEngagement,
    //     // pageDeviceManagement,
    //     // pageAlertsAdmin,
    //     // pageWritableItrazokeys,
    //     pageWritableUserManagement,
    //     pageRelationshipManagement,
    //   ];
    // }

    // if (true) {
    //   tierOne = tierOne;
    //   tierTwo = [
    //     pageInsights,
    //     pageProductTracking,
    //     // pageAlerts,
    //     // pageWritableItrazokeys,
    //     pageWritableUserManagement,
    //   ];

    //   tierThree = [
    //     pageInsights,
    //     // pageDeviceManagement,
    //     // pageAlerts,
    //     // pageWritableItrazokeys,
    //     pageWritableUserManagement,
    //   ];

    //   tierFour = [
    //     pageInsights,
    //     pageConsumerEngagement,
    //     // pageAlertsAdmin,
    //     // pageWritableItrazokeys,
    //     pageWritableUserManagement,
    //   ];
    //   tierFive = [
    //     pageInsights,
    //     pageProductTracking,
    //     pageConsumerEngagement,
    //     // pageAlertsAdmin,
    //     // pageWritableItrazokeys,
    //     pageWritableUserManagement,
    //   ];
    // }
    // let pages: Page[] = [];

    // switch (accessLevel) {
    //   case "tierOne": {
    //     pages = tierOne;
    //     break;
    //   }
    //   case "tierTwo": {
    //     pages = tierTwo;
    //     break;
    //   }
    //   case "tierThree": {
    //     pages = tierThree;
    //     break;
    //   }
    //   case "tierFour": {
    //     pages = tierFour;
    //     break;
    //   }
    //   case "tierFive":
    //     pages = tierFive;
    //     break;
    // }

    // this.state = {
    //   // customized data to be used in this app
    //   logo: Logo,
    //   pages,
    //   pagesExternal: [],
    // };



    const access_level = getSessionStorage(SESSION_STORAGE_KEYS.ACCESS_LEVEL, true)
    const moduleAccess = useAuthStore.getState().moduleAccess
    const isAcgUser = useAuthStore.getState().isAcgUser
    const isAccUser = useAuthStore.getState().isAccUser

    const newPages: Page[] = [];

    if (access_level) {
      console.log('cur pages access_level', access_level)

      if (isAcgUser || isAccUser) {
        // if (access_level.ins && access_level.osm != 'customer') {
        if (access_level.osm != 'customer') {
          newPages.push(pageDashboard)
        }
        if (access_level.vct) {
          newPages.push(pageProductTracking)
        }

        // Info: commented for if isAcgUser || isAccUser
        if (access_level.ce || access_level.costagroup || access_level.organicoliveoil || access_level.thechosenones || access_level.tive) {
          newPages.push(pageConsumerEngagement)
        }

        if (access_level.am) {
          newPages.push(assetManagemnt)
        }
        if (access_level.osm == 'admin' || access_level.osm == 'ops' || access_level.osm == 'marketing') {
          // newPages.push(inventoryManagemnt, productionWorkManagement, visitorManagement)
          newPages.push(
            // inventoryManagemnt, productionWorkManagement,
            stockAndProductionManagement, visitorManagement, contentManagementSystem)
        }

        if (access_level.osm == 'customer') {
          newPages.push(customerOrderManagemnt)
        }
        if (access_level.osm == 'quality' || access_level.osm == "production") {
          newPages.push(
            // inventoryManagemnt, productionWorkManagement, 
            stockAndProductionManagement)
        }

        if (access_level.crm || access_level.osm == 'marketing' || access_level.osm == 'quality' || access_level.osm == 'ops') {
          newPages.push(pageRelationshipManagement)
        }
        // if (access_level.admin && access_level.osm != 'customer' && access_level.osm != 'quality' && access_level.osm != 'ops' && access_level.osm != 'marketing' && access_level.osm != "production") {
        if (access_level.osm != 'customer' && access_level.osm != 'quality' && access_level.osm != 'ops' && access_level.osm != 'marketing' && access_level.osm != "production") {
          newPages.push(pageWritableUserManagement)
        }
      } else {
        if (moduleAccess.ins.hasAccess) {
          newPages.push(pageDashboard)
        }
        if (moduleAccess.vct.hasAccess) {
          newPages.push(pageProductTracking)
        }
        if (moduleAccess.ce.hasAccess) {
          newPages.push(pageConsumerEngagement)
        }
        if (moduleAccess.am.hasAccess) {
          newPages.push(assetManagemnt)
        }
        if (moduleAccess.osm.hasAccess) {
          newPages.push(
            // inventoryManagemnt, productionWorkManagement, 
            stockAndProductionManagement, visitorManagement, contentManagementSystem)
        }
        if (moduleAccess.crm.hasAccess) {
          newPages.push(pageRelationshipManagement)
        }
        if (moduleAccess.admin.hasAccess) {
          newPages.push(pageWritableUserManagement)
        }

      }

    }

    this.state = {
      // customized data to be used in this app
      logo: Logo,
      pages: newPages,
      pagesExternal: [],
    };
  }
  render() {
    const { logo, pages, pagesExternal } = this.state;

    console.log('cur pages', pages)


    return pages.length > 0 && (
      <Wrapper>
        <BrowserView>
          <DesktopLayout
            logo={logo}
            pages={pages}
            pagesExternal={pagesExternal}
          />
          <a href={process.env.REACT_APP_CUSTOMER_ONBOARDING_LINK} target='_blank'> Customer Onboarding</a>

        </BrowserView>
        <MobileView>
          <MobileLayout pages={pages} />
        </MobileView>

      </Wrapper>
    );
  }
}



export default App;
