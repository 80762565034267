import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { EditProduct, Products } from "containers/Pages/ContentManagementSystem/pages";
import VisitorManagement from "containers/Pages/VisitorManagement";
import { VISITOR_MANAGEMENT_ROOT_PATH } from "containers/Pages/VisitorManagement/VisitorManagementRoutes";
import { AddressBook, Dashboard, FoB, Logs } from "containers/Pages/VisitorManagement/pages";

const Right = styled.div`
  height: 100%;
  padding: 0 0.5em 0.5em 0.5em;
  border-radius: 5px;
  margin-right: 1em;
  margin-left: 1em;
`;

const PageLayout = ({ children }: { children: React.ReactNode }) => (
  <>
    <VisitorManagement />
    <Right>{children}</Right>
  </>
);

const VisitorManagementRouting = () => {
  return (
    <Routes>
      <Route
        path={VISITOR_MANAGEMENT_ROOT_PATH}
        element={<Navigate to={`${VISITOR_MANAGEMENT_ROOT_PATH}/dashboard`} />}
      />
      <Route
        path={`${VISITOR_MANAGEMENT_ROOT_PATH}/dashboard`}
        element={<PageLayout><Dashboard /></PageLayout>}
      />
      <Route
        path={`${VISITOR_MANAGEMENT_ROOT_PATH}/logs`}
        element={<PageLayout><Logs /></PageLayout>}
      />
      <Route
        path={`${VISITOR_MANAGEMENT_ROOT_PATH}/address-book`}
        element={<PageLayout><AddressBook /></PageLayout>}
      />
      <Route
        path={`${VISITOR_MANAGEMENT_ROOT_PATH}/fob`}
        element={<PageLayout><FoB /></PageLayout>}
      />
    </Routes>
  );
};

export default VisitorManagementRouting;