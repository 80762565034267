import { Col, Row, message } from 'antd';
import AdiTable from 'components/AdiTable'
import React, { useCallback, useEffect, useState } from 'react'
import { DisplayEmptyTextPrimary, DisplaySecondaryText, FlexWrapper, TrackGridFour, TrackGridThree, TrackGridTwo } from 'statics/styles/StyledComponents';
import { TABLE_CELL_CONFIG } from 'utilities/CONSTANTS';
import { capitaliseFirst, formatTitle, initialCellValue } from 'utilities/Functions/FormatFunctions';
import { getSelectorOptions } from 'utilities/Functions/GlobalHelperFunctions';
import Input from 'components/Input';
import GlobalDrawer from 'components/GlobalModal/GlobalDrawer';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import styled from 'styled-components';
import { checkWindowSelectedText } from 'containers/Pages/InventoryManagement/utilities/functions';
import moment from 'moment';
import { DisposalReasonOptions } from 'utilities/CONSTANTS';

import DisposalAPI from '../../apis/DisposalAPI';
import useAuthStore, { AuthStore } from 'zustand-stores/useAuthStore';
import TableCard from 'components/AdiTable/TableCard';

const DisposalReport = () => {
    const isAmReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.am.isReadOnly)


    const [tableData, setTableData] = useState<any>([]);
    const [filterData, setFilterData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');
    const [selectedItemData, setSelectedItemData] = useState<any>(null);
    const [open, setOpen] = useState<boolean>(false);


    const getTableData = useCallback(
        async () => {
            setIsLoading(true)

            try {
                const result = await DisposalAPI.getAll()
                if (result && result.length > 0) {
                    const newResult = Array.isArray(result) && result.map((item: any) => {
                        const newAssetList = Array.isArray(item.asset_data) && item.asset_data.map((asset: any) => {
                            return {
                                ...asset,
                                disposal_ID: item.disposal_ID,
                                disposed_on: item.disposed_on,
                            }
                        })
                        return newAssetList
                    }).flat()
                    console.log('result', newResult)
                    const finalResult = Array.isArray(newResult) && newResult.map((item: any, i: number) => {
                        return {
                            ...item,
                            key: i + 1,
                        }
                    })
                    console.log('finalResult', finalResult)


                    setTableData(finalResult)
                    setFilterData(finalResult)
                } else {
                    message.info('No result found')
                    setTableData([])
                }
                // setIsLoading(false)
                // dispatch(actions.setLoading(false))
            } catch (err) {
                console.log('getTableData disposal fail...', err)
                // setIsLoading(false)
                // dispatch(actions.setLoading(false))

            } finally {
                // dispatch(actions.setLoading(false))
                setIsLoading(false)
            }

        },
        [],
    )

    const initialize = () => {
        getTableData()
    }

    useEffect(initialize, [])

    const handleRowClick = (record: any, index: number) => {
        console.log('handleRowClick', record)
        setSelectedItemData(record)
        setOpen(true)
    }

    const renderOptions = (type: string) => {
        if (type == 'reason') {
            return getSelectorOptions(DisposalReasonOptions, 'Reason For Disposal', 'value', 'label')
        } else {
            return getSelectorOptions([], 'Select')
        }
    }

    const columns: any = [

        {
            title: formatTitle("Disposal Report ID"),
            dataIndex: "disposal_ID",
            key: "disposal_ID",
            width: 200,
            render: initialCellValue,
        },
        {
            title: formatTitle("Disposed On"),
            dataIndex: "disposed_on",
            ...TABLE_CELL_CONFIG,
            key: "disposed_on",
            render: (text: any, record: any, index: number) => text ? moment(text).format('DD/MM/YYYY') : initialCellValue(text)
        },
        {
            title: formatTitle("Asset Name"),
            dataIndex: "asset_name",
            key: "asset_name",
            ...TABLE_CELL_CONFIG,
            render: initialCellValue,
        },
        {
            title: formatTitle("Asset ID"),
            dataIndex: "asset_ID",
            key: "asset_ID",
            ...TABLE_CELL_CONFIG,
            render: initialCellValue,
        },
        {
            title: formatTitle("Asset Category"),
            dataIndex: "asset_category",
            key: "asset_category",
            ...TABLE_CELL_CONFIG,
            render: initialCellValue,
        },
        {
            title: formatTitle("Asset Type"),
            dataIndex: "asset_type",
            key: "asset_type",
            ...TABLE_CELL_CONFIG,
            render: initialCellValue,
        },
        {
            title: formatTitle("Asset Value"),
            dataIndex: "asset_price",
            key: "asset_price",
            ...TABLE_CELL_CONFIG,
            render: initialCellValue,
        },
        // {
        //     title: formatTitle("Customer Name"),
        //     dataIndex: "customer_ID",
        //     key: "customer_ID",
        //     ...TABLE_CELL_CONFIG,
        //     render: initialCellValue,
        // },
        // {
        //     title: formatTitle("Customer ID"),
        //     dataIndex: "customer_ID",
        //     key: "customer_ID",
        //     ...TABLE_CELL_CONFIG,
        //     render: initialCellValue,
        // },
        {
            title: formatTitle("Home Location"),
            dataIndex: "home_location",
            key: "home_location",
            ...TABLE_CELL_CONFIG,
            render: initialCellValue,
        },
        {
            title: formatTitle("Sensor ID"),
            dataIndex: "sensor_ID",
            key: "sensor_ID",
            ...TABLE_CELL_CONFIG,
            render: initialCellValue,
        },
        {
            title: formatTitle("Reason for Disposal"),
            dataIndex: "reason_for_disposal",
            key: "reason_for_disposal",
            ...TABLE_CELL_CONFIG,
            render: (text: any, record: any, index: number) => text ? capitaliseFirst(text) : ''
        },
        {
            title: formatTitle("comments"),
            dataIndex: "comments",
            key: "comments",
            ...TABLE_CELL_CONFIG,
            render: (text: any, record: any, index: number) => text ? capitaliseFirst(text) : ''
        },
    ];


    isAmReadOnly && columns.pop()

    const onTextSearch = () => {
        console.log('onTextSearch', searchText, tableData)
        const filtered = tableData.filter((item: any) => {
            const assetName = item.asset_name.toLowerCase() || ''
            const assetID = item.asset_ID.toLowerCase() || ''
            const disposalID = item.disposal_ID.toLowerCase() || ''
            const search = searchText?.toLowerCase() || ''
            return assetName.includes(search) || assetID.includes(search) || disposalID.includes(search)
        })
        setFilterData(filtered)
    }
    const renderFooterBtns = () => (
        <FlexWrapper flexEnd key='0'>
            <SimpleButton
                id='modal-btn-width-regular'
                text={'View Only'}
                disabled
            />
        </FlexWrapper>
    )
    const closeModal = () => {
        setOpen(false)
        setSelectedItemData(null)
    }

    const renderModal = () => {
        return (
            <GlobalDrawer
                open={open}
                onClose={closeModal}
                title="Disposal Report Details"
                footer={renderFooterBtns()}
            >
                <>
                    <Row gutter={[20, 0]}>
                        <Col sm={{ span: '24' }} md={{ span: '12' }} style={{ marginBottom: '20px' }}>
                            <Input
                                label="Disposal Report ID"
                                name="disposal_ID"
                                type="text"
                                placeholder="Disposal Report ID"
                                value={selectedItemData?.disposal_ID}
                                disabled
                            />
                        </Col>
                        <Col sm={{ span: '24' }} md={{ span: '12' }} style={{ marginBottom: '20px' }}>
                            <Input
                                label="Disposal On"
                                name="disposed_on"
                                type="text"
                                placeholder="Disposal On"
                                value={moment(selectedItemData?.disposed_on).format('DD/MM/YYYY')}
                                disabled
                            />
                        </Col>
                        <Col sm={{ span: '24' }} md={{ span: '12' }} style={{ marginBottom: '20px' }}>
                            <Input
                                label="Asset Name"
                                name="asset_name"
                                type="text"
                                placeholder="Asset Name"
                                value={selectedItemData?.asset_name}
                                disabled
                            />
                        </Col>
                        <Col sm={{ span: '24' }} md={{ span: '12' }} style={{ marginBottom: '20px' }}>
                            <Input
                                label="Asset ID"
                                name="asset_ID"
                                type="text"
                                placeholder="Asset ID"
                                value={selectedItemData?.asset_ID}
                                disabled
                            />
                        </Col>
                        <Col sm={{ span: '24' }} md={{ span: '12' }} style={{ marginBottom: '20px' }}>
                            <Input
                                label="Asset Category"
                                name="asset_category"
                                type="text"
                                placeholder="Asset Category"
                                value={selectedItemData?.asset_category}
                                disabled
                            />
                        </Col>
                        <Col sm={{ span: '24' }} md={{ span: '12' }} style={{ marginBottom: '20px' }}>
                            <Input
                                label="Asset Type"
                                name="asset_type"
                                type="text"
                                placeholder="Asset Type"
                                value={selectedItemData?.asset_type}
                                disabled
                            />
                        </Col>
                        <Col sm={{ span: '24' }} md={{ span: '12' }} style={{ marginBottom: '20px' }}>
                            <Input
                                label="Asset Value"
                                name="asset_price"
                                type="text"
                                placeholder="Asset Value"
                                value={selectedItemData?.asset_price}
                                disabled
                            />
                        </Col>
                        <Col sm={{ span: '24' }} md={{ span: '12' }} style={{ marginBottom: '20px' }}>
                            <Input
                                label="Home Location"
                                name="home_location"
                                type="text"
                                placeholder="Home Location"
                                value={selectedItemData?.home_location}
                                disabled
                            />
                        </Col>
                        {/* <Col sm={{ span: '24' }} md={{ span: '12' }} style={{ marginBottom: '20px' }}>
                            <Input
                                label="Customer ID"
                                name="customer_ID"
                                type="text"
                                placeholder="Customer ID"
                                value={selectedItemData?.customer_ID}
                                disabled
                            />
                        </Col> */}
                        <Col sm={{ span: '24' }} md={{ span: '12' }} style={{ marginBottom: '20px' }}>
                            <Input
                                label="Sensor ID"
                                name="sensor_ID"
                                type="text"
                                placeholder="Sensor ID"
                                value={selectedItemData?.sensor_ID}
                                disabled
                            />
                        </Col>
                        <Col sm={{ span: '24' }} md={{ span: '12' }} style={{ marginBottom: '20px' }}>
                            <Input
                                label="Reason for Disposal"
                                name="reason_for_disposal"
                                placeholder="Reason for Disposal"
                                value={selectedItemData?.reason_for_disposal}
                                disabled
                                type="select"
                                options={renderOptions('reason')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={{ span: '24' }} md={{ span: '12' }} style={{ marginBottom: '20px' }}>
                            <Input
                                label="Additional Comment"
                                name="comments"
                                type="textarea"
                                placeholder="Additional Comment"
                                value={selectedItemData?.comments}
                                disabled
                            />
                        </Col>
                    </Row>
                </>
            </GlobalDrawer>
        )
    };


    return (
        <>
            {!isLoading && Array.isArray(tableData) && tableData.length == 0 ?
                (<>
                    <DisplayEmptyTextPrimary style={{ marginTop: '200px' }}>
                        No asset disposal reports are available.
                        <DisplaySecondaryText>
                            Reports will be generated upon asset disposal.
                        </DisplaySecondaryText>
                    </DisplayEmptyTextPrimary>

                </>
                ) : (
                    <>
                        <TableCard>
                            <TrackGridTwo style={
                                {
                                    padding: '0 10px',
                                }
                            }>
                                <Input
                                    style={{ width: '300px' }}
                                    type="search"
                                    placeholder="Search by Asset Name, ID or Disposal ID"
                                    onChange={(e: any) => {
                                        setSearchText(e.target.value)
                                    }}
                                    onSearch={onTextSearch}
                                    value={searchText}
                                    allowClear={searchText != undefined || searchText != ""}
                                />
                            </TrackGridTwo>
                            {/* <Label>Click on a specific row to view full details.</Label> */}

                            <AdiTable
                                loading={isLoading}
                                fullWidth
                                marginTop='20px'
                                className="mergeable-table"
                                tableData={filterData}
                                columns={columns}
                                onRowClick={(record: any, index: any, event: any) => {
                                    // prevent row click if trying to select text
                                    if (checkWindowSelectedText()) return
                                    handleRowClick(record, index)
                                }}
                                extraPaginationPadding
                            />
                        </TableCard>

                        {renderModal()}
                    </>
                )}


        </>

    )
}

export default DisposalReport
const Label = styled.label`
    font-size: 12px;
    color: #c6c6c6;
    margin-top: 20px;
`;