/**
 * This file defines all api functions associated with Order API
 */


import { logError } from "../utilities/functions";
import { OSM_API, CRM_API } from "utilities/AdiApi";

import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";

// Error handling is in the component that calls the api function
const OrderAPI = {
  createOrder: async (payload: any) => {
    console.log('payload createOrder')

    try {
      const res = await OSM_API({
        method: 'POST',
        url: `order-placement`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  },
  updateOrder: async (payload: any) => {
    console.log('payload update order')

    try {
      const res = await OSM_API({
        method: 'PUT',
        url: `order-placement`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  },
  getOrdersSummary: async (orderFor: string) => {
    try {
      const url = `/order-placement?get_summary=${orderFor}`
      const res = await OSM_API.get(url)
      console.log('res', res)
      return res.data.body.Item
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getOrdersByCustomerId: async (id: string) => {
    try {
      const res = await OSM_API.get(`/order-placement?customer_ID=${id}`)
      return res.data.body.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getOrdersID: async () => {
    try {
      const res = await OSM_API.get(`/order-placement/order-id`)
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getOrdersByOrderStatus: async (status: string, orderFor: string) => {
    try {
      const res = await OSM_API.get(`/order-placement?order_status=${status}&order_for=${orderFor}`)
      return res.data.body.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },

  getAddressesByBusId: async (id: string) => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API.get(`${bizCode}/Address?business_ID=${id}`)
      return res.data.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getAllCustomer: async () => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API.get(`${bizCode}/Customer`)
      return res.data.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },

  getAllCompanies: async () => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API.get(`${bizCode}/Business?`)
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }

  },
  getAllOrders: async () => {
    try {
      const res = await OSM_API.get(`/order-placement`)
      return res.data.body.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getAllPurchaseOrders: async () => {
    try {
      const res = await OSM_API.get(`/purchase-orders`)
      return res.data.body.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getPurchaseOrderByOrderId: async (id: string) => {
    try {
      const res = await OSM_API.get(`/purchase-orders?order_id=${id}`)
      return res.data.body.Item
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getAllReceivePurchaseOrders: async () => {
    try {
      const res = await OSM_API.get(`/receive-purchase-orders`)
      return res.data.body.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getAddInventoryOrderData: async (orderId: string) => {
    // only used on Add Inventory Page, it includes data of purchase order, received purchase order and associated supplier
    try {
      const res = await OSM_API.get(`/purchase-orders?order_ID=${orderId}&add_inventory=true`)
      return res.data.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
  updateOrderPlacement: async (data: any) => {
    try {
      const res = await OSM_API.put(`/order-placement`, data)
      return res.data.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getBatchNumberIncrement: async () => {
    try {
      const res = await OSM_API.get(`/order-placement/batch-number`)
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }
  },
}

export default OrderAPI