/**
 * This is the app entrance.
 *
 * It requires user to sign-in first.
 * When successfully signed-in, user will be navigated to the authorized app
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React, { Component } from "react";
// import { BrowserRouter as Router } from "react-router-dom";
import { HashRouter as Router } from "react-router-dom";
import axios from "axios";
import Amplify, { Auth } from "aws-amplify";
import styled, { ThemeProvider } from "styled-components";
import EmailValidator from "email-validator";
import AuthorizedApp from "./Routing/index";
import Loading from "../../components/Loading";
import { SignOutContext } from "../../components/GlobalButtons/LogOutBotton";
import {
  SESSION_STORAGE_KEYS,
  CHALLENGE_NAMES,
  AMPLIFY_ERROR_CODE,
  KEYBOARD_CODES,
  ENCRYPTION_KEY,
} from "../../utilities/CONSTANTS";
import { onKeyEvent } from "../../utilities/Functions/Functions";
import { theme, Button } from "../../statics/styles/StyledComponents";
import DialogProvider from "../../components/Dialog";
import { CheckOutlined } from "@ant-design/icons";
import AntdIcon from "@ant-design/icons-react";
import { isMobile } from "react-device-detect";
// import { AWSSDK } from '../../utils/aws-client';
import { Portal } from 'react-portal';
import TextInput from "../../components/Input/TextInput";
import Logo from "../../components/Logo";
import CryptoJS from "crypto-js";
import AWS from "aws-sdk";
import {
  GetIdInput,
  GetOpenIdTokenInput,
} from "aws-sdk/clients/cognitoidentity";
import { AssumeRoleWithWebIdentityRequest } from "aws-sdk/clients/sts";
import { encryptData, getSessionStorage, setSessionStorage } from "utilities/Functions/GlobalHelperFunctions";
import { AccessModulesShortcut } from "utilities/types";
import { Col, message, Row } from "antd";
import { useAuthStore, useGeneralStore } from 'zustand-stores'
import useResponsive from "utilities/hooks/useResponsive";
import logo from '../../statics/images/Logo1.png';
import GlobalDataControl from "./GlobalDataControl";
import { GeneralStore } from "zustand-stores/useGeneralStore";
import { useShowAddBtns } from "zustand-stores/useShowAddBtns";
import { useAssetsDropDownOpen } from "zustand-stores/useAssetsDropDownOpen";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
type State = {
  redirect:
  | "SignIn"
  | "SetNewPassword"
  | "AuthorizedApp"
  | "ForgetPassword"
  | "LoginWithItrazo";
  showPassword: boolean;
  isCodeSent: boolean;
  email: string;
  username: string;
  password: string;
  reset: string;
  confirm: string;
  message: string;
  type: "warning" | "error" | "success";
  securityCode: string;
  focusEmail: boolean;
  focusPassword: boolean;
  focusSecure: boolean;
  focusReset: boolean;
  focusConfirm: boolean;
  auth: any;
  loading: boolean;
  remember: boolean;
  redirectUrl?: string | null;
  stat?: string | null;
  isGlobalLoading: boolean;
};

type MessageType = "warning" | "error" | "success";



const amplifyConfig = {
  Auth: {
    region: "ap-southeast-2",
    userPoolId: `${process.env.REACT_APP_COGNITO_ID}`,
    userPoolWebClientId: `${process.env.REACT_APP_COGNITO_CLIENT_KEY}`,
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
};

interface AppWrapperInterface {
  children: React.ReactNode
}

function AppWrapper({ children }: AppWrapperInterface) {
  const { setClose } = useShowAddBtns();
  const { close } = useAssetsDropDownOpen();

  return (
    <div onClick={() => {
      setClose()
      close();
    }}>
      {children}
    </div>
  )
}

// old verstion has {} as first param
// class App extends Component<{}, State> {
class App extends Component<any, State> {
  // timer to automatically sign out user when session time out
  // need to be clear when logout, timeout, and unmount page
  timer: any = null;

  // Zustand store unsubscribe
  unsubscribe: any = null;

  constructor(props: any) {
    super(props);
    this.state = {
      // if the user logged in before 
      // redirect: sessionStorage.getItem('isLogin') == 'yes' ? "AuthorizedApp" : "SignIn",
      redirect: "SignIn",
      showPassword: false,
      isCodeSent: false,
      email: "",
      username: "",
      password: "",
      securityCode: "",
      reset: "",
      confirm: "",
      message: "",
      type: "warning",
      focusEmail: true,
      focusPassword: false,
      focusSecure: false,
      focusReset: false,
      focusConfirm: false,
      auth: undefined,
      loading: false,
      remember: false,
      redirectUrl: "",
      stat: "",
      isGlobalLoading: useGeneralStore.getState().isGlobalLoading,
    };
  }

  getValue(name: any, url: any) {
    if (!url) url = window.location.href;
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    const regexS = "[\\?&]" + name + "=([^&#]*)";
    const regex = new RegExp(regexS);
    const results = regex.exec(url);
    return results == null ? null : results[1];
  }

  componentDidMount() {
    this.checkStorage();
    Amplify.configure(amplifyConfig);

    const stat = this.getValue("state", window.location.href);
    if (stat) {
      this.setState({
        redirectUrl: decodeURIComponent(
          (this.getValue("redirect_uri", window.location.href)
            ? this.getValue("redirect_uri", window.location.href)
            : "") || ""
        ),
        stat: this.getValue("state", window.location.href)
          ? this.getValue("state", window.location.href)
          : "",
        redirect: "LoginWithItrazo",
      });
    }

    // Subscribe to zustand state changes
    this.unsubscribe = useGeneralStore.subscribe((state: GeneralStore) => {
      this.setState({
        isGlobalLoading: state.isGlobalLoading
      })
    }
    )

  }

  componentWillUnmount() {
    // Unsubscribe to zustand state when the component is unmounted
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  UNSAFE_componentWillMount() {
    const idToken = getSessionStorage(SESSION_STORAGE_KEYS.IDTOKEN, true)
    if (!idToken) {
      const credentials = localStorage.getItem("credentials");
      if (credentials) {
        console.log("cred", credentials);
        const bytes = CryptoJS.AES.decrypt(credentials, ENCRYPTION_KEY);
        const dataOne = JSON.parse(
          bytes.toString(CryptoJS.enc.Utf8)
            ? bytes.toString(CryptoJS.enc.Utf8)
            : ""
        );
        const data = JSON.parse(dataOne ? dataOne : "");
        if (data && data.email) {
          this.setState({
            email: data.email,
            remember: true,
          });
        }
        if (data && data.password) {
          this.setState({
            password: data.password,
          });
        }
      }
    }
  }

  // componentWillUnmount() {
  //   this.signout();
  // }

  automaticTimeout = (time: number) => {
    this.timer = setTimeout(() => {
      // eslint-disable-next-line no-alert
      alert("Session time out, please sign in again");
      this.signout();
    }, time);
  };

  configAxio = (jwtToken: string) => {
    axios.defaults.headers.common.Authorization = `Bearer ${jwtToken}`;
  };

  checkStorage = () => {
    const tokenString = getSessionStorage(SESSION_STORAGE_KEYS.IDTOKEN, true);
    // if there is a token in local storage and not expired
    if (tokenString) {
      const token = tokenString;
      const { jwtToken, payload } = token;
      const { exp } = payload;
      const difference = exp * 1000 - new Date().getTime();
      if (difference > 0) {
        this.automaticTimeout(difference);
        this.configAxio(jwtToken);
        this.setState({
          redirect: "AuthorizedApp",
          email: "",
          password: "",
          securityCode: "",
          reset: "",
          confirm: "",
        });
      }
    }
  };
  // // old version of checkStorage
  // checkStorage = () => {
  //   const tokenString = sessionStorage.getItem(SESSION_STORAGE_KEYS.IDTOKEN);
  //   // if there is a token in local storage and not expired
  //   if (tokenString) {
  //     const token = JSON.parse(tokenString);
  //     const { jwtToken, payload } = token;
  //     const { exp } = payload;
  //     const difference = exp * 1000 - new Date().getTime();
  //     if (difference > 0) {
  //       this.automaticTimeout(difference);
  //       this.configAxio(jwtToken);
  //       this.setState({
  //         redirect: "AuthorizedApp",
  //         email: "",
  //         password: "",
  //         securityCode: "",
  //         reset: "",
  //         confirm: "",
  //       });
  //     }
  //   }
  // };

  // checkPassword = (password: string) => {
  //   const regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*.])[a-zA-Z0-9!@#$%^&*.]{6,}$/;
  //   return regularExpression.test(password);
  // };

  signout = () => {
    this.setState({
      loading: true,
    });
    // {global: true}
    Auth.signOut()
      .then(() => {
        clearTimeout(this.timer);
        sessionStorage.clear();
        localStorage.removeItem("accessLevel");
        // reset page location
        document.location.replace("");
      })
      .catch((e) => {
        message.error(`Sign out failed. Please try again.\n${e.message}`);
        // console.log(`Sign out failed. Please try again.\n${e.message}`);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  getCognitoIdentityCredentials = async (idToken: string) => {
    AWS.config.region = "ap-southeast-2";
    const sts = new AWS.STS();
    const cognitoidentity = new AWS.CognitoIdentity();
    const params: GetIdInput = {
      IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID! /* required */,
      AccountId: process.env.REACT_APP_ACCOUNT,
      Logins: {
        ["cognito-idp.ap-southeast-2.amazonaws.com/" +
          process.env.REACT_APP_COGNITO_ID]: idToken,
        /* '<IdentityProviderName>': ... */
      },
    };

    return new Promise((resolve, reject) => {

      cognitoidentity.getId(params, function (err, data) {
        if (err) {

          console.log(err, err.stack) // an error occurred
          return reject(err)
        }
        else {
          console.log(data);
          const params: GetOpenIdTokenInput = {
            IdentityId: data.IdentityId! /* required */,
            Logins: {
              ["cognito-idp.ap-southeast-2.amazonaws.com/" +
                process.env.REACT_APP_COGNITO_ID]: idToken,
              // idToken,
              /* '<IdentityProviderName>': ... */
            },
          };
          cognitoidentity.getOpenIdToken(params, async function (err, data) {
            if (err) {
              console.log(err, err.stack) // an error occurred
              return reject(err)
            }
            else {
              console.log(data); // successful response
              const stsParams: AssumeRoleWithWebIdentityRequest = {
                DurationSeconds: 43200,
                // optional / intersection: Policy: "{\"Version\":\" etc.
                RoleArn:
                  "arn:aws:iam::" +
                  process.env.REACT_APP_ACCOUNT +
                  ":role/itrazoadi-CognitoAuthorizedRole",
                RoleSessionName: "itrazoAidSession", // probably can be anything that's not too long
                WebIdentityToken: data.Token!,
              };

              const credentials = await sts
                .assumeRoleWithWebIdentity(stsParams)
                .promise();
              console.log(credentials.Credentials);
              if (credentials && credentials.Credentials) {

                setSessionStorage(
                  SESSION_STORAGE_KEYS.ACCESS_KEY_ID,
                  credentials.Credentials.AccessKeyId
                );
                setSessionStorage(
                  SESSION_STORAGE_KEYS.ACCESS_KEY_SEC,
                  credentials.Credentials.SecretAccessKey
                );
                setSessionStorage(
                  SESSION_STORAGE_KEYS.SESSION_TOKEN,
                  credentials.Credentials.SessionToken
                );
                // const encryptedAccessKeyId = JSON.stringify(encryptData(credentials.Credentials.AccessKeyId))
                // const encryptedAccessKeySec = JSON.stringify(encryptData(credentials.Credentials.SecretAccessKey))
                // const encryptedSessionToken = JSON.stringify(encryptData(credentials.Credentials.SessionToken))
                // sessionStorage.setItem(
                //   SESSION_STORAGE_KEYS.ACCESS_KEY_ID,
                //   encryptedAccessKeyId
                // );
                // sessionStorage.setItem(
                //   SESSION_STORAGE_KEYS.ACCESS_KEY_SEC,
                //   encryptedAccessKeySec
                // );
                // sessionStorage.setItem(
                //   SESSION_STORAGE_KEYS.SESSION_TOKEN,
                //   encryptedSessionToken
                // );

                return resolve(credentials)

              } else {
                console.log("get credentails undefine");
                return reject('get credentails undefine')
              }

              // setisNewPassword(false);
              // setIsLogin(true);
              // }, 3000);
            }
          });
        } // successful response
      });
    })


  };

  onReady = async (response: any) => {
    const { signInUserSession } = response;
    const { idToken } = signInUserSession;
    const { jwtToken, payload } = idToken;
    const { exp } = payload;
    const difference = exp * 1000 - new Date().getTime();
    this.automaticTimeout(difference);

    this.configAxio(jwtToken);

    console.log('login res, need to be removed after', response)

    // reduce other information in the id token
    // keep jwttoken for api config(old portal is using)
    // keep exp to logout automatically
    const reducedInfoIdToken = {
      jwtToken,
      payload: {

        exp
      }
    }



    const cur_user_s3_bucket = payload['custom:bucket']
    const cur_username = payload['cognito:username']
    const cur_user_groups = payload['cognito:groups']
    const BIZ_CODE = payload['custom:customer']

    // encryped the user bucket and biz code string
    // const encryped_cur_user_s3_bucket = encryptData(cur_user_s3_bucket)
    // const encryped_BIZ_CODE = encryptData(BIZ_CODE)

    // console.log("user_s3_bucket", cur_user_s3_bucket);
    // console.log("cur_username", cur_username);
    // console.log("cur_user_groups", cur_user_groups);
    // console.log("after set: need to set the biz code", BIZ_CODE);

    // ins: 'any',
    // vct: undefined,
    // ce: undefined,
    // am: undefined,
    // som: undefined,
    // crm: undefined,
    // admin: 'any',

    const userGroupsAndRoles: { [key: string]: string | undefined } = {
      // each field represent like - module: role

    }
    if (Array.isArray(cur_user_groups)) {
      cur_user_groups.forEach((item: any) => {
        const moduleRoleLst = item.split('_')
        const module = moduleRoleLst[0]
        const moduleRole = moduleRoleLst[1]
        userGroupsAndRoles[module] = moduleRole
      });
    }


    // userGroupsAndRoles.ins = 'any'
    // userGroupsAndRoles.admin = 'any'

    // this.props.setUsername(cur_username)
    // this.props.setUserS3Butcket(cur_user_s3_bucket)
    // this.props.setUserGroupsAndRoles(userGroupsAndRoles)
    // this.props.setUserBizCode(BIZ_CODE)

    // this.props.setJwtToken(jwtToken)

    // const encrypedAccessLevel = encryptData(JSON.stringify(userGroupsAndRoles))
    // console.log("access_level", JSON.stringify(userGroupsAndRoles));

    // SAVE NEW IDTOKEN, USER S3 BUCKET, ACCESS LEVEL, BIZ CODE TO SESSION STORAGE


    // sessionStorage.setItem(SESSION_STORAGE_KEYS.ACCESS_LEVEL, JSON.stringify(userGroupsAndRoles));
    // sessionStorage.setItem(SESSION_STORAGE_KEYS.USER_NAME, JSON.stringify(cur_username));
    // sessionStorage.setItem(SESSION_STORAGE_KEYS.USER_STORAGE, JSON.stringify(encryped_cur_user_s3_bucket));
    // sessionStorage.setItem(SESSION_STORAGE_KEYS.BIZ_CODE, JSON.stringify(encryped_BIZ_CODE));

    setSessionStorage(SESSION_STORAGE_KEYS.IDTOKEN, reducedInfoIdToken, true)
    setSessionStorage(SESSION_STORAGE_KEYS.ACCESS_LEVEL, userGroupsAndRoles, true);
    setSessionStorage(SESSION_STORAGE_KEYS.USER_STORAGE, cur_user_s3_bucket)
    setSessionStorage(SESSION_STORAGE_KEYS.BIZ_CODE, BIZ_CODE)
    setSessionStorage(SESSION_STORAGE_KEYS.USER_NAME, cur_username)

    useAuthStore.getState().setAuth(userGroupsAndRoles, BIZ_CODE, cur_username)

    // localStorage.setItem("accessLevel", 'tierOne');
    // console.log("accessLevel", 'tierOne')
    // sessionStorage.setItem(
    //   SESSION_STORAGE_KEYS.IDTOKEN,
    //   JSON.stringify(idToken)
    // );

    // this.getCognitoIdentityCredentials(jwtToken).then((credentials: any) => {
    //   if (credentials && credentials.Credentials) {
    //     console.log('credentials tokens', credentials)
    //     this.setState({
    //       redirect: "AuthorizedApp",
    //       email: "",
    //       password: "",
    //       securityCode: "",
    //       reset: "",
    //       confirm: "",
    //     });
    //   }
    // }).catch(tokenErr => {
    //   console.log('error happen in getting tokens', tokenErr)
    // })


    try {
      // set the access token for S3
      const credentials: any = await this.getCognitoIdentityCredentials(jwtToken)

      if (credentials.Credentials) {
        if (this.state.remember) {
          localStorage.setItem(
            "credentials",
            CryptoJS.AES.encrypt(
              JSON.stringify(
                JSON.stringify({
                  email: this.state.email,
                  password: this.state.password,
                })
              ),
              ENCRYPTION_KEY
            ).toString()
          );
        } else {
          localStorage.clear();
        }

        this.setState({
          redirect: "AuthorizedApp",
          email: "",
          password: "",
          securityCode: "",
          reset: "",
          confirm: "",
        });
      }

    } catch (error) {

      console.log('error happen in getting tokens', error)

    }




    // // this step is to call the api to get the tierLevel
    // // but with new user onboard process, this will not be needed
    // // but still keep it here
    // axios
    //   .post(`${process.env.REACT_APP_API_BASE}data/itrazo`, {
    //     access_token: jwtToken,
    //   })
    //   .then((rec: any) => {


    //     localStorage.setItem("accessLevel", rec.data.response);
    //     if (rec) {
    //       sessionStorage.setItem(
    //         SESSION_STORAGE_KEYS.IDTOKEN,
    //         JSON.stringify(idToken)
    //       );
    //       sessionStorage.setItem(
    //         SESSION_STORAGE_KEYS.USER_NAME,
    //         signInUserSession.idToken.payload["cognito:username"]
    //       );
    //       this.setState({
    //         redirect: "AuthorizedApp",
    //         email: "",
    //         password: "",
    //         securityCode: "",
    //         reset: "",
    //         confirm: "",
    //       });
    //     }
    //     console.log("after==>", rec);
    //   })
    //   .catch((e) => {
    //     console.log("error==> axios", e);
    //   });

    // TODO: uncomment the below two lines once the backedn is ready
    // set the access tokens
    // this.getCognitoIdentityCredentials(jwtToken)

    //   AWSSDK( signInUserSession.idToken.payload['cognito:groups'][0] ).then( ( rec: any ) => {
    //     localStorage.setItem( "accessLevel", rec )
    //     if(rec){
    //     sessionStorage.setItem( SESSION_STORAGE_KEYS.IDTOKEN, JSON.stringify( idToken ) );
    //     sessionStorage.setItem( SESSION_STORAGE_KEYS.USER_NAME, signInUserSession.idToken.payload["cognito:username"] );
    //     this.setState( {
    //       redirect: 'AuthorizedApp',
    //       email: '',
    //       password: '',
    //       securityCode: '',
    //       reset: '',
    //       confirm: '',
    //     } );
    //   }
    // } ).catch(() => {
    //     this.setState( {
    //       message: 'Unable to find access level for this account',
    //       type: 'warning',
    //       focusReset: false,
    //       focusConfirm: true,
    //       focusEmail: false,
    //       focusPassword: false,
    //       focusSecure: false,
    //     } );
    //   } )

  };

  amplifySignIn = (email: string, password: string) => {
    this.setState({
      loading: true,
    });
    Auth.signIn(email, password)
      .then((response: any) => {
        this.setState({
          auth: response,
        });
        switch (response.challengeName) {
          case CHALLENGE_NAMES.NEW_PASSWORD_REQUIRED: {
            message.success("First time log in. Please reset your password");
            this.setState({
              // message: "First time log in. Please reset your password",
              type: "success",
              focusReset: true,
              focusConfirm: false,
              focusEmail: false,
              focusPassword: false,
              focusSecure: false,
              redirect: "SetNewPassword",
              email: "",
              password: "",
              securityCode: "",
              reset: "",
              confirm: "",
              showPassword: false,
            });
            break;
          }
          case undefined: {
            console.log("undefined", response);
            this.onReady(response);
            break;
          }
          default: {
            console.log("Challenge", response.challengeName);
          }
        }
      })
      .catch((e: any) => {
        console.log(e);
        const { code } = e;
        switch (code) {
          case AMPLIFY_ERROR_CODE.PasswordResetRequiredException: {
            message.error(e.message);
            this.setState({
              // message: e.message,
              type: "error",
              redirect: "ForgetPassword",
              email: "",
              password: "",
              securityCode: "",
              reset: "",
              confirm: "",
              focusReset: false,
              focusConfirm: false,
              focusEmail: true,
              focusPassword: false,
              focusSecure: false,
              showPassword: false,
            });
            break;
          }
          default: {
            message.error(e.message);
            this.setState({
              // message: e.message,
              type: "error",
              focusEmail: false,
              focusPassword: false,
              focusSecure: false,
              focusReset: false,
              focusConfirm: false,
            });
          }
        }
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  amplifySignInForCeres = (email: string, password: string) => {
    this.setState({
      loading: true,
    });
    Auth.signIn(email, password)
      .then((response: any) => {
        this.setState({
          auth: response,
        });
        switch (response.challengeName) {
          case undefined: {
            const { signInUserSession, username } = response;
            const { idToken } = signInUserSession;
            const { jwtToken, payload } = idToken;
            console.log(
              "urllll",
              `${this.state.redirectUrl}?state=${this.state.stat}&access_token=${jwtToken}`
            );
            window.location.href = `${this.state.redirectUrl}?state=${this.state.stat}&access_token=${jwtToken}&expires_in=3600`;
            break;
          }
          default: {
            console.log("Challenge", response.challengeName);
          }
        }
      })
      .catch((e: any) => {
        console.log(e);
        const { code } = e;
        switch (code) {
          case AMPLIFY_ERROR_CODE.PasswordResetRequiredException: {
            message.error(e.message);
            this.setState({
              // message: e.message,
              type: "error",
              redirect: "ForgetPassword",
              email: "",
              password: "",
              securityCode: "",
              reset: "",
              confirm: "",
              focusReset: false,
              focusConfirm: false,
              focusEmail: true,
              focusPassword: false,
              focusSecure: false,
              showPassword: false,
            });
            break;
          }
          default: {
            message.error(e.message);
            this.setState({
              // message: e.message,
              type: "error",
              focusEmail: false,
              focusPassword: false,
              focusSecure: false,
              focusReset: false,
              focusConfirm: false,
            });
          }
        }
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  signin = () => {
    const { email, password } = this.state;
    // validate Email
    if (!email) {
      message.warning("Please enter a valid email/username ");
      this.setState({
        // message: "Please enter a valid email/username ",
        type: "warning",
        focusEmail: true,
        focusPassword: false,
        focusSecure: false,
        focusReset: false,
        focusConfirm: false,
      });
      return;
    }

    // validate password
    if (!password) {
      message.warning("Please enter your password");
      this.setState({
        // message: "Please enter your password",
        type: "warning",
        focusEmail: false,
        focusPassword: true,
        focusSecure: false,
        focusReset: false,
        focusConfirm: false,
      });
      return;
    }

    this.amplifySignIn(email, password);
  };

  signinForCeres = () => {
    const { email, password } = this.state;
    console.log("emial", this.state);
    // validate Email
    if (!email) {
      message.warning("Please enter a valid email/username ");
      this.setState({
        // message: "Please enter a valid email/username ",
        type: "warning",
        focusEmail: true,
        focusPassword: false,
        focusSecure: false,
        focusReset: false,
        focusConfirm: false,
      });
      return;
    }

    // validate password
    if (!password) {
      message.warning("Please enter your password");
      this.setState({
        // message: "Please enter your password",
        type: "warning",
        focusEmail: false,
        focusPassword: true,
        focusSecure: false,
        focusReset: false,
        focusConfirm: false,
      });
      return;
    }

    this.amplifySignInForCeres(email, password);
  };

  resetPassword = () => {
    const { reset, confirm, auth } = this.state;
    // validate password
    // if (!this.checkPassword(reset)) {
    //   this.setState({
    //     message: 'Invalid Password',
    //     type: 'warning',
    //     focusReset: true,
    //     focusConfirm: false,
    //   });
    //   return;
    // }

    // validate password
    if (!(reset === confirm)) {
      message.warning("Passwords do not match");
      this.setState({
        // message: "Passwords do not match",
        type: "warning",
        focusReset: false,
        focusConfirm: true,
        focusEmail: false,
        focusPassword: false,
        focusSecure: false,
      });
      return;
    }

    this.setState({
      loading: true,
    });
    Auth.completeNewPassword(auth, reset, {})
      .then((response: any) => {
        console.log("ResetPassword", response);
        const { signInUserSession, username } = response;
        const { idToken } = signInUserSession;
        const { jwtToken, payload } = idToken;
        this.configAxio(jwtToken);
        axios
          .post(`${process.env.REACT_APP_API_BASE}user/activate`)
          .then((rec: any) => console.log("after==>", rec))
          .catch((e) => {
            console.log("error==>", e);
          });
        this.onReady(response);
      })
      .catch((e) => {
        message.error(e.message);
        this.setState({
          // message: e.message,
          type: "error",
          focusEmail: false,
          focusPassword: false,
          focusSecure: false,
          focusReset: true,
          focusConfirm: false,
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  sendSecurityCode = () => {
    const { email } = this.state;

    console.log("sendSecurityCode set isCodeSent to false XXX ===> ");

    this.setState({
      isCodeSent: false,
    });

    // validate Email
    // if (!EmailValidator.validate(email)) {
    //   this.setState({
    //     message: "Please enter a valid email address",
    //     type: "warning",
    //     focusEmail: true,
    //     focusReset: false,
    //     focusConfirm: false,
    //     focusPassword: false,
    //     focusSecure: false,
    //   });
    //   return;
    // }

    this.setState({
      loading: true,
    });

    // console.log('sendSecurityCode set isCodeSent to TRUE ===> ', );
    // this.setState( {
    //   message: 'Security code has been sent to your email. Please check.',
    //   type: 'success',
    //   focusReset: false,
    //   focusConfirm: false,
    //   focusEmail: false,
    //   focusPassword: false,
    //   focusSecure: true,
    //   isCodeSent: true,
    // } );

    Auth.forgotPassword(email)
      .then(() => {
        console.log("sendSecurityCode set isCodeSent to TRUE ===> ");
        message.success("Security code has been sent to your email. Please check.");
        this.setState({
          // message: "Security code has been sent to your email. Please check.",
          type: "success",
          focusReset: false,
          focusConfirm: false,
          focusEmail: false,
          focusPassword: false,
          focusSecure: true,
          isCodeSent: true,
        });
      })
      .catch((e: any) => {
        message.error(e.message);
        this.setState({
          // message: e.message,
          type: "error",
          focusReset: false,
          focusConfirm: false,
          focusEmail: true,
          focusPassword: false,
          focusSecure: false,
          isCodeSent: false,
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  forgetPasswordSubmit = () => {
    const { email, password, securityCode } = this.state;
    // validate Email
    // if (!EmailValidator.validate(email)) {
    //   this.setState({
    //     message: "Please enter a valid email address",
    //     type: "warning",
    //     focusReset: false,
    //     focusConfirm: false,
    //     focusEmail: true,
    //     focusPassword: false,
    //     focusSecure: false,
    //   });
    //   return;
    // }

    // validate password
    if (!password) {
      message.warning("Please enter your password");
      this.setState({
        // message: "Please enter your password",
        type: "warning",
        focusEmail: false,
        focusPassword: true,
        focusSecure: false,
        focusReset: false,
        focusConfirm: false,
      });
      return;
    }

    this.setState({
      loading: true,
    });

    Auth.forgotPasswordSubmit(email, securityCode, password)
      .then((response: any) => {
        message.success("Reset Success, please wait for log in ...");
        this.setState({
          focusConfirm: false,
          focusEmail: false,
          focusPassword: false,
          focusReset: false,
          focusSecure: false,
          // message: "Reset Success, please wait for log in ...",
          type: "success",
        });
        this.amplifySignIn(email, password);
      })
      .catch((e) => {
        message.error(e.message);
        this.setState({
          // message: e.message,
          type: "error",
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  onEmailChange = (email: string) => {
    this.setState({
      focusReset: false,
      focusConfirm: false,
      focusEmail: true,
      focusPassword: false,
      focusSecure: false,
      email: email.toLowerCase(),
      // email,
    });
  };

  onPasswordChange = (password: string) => {
    this.setState({
      focusReset: false,
      focusConfirm: false,
      focusEmail: false,
      focusPassword: true,
      focusSecure: false,
      password,
    });
  };

  onSecurityCodeChange = (securityCode: string) => {
    this.setState({
      focusReset: false,
      focusConfirm: false,
      focusEmail: false,
      focusPassword: false,
      focusSecure: true,
      securityCode,
    });
  };

  onResetChange = (reset: string) => {
    this.setState({
      focusReset: true,
      focusConfirm: false,
      focusEmail: false,
      focusPassword: false,
      focusSecure: false,
      reset,
    });
  };

  onConfirmChange = (confirm: string) => {
    this.setState({
      focusReset: false,
      focusConfirm: true,
      focusEmail: false,
      focusPassword: false,
      focusSecure: false,
      confirm,
    });
  };

  togglePasswordDisplay = () => {
    const { showPassword } = this.state;
    this.setState({
      showPassword: !showPassword,
    });
  };



  render() {
    const containerClass = "container-xxxs pad-lg-h mrg-xl-top remove-left-margin";

    console.log("isMobile==>", isMobile);
    const {
      redirect,
      showPassword,
      message,
      type,
      focusEmail,
      focusPassword,
      focusReset,
      focusConfirm,
      focusSecure,
      loading,
      isCodeSent,
      isGlobalLoading
    } = this.state;




    console.log("isMobile state redirect==>", redirect);

    let display: any;
    switch (redirect) {
      case "AuthorizedApp": {
        display = (
          <Router>
            <SignOutContext.Provider
              value={() => {
                this.signout();
              }}
            >
              <AuthorizedApp />
            </SignOutContext.Provider>
          </Router>
        );
        break;
      }
      case "SetNewPassword": {
        display = (
          <Row className="mobileChangeToColumn" >
            <Col xs={24} sm={24} md={14} lg={14}
            >
              <div style={{ height: '100vh', paddingTop: '25rem' }} className="showItrazoImage">
                <div className={containerClass}>
                  <Logo />
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={10} lg={10}
            >
              <div className='rightContainer' style={{ paddingTop: '23rem' }}>
                <div style={{ position: "relative" }} className={containerClass}>
                  {/* {message ? <Message type={type}>{message}</Message> : null} */}
                  <LabelHeader>Password</LabelHeader>
                  <Input
                    className="my-input mrg-md-bottom mrg-md-top input-customize"
                    key="resetPassword"
                    focus={focusReset}
                    placeholder="Password"
                    password={!showPassword}
                    onChange={(value: string) => this.onResetChange(value)}
                    onKeyEvent={(e: any) => {
                      onKeyEvent(e, KEYBOARD_CODES.ENTER, () => {
                        this.resetPassword();
                      });
                    }}
                  />
                  <LabelHeader>Confirm Password</LabelHeader>
                  <Input
                    className="my-input mrg-md-bottom mrg-md-top input-customize"
                    key="confirmPassword"
                    focus={focusConfirm}
                    placeholder="Confirm Password"
                    password={!showPassword}
                    onChange={(value: string) => this.onConfirmChange(value)}
                    onKeyEvent={(e: any) => {
                      onKeyEvent(e, KEYBOARD_CODES.ENTER, () => {
                        this.resetPassword();
                      });
                    }}
                  />
                  <ResetShowPassword
                    type="button"
                    onClick={() => {
                      this.togglePasswordDisplay();
                    }}
                    bottom="8.5em"
                  >
                    {showPassword ? "Hide Password" : "Show Password"}
                  </ResetShowPassword>

                  {/* <Reminder>
              <li>at least 6 characters</li>
              <li>must include uppercase, numeric, and special characters</li>
            </Reminder> */}
                  <ResetMessageForPassword>
                    Password must be a minimum of 8 characters and contain a capital
                    letter and alphanumeric character
                  </ResetMessageForPassword>
                  <StyledButtonLarge
                    className=""
                    type="button"
                    onClick={() => {
                      this.resetPassword();
                    }}
                  >
                    Reset
                  </StyledButtonLarge>
                </div>
              </div>
            </Col>
          </Row>
        );
        break;
      }
      case "ForgetPassword": {
        display = (
          <Row className="mobileChangeToColumn" >
            <Col xs={24} sm={24} md={10} lg={10}
            >
              <div style={{ height: '100vh', paddingTop: '25rem' }} className="showItrazoImage">
                <div className={containerClass}>
                  <Logo />
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={14} lg={14}
            >
              <div className='rightContainer'>
                <div className={containerClass}>
                  {/* {message ? <Message type={type}>{message}</Message> : null} */}
                  <PositionWrapper>
                    {!isCodeSent ? (
                      <MessageForEmail>
                        A passcode will be sent to your email to reset password
                      </MessageForEmail>
                    ) : null}
                    {/* <LabelHeader>Username</LabelHeader> */}
                    <Input
                      className="my-input mrg-md-bottom input-customize"
                      key="forgetEmail"
                      focus={focusEmail}
                      placeholder="Enter your Username"
                      onChange={(value: string) => this.onEmailChange(value)}
                      onKeyEvent={(e: any) => {
                        onKeyEvent(e, KEYBOARD_CODES.ENTER, () => {
                          this.sendSecurityCode();
                        });
                      }}
                      value="should be email address"
                    />
                    {isCodeSent ? (
                      <>
                        <LabelHeader>Security Code</LabelHeader>
                        <Input
                          className="my-input mrg-md-bottom mrg-md-top input-customize"
                          key="forgetSecurityCode"
                          focus={focusSecure}
                          placeholder="Security Code"
                          onChange={(value: string) =>
                            this.onSecurityCodeChange(value)
                          }
                          onKeyEvent={(e: any) => {
                            onKeyEvent(e, KEYBOARD_CODES.ENTER, () => {
                              this.forgetPasswordSubmit();
                            });
                          }}
                          display="inline-block"
                        />
                        <LabelHeader>New Password</LabelHeader>
                        <Input
                          className="my-input mrg-md-bottom mrg-md-top input-customize"
                          key="forgetPassword"
                          focus={focusPassword}
                          placeholder="New Password"
                          password={!showPassword}
                          onChange={(value: string) => this.onPasswordChange(value)}
                          onKeyEvent={(e: any) => {
                            onKeyEvent(e, KEYBOARD_CODES.ENTER, () => {
                              this.forgetPasswordSubmit();
                            });
                          }}
                        />
                        <ShowPassword
                          type="button"
                          onClick={() => {
                            this.togglePasswordDisplay();
                          }}
                          bottom="6.5em"
                        >
                          {showPassword ? "Hide" : "Show"}
                        </ShowPassword>
                        <MessageForPassword>
                          Password must be a minimum of 8 characters and contain a
                          capital letter and alphanumeric character
                        </MessageForPassword>
                      </>
                    ) : (
                      ""
                    )}
                  </PositionWrapper>
                  <StyledButtonLarge
                    type="button"
                    // onClick={() => { this.forgetPasswordSubmit(); }}
                    onClick={() => {
                      if (isCodeSent) {
                        this.forgetPasswordSubmit();
                      } else {
                        this.sendSecurityCode();
                      }
                    }}
                  >
                    Submit
                  </StyledButtonLarge>
                  <StyledButtonLarge
                    type="button"
                    onClick={() => {
                      this.setState({
                        redirect: "SignIn",
                        showPassword: false,
                        isCodeSent: false,
                        email: "",
                        username: "",
                        password: "",
                        securityCode: "",
                        reset: "",
                        confirm: "",
                        message: "",
                        type: "warning",
                        focusEmail: true,
                        focusPassword: false,
                        focusSecure: false,
                        focusReset: false,
                        focusConfirm: false,
                        auth: undefined,
                        loading: false,
                        remember: false,
                        redirectUrl: "",
                        stat: "",
                      })
                    }}
                  >
                    Back
                  </StyledButtonLarge>
                </div>
              </div>
            </Col>
          </Row>

          // OLD VERSION OF FORGET PASSWORD
          // <div className={containerClass}>
          //   { message ? <Message type={type}>{message}</Message> : null}
          //   <PositionWrapper>
          //     <Input
          //       className="my-input mrg-md-bottom"
          //       key="forgetEmail"
          //       focus={focusEmail}
          //       placeholder="Email Address"
          //       onChange={( value: string ) => this.onEmailChange( value )}
          //       onKeyEvent={( e: any ) => {
          //         onKeyEvent( e, KEYBOARD_CODES.ENTER, () => { this.sendSecurityCode(); } );
          //       }}

          //     />
          //     <Input
          //       className="my-input mrg-md-bottom"
          //       key="forgetSecurityCode"
          //       focus={focusSecure}
          //       placeholder="Security Code"
          //       onChange={( value: string ) => this.onSecurityCodeChange( value )}
          //       onKeyEvent={( e: any ) => {
          //         onKeyEvent( e, KEYBOARD_CODES.ENTER, () => {
          //           this.forgetPasswordSubmit();
          //         } );
          //       }}
          //       display="inline-block"
          //       width="70%"
          //     />
          //     <Input
          //       className="my-input mrg-md-bottom"
          //       key="forgetPassword"
          //       focus={focusPassword}
          //       placeholder="New Password"
          //       password={!showPassword}
          //       onChange={( value: string ) => this.onPasswordChange( value )}
          //       onKeyEvent={( e: any ) => {
          //         onKeyEvent( e, KEYBOARD_CODES.ENTER, () => {
          //           this.forgetPasswordSubmit();
          //         } );
          //       }}
          //     />
          //     <ShowPassword
          //       type="button"
          //       onClick={() => { this.togglePasswordDisplay(); }}
          //       bottom="2.0em"
          //     >
          //       {showPassword ? 'Hide' : 'Show'}
          //     </ShowPassword>
          //     <StyledButton
          //       type="button"
          //       onClick={() => {
          //         this.sendSecurityCode();
          //       }}
          //     >
          //       Send Code
          //     </StyledButton>
          //   </PositionWrapper>
          //   <StyledButtonLarge
          //     type="button"
          //     onClick={() => { this.forgetPasswordSubmit(); }}
          //   >
          //     Submit
          //   </StyledButtonLarge>
          // </div>
        );
        break;
      }
      case "LoginWithItrazo": {
        display = (
          <Row className="mobileChangeToColumn" >
            <Col xs={24} sm={24} md={10} lg={10}
            >
              <div style={{ height: '100vh', paddingTop: '25rem' }} className="showItrazoImage">
                <div className={containerClass}>
                  <Logo />
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={14} lg={14}
            >
              <div className='rightContainer'>
                <div className={containerClass}>
                  <div className="pad-xl max-width-90p mrg-auto-left mrg-auto-right showLogoMobile">
                    <div className="mrg-xl-top align-center">
                      <img className="logo-for-mobile-size" src={logo} alt="logo" />
                    </div>
                  </div>
                  {/* <Logo /> */}
                  <div className="relative logincard">
                    <LoginText>Login</LoginText>
                    {/* {message ? <Message type={type}>{message}</Message> : null} */}
                    <LabelHeader>Email Address / User Name</LabelHeader>
                    <Input
                      className="my-input mrg-md-bottom mrg-md-top input-customize"
                      key="signinEmail"
                      focus={focusEmail}
                      placeholder="Email Address / User Name"
                      onChange={(value: string) => this.onEmailChange(value)}
                      onKeyEvent={(e: any) => {
                        onKeyEvent(e, KEYBOARD_CODES.ENTER, () => {
                          this.signin();
                        });
                      }}
                      value={this.state.email ? this.state.email : ""}
                    />
                    <LabelHeader>Password</LabelHeader>
                    <Input
                      className="my-input mrg-md-bottom mrg-md-top input-customize"
                      key="signinPassword"
                      focus={focusPassword}
                      placeholder="Password"
                      password={!showPassword}
                      onChange={(value: string) => this.onPasswordChange(value)}
                      onKeyEvent={(e: any) => {
                        onKeyEvent(e, KEYBOARD_CODES.ENTER, () => {
                          this.signinForCeres();
                        });
                      }}
                      value={this.state.password ? this.state.password : ""}
                    />

                    <ShowPassword
                      type="button"
                      onClick={() => {
                        this.togglePasswordDisplay();
                      }}
                      bottom={isMobile ? "13.8em" : "13.8em"}
                    >
                      {showPassword ? "Hide" : "Show"}
                    </ShowPassword>
                    <div className="mrg-lg-top">
                      <StyledButtonLarge
                        type="button"
                        onClick={() => {
                          this.signin();
                        }}
                      >
                        Login
                      </StyledButtonLarge>
                    </div>

                  </div>
                </div>
              </div>
            </Col>
          </Row>
        );
        break;
      }
      case "SignIn":
      default: {
        display = (
          <Row className="mobileChangeToColumn" >
            <Col xs={24} sm={24} md={10} lg={10}
            >
              <div style={{ height: '100vh', paddingTop: '25rem' }} className="showItrazoImage">
                <div className={containerClass}>
                  <Logo />
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={14} lg={14}
            >
              <div className='rightContainer'>
                <div className={containerClass}>
                  <div className="pad-xl max-width-90p mrg-auto-left mrg-auto-right showLogoMobile">
                    <div className="mrg-xl-top align-center">
                      <img className="logo-for-mobile-size" src={logo} alt="logo" />
                    </div>
                  </div>
                  {/* <Logo /> */}
                  <div className="relative logincard">
                    <LoginText>Login</LoginText>
                    {/* {message ? <Message type={type}>{message}</Message> : null} */}
                    <LabelHeader>Email Address / User Name</LabelHeader>
                    <Input
                      className="my-input mrg-md-bottom mrg-md-top input-customize"
                      key="signinEmail"
                      focus={focusEmail}
                      placeholder="Email Address / User Name"
                      onChange={(value: string) => this.onEmailChange(value)}
                      onKeyEvent={(e: any) => {
                        onKeyEvent(e, KEYBOARD_CODES.ENTER, () => {
                          this.signin();
                        });
                      }}
                      value={this.state.email ? this.state.email : ""}
                    />
                    <LabelHeader>Password</LabelHeader>
                    <Input
                      className="my-input mrg-md-bottom mrg-md-top input-customize"
                      key="signinPassword"
                      focus={focusPassword}
                      placeholder="Password"
                      password={!showPassword}
                      onChange={(value: string) => this.onPasswordChange(value)}
                      onKeyEvent={(e: any) => {
                        onKeyEvent(e, KEYBOARD_CODES.ENTER, () => {
                          this.signin();
                        });
                      }}
                      value={this.state.password ? this.state.password : ""}
                    />
                    {/* <MessageForPassword>Password must be a minimum of 8 characters and contain a capital letter and alphanumeric character​</MessageForPassword> */}

                    {/* New design  for remember me and forget password */}
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center"
                      }}>
                        <CheckBoxContainer
                          type="checkbox"
                          id="rememberMe"
                          onChange={() => {
                            this.setState((prevState) => ({
                              remember: !prevState.remember,
                            }))
                          }
                          }
                          checked={this.state.remember}
                        ></CheckBoxContainer>
                        <CheckBox htmlFor='rememberMe'>Remember Me</CheckBox>
                      </div>
                      <div style={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                        // border: "1px solid red"
                      }}>
                        <ForgetPassword
                          className="color-9"
                          type="button"
                          onClick={() =>
                            this.setState({
                              redirect: "ForgetPassword",
                              email: "",
                              password: "",
                              securityCode: "",
                              reset: "",
                              confirm: "",
                              message: "",
                              focusReset: false,
                              focusConfirm: false,
                              focusEmail: true,
                              focusPassword: false,
                              focusSecure: false,
                              showPassword: false,
                            })
                          }
                        >
                          Forgot Password?
                        </ForgetPassword>
                      </div>
                    </div>
                    <ShowPassword
                      type="button"
                      onClick={() => {
                        this.togglePasswordDisplay();
                      }}
                      bottom={isMobile ? "15em" : "15em"}
                    // right='35px'
                    >
                      {showPassword ? "Hide" : "Show"}
                    </ShowPassword>
                    <div className="mrg-lg-top">
                      <StyledButtonLarge
                        type="button"
                        onClick={() => {
                          this.signin();
                        }}
                      >
                        Login
                      </StyledButtonLarge>
                    </div>

                  </div>
                  <p className="relative logincard" style={{ textAlign: 'center', zIndex: '1000', color: 'var(--white-text-color)', fontSize: '13px', paddingTop: '0', marginTop: '-30px' }}>
                    Don't have a subscription?{' '}
                    <a style={{ color: '#3E7BFA' }} rel="noreferrer" href={process.env.REACT_APP_CUSTOMER_ONBOARDING_LINK} target="_blank">Register</a>
                  </p>
                </div>
              </div>
            </Col>
          </Row >
        );
        break;
      }
    }


    return (
      <AppWrapper>
        <ThemeProvider theme={theme}>
          <DialogProvider>
            <GlobalDataControl />
            <SignInPage >
              <Portal>
                <Loading show={loading || isGlobalLoading} />
              </Portal>
              {display}
            </SignInPage>
          </DialogProvider>
        </ThemeProvider>
      </AppWrapper>
    );
  }
}



export default App;
// export default App;

const MessageForPassword = styled.p`
  
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
  opacity: 0.7;
`;
const ResetMessageForPassword = styled.p`
  
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
  opacity: 0.7;
  margin-top: 4rem;
`;

const MessageForEmail = styled.p`
  
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
  opacity: 0.7;
  margin-bottom: -20px;
`;

const CheckBox = styled.label`
  text-align: center;
  display: flex;
  align-items: center;
  width: 102px;
  height: 18px;
  opacity: 0.7;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  /* or 18px */
  color: #ffffff;
  cursor: pointer;
`;
// const CheckBox = styled.span`
//   width: 102px;
//   height: 18px;
//   opacity: 0.7;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 130%;
//   line-height: 130%;
//   /* or 18px */
//   color: #ffffff;
// `;
const Grid = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
`;
const LoginText = styled.p`
  // width: 86px;
  font-weight: 500;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 29px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  text-align: left;
  color: #ffffff;
  margin-top: 10px;
  // margin-bottom: 50px;
  margin-bottom: 70px;
`;
const StyledButton = styled(Button)`
  position: absolute;
  top: 12rem;
  right: 0;
  font-size: 1.16rem;
  width: 25%;
  height: 5rem;
  padding: 1rem;
  color: white;
  border-radius: 0.5rem;
  background: ${(props) => props.theme.main};
`;

const StyledButtonLarge = styled(Button)`
  font-size: 15px;
  width: 100%;
  font-weight: bold;
  weight: bold;
  padding: 1em;
  // height: 65px; //old height
  height: 50px;
  color: white;
  border-radius: 0.5rem;
  background: ${(props) => props.theme.main};
  margin-bottom: 30px;
`;

const SignInPage = styled.div`
  height: 100vh;
  width: 100vw;
  max-height: $max-height;
  max-width: $max-width;
  overflow-y: scroll;
  // padding: 5rem 0;

  display: grid;
  align-items: center;
  
`;

const PositionWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled(TextInput)`
  display: ${({ display }: { display?: string; width?: string }) => {
    if (display) return display;
    return "block";
  }};
  margin: 3rem 0;
  border-radius: 5px;
  height: 65px;
  width: ${({ width }: { display?: string; width?: string }) => {
    if (width) return width;
    return "100%";
  }};
  padding: 0.5em 0.75em;
  outline: none;
  font-size: 1.4rem;
`;

const ForgetPassword = styled(Button)`
  color: red;
  font-size: 1.2rem;
  opacity: 0.7;
  text-align: center;
`;

const ShowPassword = styled(Button)`
  color: white;
  font-size: 1.2rem;
  position: absolute;

  bottom: ${({ bottom }: { bottom?: string }) => {
    if (bottom) return bottom;
    return "0";
  }};
  right: 35px;
`;

const ResetShowPassword = styled(Button)`
  
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
  opacity: 0.7;
  position: absolute;

  bottom: ${({ bottom }: { bottom?: string }) => {
    if (bottom) return bottom;
    return "0";
  }};
  left: 12px;
`;
// right: ${({ right }: { right?: string }) => {
//   if(right) return right;
//   return '20px'
// }}

const Message = styled.div`
  margin-top: 0.5rem;
  font-size: 1.4rem;

  font-weight: 300;
  padding: 1rem;
  text-align: center;
  width: 100%;
  border-radius: 5px;
  color: ${({ type }: { type: MessageType }) => {
    switch (type) {
      case "warning": {
        return "rgb(255, 241, 201)";
      }
      case "success":
      case "error":
      default:
        return "red";
    }
  }};
`;

const Reminder = styled.ul`
  color: white;
  padding-left: 1.5rem;
`;

type CheckBoxProps = {
  remember: Boolean;
}

const CheckBoxContainer = styled.input`
  display: inline-block;
  width: 15px;
  height: 14px;
  border-radius: 2px;
  margin-right: 8px;
  cursor: pointer;
  accent-color: #fff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  padding-left: 1px;

  &:not(:checked) {
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
    background-color: transparent;
  }
`;
// const CheckBoxContainer = styled.div`
//   display: inline-block;
//   background: white;
//   width: 15px;
//   height: 13px;
//   border-radius: 2px;
//   margin-right: 8px;
//   color: black;
//   cursor: pointer;
//   background: ${({ background }: { background?: string }) => {
//     if (background) return background;
//     return "transparent";
//   }};
//   border: 1px solid #ffffff;
//   box-sizing: border-box;
//   padding-left: 1px;
// `;
const LabelHeader = styled.label`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ffffff;
  `;
