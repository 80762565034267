import PageTabs from 'components/PageTabs'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import ProductList from './ProductList';
import { PRODUCT_TABS } from '../../utils/constant';

const tabsData = [
  {
    key: PRODUCT_TABS.ALL,
    text: PRODUCT_TABS.ALL,
  },
  {
    key: PRODUCT_TABS.ACTIVE,
    text: PRODUCT_TABS.ACTIVE,
  },
  {
    key: PRODUCT_TABS.DRAFT,
    text: PRODUCT_TABS.DRAFT,
  },
  {
    key: PRODUCT_TABS.CREATED,
    text: PRODUCT_TABS.CREATED,
  },
  {
    key: PRODUCT_TABS.ARCHIVED,
    text: PRODUCT_TABS.ARCHIVED,
  }
]

const Products = () => {
  return (
    <div>
      <ProductPageTabs />
      <ProductList />
    </div>
  )
}

export default Products

const ProductPageTabs = () => {
  const navigate = useNavigate();
  // const searchParams = new URLSearchParams(window.location.search)
  // const productTab = searchParams.get('tab')

  const locationHash = window.location.hash; // Get the hash fragment
  const queryString = locationHash.split('?')[1]; // Extract query string
  const searchParams = new URLSearchParams(queryString); // Parse query string

  const productTab = searchParams.get('tab');

  console.log('abc productTab', searchParams, productTab, location)

  useEffect(() => {
    if (!productTab) {
      searchParams.set('tab', PRODUCT_TABS.ALL)
      navigate({ search: searchParams.toString() })
    }
  }, [])

  const handleTabClick = (tabKey: string) => {
    searchParams.set('tab', tabKey)
    navigate({ search: searchParams.toString() })
  };

  const tabs = tabsData.map((item: any) => ({
    text: item.text,
    onClick: () => handleTabClick(item.key),
    active: productTab === item.key
  }));

  return <PageTabs tabs={tabs} isSubTabs={true} />
}
