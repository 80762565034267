/**
 * This is the gs1 key creation component
 *
 * @author Ricardo Enriquez
 * @version 1.0.0
 * @Date 25 Aug 2023
 */

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import { Col, Divider, Form, Row, Radio, Space, message } from 'antd';
import GlobalModal from 'components/GlobalModal';
import Input from 'components/Input';
import { FlexWrapper, FlexBox, } from 'statics/styles/StyledComponents';
import { PRODUCT_TYPE } from '../../InventoryManagement/statics/constants';
import AddGS1KeyAPI from "./../apis/Gs1Key";
import { InventoryItemAPI } from "containers/Pages/InventoryManagement/apis";
import moment from "moment";
import { GS1_KEY_API } from "utilities/AdiApi";
import GlobalDrawer from "components/GlobalModal/GlobalDrawer";
import { getSessionStorage } from "utilities/Functions/GlobalHelperFunctions";
import { EXPECTED_LIFE_PERIOD, INDUSTRY_BASED_CATEGORY, INDUSTRY_OPTIONS_LIST, SESSION_STORAGE_KEYS } from "utilities/CONSTANTS";
import CustomValueSelect from "components/Input/CustomValueSelect";
import ProductPartnerAPI from "../apis/ProductPartnerAPI";

const { ModalLayout } = GlobalDrawer;

type Props = {
  open: boolean;
  onClose: Function;
  getTableData: Function;
};


const AddGS1Key = (props: Props) => {
  const { open, onClose, getTableData } = props;

  const [form] = Form.useForm();
  const [barcodeCategory, setBarcodeCategory] = useState('');
  const [barcodeTypeOptions, setBarcodeTypeOptions] = useState<string[]>([]);
  const [barcodeType, setBarcodeType] = useState('');
  const [inventoryType, setInventoryType] = useState(PRODUCT_TYPE.generalProduct.value);
  const [productsListing, setProductsListing] = useState<any[]>([]);
  const CLIENT_CODE = getSessionStorage(SESSION_STORAGE_KEYS.BIZ_CODE);

  const isACGCustomer = CLIENT_CODE === 'acg';

  const [nonACGCategory, setNonACGCategory] = useState<any[]>([]);

  useEffect(() => {
    getProductsList()
  }, [])

  const getProductsList = () => {
    ProductPartnerAPI.getAllProducts(CLIENT_CODE).then((products: any) => {
      if (Array.isArray(products) && products.length > 0) {
        // filter the products that has void as false
        const filteredProducts = products?.filter((item: any) => item.void === 'false' || item.void === false)

        const productOptions = filteredProducts?.map((product: any) => {
          return {
            value: product.product_name,
            label: product.product_name,
          }
        });

        setProductsListing(productOptions)

      }
    }).catch((err: any) => {
      console.log('err', err)
    }).finally(() => {
      console.log('finally')
    })
  };


  const formOnFinish = async (values: any) => {

    let payload = {
      created_date: moment().toISOString()
    }

    if (isACGCustomer) {
      values.inventory_type = inventoryType;
      console.log(values.sku[0])
      payload = {
        ...payload,
        ...values,
        sku: values.sku[0],
      }

    } else {
      payload = {
        ...payload,
        ...values,
      }
    }

    console.log('payload,', payload)

    try {
      const response = await AddGS1KeyAPI.addGS1Key(payload)
      console.log(response)
      message.success(response.success)
      getTableData()
      modalClose()
    }
    catch {
      console.log('error')
    }

  }


  const handleBarcodeCategoryChange = (value: string) => {
    setBarcodeCategory(value);

    switch (value) {
      case 'EAN/UPC':
        setBarcodeTypeOptions(["UPC-A", "EAN - 13"]);
        break;
      case '2D Barcodes':
        setBarcodeTypeOptions(['Data Matrix', 'QR Code']);
        break;
      case 'Data Bar':
        setBarcodeTypeOptions(['Omnidirectional', 'Truncated', 'Expanded', 'Stacked']);
        break;
      default:
        setBarcodeTypeOptions([]);
        break;
    }
  };
  const [category, setCategory] = useState([])
  const [subCategory, setSubCategory] = useState([])
  const [productListing, setProductListing] = useState([])
  const [categoryList, setCategoryList] = useState([])

  const selectInventory = async (e: any) => {
    setInventoryType(e.target.value)
    let categories: any = [];
    try {
      const items = await InventoryItemAPI.getItemsByMarkFinishedProduct(e.target.value)
      const uniqueCategories = Array.from(new Set(items.map((item: any) => item.item_category)))

      setCategoryList(items);
      categories = uniqueCategories.map((category: any) => {
        return {
          value: category,
          label: category
          // item_category: category,
        }
      })
      console.log(categories)
      categories = categories.sort((a: any, b: any) => {
        return a.value.localeCompare(b.value)
      })
      console.log(categories)
      setCategory(categories)
    }
    catch {
      console.log('error')
    }

  }
  const categoryHandler = (e: any) => {
    // setCategory(e)
    let subCategories: any = [];
    console.log(e)
    const subCategory = categoryList.filter((subCat: any) => subCat.item_category === e)
    console.log(subCategory)
    const uniqueCategories = Array.from(new Set(subCategory.map((item: any) => item.item_sub_category)))

    // setCategoryList(items);
    subCategories = uniqueCategories.map((category: any) => {
      return {
        value: category,
        label: category
        // item_category: category,
      }
    })
    console.log(subCategories)
    subCategories = subCategories.sort((a: any, b: any) => {
      return a.value.localeCompare(b.value)
    })
    console.log(subCategories)
    setSubCategory(subCategories)
  }
  const handleSubCategory = (e: any) => {
    console.log(e)
    let products: any = [];

    const subCategory = categoryList.filter((subCat: any) => subCat.item_sub_category === e)
    console.log(subCategory)
    const uniqueCategories = Array.from(new Set(subCategory.map((item: any) => item.item_name)))
    console.log(uniqueCategories)
    // setCategoryList(items);
    products = uniqueCategories.map((category: any) => {
      return {
        value: category,
        label: category
        // item_category: category,
      }
    })
    console.log(products)
    products = products.sort((a: any, b: any) => {
      return a.value.localeCompare(b.value)
    })
    console.log(products)
    setProductListing(products)
  }
  const productHandler = (e: any) => {
    console.log(e);
    console.log(e)
    const subCategory = categoryList.filter((subCat: any) => subCat.item_name === e)

    const uniqueCategories = Array.from(new Set(subCategory.map((item: any) => item.sku)))
    console.log(uniqueCategories)


    form.setFieldsValue({ sku: uniqueCategories })
  }

  const modalClose = () => {
    onClose();
    form.resetFields();
  };

  return (
    <GlobalDrawer
      title={`Add GS1 Key`}
      open={open}
      onClose={modalClose}
      width={700}
      footer={[
        <FlexBox style={{ justifyContent: 'flex-end', margin: '0' }} key='0'>
          <SimpleButton
            id='modal-btn-width-regular'
            isCancel={true}
            text="Cancel"
            onClick={modalClose}
            style={{ marginRight: '1rem' }}
          />
          <SimpleButton
            id='modal-btn-width-regular'
            htmlType="submit"
            text="Save"
            form={'addUser'}
          />
        </FlexBox>
      ]}
    >
      <Form
        form={form}
        name={'addUser'}
        onFinish={(values: any) => formOnFinish(values)}
      >
        <ModalLayout>

          <ModalLayout.ModalSection title="Key Type">
            <Row gutter={[15, 0]}>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="key_type"
                  rules={[
                    {
                      required: true,
                      message: 'Please Select Key Type',
                    }
                  ]}
                >
                  <Input
                    type='select'
                    label='Key Type'
                    placeholder="Select key type"
                    options={[
                      { value: 'GTIN', label: 'GTIN' },
                      { value: 'GLN', label: 'GLN' },
                      { value: 'SSCC', label: 'SSCC' },
                      { value: 'GSIN', label: 'GSIN' },
                      { value: 'GINC', label: 'GINC' },
                      { value: 'GRAI', label: 'GRAI' },
                    ]}
                    required
                  />
                </Form.Item>
              </Col>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter code',
                    }
                  ]}
                >
                  <Input
                    type='text'
                    label='Code '
                    placeholder="Code"
                    required
                  />
                </Form.Item>
              </Col>
            </Row><Row gutter={[15, 0]}>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="gs1_company"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter GS1 Company Prefix',
                    }
                  ]}
                >
                  <Input
                    type='text'
                    label='GS1 Company Prefix (GCP)'
                    placeholder="GS1 Company Prefix"
                    required
                  />
                </Form.Item>
              </Col>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="check_digit"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Check Digit',
                    }
                  ]}
                >
                  <Input
                    type='text'
                    label='Check Digit '
                    placeholder="Check Digit"
                    required
                  />
                </Form.Item>
              </Col>
            </Row>
          </ModalLayout.ModalSection>

        </ModalLayout>
        <ModalLayout>

          <ModalLayout.ModalSection title={`${isACGCustomer ? 'Inventory Details' : 'Product Details'}`}>
            {isACGCustomer ? (
              <>
                {/* <Row gutter={[15, 0]}> */}
                <Space direction="vertical">
                  <Row gutter={[20, 0]}>
                    <Radio.Group onChange={(e: any) => selectInventory(e)} value={inventoryType} style={{
                      marginLeft: '10px',
                    }}>
                      <Radio style={{ fontSize: '12px', color: '#E4E4EB' }} value={PRODUCT_TYPE.acgProduct.value}>{PRODUCT_TYPE.acgProduct.label}</Radio>
                      <Radio style={{ fontSize: '12px', color: '#E4E4EB' }} value={PRODUCT_TYPE.eCommerceProduct.value}>{PRODUCT_TYPE.eCommerceProduct.label}</Radio>
                      <Radio style={{ fontSize: '12px', color: '#E4E4EB' }} value={PRODUCT_TYPE.xplProduct.value}>{PRODUCT_TYPE.xplProduct.label}</Radio>
                    </Radio.Group>
                  </Row>
                  <Row gutter={[20, 0]}></Row>
                </Space>
                {/* </Row> */}
                <Row gutter={[15, 0]}>
                  <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                    <Form.Item
                      name="item_category"
                      rules={[
                        {
                          required: true,
                          message: 'Please Select Product Category',
                        }
                      ]}
                    >
                      <Input
                        type='select'
                        label='Product Category'
                        placeholder="Please Select Product Category"
                        options={category}
                        onChange={(e: any) => categoryHandler(e)}
                        required
                      />
                    </Form.Item>
                  </Col>
                  <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                    <Form.Item
                      name="item_sub_category"
                      rules={[
                        {
                          required: true,
                          message: 'Please Select Product Subcategory',
                        }
                      ]}
                    >
                      <Input
                        type='select'
                        label='Product Subcategory'
                        placeholder="Please Select Product Subcategory"
                        options={subCategory}
                        onChange={(e: any) => handleSubCategory(e)}
                        required
                      />
                    </Form.Item>
                  </Col>
                  <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                    <Form.Item
                      name="item_name"
                      rules={[
                        {
                          required: true,
                          message: 'Please Select Product Name',
                        }
                      ]}
                    >
                      <Input
                        type='select'
                        label='Product Name'
                        placeholder="Please Select Product Name"
                        options={productListing}
                        onChange={(e: any) => { productHandler(e) }}
                        required
                      />
                    </Form.Item>
                  </Col>
                  <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                    <Form.Item
                      name="sku"
                      rules={[
                        {
                          required: true,
                          message: 'Please Enter Inventory ID',
                        }
                      ]}
                    >
                      <Input
                        type='text'
                        label='Inventory ID '
                        placeholder="Inventory ID"
                        required
                        disabled
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row gutter={[15, 0]}>
                  <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                    <Form.Item
                      name={'item_name'}
                      rules={[
                        {
                          required: true,
                          message: 'Please select Product Name',
                        }
                      ]}
                    >
                      <Input
                        type='select'
                        label='Product Name'
                        placeholder="Select Product Name"
                        options={productsListing}
                        required
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </ModalLayout.ModalSection>

        </ModalLayout>
        <ModalLayout>

          <ModalLayout.ModalSection title="Barcode Type">
            <Row gutter={[15, 0]}>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="barcode_category"
                  rules={[
                    {
                      required: true,
                      message: 'Please Select Barcode Category',
                    },
                  ]}
                >
                  <Input
                    type='select'
                    label='Barcode Category'
                    placeholder="Barcode Category"
                    options={[
                      { value: 'EAN/UPC', label: 'EAN/UPC' },
                      { value: '2D Barcodes', label: '2D Barcodes' },
                      { value: 'Data Bar', label: 'Data Bar' },
                    ]}
                    onChange={handleBarcodeCategoryChange}
                    required
                  />
                </Form.Item>
              </Col>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="barcode_type"
                  rules={[
                    {
                      required: true,
                      message: 'Please Select Barcode Type',
                    },
                  ]}
                >
                  <Input
                    type='select'
                    label='Barcode Type'
                    placeholder="Barcode Type"
                    options={barcodeTypeOptions.map(option => ({ value: option, label: option }))}
                    value={barcodeType}
                    onChange={setBarcodeType}
                    required
                  />
                </Form.Item>
              </Col>
            </Row>
          </ModalLayout.ModalSection>

        </ModalLayout>
      </Form>
    </GlobalDrawer>
  )
};


export default AddGS1Key;

