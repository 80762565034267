import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Dashboard, Purchase, QualityManagement } from "../InventoryManagement/pages";
import Category from "../InventoryManagement/pages/admin/Inventory/Category";
import CreateProduct from "../InventoryManagement/pages/admin/Inventory/CreateProduct";
import { CrossDocking, ACGProductOrders, CustomerOrders, DistributionOrders, ECommerceOrders } from "./pages";

export const SPM_ROOT_PATH = "/app/stock-production-management";
export const SPM_ROUTES = {
  dashboard: `${SPM_ROOT_PATH}/dashboard`,
  procurement: `${SPM_ROOT_PATH}/procurement`,
  qualityManagement: `${SPM_ROOT_PATH}/quality-management`,
  inventory: `${SPM_ROOT_PATH}/inventory`,
  inventoryProductsCategory: `${SPM_ROOT_PATH}/inventory/products/category`,
  inventoryCreateProduct: `${SPM_ROOT_PATH}/inventory/products/create-product`,
  acgProductOrders: `${SPM_ROOT_PATH}/acg-product-orders`,
  distributionOrders: `${SPM_ROOT_PATH}/distribution-orders`,
  eCommerceOrders: `${SPM_ROOT_PATH}/e-commerce-orders`,
  customerPortalOrders: `${SPM_ROOT_PATH}/customer-portal-orders`,
  crossDocking: `${SPM_ROOT_PATH}/cross-docking`,
};

const SPMRoutes = () => {
  return (
    <Routes>
      <Route
        path={SPM_ROOT_PATH}
        element={<Navigate to={`${SPM_ROOT_PATH}/dashboard`} />}
      />
      <Route path={SPM_ROUTES.dashboard} element={<Dashboard />} />
      <Route path={SPM_ROUTES.procurement} element={<Purchase />} />
      <Route
        path={SPM_ROUTES.qualityManagement}
        element={<QualityManagement />}
      />
      <Route path={SPM_ROUTES.inventory} element={<InventoryRoutes />} />
      <Route
        path={SPM_ROUTES.acgProductOrders}
        element={<ACGProductOrders />}
      />
      <Route
        path={SPM_ROUTES.distributionOrders}
        element={<DistributionOrders />}
      />
      <Route
        path={SPM_ROUTES.eCommerceOrders}
        element={<ECommerceOrders />}
      />
      <Route
        path={SPM_ROUTES.customerPortalOrders}
        element={<CustomerOrders />}
      />
      <Route
        path={SPM_ROUTES.crossDocking}
        element={<CrossDocking />}
      />
    </Routes>
  );
};

const InventoryRoutes = () => {
  return (
    <Routes>
      <Route
        path={SPM_ROUTES.inventoryProductsCategory}
        element={<Category />}
      />
      <Route
        path={SPM_ROUTES.inventoryCreateProduct}
        element={<CreateProduct />}
      />
    </Routes>
  );
};

export default SPMRoutes;
export { InventoryRoutes };
