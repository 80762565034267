import React, { useState, useEffect } from 'react'
import AdiTable from 'components/AdiTable'
import { ActionButton, DisplayEmptyText } from 'statics/styles/StyledComponents'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { BusinessAPI } from '../../apis'
import { Dropdown, Menu, message } from 'antd'

import { TABLE_CELL_CONFIG, TABLE_ACTION_WIDTH, TABLE_PHONE_WIDTH, TABLE_DATE_WIDTH, TABLE_ACTIVE_STATUS_WIDTH } from 'utilities/CONSTANTS'
import { BusinessType } from '../../statics/types'
import convertIcon from '../../statics/images/convertIcon.svg'
import moment from 'moment'
import { AllBusinessTypes } from '../../statics/constants'
import { getTableMenuList, includeColumn, checkWindowSelectedText } from '../../utilities/functions'
import { ConvertCompany } from '../../components'
import { ADI_CONFIRM } from 'utilities/Functions/GlobalHelperFunctions'
import { capitaliseFirst, formatTitle, initialCellValue } from 'utilities/Functions/FormatFunctions'
import styled from 'styled-components'
import { useAuthStore, useGeneralStore } from 'zustand-stores'
import { AuthStore } from 'zustand-stores/useAuthStore'
import { GeneralStore } from 'zustand-stores/useGeneralStore'
import set from 'lodash/set';
import { label } from 'aws-amplify'



type Props = {
  data: any,
  actions: { onConvertSuccess: any, edit: any, delete: any },
  onRowClick: Function
  selectedTableColumns: any[]
  isLoading: boolean
}

const AllCompaniesTable = (props: Props) => {
  const { data, actions, onRowClick, isLoading } = props
  const isCrmReadonly = useAuthStore((state: AuthStore) => state.moduleAccess.crm.isReadOnly)
  const [tableData, setTableData] = useState<any>([])

  const defaultOpens = {
    open: false,
    cur_type: '',
    target_type: ''

  }
  const [modalOpens, setModalOpens] = useState<{ open: boolean, cur_type: string, target_type: string }>(defaultOpens)

  const [modalData, setModalData] = useState<any>(null)

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  const fixedColumns = [
    {
      title: formatTitle('Company ID'),
      dataIndex: 'bus_id',
      key: 'bus_id',
      ...TABLE_CELL_CONFIG,
      render: (text: string, record: any) => {
        const businessIds = `${record?.businessType.toLowerCase() == 'customer' ? `${record?.customer_ID}` :
          record?.businessType.toLowerCase() == 'lead' ? `${record?.lead_ID}` :
            record?.businessType.toLowerCase() == 'supplier' ? `${record?.supplier_ID}` : ''}`

        return businessIds ? <span>{businessIds}</span> : initialCellValue(text)
      },
    },
    {
      title: formatTitle('Company Name'),
      dataIndex: 'bus_name',
      key: 'bus_name',
      ...TABLE_CELL_CONFIG,
      sorter: (a: any, b: any) => a.bus_name?.localeCompare(b.bus_name),
      render: initialCellValue,
    },
    {
      title: formatTitle('Industry'),
      dataIndex: 'industry',
      key: 'industry',
      ...TABLE_CELL_CONFIG,
      sorter: (a: any, b: any) => a.industry?.localeCompare(b.industry),
      render: initialCellValue
    },
    {
      title: formatTitle('Business Relationship'),
      dataIndex: 'businessType',
      key: 'businessType',
      ...TABLE_CELL_CONFIG,
      filters: [
        { text: 'Customer', value: 'Customer' },
        { text: 'Supplier', value: 'Supplier' },
        { text: 'Lead', value: 'Lead' },
        // { text: 'Unassigned', value: 'Unassigned' },
      ],
      onFilter: (value: string | number | boolean, record: any) => record.businessType.includes(value),
      render: (text: string) => capitaliseFirst(text)
    },
  ]

  const flexibleColumns = [
    {
      title: formatTitle('Email'),
      dataIndex: 'bus_email',
      key: 'bus_email',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue
    },
    {
      title: formatTitle('Phone Number'),
      dataIndex: 'bus_phone',
      key: 'bus_phone',
      width: TABLE_PHONE_WIDTH,
      ellipsis: true,
      render: initialCellValue
    },
    {
      title: formatTitle('Created On'),
      dataIndex: 'creation_date',
      key: 'creation_date',
      width: TABLE_DATE_WIDTH,
      ellipsis: true,
      // align: 'center',
      sorter: (a: any, b: any) => a.creation_date?.localeCompare(b.creation_date),
      render: (text: string) => text ? moment(text).format('DD/MM/YYYY') : initialCellValue(text)
    },
    {
      title: formatTitle('Status'),
      dataIndex: 'active',
      key: 'active',
      width: TABLE_ACTIVE_STATUS_WIDTH,
      ellipsis: true,
      // align: 'center',
      filters: [
        { text: 'Active', value: true },
        { text: 'Inactive', value: false },
      ],
      onFilter: (value: string | number | boolean, record: any) => record.active === value,
      render: (status: boolean) => status === false ? 'Inactive' : 'Active'
    },

  ]

  const actionColumn = isCrmReadonly ? [] : [
    // {
    //   title: formatTitle('Actions'),
    //   dataIndex: 'actions',
    //   key: 'actions',
    //   fixed: 'right',
    //   width: 150,
    //   align: 'center',
    //   render: (text: any, record: any, index: number) => (
    //     <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'center' }}>
    //       <>
    //         <Dropdown
    //           getPopupContainer={trigger => document.getElementById('all-table') as HTMLElement}
    //           overlay={getMenu(record)} placement="bottomCenter"
    //           arrow
    //           overlayClassName="adi-table-dropdown"
    //         >

    //           <ActionButton
    //             // style={{ padding: '0 10px', height: '90%' }}
    //             title="Convert"
    //             onClick={(e) => {
    //               e.stopPropagation()
    //               e.preventDefault()
    //             }}
    //           >
    //             <img src={convertIcon} height='20px' />
    //           </ActionButton>
    //         </Dropdown>
    //       </>
    //       <ActionButton
    //         title="Edit"
    //         onClick={(e) => {
    //           e.stopPropagation()
    //           e.preventDefault()
    //           actions.edit(record)
    //         }}
    //       >
    //         <EditOutlined style={{ fontSize: '15px' }} />
    //       </ActionButton>
    //       <ActionButton
    //         title="Delete"
    //         onClick={(e) => {
    //           e.stopPropagation()
    //           e.preventDefault()
    //           actions.delete(record)
    //         }}
    //       >
    //         <DeleteOutlined style={{ fontSize: '15px' }} />
    //       </ActionButton>
    //     </div>
    //   ),

    // },
    {
      title: formatTitle('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (text: any, record: any) => {
        const businessType = record.businessType.toLowerCase();
        console.log('abc businessType', businessType)
        const actionItems = [
          {
            key: 'duplicateToCustomer',
            label: 'Duplicate as Customer',
            icon: <img src={convertIcon} height='20px' />,
            onClick: () => handleMenuClick(record, 'Customer'),
          }, {
            key: 'duplicateToSupplier',
            label: 'Duplicate as Supplier',
            icon: <img src={convertIcon} height='20px' />,
            onClick: () => handleMenuClick(record, 'Supplier'),
          },
          {
            key: 'convertToCustomer',
            label: 'Convert to Customer',
            icon: <img src={convertIcon} height='20px' />,
            onClick: () => handleMenuClick(record, 'Customer'),
          },
          {
            key: 'convertToSupplier',
            label: 'Convert to Supplier',
            icon: <img src={convertIcon} height='20px' />,
            onClick: () => handleMenuClick(record, 'Supplier'),
          },
          {
            key: 'convertToLead',
            label: 'Edit',
            icon: <EditOutlined />,
            onClick: () => actions.edit(record),
          },
          {
            key: 'delete',
            label: 'Delete',
            icon: <DeleteOutlined />,
            onClick: () => actions.delete(record),
          },
        ];
        let items = actionItems
        // based on business type show the menu items
        // if busniess type is customer, then only key duplicate to customer should be shown with edit and delete
        if (businessType === AllBusinessTypes.CUSTOMER) {
          items = actionItems.filter(item => item.key === 'duplicateToSupplier' || item.key === 'edit' || item.key === 'delete')
        } else if (businessType === AllBusinessTypes.SUPPLIER) {
          items = actionItems.filter(item => item.key === 'duplicateToCustomer' || item.key === 'edit' || item.key === 'delete')
        }
        else if (businessType === AllBusinessTypes.LEAD) {
          items = actionItems.filter(item => item.key === 'convertToCustomer' || item.key === 'convertToSupplier' || item.key === 'edit' || item.key === 'delete')
        }

        console.log('itemsaaaa', items);

        return <AdiTable.ActionDropdown menuItems={items} />;
      },

    }
  ]

  const newColArray = flexibleColumns.filter((item: any) => {
    if (includeColumn(props.selectedTableColumns, item.dataIndex)) {
      return item
    }
  })



  const finalTableColumns = [
    ...fixedColumns,
    ...newColArray,
    ...actionColumn
  ]




  // duplicate customer/supplier
  const handleMenuClick = (record: any, targetBizType: string) => {

    const lowercaseBusinessType = record.businessType.toLowerCase()
    const lowercaseTargetBusinessType = targetBizType.toLowerCase()

    console.log('handleMenuClick', record)

    // customer and suppler convert to each other
    if ((lowercaseBusinessType == AllBusinessTypes.SUPPLIER && lowercaseTargetBusinessType == AllBusinessTypes.CUSTOMER) ||
      (lowercaseBusinessType == AllBusinessTypes.CUSTOMER && lowercaseTargetBusinessType == AllBusinessTypes.SUPPLIER)) {
      ADI_CONFIRM({
        actionTitle: `duplicate this ${lowercaseBusinessType} as ${lowercaseTargetBusinessType} `,
        onConfirm: () => {
          // console.log('convert')
          var payload: any = {
            abn: record.abn || '',
            bus_name: record.bus_name,
            industry: record.industry || '',
            bus_email: record.bus_email || '',
            bus_phone: record.bus_phone || '',
            PAIRED_WITH: '',
            confirm_similarity: true,
          }

          if (Array.isArray(record.lead_conversion_daterange) && record.lead_conversion_daterange.length > 0) {
            payload = {
              ...payload,
              lead_conversion_daterange: record.lead_conversion_daterange
            }
          }
          if (lowercaseTargetBusinessType == AllBusinessTypes.SUPPLIER) {
            // duplicate a customer to a spupplier
            payload.PAIRED_WITH = record.customer_ID
          } else {
            // duplicate a supplier to a customer
            payload.PAIRED_WITH = record.supplier_ID
          }
          var key = 'duplicating'
          // message.loading({ content: 'Duplicating...', key })
          setIsGlobalLoading(true)
          BusinessAPI.duplicateBusiness(lowercaseTargetBusinessType as 'customer' | 'supplier', payload)
            .then(res => {
              message.success({
                content: 'Duplicated successfully',
                key,
                duration: 1,
                onClose: () => actions.onConvertSuccess()
              })
            }).catch(e => {
              // console.log('duplicateBusiness error', e)
              let err: any = e
              let statusCode = err?.response?.status
              if (statusCode === 467 || statusCode === 466 || statusCode === 465) {
                message.error({ content: `Failed to duplicate business. ${targetBizType} already exists in our system.`, key })
              } else {
                message.error({ content: 'Failed to duplicate', key })
              }

            }).finally(() => {
              setIsGlobalLoading(false)
            })

        }
      })
    } else {
      setModalData(record)
      setModalOpens({
        open: true,
        cur_type: lowercaseBusinessType,
        target_type: lowercaseTargetBusinessType
      })

    }

  }


  // const getMenu = (record: any) => {

  //   const businessType = record.businessType.toLowerCase()
  //   console.log('abc businessType getMenu businessType', businessType)
  //   const menuList: any = getTableMenuList(businessType)

  //   return (
  //     <>
  //       <Menu
  //         onClick={(e: any) => {
  //           const event = e.domEvent
  //           event.preventDefault()
  //           event.stopPropagation()
  //           const targetBizType = e.key.split(' ')[e.key.split(' ').length - 1]
  //           handleMenuClick(record, targetBizType)
  //         }}
  //         selectable={false}
  //         style={{ borderRadius: '2px', borderRight: 'none' }}
  //       >
  //         {menuList.map((item: any, index: number) => (
  //           <Menu.Item
  //             key={item.label}
  //             style={{ borderTop: index !== 0 ? '1px solid grey' : 'none', marginBottom: '0' }}
  //           >
  //             {item.label}
  //           </Menu.Item>
  //         ))}

  //       </Menu>
  //     </>

  //   )
  // };



  const convertToTableData = (data: any) => {
    const unassigned = data?.Companies || []
    const customers = data?.Customers || []
    const suppliers = data?.Suppliers || []
    let leads = data?.Leads || []

    const getBusinessType = (comp: any) => {
      if (customers.includes(comp)) return 'Customer'
      if (suppliers.includes(comp)) return 'Supplier'
      if (leads.includes(comp)) return 'Lead'
      return 'Unassigned'
    }

    // remove the leads that have been converted by checking the approved_date
    if (leads.length > 0) {
      leads = leads.filter((lead: any) => {
        if (!lead?.approved_date) {
          return lead
        }
      })

    }

    const tableData = [...unassigned, ...customers, ...suppliers, ...leads].map((company: any, i: number) => {
      return {
        ...company,
        key: i,
        businessType: getBusinessType(company),
      }
    })

    // default sort - by creation date
    tableData.sort((a: any, b: any) => {
      return new Date(b.creation_date).getTime() - new Date(a.creation_date).getTime()
    })

    // return [...tableData, ...tableData] // text scroll bar
    return tableData
  }

  // convert to table data
  useEffect(() => {
    const tableData = convertToTableData(data)
    setTableData(tableData)
  }, [data])




  const onCloseModal = () => {
    // onClose()

    // formDispatch({ type: ActionType.RESET })
    setModalOpens(defaultOpens)

    // setSelectedContact(emptyContactPayload)
  }



  return (
    !isLoading && tableData.length === 0 ?
      <DisplayEmptyText>
        No Company data to display.
      </DisplayEmptyText>
      :
      <div id='all-table'>
        <AdiTable
          loading={isLoading}
          className=''
          columns={finalTableColumns}
          tableData={tableData}
          fullWidth
          marginTop='20px'
          scroll={{ y: '70vh' }}
          onRowClick={(record: any, rowIndex: any, event: any) => {
            // prevent row click if trying to select text
            if (checkWindowSelectedText()) return
            onRowClick(record)
          }}
          extraPaginationPadding
        // sticky
        />
        <ConvertCompany
          open={modalOpens.open}
          types={{ curType: modalOpens.cur_type, targetType: modalOpens.target_type }}
          onClose={() => { onCloseModal() }}
          selectedCompany={modalData}
          onConvertSuccess={actions.onConvertSuccess}
        />

      </div>
  )
}

export default AllCompaniesTable

