import React, { useCallback, useEffect, useState, MouseEvent } from "react";
import { Form, message, } from "antd";
import { ActionButton, DisplayEmptyTextPrimary, DisplayEmptyTextSecondary, MergeableTableCellP } from "../../../../../statics/styles/StyledComponents";
import { getBizCode, getSelectorOptions } from "utilities/Functions/GlobalHelperFunctions";
import { ASSET_MANAGEMENT_API } from "utilities/AdiApi";
import AdiTable from "components/AdiTable";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { ALERT_PRESET_FLOW_STEPS, ASSET_ALERTS_CATEGORY, ASSET_ALERT_RESPONSE_TYPES, } from "utilities/CONSTANTS";
import { AlertRule, AssetAlertType } from "utilities/types";
import _ from 'lodash'
import { ADI_CONFIRM } from "utilities/Functions/GlobalHelperFunctions";
import { formatTitle } from "utilities/Functions/FormatFunctions";
import { MasterDataHeader } from "../../components";
import CreateAlertPresetModal from "./CreateAlertPresetModal";


import FlowInfoPanel from "components/FlowInfoPanel";
import DropdownAPI from "../../apis/DropdownAPI";
import MasterDataAPI from "../../apis/MasterDataAPI";
import { useGeneralStore } from "zustand-stores";
import { GeneralStore } from "zustand-stores/useGeneralStore";
import useAuthStore, { AuthStore } from "zustand-stores/useAuthStore";
import TableCard from "components/AdiTable/TableCard";
import EmptyDisplay from "components/AdiTable/EmptyDisplay";
import SimpleButton from "components/GlobalButtons/SimpleButton";


type Props = {};


const AlertRules = (props: Props) => {

	const [form] = Form.useForm();
	const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
	const isAmReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.am.isReadOnly)

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [tableData, setTableData] = useState<any>([]);
	const [filteredTableData, setFilteredTableData] = useState<any>([]);


	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isViewMode, setIsViewMode] = useState<boolean>(false);

	const [allTypes, setAllTypes] = useState<string[]>([])
	const [alertTypesList, setAlertTypesList] = useState<any[]>([])
	const [geofenceList, setGeofenceList] = useState<any[]>([])





	const emptyAlertData: AssetAlertType = {

		refID: '',
		class: "ALERT",
		alert_preset_name: "",
		asset_IDs: [],
		alerts: [{
			alert_name: "",
			type: "",
			alert_response: [],
			notify: [],
			attribute: '',
			minRule: '',
			maxRule: '',
			geofenceName: '',
			shipmentID: '',
		}]

	}

	const [modalData, setModalData] = useState<AssetAlertType>(emptyAlertData);

	const getTableData = async () => {

		try {
			setIsLoading(true)
			const result = await MasterDataAPI.getMasterType('ALERT')
			console.log('getTableData alerts rules res', result)
			if (result && result.length > 0) {
				setTableData(result)
				setFilteredTableData(result)
			} else {
				// message.info('No result found')
				setTableData([])
				setFilteredTableData([])
			}

			setIsGlobalLoading(false)

		} catch (err) {
			console.log('getTableData alerts fail...', err)

			setIsGlobalLoading(false)
		} finally {
			setIsLoading(false)
		}
	}

	const initialize = useCallback(
		() => {
			getTableData()
		},
		[],
	)


	useEffect(initialize, [])


	const handleModalOpen = async (curRecord?: any) => {


		// setIsLoading(true)
		// api call to get the all the types and categories

		try {
			const result = await DropdownAPI.getDropdowns({
				// alert_notify: true,
				alert_types: true,
				geofence: true,

			})
			const { alert_types, geofence } = result



			let finalModalData: any = {}
			const newAllTypes: string[] = []

			// edit current record
			if (curRecord && curRecord.refID) {
				console.log('curRecord', curRecord)
				const { alert_preset_name, apply_to, refID, alerts } = curRecord


				const formatedAlerts = alerts.map((item: any, index: number) => {
					const { alert_name, attribute, geofenceName, maxRule, minRule, notification, shipmentID, type, placeholder } = item

					const formatResponse = []
					if (notification.email) {
						formatResponse.push('email')
					}
					if (notification.SMS) {
						formatResponse.push('SMS')
					}
					const formatNotify = notification.recipients && notification.recipients.length > 0 ? notification.recipients.map((item: any) => (`${item}`)) : []
					// const formatNotify = notification.recipients && notification.recipients.length > 0 ? notification.recipients.map((item: any) => (`${item.name}#${item.email}#${item.number}`)) : []

					const curType = `${type}#${attribute}#${placeholder}`

					newAllTypes.push(type)



					const newObj = {
						alert_name,
						attribute,
						geofenceName, maxRule, minRule,
						alert_response: [...formatResponse],
						notify: [...formatNotify],
						shipmentID,
						type: curType
					}

					return newObj

				})

				finalModalData = {
					alert_preset_name,
					refID,
					alerts: [...formatedAlerts],
					asset_IDs: apply_to,
					class: curRecord.class
				}

			}
			// add new record
			else {

				finalModalData = {
					...modalData,
				}
			}

			console.log(' finalModalData asset data', finalModalData)


			form.setFieldsValue({
				...finalModalData,
			})

			setModalData(finalModalData)
			setAllTypes([...newAllTypes])
			setIsModalOpen(true);

			// setNotifyList(alert_notify)
			setAlertTypesList(alert_types)
			setGeofenceList(geofence)
		} catch (err) {
			console.log('handleModalOpen fail...', err)
		}


	};

	const handleModalClose = (e?: MouseEvent) => {
		if (e) {
			e.preventDefault();
		}

		setIsModalOpen(false);
		setIsViewMode(false);

		console.log('close')
		// reset fields for accordion

		setAllTypes([])
		form.resetFields()
		setModalData(emptyAlertData)
		form.setFieldsValue({ ...emptyAlertData })
	};

	const handleDelete = async (record: any) => {

		console.log('deleterecord', record)
		const { refID, } = record
		try {
			setIsGlobalLoading(true)
			const res = await MasterDataAPI.deleteMaster(refID, record.class)
			console.log('handleDelete res', res)
			setIsGlobalLoading(false)

			message.success(`Asset master data deleted successfully!`)
			getTableData()
		} catch (err) {
			console.log('Delete asset master data fail...', err)
			message.error('Failed to delete asset master data.')
			setIsGlobalLoading(false)
		}

	}

	const handleConfirm = async (values: any) => {
		// setIsModalOpen(false);
		console.log("modalData", modalData);
		console.log("values", values);



		const { alert_preset_name, alerts } = values


		const formatedAlerts = alerts.map((item: AlertRule) => {
			const { alert_name, type, alert_response, attribute, geofenceName, maxRule, minRule, notify, shipmentID, } = item

			const typeLst = type.split('#')
			const curType = typeLst[0]
			const curAttribute = typeLst[1]
			const curPlaceholder = typeLst[2]

			// const formatedNotify = notify.map((item: string) => {
			// 	const notifyLst = item.split('#')
			// 	return ({
			// 		name: notifyLst[0],
			// 		email: notifyLst[1],
			// 		number: notifyLst[2],
			// 	})
			// })

			let curGeofenceName: string | number | undefined = ""
			let curShipmentID: string | number | undefined = ""
			let curMin: string | number | undefined = ""
			let curMax: string | number | undefined = ""

			if (curType == ASSET_ALERTS_CATEGORY.NUMERIC) {
				curMin = minRule
				curMax = maxRule
				curGeofenceName = ""
				curShipmentID = ""
			} else if (curType == ASSET_ALERTS_CATEGORY.GEOFENCE) {
				curMin = ""
				curMax = ""
				curGeofenceName = geofenceName
				curShipmentID = ""
			} else if (curType == ASSET_ALERTS_CATEGORY.SHIPMENT) {
				curMin = ""
				curMax = ""
				curGeofenceName = ''
				curShipmentID = shipmentID
			}


			const singleAlert: any = {
				alert_name,
				type: curType,

				attribute: curAttribute,
				placeholder: curPlaceholder,

				maxRule: curMax,
				minRule: curMin,

				geofenceName: curGeofenceName,

				shipmentID: curShipmentID,

				notification: {
					"email": alert_response.indexOf('email') < 0 ? false : true,
					"SMS": alert_response.indexOf('SMS') < 0 ? false : true,
					recipients: notify
					// recipients: formatedNotify
				}

			}

			return singleAlert
		})


		const payload = {
			alert_preset_name,
			class: modalData.class,
			apply_to: modalData.asset_IDs,
			refID: modalData.refID,
			alerts: [...formatedAlerts],
		}


		console.log('alert rule submit  payload', payload)

		setIsGlobalLoading(true)

		try {
			let res: any
			if (modalData.refID && modalData.refID.length > 0) {
				res = await MasterDataAPI.updateMaster(payload)
			} else {
				res = await MasterDataAPI.addMaster(payload)
			}
			message.success('Alert added successfully!')
			getTableData()
			handleModalClose()
			setIsGlobalLoading(false)

		} catch (err) {
			console.log('alert rule submit fail...', err)
			setIsGlobalLoading(false)
			message.error('Failed to add alert, please try again')
		}

	};


	const renderOption = (type?: string) => {
		// console.log("renderOption modalData ", modalData);

		if (type == 'alert_response') {
			const keys: any = Object.keys(ASSET_ALERT_RESPONSE_TYPES).map((item: string) => ({ label: ASSET_ALERT_RESPONSE_TYPES[item], value: item }))
			return getSelectorOptions(keys, "Select Response Type", 'value', 'label')

		} else if (type == 'notify') {
			const optArr = [{ notify_id: 'admin', notify_name: 'Admin' }] // role list, for now use admin role only

			return getSelectorOptions(optArr, '', 'notify_id', 'notify_name')

		} else if (type == 'alert_types') {
			const optArr = alertTypesList.map((item: any,) => ({
				value: `${item.type}#${item.attribute}#${item.placeholder}`,
				label: item.placeholder
			}))
			return getSelectorOptions(optArr, 'Select Measurement Type', 'value', 'label')

		} else if (type == 'geofence') {
			const optArr = geofenceList.map((item: any,) => item.location_name)
			return getSelectorOptions(optArr, 'Select Geofence')

		}



		return getSelectorOptions([], 'Select')

	}



	const renderMergeableTableCell = (text: any, record: any, index: number, cellDataKey: string) => {

		return {
			props: { style: { padding: 0, } },
			children: record.alerts && record.alerts.length > 0 ? (
				<>
					{record.alerts.map((item: any, idx: number) => {
						if (cellDataKey == 'alert_response') {
							const result = []
							if (item.notification.email) {
								result.push(ASSET_ALERT_RESPONSE_TYPES.email)
							}
							if (item.notification.SMS) {
								result.push(ASSET_ALERT_RESPONSE_TYPES.SMS)
							}

							let finalStr = ''
							result.map((str: string, iddx: number) => {
								if (iddx == 0) {
									if (result.length != 1) {
										finalStr = `[ ${str}, `
									} else {
										finalStr = `[ ${str} ]`
									}

								} else if (iddx == result.length - 1) {
									finalStr = finalStr + `${str} ]`
								} else {
									finalStr = finalStr + `${str}, `
								}

							})
							return (
								<MergeableTableCellP
									key={`${cellDataKey}#${idx}`}
									style={{ borderBottom: (idx == record.alerts.length - 1) ? 'none' : '1px solid #555555' }}
								>
									{finalStr.length > 0 ? finalStr : '---'}
								</MergeableTableCellP>
							)

						} else if (cellDataKey == 'notify') {
							if (item.notification.recipients) {
								const recipientsArr = item.notification.recipients
								let finalStr = ''
								recipientsArr.map((itemm: any, iddx: number) => {
									if (iddx == 0) {
										if (recipientsArr.length != 1) {
											finalStr = `[ ${itemm}, `
										} else {
											finalStr = `[ ${itemm} ]`
										}

									} else if (iddx == recipientsArr.length - 1) {
										finalStr = finalStr + `${itemm} ]`
									} else {
										finalStr = finalStr + `${itemm}, `
									}

								})

								return (
									<MergeableTableCellP
										key={`${cellDataKey}#${idx}`}
										style={{ borderBottom: (idx == record.alerts.length - 1) ? 'none' : '1px solid #555555' }}
									>
										{finalStr.length > 0 ? finalStr : '---'}
									</MergeableTableCellP>
								)

							}

						} else if (cellDataKey == 'value') {
							let finalStr = ''
							if (item.type == ASSET_ALERTS_CATEGORY.GEOFENCE) {
								finalStr = item.geofenceName

							} else if (item.type == ASSET_ALERTS_CATEGORY.SHIPMENT) {
								finalStr = item.shipmentID
							} else {
								finalStr = `Min: ${item.minRule}, Max: ${item.maxRule}`
							}

							return (
								<MergeableTableCellP
									key={item[cellDataKey]}
									style={{ borderBottom: (idx == record.alerts.length - 1) ? 'none' : '1px solid #555555' }}
								>
									{finalStr.length > 0 ? finalStr : '---'}
								</MergeableTableCellP>
							)

						} else {
							return (
								<MergeableTableCellP
									key={item[cellDataKey]}
									style={{ borderBottom: (idx == record.alerts.length - 1) ? 'none' : '1px solid #555555' }}
								>
									{`${item[cellDataKey]}`}
								</MergeableTableCellP>
							);
						}


					})}
				</>
			) : (
				<MergeableTableCellP >
					---
				</MergeableTableCellP>
			)
		}
	}


	const columns: any = [

		{
			title: formatTitle("No"),
			dataIndex: "alert_ID",
			align: "left",
			key: "alert_ID",
			render: (text: any, record: any, index: number) => (<>{index + 1}</>)
		},
		{
			title: formatTitle("Alert Preset"),
			dataIndex: "alert_preset_name",
			align: "left",
			key: "alert_preset_name",
		},
		{
			title: formatTitle("Rule"),
			dataIndex: "alerts",
			align: "left",
			key: "alerts",
			render: (text: any, record: any, index: number) => {
				return renderMergeableTableCell(text, record, index, 'alert_name')
				// return {
				//     props: { style: { padding: 0, } },
				//     children: record.alerts && record.alerts.length > 0 ? (
				//         <>
				//             {record.alerts.map((item: any, idx: number) => {

				//                 return (
				//                     <MergeableTableCellP
				//                         key={item.alert_name}
				//                         style={{ borderBottom: (idx == record.alerts.length - 1) ? 'none' : '1px solid #989898' }}
				//                     >
				//                         {`${item.alert_name}`}
				//                     </MergeableTableCellP>
				//                 );
				//             })}
				//         </>
				//     ) : (
				//         <MergeableTableCellP >
				//             --
				//         </MergeableTableCellP>
				//     )
				// }
			},
		},

		{
			title: formatTitle("Measurement Type"),
			dataIndex: "alerts",
			align: "left",
			key: "alerts",
			render: (text: any, record: any, index: number) => {
				return renderMergeableTableCell(text, record, index, 'placeholder')

			},
		},
		{
			title: formatTitle("Value"),
			dataIndex: "alerts",
			align: "left",
			key: "alerts",
			render: (text: any, record: any, index: number) => {
				return renderMergeableTableCell(text, record, index, 'value')
			},

		},
		{
			title: formatTitle("Alert Response"),
			dataIndex: "alerts",
			align: "left",
			key: "alerts",
			render: (text: any, record: any, index: number) => {
				return renderMergeableTableCell(text, record, index, 'alert_response')
			},
		},
		{
			title: formatTitle("Notify"),
			dataIndex: "alerts",
			align: "left",
			key: "alerts",
			render: (text: any, record: any, index: number) => {
				return renderMergeableTableCell(text, record, index, 'notify')
			},

		},

		// {
		// 	title: formatTitle("Action"),
		// 	// dataIndex: "asset_type",
		// 	align: "left",
		// 	// key: "asset_master_name",
		// 	render: (text: any, record: any, index: number) => (
		// 		<>
		// 			<p style={{ marginBottom: "0" }}>
		// 				<ActionButton
		// 					title="Edit"
		// 					onClick={(e: MouseEvent) => {
		// 						e.stopPropagation()
		// 						setIsViewMode(false)
		// 						handleModalOpen(e, record)
		// 					}}
		// 				>
		// 					<EditOutlined />
		// 				</ActionButton>
		// 				<ActionButton
		// 					title="Delete"
		// 					onClick={(e: MouseEvent) => {
		// 						e.preventDefault()
		// 						e.stopPropagation()
		// 						ADI_CONFIRM({
		// 							customizeTitle: 'Are you sure?',
		// 							isdelete: true,
		// 							onConfirm: () => {
		// 								console.log('Delete')
		// 								// setIsModalOpen(true)
		// 								handleDelete(record)
		// 							}
		// 						})
		// 					}}
		// 				>
		// 					<DeleteOutlined />
		// 				</ActionButton>
		// 			</p>
		// 		</>
		// 	),
		// },
		{
			title: formatTitle('Actions'),
			dataIndex: 'actions',
			key: 'actions',
			fixed: 'right',
			width: 150,
			align: 'center',
			render: (text: any, record: any) => {

				const items = [
					{
						label: "Edit",
						icon: <EditOutlined />,
						onClick: () => {
							setIsViewMode(false)
							handleModalOpen(record)
						},
					},
					{
						label: "Delete",
						icon: <DeleteOutlined />,
						onClick: () => {
							ADI_CONFIRM({
								customizeTitle: 'Are you sure?',
								isdelete: true,
								onConfirm: () => {
									console.log('Delete')
									handleDelete(record)
								}
							})
						},
					}
				];

				return <AdiTable.ActionDropdown menuItems={items} />;
			},

		}

	];

	isAmReadOnly && columns.pop()



	// search from table data
	const searchByPresetName = (searchText: string) => {
		const filteredData = tableData.filter((item: any) => {
			return item.alert_preset_name.toLowerCase().includes(searchText.toLowerCase())
		})
		setFilteredTableData(filteredData)

	}

	return (
		<>
			<div style={{ margin: '10px 0px 0px 0px' }}>
				<FlowInfoPanel
					items={[...ALERT_PRESET_FLOW_STEPS]}
					style={{ maxWidth: '700px', marginLeft: '16px' }}
				/>
			</div>
			{/* <ImgDiv2>
				<img src={alertPreset} alt='alertPreset' style={{ width: '100%' }} />
			</ImgDiv2> */}
			{
				!isLoading && tableData.length == 0 ?
					<EmptyDisplay data={{
						title: 'Start managing your Alert Presets.', text: 'Configure Alerts to  notify individuals or teams of potential issues, changes in status, or threshold violations, allowing them to take action and resolve the issue before it becomes critical.',
						buttons: [<SimpleButton
							text='+ Create Alert Preset'
							onClick={handleModalOpen}
						/>],
					}} />
					:
					<TableCard>
						<MasterDataHeader
							onSearch={searchByPresetName}
							createOnClick={handleModalOpen}
							searchPlaceholder="Search by Preset Name"
							createBtnText="+ Create Alert Preset"
						/>
						<AdiTable
							loading={isLoading}
							className="mergeable-table"
							tableData={filteredTableData}
							columns={columns}
							fullWidth
							marginTop="20px"
							onRowClick={(record: any, rowIndex: any, e: any) => {
								setIsViewMode(true)
								handleModalOpen(record)
							}}
							extraPaginationPadding
						/>
					</TableCard>
			}

			<CreateAlertPresetModal
				isModalOpen={isModalOpen}
				isViewMode={isViewMode}
				handleModalClose={handleModalClose}
				modalData={modalData}
				form={form}
				handleConfirm={handleConfirm}
				renderOption={renderOption}
				allTypes={allTypes}
				setAllTypes={setAllTypes}
			/>

		</>

	)
};



export default AlertRules;

