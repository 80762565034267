import React, { useEffect, useMemo, useState } from 'react'
import { Col, Divider, Form, message, Radio, Row, Space, Table } from 'antd'
import GlobalDrawer from 'components/GlobalModal/GlobalDrawer'
import Input from 'components/Input';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import { FlexWrapper } from 'statics/styles/StyledComponents';
import { AddInventoryItemAPI, FinishedProductAPI, InventoryItemAPI, OrderAPI, QACheckAPI, ReceivePurchaseOrderAPI, SupplierAPI } from '../../apis';
import moment from 'moment';
import { capitaliseFirst, formatTitle, initialCellValue } from 'utilities/Functions/FormatFunctions';
import { convertDateAndTime } from 'utilities/Functions/GlobalHelperFunctions'


import { ItemLocation, getLocationOptions, getLocationIdOptions } from 'containers/Pages/InventoryManagement/statics/ItemLocationIDs';
import styled from 'styled-components';
import { PRODUCT_TYPE } from '../../statics/constants';
import AdiTable from 'components/AdiTable';
import { TABLE_CELL_CONFIG } from 'utilities/CONSTANTS';
import GeneratePicklistAPI from '../../apis/GeneratePicklistAPI';
import { useGeneralStore, useUserStore } from 'zustand-stores';
import { UserStore } from 'zustand-stores/useUserStore';
import { GeneralStore } from 'zustand-stores/useGeneralStore';
import { titleOptions } from 'containers/Pages/RelationshipManagement/statics/constants';

const { ModalLayout } = GlobalDrawer;


type Props = {
  open: boolean;
  viewOnly?: boolean;
  onClose: Function;
  inventoryData?: {
    purchasedOrder: any;
    receivedOrder?: any;
    item: any;
  };
  reload: Function;  // onSuccess callback
  bypass?: boolean;  // if true, the details will be filled manually
  transferLocation?: boolean;
  // inventoryType?: string; // inventory type by pass or product finished
  // setInventoryType?: any;
  formName?: string;
}

const ORDER_STATUS = {
  sent: 'sent',
  partiallyDelivered: 'partially_delivered',
  fullyDelivered: 'fully_delivered',
}

const FINISHED_PRODUCT_PRODUCT_TYPES = [
  PRODUCT_TYPE.xplProduct.value,
  PRODUCT_TYPE.eCommerceProduct.value,
]

const ACG_PRODUCT_PRODUCT_TYPES = [
  PRODUCT_TYPE.acgProduct.value,
]


const AddInventoryItemForm = (props: Props) => {
  const { formName, open, onClose, inventoryData, reload, bypass, transferLocation, viewOnly } = props
  console.log('inventoryData', inventoryData, transferLocation)
  const username = useUserStore((state: UserStore) => state.username)

  const [inventoryType, setInventoryType] = useState(PRODUCT_TYPE.generalProduct.value)

  const isFinishedProduct = useMemo(() => FINISHED_PRODUCT_PRODUCT_TYPES.includes(inventoryType), [inventoryType])
  const isAcgProduct = useMemo(() => ACG_PRODUCT_PRODUCT_TYPES.includes(inventoryType), [inventoryType])

  const [orderNumberOptions, setOrderNumberOptions] = useState<any[]>([])

  const [acgProductTableData, setAcgProductTableData] = useState<any>([])

  const [productList, setProductList] = useState<any>({
    general: [],
    distribution: [],
    ecommerce: [],
  })

  const [form] = Form.useForm()
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  const [selectedLocation, setSelectedLocation] = useState<ItemLocation | ''>('')
  const [categoryOptions, setCategoryOptions] = useState<any[]>([])
  const [subCategoryOptions, setSubCategoryOptions] = useState<any[]>([])
  const [productNameOptions, setProductNameOptions] = useState<any[]>([])

  const [supplierOptions, setSupplierOptions] = useState<any[]>([])


  const supplierData = useMemo(() => {
    const supplierName = inventoryData?.purchasedOrder?.supplier_name || inventoryData?.item?.supplier_name
    const supplierId = inventoryData?.purchasedOrder?.supplier_id || inventoryData?.item?.supplier_id

    return {
      supplierName,
      supplierId
    }
  }, [inventoryData])

  // const 

  useEffect(() => {
    if (!open) return;
    if (viewOnly) {
      initializeViewOnly()
    } else if (bypass) {
      initializeBypass()
    } else if (transferLocation) {
      initializeTransferLocation()
    } else {
      // happens when receiving purchase order
      initializeAddInventory()
      console.log('inventoryData::', inventoryData)
    }

    return () => form.resetFields()

  }, [open, viewOnly, bypass])


  const initializeViewOnly = () => {
    const values = {
      ...inventoryData?.item,
      ...inventoryData?.purchasedOrder,
    }
    console.log('viewOnly_values', values)
    form.setFieldsValue(values)

  }

  const initializeBypass = async () => {
    form.setFieldsValue({
      recorded_by: username,
      recorded_on: new Date(),
      gr_created_date: new Date(),
    })

    setIsGlobalLoading(true)
    await getProductList()

    // get internal lot number for general product and 3pl product
    await ReceivePurchaseOrderAPI.getInternalLotNumber()
      .then(number => {
        form.setFieldsValue({ internal_lot_number: number, internal_lot_numbers: number })
      }).catch(e => {
        console.log('error', e)
        message.error('Failed to get internal lot number.')
      })
    // get supplier options
    await SupplierAPI.getAllSuppliers()
      .then(data => {
        // convert data to options
        const options = data.map((item: any) => {
          return {
            label: item.bus_name,
            value: item.supplier_ID
          }
        })
        setSupplierOptions(options)
      }).catch(e => {
        console.log('error', e)
        message.error('Failed to get suppliers.')
      })

    setIsGlobalLoading(false)

  }

  const initializeAddInventory = async () => {
    form.setFieldsValue({
      recorded_by: username,
      recorded_on: new Date(),
      gr_created_date: new Date(),
    })

    setIsGlobalLoading(true)

    // get internal lot number
    await ReceivePurchaseOrderAPI.getInternalLotNumber()
      .then(number => {
        form.setFieldsValue({ internal_lot_number: number })
      }).catch(e => {
        console.log('error', e)
        message.error('Failed to get internal lot number.')
      })

    setIsGlobalLoading(false)

    // handle date data
    form.setFieldsValue({
      ...inventoryData?.item,
      expiry_date: inventoryData?.item?.expiry_date ? moment(inventoryData?.item?.expiry_date) : '',
      manufacturing_date: inventoryData?.item?.manufacturing_date ? moment(inventoryData?.item?.manufacturing_date) : '',
      quantity_received: undefined,   // this should be empty
    })
    // handle locations data, if there is a location, set it as selected
    const itemLocation = inventoryData?.item?.item_location || ''
    if (itemLocation) {
      setSelectedLocation(itemLocation)
    }

  }

  const initializeTransferLocation = () => {
    // handle current locations and new locations
    form.setFieldsValue({
      ...inventoryData?.item,
      cur_location: inventoryData?.item?.item_location,
      cur_id: inventoryData?.item?.location_id,
      item_location: undefined,
      location_id: undefined,
      recorded_by: username,
    })
  }

  // get all inventory items
  const getProductList = async () => {
    try {
      const products = await InventoryItemAPI.getAllItems()
      const generalProducts = products.filter((item: any) => item.mark_finished_product === PRODUCT_TYPE.generalProduct.value)
      const distributionProducts = products.filter((item: any) => item.mark_finished_product === PRODUCT_TYPE.xplProduct.value)
      const ecomProducts = products.filter((item: any) => item.mark_finished_product === PRODUCT_TYPE.eCommerceProduct.value)
      setProductList((prev: any) => ({
        ...prev,
        general: generalProducts,
        distribution: distributionProducts,
        ecommerce: ecomProducts,
      }))

    } catch (e) {
      console.log('error', e)
      message.error('Failed to get products.')
    }
  }

  const getProductsByType = (type: string) => {
    let products = productList.general
    if (type === PRODUCT_TYPE.xplProduct.value) {
      products = productList.distribution
    } else if (type === PRODUCT_TYPE.eCommerceProduct.value) {
      products = productList.ecommerce
    }
    return products
  }

  const getProductCategoryOptions = async (type: string) => {
    const products = getProductsByType(type)

    // get product category options
    const categories: any[] = products.map((item: any) => item.item_category)
    // extract unique categories
    const uniqueCategories: any[] = Array.from(new Set(categories))
    // create options array
    const options = uniqueCategories.map((item: any) => ({ value: item, label: item }))

    setCategoryOptions(options)
  }



  const updateLocation = async (values: any) => {
    console.log('updateLocation', values, inventoryData)
    const payload = {
      order_ID: inventoryData?.item?.order_ID,
      sku: values.sku,
      batch_number: inventoryData?.item?.batch_number, // need to pass this
      supplier_lot_number: values.supplier_lot_number,
      item_location: values.item_location,
      location_id: values.location_id,
    }
    console.log('payload', payload)
    // return
    setIsGlobalLoading(true)
    await AddInventoryItemAPI.updateBatchTrackingItem(payload)
      .then(res => {
        message.success('Item transferred successfully!')
        reload()
      }).catch(e => {
        console.log('error', e)
        message.error('Failed to transfer item location.')
      }).finally(() => {
        setIsGlobalLoading(false)
      })
  }

  const processItemPayload = (values: any) => {
    // turn undefined to empty string
    Object.keys(values).forEach((key) => {
      if (values[key] === undefined) {
        values[key] = ''
      }
    })
    let itemPayload = { ...values }

    // process special fields
    itemPayload.manufacturing_date = convertDateAndTime(moment(itemPayload.manufacturing_date), 'datetime')
    itemPayload.expiry_date = convertDateAndTime(moment(itemPayload.expiry_date), 'datetime')
    itemPayload.gr_created_date = convertDateAndTime(moment(itemPayload.gr_created_date), 'datetime')
    itemPayload.recorded_on = convertDateAndTime(moment(itemPayload.recorded_on), 'datetime')

    // add additional fields
    const additional = {
      rejection_report_created: 'false',  // yes it's string
      item_status: '',
      quantity: inventoryData?.item?.quantity ? inventoryData?.item?.quantity : '',
      price: inventoryData?.item?.cost_price ? inventoryData?.item?.cost_price : '',
      added_to_inventory: false,
      inventory_status: 'quarantine',
      received_date: '',
      stock_in_quarantine: '',
      stock_on_hand: '',
    }

    itemPayload = { ...itemPayload, ...additional }
    console.log('itemPayload', itemPayload)
    return itemPayload
  }

  const getAddInventoryPayloadFormat = async (isOrderReceived: boolean, itemValues: any) => {
    if (bypass) {
      const format: any = {
        order_ID: '',
        order_status: '',
        supplier_id: itemValues?.supplier_id,
        added_by: 'manual',
        product_info: [],
        order_for: inventoryType,
      }
      return format
    }
    if (!isOrderReceived) {
      // None of the item has been received, construct the payload format
      const format: any = {
        order_ID: inventoryData?.purchasedOrder?.order_ID,
        order_status: inventoryData?.purchasedOrder?.order_status,
        supplier_id: inventoryData?.purchasedOrder?.supplier_id,
        added_by: 'by_order',
        order_for: inventoryData?.purchasedOrder?.order_for,
        product_info: [],
      }
      return format
    } else {
      console.log('called')
      // get the payload format from the received purchase order
      try {
        const orderId = inventoryData?.purchasedOrder?.order_ID
        const receivedOrder = await ReceivePurchaseOrderAPI.getReceiveOrderByPurchaseOrderId(orderId)
        console.log('receivedOrder', receivedOrder)
        return receivedOrder
      } catch (e) {
        console.log('error', e)
        message.error('Failed to get received order.')
      }

      return null
    }
  }

  const processFinishedPayload = (values: any) => {
    console.log('processFinishedPayload', values)
    const addedDate = new Date()
    const itemPayload = {
      ...values,
      product_type: inventoryType,
      manufacturing_date: convertDateAndTime(moment(values?.manufacturing_date), 'datetime'),
      // batch_number: values?.batch_number,
      supplier_lot_number: values?.supplier_lot_number,
      expiry_date: convertDateAndTime(moment(values?.expiry_date), 'datetime'),
      internal_lot_number: values?.internal_lot_numbers,
      added_date: convertDateAndTime(moment(addedDate), 'datetime'),
      inventory_status: 'quarantine',
      recorded_on: convertDateAndTime(moment(addedDate), 'datetime'),
      "stock_allocation": "",
      "stock_dispatched": "",
    }
    delete itemPayload.internal_lot_numbers

    return itemPayload
  }

  const addFinishedProduct = async (values: any) => {
    let payload: any = {};
    // construct payload for finished product
    const finishedPayload: any = processFinishedPayload(values)
    console.log('finishedPayload', finishedPayload)
    payload = { ...finishedPayload }

    console.log('final finished finalpayload===>', payload)

    // return;

    setIsGlobalLoading(true)
    FinishedProductAPI.createFinishedProduct(payload)
      .then(res => {
        if (res?.data?.statusCode === '401') {
          console.log('error==>', res, res?.data?.message)
          // throw error - duplicated internal lot number
          // the Error should have the same format as the error from the backend
          throw {
            response: {
              status: 401,
              data: 'Internal lot number already exists'
            }
          }
        }
        message.success('Product added successfully!')
        reload()
      }).catch(e => {
        console.log('error', e)
        if (e?.response?.status === 401 && e?.response?.data) {
          // checkBatchExists(e?.response?.data)
          message.error(`${capitaliseFirst(e?.response?.data)}`)
          return;
        }
        message.error('Failed to add to inventory.')
      }).finally(() => {
        setIsGlobalLoading(false)
      })
  }

  const addAcgProduct = async (values: any) => {
    console.log('addAcgProduct', values, acgProductTableData)

    // remove key from acgProductTableData
    const acgProductTableDataWithoutKey = acgProductTableData.map((item: any) => {
      const { key, ...rest } = item
      return {
        ...rest,
        inventory_status: 'quarantine',
      }
    });

    const hasError = acgProductTableDataWithoutKey.some((item: any) => {
      return !item.item_location || !item.location_id
    })
    if (hasError) {
      message.error({ content: 'Please select location and location ID for all items.', duration: 5 })
      return;
    }

    let payload: any = {};
    payload = {
      order_ID: values?.order_ID,
      order_details: acgProductTableDataWithoutKey,
      // inventory_status: 'no status',
    }

    console.log('final acg finalpayload===>', payload)

    setIsGlobalLoading(true)
    await FinishedProductAPI.createFinishedProduct(payload)
      .then(res => {
        message.success('Item added successfully!')
        reload()
      }).catch(e => {
        console.log('error', e)
        message.error('Failed to add item.')
      }).finally(() => {
        setIsGlobalLoading(false)
      })

  }


  const formOnFinish = async (values: any) => {
    console.log('values', values, transferLocation, bypass)
    // byPass && 'finishedProduct' (POST Call) finished-products API
    // byPass && 'byPass general' (POST Call) receive-purchase-order API

    // return

    if (transferLocation) {
      updateLocation(values)
      return;
    }

    if (bypass && isFinishedProduct) {
      addFinishedProduct(values)
      return;
    }

    if (bypass && isAcgProduct) {
      addAcgProduct(values)
      return;
    }


    // process payload for the current item
    const itemPayload = processItemPayload(values)
    console.log('payload===>', itemPayload)

    // return;

    // check whether any of the item from this order has already been received by checking order status
    let isOrderReceived = inventoryData?.purchasedOrder?.order_status !== ORDER_STATUS.sent

    console.log('isOrderReceived', isOrderReceived, inventoryData)

    // get full payload format
    const payload = await getAddInventoryPayloadFormat(isOrderReceived, values)
    if (!payload) {
      message.error('Failed to add to inventory.')
      return;
    }
    if (bypass) {
      delete itemPayload.supplier_id
      isOrderReceived = false
    }
    // push the new item to the product info array
    payload.product_info.push(itemPayload)

    console.log('final payload===>', payload, isOrderReceived)

    // add item to inventory (receive purchase order)
    setIsGlobalLoading(true)
    let promise = null
    if (isOrderReceived) {
      // put, received partially
      promise = ReceivePurchaseOrderAPI.updateOrder(payload)
    } else {
      // post, first time received
      promise = ReceivePurchaseOrderAPI.createItem(payload)
    }

    promise.then((res: any) => {
      // no need to check

      // if (res?.data?.statusCode === '401') {
      //   // throw error - duplicated batch number
      //   // the Error should have the same format as the error from the backend
      //   throw {
      //     response: {
      //       status: 401,
      //       data: [{
      //         batch_number: itemPayload.batch_number,
      //         item_name: itemPayload.item_name,
      //       }]
      //     }
      //   }

      // }

      message.success({ content: 'Product added successfully!', duration: 1 })
      reload(itemPayload)
      // closeForm() should run after reload()
      closeForm()
    }).catch((e: any) => {

      console.log('Failede, e', e)
      // no need to check

      // if (e?.response?.status === 401 && Array.isArray(e?.response?.data)) {
      //   // duplicated batch number
      //   const batchNumber = e?.response?.data[0]?.batch_number || ''
      //   const itemName = e?.response?.data[0]?.item_name || ''
      //   message.warning({
      //     content: `Supplier Lot Number "${batchNumber}" has already been assigned to product "${itemName}".`,
      //     duration: 3,
      //   })
      //   return;
      // }

      message.error('Failed to add to inventory.')
    }).finally(() => {
      setIsGlobalLoading(false)
    })
  }

  const closeForm = () => {
    onClose()
    form.resetFields()
    setSelectedLocation('')
    setInventoryType(PRODUCT_TYPE.generalProduct.value)
  }

  const renderFooterBtns = () => (
    <FlexWrapper flexEnd key='0' gap='10px'>
      <SimpleButton
        text='Cancel'
        id='modal-btn-width-regular'
        onClick={closeForm}
        isCancel
      />
      {
        !viewOnly &&
        <SimpleButton
          text={transferLocation ? 'Save' : viewOnly ? 'View Only' : 'Add'}
          form={formName || 'item-details-form'}
          id='modal-btn-width-regular'
          htmlType="submit"
          disabled={viewOnly}
          isCancel={viewOnly}
        />
      }

    </FlexWrapper>
  )

  const modalTitle = useMemo(() => {
    const prefix = 'Add to Inventory - '
    if (transferLocation) {
      return 'Transfer Location - ' + inventoryData?.item?.internal_lot_number || ''
    }
    if (viewOnly) {
      return prefix + 'View Product Details'
    }
    if (bypass) {
      if (isFinishedProduct) {
        return prefix + 'Finished Product'
      } else if (isAcgProduct) {
        return prefix + 'ACG Product'
      } else {
        return prefix + 'Manual Entry'
      }
    }
    return prefix + inventoryData?.purchasedOrder?.order_ID || ''
  }, [inventoryData, transferLocation, bypass, isFinishedProduct, isAcgProduct])



  // manage fields change when switching between finished product and manual entry
  useEffect(() => {

    form.setFieldsValue({
      // receipt_ID: undefined,
      recorded_on: new Date(),
      supplier_id: undefined,
      item_category: undefined,
      item_sub_category: undefined,
      item_name: undefined,
      sku: undefined,
      supplier_lot_number: undefined,
      // internal_lot_number: undefined,
      // internal_lot_numbers: undefined,
      quantity_received: '',
      measurement_unit: undefined,
      manufacturing_date: undefined,
      expiry_date: undefined,
      item_location: undefined,
      location_id: undefined,
      order_ID: undefined,
    })

    setAcgProductTableData([]); // reset acg product table data

    getProductCategoryOptions(inventoryType)

    // commented out because internal lot should be auto populated for all products

    // if (!isFinishedProduct || inventoryType === PRODUCT_TYPE.xplProduct.value) {
    //   // get internal lot number
    //   ReceivePurchaseOrderAPI.getInternalLotNumber()
    //     .then(number => {
    //       form.setFieldsValue({ internal_lot_number: number, internal_lot_numbers: number })
    //     }).catch(e => {
    //       console.log('error', e)
    //       message.error('Failed to get internal lot number.')
    //     })
    // }
  }, [isFinishedProduct, inventoryType, productList])

  useEffect(() => {
    // call API to get the list of order numbers
    const status = 'open'
    const order_for = 'acg_product'

    OrderAPI.getOrdersByOrderStatus(status, order_for).then((res: any) => {
      console.log('acg orders', res)
      // filter the data that has order_status = picked
      res = res?.filter((item: any) => item?.order_status === 'picked')

      const options = res?.map((item: any) => {
        return {
          label: item.order_ID,
          value: item.order_ID,
          data: item,
        }
      })
      console.log('acg orderroptions', options)
      setOrderNumberOptions(options)
    }).catch((e: any) => {
      console.log('error', e)
      message.error('Failed to get order numbers.')
    })

  }, [isAcgProduct])

  const disabledDateFunction = (current: any) => {
    return current && current < new Date().setHours(0, 0, 0, 0)
  };

  const handleSelectCategory = (value: any) => {
    // get subcategories from product list
    const products = getProductsByType(inventoryType)


    let subcategories = products
      .filter((item: any) => item.item_category === value)
      .map((item: any) => item.item_sub_category)

    // make subcategories unique
    subcategories = Array.from(new Set(subcategories))


    // convert data to options
    const options: any = subcategories.map((item: any) => {
      return {
        label: item,
        value: item
      }
    })
    setSubCategoryOptions(options)
    // empty name values
    setProductNameOptions([])
    form.setFieldsValue({
      item_name: undefined,
      sku: undefined,
      item_sub_category: undefined,
      measurement_unit: undefined
    })

  }

  const handleSelectSubCategory = (value: any) => {
    const category = form.getFieldValue('item_category')
    // get products from product list
    const products = getProductsByType(inventoryType).filter((item: any) => item.item_category === category && item.item_sub_category === value)


    // convert data to options
    const options: any = Array.isArray(products) && products.map((item: any) => {
      return {
        label: item.item_name,
        value: item.item_name
      }
    })
    setProductNameOptions(options)
    // empty product values
    form.setFieldsValue({ item_name: undefined, sku: undefined, measurement_unit: undefined })
  }

  const handleSelectProductName = (name: any) => {
    let product: any = {
      sku: '',
      measurement_unit: ''
    }

    product = getProductsByType(inventoryType).find((item: any) => item.item_name === name)

    // set sku and unit
    form.setFieldsValue({
      sku: product?.sku,
      measurement_unit: product?.measurement_unit,
    })

  }

  const handleSelectOrderID = (value: any) => {
    console.log('order id==>', value)
    if (value) {
      // call API to get the product details
      setIsGlobalLoading(true)
      GeneratePicklistAPI.getAcgProductFromPicklistByOrderId(value).then((res: any) => {
        console.log('acg product', res)
        if (!res) {
          message.error('No product details found for this order.')
          return
        }

        // create newRes 
        const newRes: any = []
        res.forEach((item: any, index: number) => {
          return newRes.push({
            key: index + 1,
            batch_number: item.batch_number,
            expiry_date: item.expiry_date,
            item_category: item.item_category,
            item_name: item.item_name,
            item_sub_category: item.item_sub_category,
            manufacturing_date: item.manufacturing_date,
            measurement_unit: item.measurement_unit,
            picklist_ID: item.picklist_ID,
            processing_line: item.processing_line,
            quantity_ordered: item.quantity_ordered,
            sku: item.sku,
          })
        })

        // set the data
        setAcgProductTableData(newRes)
      }).catch((e: any) => {
        console.log('error', e)
        message.error('Failed to get product details.')
      }).finally(() => {
        setIsGlobalLoading(false)
      })

    }
  }

  const acgProductColumns = [
    {
      title: formatTitle('S. No.'),
      dataIndex: 'key',
      key: 'key',
      ...TABLE_CELL_CONFIG,
      width: 100,
      render: initialCellValue,
    },
    {
      title: formatTitle('Product Name'),
      dataIndex: 'item_name',
      key: 'item_name',
      ...TABLE_CELL_CONFIG,
      width: 150,
      render: initialCellValue,
    },
    {
      title: formatTitle('Inventory ID'),
      dataIndex: 'sku',
      key: 'sku',
      ...TABLE_CELL_CONFIG,
      width: 150,
      render: initialCellValue,
    },
    {
      title: formatTitle('Quantity'),
      dataIndex: 'quantity_ordered',
      key: 'quantity_ordered',
      ...TABLE_CELL_CONFIG,
      width: 100,
      render: initialCellValue,
    },
    {
      title: formatTitle('UoM'),
      dataIndex: 'measurement_unit',
      key: 'measurement_unit',
      ...TABLE_CELL_CONFIG,
      width: 100,
      render: initialCellValue,
    },
    {
      title: formatTitle('MFD'),
      dataIndex: 'manufacturing_date',
      key: 'manufacturing_date',
      ...TABLE_CELL_CONFIG,
      width: 150,
      render: (text: string) => text ? moment(text).format('DD/MM/YYYY') : initialCellValue(text),
    },
    {
      title: formatTitle('EXD'),
      dataIndex: 'expiry_date',
      key: 'expiry_date',
      ...TABLE_CELL_CONFIG,
      width: 150,
      render: (text: string) => text ? moment(text).format('DD/MM/YYYY') : initialCellValue(text),
    },
    {
      title: formatTitle('Batch No.'),
      dataIndex: 'batch_number',
      key: 'batch_number',
      ...TABLE_CELL_CONFIG,
      width: 150,
      render: initialCellValue,
    },
    {
      title: formatTitle('Processing Line'),
      dataIndex: 'processing_line',
      key: 'processing_line',
      ...TABLE_CELL_CONFIG,
      width: 150,
      render: initialCellValue,
    },
    {
      title: formatTitle('Location'),
      dataIndex: 'item_location',
      key: 'item_location',
      ...TABLE_CELL_CONFIG,
      width: 200,
      render: (_text: any, _record: any, index: any) => {
        return (
          <div>
            <Input
              placeholder="Location"
              type='select'
              isGetPopupContainer={false}
              // options={renderOptions('individualAction', index)}
              options={getLocationOptions()}
              onChange={(value: any) => {
                console.log('value==>', value)
                /// nned to add the item_location in the acgProductTableData on the basis of index
                const newAcgProductTableData = [...acgProductTableData]
                newAcgProductTableData[index].item_location = value
                setAcgProductTableData(newAcgProductTableData)
              }}
            />
          </div>
        )
      }
    },
    {
      title: formatTitle('Location ID'),
      dataIndex: 'location_id',
      key: 'location_id',
      ...TABLE_CELL_CONFIG,
      width: 200,
      render: (text: any, record: any, index: any) => {
        return (
          <div>
            <Input
              placeholder="Location ID"
              type='select'
              isGetPopupContainer={false}
              // options={renderOptions('individualAction', index)}
              options={getLocationIdOptions(record?.item_location)}
              onChange={(value: any) => {
                console.log('value', value)
                // nned to add the location_id in the acgProductTableData on the basis of index
                const newAcgProductTableData = [...acgProductTableData]
                newAcgProductTableData[index].location_id = value
                setAcgProductTableData(newAcgProductTableData)
              }}
            />
          </div>
        )
      },
    },
  ]

  return (
    <GlobalDrawer
      open={open}
      width={800}
      onClose={closeForm}
      title={modalTitle}
      footer={renderFooterBtns()}
    >
      <Form
        form={form}
        name={formName || 'item-details-form'}
        onFinish={formOnFinish}
      // initialValues={{ measurement_unit: 'kg' }}
      >
        {
          bypass ?
            <>
              {/* ByPass - ACG, 3PL, and E-Commerce */}
              <ModalLayout>

                <ModalLayout.ModalSection title="Inventory Type">
                  <Radio.Group onChange={(e: any) => setInventoryType(e.target.value)} value={inventoryType} disabled={viewOnly} style={{
                    marginLeft: '10px'
                  }}>
                    <Space direction="vertical">
                      <Row gutter={[15, 0]}>
                        <Radio value={PRODUCT_TYPE.generalProduct.value}>{PRODUCT_TYPE.generalProduct.label}</Radio>
                        <Radio value={PRODUCT_TYPE.acgProduct.value}>{PRODUCT_TYPE.acgProduct.label}</Radio>
                      </Row>
                      <Row gutter={[15, 0]}>
                        <Radio value={PRODUCT_TYPE.xplProduct.value}>{PRODUCT_TYPE.xplProduct.label}</Radio>
                        <Radio value={PRODUCT_TYPE.eCommerceProduct.value}>{PRODUCT_TYPE.eCommerceProduct.label}</Radio>
                      </Row>
                    </Space>
                  </Radio.Group>
                </ModalLayout.ModalSection>

              </ModalLayout>
              {
                (bypass && !isFinishedProduct && !isAcgProduct) &&
                <>
                  {/* General Product*/}
                  <ModalLayout>

                    <ModalLayout.ModalSection title="Log Details">
                      <Row gutter={[15, 0]}>
                        <>
                          {/* <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                            <Form.Item
                              name='receipt_ID'
                              rules={[{ required: true, message: 'Please enter Goods Receipt No.' }]}
                            >
                              <Input
                                label="Goods Receipt No."
                                type="text"
                                placeholder="Goods Receipt No."
                              // disabled={viewOnly || transferLocation}
                              />
                            </Form.Item>
                          </Col> */}
                          <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                            <Form.Item
                              name='gr_created_date'
                              rules={[{ required: true, message: 'Please enter Order Received Date.' }]}
                            >
                              <Input
                                label="Received On"
                                type="date"
                                placeholder="Received On"
                                required
                                disabledDate={(current: any) => {
                                  // disable future date by default
                                  return current && current > moment().endOf('day');
                                }}
                              // disabled={viewOnly || transferLocation}
                              />
                            </Form.Item>
                          </Col>
                        </>
                        <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                          <Form.Item
                            name='recorded_by'
                          >
                            <Input
                              label="Recorded By"
                              type="text"
                              placeholder="Recorded By"
                              disabled
                            />
                          </Form.Item>
                        </Col>
                        <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                          <Form.Item
                            name='recorded_on'
                          >
                            <Input
                              label="Recorded On"
                              type="date"
                              placeholder="Recorded On"
                              disabled
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </ModalLayout.ModalSection>

                  </ModalLayout>
                  <ModalLayout>

                    <ModalLayout.ModalSection title="Supplier Details">
                      <Row gutter={[15, 15]}>
                        <Col xs={{ span: '24' }} md={{ span: '12' }}>
                          <Form.Item
                            name='supplier_id'
                            rules={[{ required: true, message: 'Please select Supplier.' }]}
                          >
                            <Input
                              label="Supplier"
                              type="select"
                              options={supplierOptions}
                              placeholder='Select Supplier'
                              required
                              showSearch
                            />
                          </Form.Item>
                        </Col>
                        {/* TODO: As per new design need to display the supplier id in the form */}
                        <Col xs={{ span: '24' }} md={{ span: '12' }}>
                          <Form.Item
                            name='supplier_id'
                          // rules={[{ required: true, message: 'Please select Supplier ID.' }]}
                          >
                            <Input
                              label="Supplier ID"
                              type="text"
                              placeholder='Supplier ID (Auto Populated)'
                              // required
                              disabled
                              showSearch
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </ModalLayout.ModalSection>

                  </ModalLayout>
                  <ModalLayout>

                    <ModalLayout.ModalSection title="Product Details">
                      <Row gutter={[15, 0]}>
                        <Col xs={{ span: '24' }} md={{ span: '12' }}>
                          <Form.Item
                            name='item_category'
                            rules={[{ required: true, message: `Please select an Inventory Category.` }]}
                            getValueFromEvent={(e) => {
                              const value = e
                              handleSelectCategory(value)
                              return e
                            }}
                          >
                            <Input
                              label={`Inventory Category`}
                              placeholder={`Select an Inventory Category`}
                              type="select"
                              options={categoryOptions}
                              disabled={!bypass}
                              required
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: '24' }} md={{ span: '12' }}>
                          <Form.Item
                            name='item_sub_category'
                            rules={[{ required: true, message: `Please select an Inventory Subcategory.` }]}
                            getValueFromEvent={(e) => {
                              const value = e
                              // const category = form.getFieldValue('item_category')
                              // getProductNameOptions(category, e)
                              // // empty name value
                              // setProductNameOptions([])
                              // form.setFieldsValue({ item_name: undefined, sku: undefined })
                              handleSelectSubCategory(value)
                              return e
                            }}
                          >
                            <Input
                              label={`Inventory Subcategory`}
                              placeholder={`Select an Inventory Subcategory`}
                              type="select"
                              options={subCategoryOptions}
                              disabled={!bypass}
                              required
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: '24' }} md={{ span: '12' }}>
                          <Form.Item
                            name='item_name'
                            rules={[{ required: true, message: 'Please select Product Name.' }]}
                            getValueFromEvent={e => {
                              const value = e
                              handleSelectProductName(value)
                              return e
                            }}
                          >
                            <Input
                              label="Product Name"
                              placeholder='Product Name'
                              type="select"
                              options={productNameOptions}
                              disabled={!bypass}
                              required
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: '24' }} md={{ span: '12' }}>
                          <Form.Item
                            name='sku'
                            rules={[{ required: true, message: 'Please enter Inventory ID.' }]}
                          >
                            <Input
                              label="Inventory ID"
                              placeholder="Inventory ID"
                              type="text"
                              disabled
                              required
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </ModalLayout.ModalSection>

                  </ModalLayout>
                  <ModalLayout>

                    <ModalLayout.ModalSection title="Inventory Details">
                      <Row gutter={[15, 0]}>
                        <Col xs={{ span: '24' }} md={{ span: '12' }}>
                          <Form.Item
                            name='supplier_lot_number'
                            rules={[{ required: true, message: 'Please enter Supplier Lot Number.' }]}
                          >
                            <Input
                              label="Supplier Lot Number"
                              type="text"
                              placeholder='Enter Supplier Lot Number'
                              disabled={viewOnly || transferLocation}
                              required
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: '24' }} md={{ span: '12' }}>
                          <Form.Item
                            name='internal_lot_number'
                            rules={[{ required: true, message: 'Missing Internal Lot Number.' }]}
                          >
                            <Input
                              label="Internal Lot Number"
                              type="text"
                              placeholder='Internal Lot Number'
                              disabled
                              required
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: '24' }} md={{ span: '12' }}>
                          <Form.Item
                            name='quantity_received'
                            rules={[
                              { required: true, message: 'Please enter Quantity Received.' },
                            ]}
                          >
                            <Input
                              label="Quantity Received"
                              type="number"
                              placeholder='Enter Quantity Received'
                              disabled={viewOnly || transferLocation}
                              isGreaterthanOneRequired
                              required
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={{ span: '24' }} md={{ span: '12' }}>
                          <Form.Item
                            name='measurement_unit'
                            rules={[{ required: true, message: 'Please select Unit of Measure.' }]}
                          >
                            <Input
                              label="Unit of Measure"
                              placeholder="Select Unit of Measure"
                              type="text"
                              disabled
                              required
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: '24' }} md={{ span: '12' }}>
                          <Form.Item
                            name='manufacturing_date'
                            rules={[{ required: true, message: 'Please select a Manufacturing Date.' }]}
                          >
                            <Input
                              label="Manufacture Date"
                              type="date"
                              placeholder='Manufacture Date'
                              disabled={viewOnly || transferLocation}
                              required
                              disabledDate={(current: any) => {
                                // disable future date by default
                                return current && current > moment().endOf('day');
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: '24' }} md={{ span: '12' }}>
                          <Form.Item
                            name='expiry_date'
                            rules={[{ required: true, message: 'Please select an Expiry Date.' }]}
                          >
                            <Input
                              label="Expiry Date"
                              type="date"
                              placeholder='Expiry Date'
                              disabled={viewOnly || transferLocation}
                              disabledDate={disabledDateFunction}
                              required
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: '24' }} md={{ span: '12' }}>
                          <Form.Item
                            name={transferLocation ? 'cur_location' : 'item_location'}
                            rules={[{ required: true, message: 'Please select a Location.' }]}
                            getValueFromEvent={value => {
                              setSelectedLocation(value)
                              const idFormName = transferLocation ? 'cur_id' : 'location_id'
                              form.setFieldsValue({ [idFormName]: undefined })
                              return value
                            }}
                          >
                            <Input
                              label={transferLocation ? 'Current Location' : 'Location'}
                              type="select"
                              options={getLocationOptions()}
                              placeholder='Select Tag Location'
                              // onChange={(value: ItemLocation) => setSelectedLocation(value)}
                              disabled={viewOnly || transferLocation}
                              required
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: '24' }} md={{ span: '12' }}>
                          <Form.Item
                            name={transferLocation ? 'cur_id' : `location_id`}
                            rules={[{ required: true, message: `Please select a Location ID.` }]}
                          >
                            <Input
                              label={transferLocation ? 'Current Location ID' : 'Location ID'}
                              type="select"
                              options={transferLocation ? getLocationIdOptions(form.getFieldValue('cur_location')) : getLocationIdOptions(selectedLocation)}
                              placeholder={`Select a Location ID`}
                              disabled={viewOnly || transferLocation}
                              showSearch
                              required
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </ModalLayout.ModalSection>

                  </ModalLayout>
                </>
              }
              {
                (bypass && isFinishedProduct && !isAcgProduct) &&
                <>
                  {/* 3PL Product and E- commerce*/}
                  <ModalLayout>

                    <ModalLayout.ModalSection title="Supplier Details">
                      <Row gutter={[15, 15]}>
                        <Col xs={{ span: '24' }} md={{ span: '12' }}>
                          <Form.Item
                            name='supplier_id'
                            rules={[{ required: true, message: 'Please select Supplier.' }]}
                          >
                            <Input
                              label="Supplier"
                              type="select"
                              options={supplierOptions}
                              placeholder='Select Supplier'
                              required
                              showSearch
                            />
                          </Form.Item>
                        </Col>
                        {/* TODO: As per new design need to display the supplier id in the form */}
                        <Col xs={{ span: '24' }} md={{ span: '12' }}>
                          <Form.Item
                            name='supplier_id'
                          // rules={[{ required: true, message: 'Please select Supplier.' }]}
                          >
                            <Input
                              label="Supplier ID"
                              type="text"
                              placeholder='Supplier ID (Auto Populated)'
                              // required
                              disabled
                              showSearch
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </ModalLayout.ModalSection>

                  </ModalLayout>
                  <ModalLayout>

                    <ModalLayout.ModalSection title="Inventory Details">
                      <Row gutter={[15, 0]}>
                        <Col xs={{ span: '24' }} md={{ span: '12' }}>
                          <Form.Item
                            name='item_category'
                            rules={[{ required: true, message: `Please select a Product Category.` }]}
                            getValueFromEvent={(e) => {
                              const value = e
                              handleSelectCategory(value)
                              return e
                            }}
                          >
                            <Input
                              label={`Product Category`}
                              placeholder={`Select a Product Category`}
                              type="select"
                              options={categoryOptions}
                              disabled={!bypass}
                              required
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: '24' }} md={{ span: '12' }}>
                          <Form.Item
                            name='item_sub_category'
                            rules={[{ required: true, message: `Please select a Product Subcategory.` }]}
                            getValueFromEvent={(e) => {
                              const value = e
                              handleSelectSubCategory(value)
                              return e
                            }}
                          >
                            <Input
                              label={`Product Subcategory`}
                              placeholder={`Select a Product Subcategory`}
                              type="select"
                              options={subCategoryOptions}
                              disabled={!bypass}
                              required
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: '24' }} md={{ span: '12' }}>
                          <Form.Item
                            name='item_name'
                            rules={[{ required: true, message: 'Please select Product Name.' }]}
                            getValueFromEvent={e => {
                              const value = e
                              handleSelectProductName(value)
                              return e
                            }}
                          >
                            <Input
                              label="Product Name"
                              placeholder='Product Name'
                              type="select"
                              options={productNameOptions}
                              disabled={!bypass}
                              required
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: '24' }} md={{ span: '12' }}>
                          <Form.Item
                            name='sku'
                            rules={[{ required: true, message: 'Please enter Inventory ID.' }]}
                          >
                            <Input
                              label="Inventory ID"
                              placeholder="Inventory ID"
                              type="text"
                              disabled
                              required
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={{ span: '24' }} md={{ span: '12' }}>
                          <Form.Item
                            name='quantity_received'
                            rules={[
                              { required: true, message: 'Please enter Quantity Received.' },
                            ]}
                          >
                            <Input
                              label="Quantity Received"
                              type="number"
                              placeholder='Enter Quantity Received'
                              disabled={viewOnly || transferLocation}
                              isGreaterthanOneRequired
                              required
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={{ span: '24' }} md={{ span: '12' }}>
                          <Form.Item
                            name='measurement_unit'
                            rules={[{ required: true, message: 'Please select Unit of Measure.' }]}
                          >
                            <Input
                              label="Unit of Measure"
                              placeholder="Select Unit of Measure"
                              type="text"
                              disabled
                              required
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: '24' }} md={{ span: '12' }}>
                          <Form.Item
                            name={transferLocation ? 'cur_location' : 'item_location'}
                            rules={[{ required: true, message: 'Please select a Location.' }]}
                            getValueFromEvent={value => {
                              setSelectedLocation(value)
                              const idFormName = transferLocation ? 'cur_id' : 'location_id'
                              form.setFieldsValue({ [idFormName]: undefined })
                              return value
                            }}
                          >
                            <Input
                              label={transferLocation ? 'Current Location' : 'Location'}
                              type="select"
                              options={getLocationOptions()}
                              placeholder='Select Tag Location'
                              // onChange={(value: ItemLocation) => setSelectedLocation(value)}
                              disabled={viewOnly || transferLocation}
                              required
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: '24' }} md={{ span: '12' }}>
                          <Form.Item
                            name={transferLocation ? 'cur_id' : `location_id`}
                            rules={[{ required: true, message: `Please select a Location ID.` }]}
                          >
                            <Input
                              label={transferLocation ? 'Current Location ID' : 'Location ID'}
                              type="select"
                              options={transferLocation ? getLocationIdOptions(form.getFieldValue('cur_location')) : getLocationIdOptions(selectedLocation)}
                              placeholder={`Select a Location ID`}
                              disabled={viewOnly || transferLocation}
                              showSearch
                              required
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: '24' }} md={{ span: '12' }}>
                          <Form.Item
                            name='supplier_lot_number'
                            rules={[{ required: true, message: 'Please enter Supplier Lot Number.' }]}
                          >
                            <Input
                              label="Supplier Lot Number"
                              type="text"
                              placeholder='Enter Supplier Lot Number'
                              disabled={viewOnly || transferLocation}
                              required
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: '24' }} md={{ span: '12' }}>
                          <Form.Item
                            name='internal_lot_numbers'
                          // rules={[{ required: true, message: 'Missing Internal Lot Number.' }]}
                          >
                            <Input
                              label="Internal Lot Number"
                              type="text"
                              placeholder='Internal Lot Number'
                              required
                              disabled
                            />
                          </Form.Item>
                          {/* old code, once internal lot number was manual input for e-comm */}
                          {/* <Form.Item
                            name='internal_lot_numbers'
                          // rules={[{ required: true, message: 'Missing Internal Lot Number.' }]}
                          >
                            <Input
                              label="Internal Lot Number"
                              type="text"
                              placeholder='Internal Lot Number'
                              required
                              disabled={inventoryType != PRODUCT_TYPE.eCommerceProduct.value} // disabled for 3pl only
                            />
                          </Form.Item> */}
                        </Col>
                        <Col xs={{ span: '24' }} md={{ span: '12' }}>
                          <Form.Item
                            name='manufacturing_date'
                            rules={[{ required: true, message: 'Please select a Manufacturing Date.' }]}
                          >
                            <Input
                              label="Manufacture Date"
                              type="date"
                              placeholder='Manufacture Date'
                              disabled={viewOnly || transferLocation}
                              required
                              disabledDate={(current: any) => {
                                // disable future date by default
                                return current && current > moment().endOf('day');
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: '24' }} md={{ span: '12' }}>
                          <Form.Item
                            name='expiry_date'
                            rules={[{ required: true, message: 'Please select an Expiry Date.' }]}
                          >
                            <Input
                              label="Expiry Date"
                              type="date"
                              placeholder='Expiry Date'
                              disabled={viewOnly || transferLocation}
                              disabledDate={disabledDateFunction}
                              required
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </ModalLayout.ModalSection>

                  </ModalLayout>
                </>
              }
              {
                (bypass && isAcgProduct) &&
                <>
                  <ModalLayout>

                    <ModalLayout.ModalSection title="Order No">
                      <Row gutter={[15, 15]}>
                        <Col xs={{ span: '24' }} md={{ span: '12' }}>
                          <Form.Item
                            name='order_ID'
                            rules={[{ required: true, message: 'Please select Order No.' }]}
                            getValueFromEvent={e => {
                              const value = e
                              handleSelectOrderID(value)
                              return e
                            }}
                          >
                            <Input
                              label="Order No."
                              type="select"
                              options={orderNumberOptions}
                              placeholder='Select Order No'
                              required
                            // showSearch
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </ModalLayout.ModalSection>

                  </ModalLayout>
                  <ModalLayout>

                    <ModalLayout.ModalSection title="Product Details">
                      <Row gutter={[15, 15]}>
                        <AdiTable
                          columns={acgProductColumns}
                          tableData={acgProductTableData}
                          noPagination
                        />
                      </Row>
                    </ModalLayout.ModalSection>

                  </ModalLayout>
                </>
              }
            </>
            :
            <>
              <ModalLayout>

                <ModalLayout.ModalSection title="Log Details">
                  <Row gutter={[15, 0]}>
                    <>
                      {
                        transferLocation ?
                          <>
                            {/* check gr_created_date existing or not */}
                            {
                              form.getFieldValue('gr_created_date') &&
                              <>
                                {/* <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                                  <Form.Item
                                    name='receipt_ID'
                                    rules={[{ required: true, message: 'Please enter Goods Receipt No.' }]}
                                  >
                                    <Input
                                      label="Goods Receipt No."
                                      type="text"
                                      placeholder="Goods Receipt No."
                                      disabled={viewOnly || transferLocation}
                                    />
                                  </Form.Item>
                                </Col> */}
                                <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                                  <Form.Item
                                    name='gr_created_date'
                                    rules={[{ required: true, message: 'Please enter Order Received Date.' }]}
                                  >
                                    <Input
                                      label="Received On"
                                      type="date"
                                      placeholder="Received On"
                                      required
                                      disabledDate={(current: any) => {
                                        // disable future date by default
                                        return current && current > moment().endOf('day');
                                      }}
                                      disabled={viewOnly || transferLocation}
                                    />
                                  </Form.Item>
                                </Col>
                              </>
                            }
                          </>
                          :
                          <>
                            {/* <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                              <Form.Item
                                name='receipt_ID'
                                rules={[{ required: true, message: 'Please enter Goods Receipt No.' }]}
                              >
                                <Input
                                  label="Goods Receipt No."
                                  type="text"
                                  placeholder="Goods Receipt No."
                                  disabled={viewOnly || transferLocation}
                                />
                              </Form.Item>
                            </Col> */}
                            <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                              <Form.Item
                                name='gr_created_date'
                                rules={[{ required: true, message: 'Please enter Order Received Date.' }]}
                              >
                                <Input
                                  label="Received On"
                                  type="date"
                                  placeholder="Received On"
                                  required
                                  disabledDate={(current: any) => {
                                    // disable future date by default
                                    return current && current > moment().endOf('day');
                                  }}
                                  disabled={viewOnly || transferLocation}
                                />
                              </Form.Item>
                            </Col>
                          </>
                      }
                    </>
                    <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                      <Form.Item
                        name='recorded_by'
                      >
                        <Input
                          label="Recorded By"
                          type="text"
                          placeholder="Recorded By"
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                      <Form.Item
                        name='recorded_on'
                      >
                        <Input
                          label="Recorded On"
                          type="date"
                          placeholder="Recorded On"
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </ModalLayout.ModalSection>

              </ModalLayout>
              <ModalLayout>

                <ModalLayout.ModalSection title="Supplier Details">
                  <Row gutter={[15, 0]}>
                    <Col xs={{ span: '24' }} md={{ span: '12' }}>
                      <Input
                        label="Supplier"
                        placeholder="Supplier"
                        type="select"
                        options={supplierOptions}
                        value={supplierData.supplierName}
                        disabled
                      />
                    </Col>
                    <Col xs={{ span: '24' }} md={{ span: '12' }}>
                      <Input
                        label="Supplier ID"
                        placeholder="Supplier ID"
                        type="text"
                        value={supplierData.supplierId}
                        disabled
                      />
                    </Col>
                  </Row>
                </ModalLayout.ModalSection>

              </ModalLayout>
              <ModalLayout>

                <ModalLayout.ModalSection title="Product Details">
                  <Row gutter={[15, 0]}>
                    <Col xs={{ span: '24' }} md={{ span: '12' }}>
                      <Form.Item
                        name='item_category'
                        rules={[{ required: true, message: 'Please select Inventory Category.' }]}
                      >
                        <Input
                          label="Inventory Category"
                          placeholder="Select Inventory Category"
                          type="select"
                          options={categoryOptions}
                          disabled={!bypass}
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: '24' }} md={{ span: '12' }}>
                      <Form.Item
                        name='item_sub_category'
                        rules={[{ required: true, message: 'Please select an Inventory Subcategory.' }]}
                      >
                        <Input
                          label="Inventory Subcategory"
                          placeholder="Select an Inventory Subcategory"
                          type="select"
                          options={subCategoryOptions}
                          disabled={!bypass}
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: '24' }} md={{ span: '12' }}>
                      <Form.Item
                        name='item_name'
                        rules={[{ required: true, message: 'Please select Product Name.' }]}
                      >
                        <Input
                          label="Product Name"
                          placeholder='Select Product Name'
                          type="select"
                          options={productNameOptions}
                          disabled={!bypass}
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: '24' }} md={{ span: '12' }}>
                      <Form.Item
                        name='sku'
                        rules={[{ required: true, message: 'Please enter Inventory ID.' }]}
                      >
                        <Input
                          label="Inventory ID"
                          placeholder="Enter Inventory ID"
                          type="text"
                          disabled
                          required
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </ModalLayout.ModalSection>

              </ModalLayout>
              <ModalLayout>

                <ModalLayout.ModalSection title="Inventory Details">
                  <Row gutter={[15, 0]}>
                    <Col xs={{ span: '24' }} md={{ span: '12' }}>
                      <Form.Item
                        name='supplier_lot_number'
                        rules={[{ required: true, message: 'Please enter Supplier Lot Number.' }]}
                      >
                        <Input
                          label="Supplier Lot Number"
                          type="text"
                          placeholder='Enter Supplier Lot Number'
                          disabled={viewOnly || transferLocation}
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: '24' }} md={{ span: '12' }}>
                      <Form.Item
                        name='internal_lot_number'
                        rules={[{ required: true, message: 'Missing Internal Lot Number.' }]}
                      >
                        <Input
                          label="Internal Lot Number"
                          type="text"
                          placeholder='Internal Lot Number'
                          disabled
                          required
                        />
                      </Form.Item>
                    </Col>
                    {
                      transferLocation ?
                        <>
                          <Col xs={{ span: '24' }} md={{ span: '12' }}>
                            <Form.Item
                              name='quantity_received'
                              rules={[
                                { required: true, message: 'Please enter Quantity Received.' },

                              ]}
                            >
                              <Input
                                label="Quantity Received"
                                type="number"
                                placeholder='Enter Quantity Received'
                                disabled={viewOnly || transferLocation}
                                isGreaterthanOneRequired
                                required
                              />
                            </Form.Item>
                          </Col>
                        </>
                        :
                        <>
                          <Col xs={{ span: '24' }} md={{ span: '12' }}>
                            <Form.Item
                              name='quantity_received'
                              rules={[
                                { required: true, message: 'Please enter Quantity Received.' },
                                {
                                  validator: (rule: any, value: any) => {
                                    const qtyInput = Number(value)
                                    const qtyReceived = Number(inventoryData?.item?.quantity_received) || 0
                                    const qtyTotal = Number(inventoryData?.item?.quantity) || 0
                                    const qtyMax = qtyTotal - qtyReceived
                                    if (qtyInput <= 0) {
                                      return Promise.reject('Quantity received must be greater than 0.')
                                    } else if (qtyInput > qtyMax) {
                                      return Promise.reject(`Quantity received must be less than or equal to ${qtyMax}.`)
                                    }
                                    return Promise.resolve()
                                  }
                                }

                              ]}
                            >
                              <Input
                                label="Quantity Received"
                                type="number"
                                placeholder='Enter Quantity Received'
                                disabled={viewOnly || transferLocation}
                                required
                              />
                            </Form.Item>
                          </Col>
                        </>
                    }
                    <Col xs={{ span: '24' }} md={{ span: '12' }}>
                      <Form.Item
                        name='measurement_unit'
                        rules={[{ required: true, message: 'Please select Unit of Measure.' }]}
                      >
                        <Input
                          label="Unit of Measure"
                          placeholder="Select Unit of Measure"
                          type="text"
                          disabled
                          required
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={{ span: '24' }} md={{ span: '12' }}>
                      <Form.Item
                        name='manufacturing_date'
                        rules={[{ required: true, message: 'Please select Date of Manufacturing.' }]}
                      >
                        <Input
                          label="Date of Manufacture"
                          type="date"
                          placeholder='Enter Date of Manufacture'
                          disabled={viewOnly || transferLocation}
                          required
                          disabledDate={(current: any) => {
                            // disable future date by default
                            return current && current > moment().endOf('day');
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: '24' }} md={{ span: '12' }}>
                      <Form.Item
                        name='expiry_date'
                        rules={[{ required: true, message: 'Please select Date of Expiry.' }]}
                      >
                        <Input
                          label="Date of Expiry"
                          type="date"
                          placeholder='Enter Date of Expiry'
                          disabled={viewOnly || transferLocation}
                          disabledDate={disabledDateFunction}
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: '24' }} md={{ span: '12' }}>
                      <Form.Item
                        name={transferLocation ? 'cur_location' : 'item_location'}
                        rules={[{ required: true, message: 'Please select a Location.' }]}
                        getValueFromEvent={value => {
                          setSelectedLocation(value)
                          const idFormName = transferLocation ? 'cur_id' : 'location_id'
                          form.setFieldsValue({ [idFormName]: undefined })
                          return value
                        }}
                      >
                        <Input
                          label="Location"
                          type="select"
                          options={getLocationOptions()}
                          placeholder='Select Tag Location'
                          // onChange={(value: ItemLocation) => setSelectedLocation(value)}
                          disabled={viewOnly || transferLocation}
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: '24' }} md={{ span: '12' }}>
                      <Form.Item
                        name={transferLocation ? 'cur_id' : `location_id`}
                        rules={[{ required: true, message: `Please select a Location ID.` }]}
                      >
                        <Input
                          label={`Location ID`}
                          type="select"
                          options={transferLocation ? getLocationIdOptions(form.getFieldValue('cur_location')) : getLocationIdOptions(selectedLocation)}
                          placeholder={`Select a Location ID`}
                          disabled={viewOnly || transferLocation}
                          showSearch
                          required
                        />
                      </Form.Item>
                    </Col>
                    {
                      transferLocation &&
                      <>
                        <Col xs={{ span: '24' }} md={{ span: '12' }}>
                          <Form.Item
                            name='item_location'
                            rules={[{ required: true, message: 'Please select a Location.' }]}
                            getValueFromEvent={value => {
                              setSelectedLocation(value)
                              form.setFieldsValue({ location_id: undefined })
                              return value
                            }}
                          >
                            <Input
                              label="Transfer Location"
                              type="select"
                              options={getLocationOptions()}
                              placeholder='Select Tag Location'
                              // onChange={(value: ItemLocation) => setSelectedLocation(value)}
                              required
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: '24' }} md={{ span: '12' }}>
                          <Form.Item
                            name={`location_id`}
                            rules={[{ required: true, message: `Please select a Location ID.` }]}
                          >
                            <Input
                              label={`Transfer Location ID`}
                              type="select"
                              options={getLocationIdOptions(selectedLocation)}
                              placeholder={`Select a Location ID`}
                              showSearch
                              required
                            />
                          </Form.Item>
                        </Col>
                      </>
                    }
                  </Row>
                </ModalLayout.ModalSection>

              </ModalLayout>

            </>
        }

      </Form >
    </GlobalDrawer >
  )
}

export default AddInventoryItemForm



