import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { CRM_ROOT_PATH } from "containers/Pages/RelationshipManagement/CRMRoutes";
import {
  Company,
  Contact,
  Dashboard,
  Task,
} from "containers/Pages/RelationshipManagement/pages";
import RelationshipManagement from "containers/Pages/RelationshipManagement";
import CompanyDetails from "containers/Pages/RelationshipManagement/pages/Company/DetailsView";
import ContactDetails from "containers/Pages/RelationshipManagement/pages/Contact/DetailsView";
import styled from "styled-components";

const Right = styled.div`
  height: 100%;
  padding: 0 0.5em 0.5em 0.5em;
  border-radius: 5px;
  margin-right: 1em;
  margin-left: 1em;
`;

const RelationshipManagementRouting = () => {
  return (
    <Routes>
      <Route
        path={CRM_ROOT_PATH}
        element={<Navigate to={`${CRM_ROOT_PATH}/dashboard`} />}
      />

      <Route
        path={`${CRM_ROOT_PATH}/dashboard`}
        element={
          <>
            <RelationshipManagement />
            <Right>
              <Dashboard />
            </Right>
          </>
        }
      />

      <Route
        path={`${CRM_ROOT_PATH}/company`}
        element={
          <>
            <RelationshipManagement />
            <Right>
              <Company />
            </Right>
          </>
        }
      />

      <Route
        path={`${CRM_ROOT_PATH}/contact`}
        element={
          <>
            <RelationshipManagement />
            <Right>
              <Contact />
            </Right>
          </>
        }
      />

      <Route
        path={`${CRM_ROOT_PATH}/task`}
        element={
          <>
            <RelationshipManagement />
            <Right>
              <Task />
            </Right>
          </>
        }
      />

      <Route
        path={`${CRM_ROOT_PATH}/company-details/:businessId`}
        element={
          <>
            <RelationshipManagement />
            <Right>
              <CompanyDetails />
            </Right>
          </>
        }
      />

      <Route
        path={`${CRM_ROOT_PATH}/contact-details/:contactId`}
        element={
          <>
            <RelationshipManagement />
            <Right>
              <ContactDetails />
            </Right>
          </>
        }
      />
    </Routes>
  );
};
export default RelationshipManagementRouting;