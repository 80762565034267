/**
 * @description Content Management System Page entry, renders the page header and the sub routes
 * @version 1.0.0
 * @author Bruce Zhu 
 */

import React from 'react';
import { PageWrapper, Body } from '../../../statics/styles/StyledComponents';
import { isMobile } from 'react-device-detect';
import Header from '../../Headers/DesktopHeader';
import PageTabs from 'components/PageTabs';
import CMSRoutes, { CMS_ROOT_PATH } from './CMSRoutes';
import CMSContext from './CMSContext';


const TABS = {
  PRODUCTS: 'Products',
  COLLECTIONS: 'Collections',
  TAGS: 'Tags',
};

const tabsData = [
  {
    label: TABS.PRODUCTS,
    key: TABS.PRODUCTS,
    relativeRoute: 'products'
  },
]

const ContentManagementSystem = () => {

  const tabs = tabsData.map((item: any) => {
    return {
      text: item.label,
      path: `${CMS_ROOT_PATH}/${item.relativeRoute}`
    };
  });

  return (
    <PageWrapper>
      <CMSContext>
        {!isMobile && <Header pageTitle="Content Management" />}
        <Body>
          <PageTabs tabs={tabs} rowStyle={{ marginBottom: "20px", }} />
          <CMSRoutes />
        </Body>
      </CMSContext>
    </PageWrapper>
  );
};

export default ContentManagementSystem
