import React, { MouseEvent, useEffect, useState } from "react";

import { ASSET_MANAGEMENT_API } from "utilities/AdiApi";
import { DisplayEmptyTextPrimary, DisplayEmptyTextSecondary } from "statics/styles/StyledComponents";
import { MoreOutlined } from "@ant-design/icons";
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";
import { Dropdown, Menu, Tooltip, message } from "antd";
import { TableRowSelection } from "antd/es/table/interface";
import { FIXED_TABLE_HEIGHT, TABLE_CELL_WIDTH_250 } from "utilities/CONSTANTS";
import { capitaliseFirst, formatNameString, formatTitle, initialCellValue } from "utilities/Functions/FormatFunctions";


import useFileUpload from "utilities/hooks/useFileUpload";
import AssetDataAPI from "containers/Pages/AssetManagement/apis/AssetDataAPI";
import AlertAPI from "containers/Pages/AssetManagement/apis/AlertAPI";
import { ASSET_STATUS } from "containers/Pages/AssetManagement/statics/constants";
import AdiTable from "components/AdiTable";
import AssetAPI from "containers/Pages/AssetManagement/apis/AssetAPI";
import CategoryAPI from "containers/Pages/AssetManagement/apis/CategoryAPI";
import { CateData } from "containers/Pages/AssetManagement/statics/types";
import { useGeneralStore } from "zustand-stores";
import { GeneralStore } from "zustand-stores/useGeneralStore";
import { useAssetsDropDownOpen } from "zustand-stores/useAssetsDropDownOpen";
import useAuthStore, { AuthStore } from "zustand-stores/useAuthStore";
import { ActionIcon } from "components/AdiTable/ActionDropdown";

type Props = {
  // assestTableData: any[];
  // modalData?: any;
  modalIsOpen?: boolean;
  setModalData?: Function;
  openModal?: Function;
  initialize?: Function;
  selectedAssets?: any[];
  setSelectedAssets: Function;
  selectedRowKeys: any[];
  setSelectedRowKeys: Function;
  handleDisposalModalOpen: Function;
  isLoading: boolean;
  doSearch: boolean;
  searchText: string;
};

const AssetTable = (props: Props) => {
  const { doSearch, searchText, openModal, modalIsOpen, initialize, setSelectedAssets, setSelectedRowKeys, selectedRowKeys, handleDisposalModalOpen, isLoading } = props;
  // add key in the asset table data

  const isAmReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.am.isReadOnly)

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  const [tableData, setTableData] = useState<any>([])
  const { open, close, dropDownOpen, dropDownId } = useAssetsDropDownOpen();


  const [AWSFile] = useFileUpload()

  const BIZ_CODE = getBizCode();

  const STATUS = Object.keys(ASSET_STATUS).map((key: string) => ({ value: ASSET_STATUS[key], label: formatNameString(key), text: formatNameString(key) }))

  // [
  //   { value: ("in_maintenance"), label: "In Maintenance", text: "In Maintenance" },
  //   { value: ("Unallocated"), label: "Unallocated", text: "Unallocated" },
  //   { value: ("allocated"), label: "Allocated", text: "Allocated" },
  // ]

  const [cateDataList, setCateDataList] = useState<CateData[]>([]);

  const getCategories = async () => {
    // setIsGlobalLoading(true)
    try {
      const categories = await CategoryAPI.getAllCategory()
      const formatedCates = categories.map((item: any) => {
        const { asset_category: category_name, asset_types: category_types } = item
        if (!category_types || (category_types && category_types.length == 0)) {
          return {
            category_name,
            category_types: [],

          }
        } else {
          return {
            category_name,
            category_types,

          }
        }
      })

      console.log('formatedCates', formatedCates)

      setCateDataList([...formatedCates])
      // setIsGlobalLoading(false)

    } catch (err) {
      console.log('getCategories asset table fail...', err)
      // setIsGlobalLoading(false)
    }

  }
  const getTableData = async () => {
    console.log('AssetTable  searchText', searchText)
    let url = ``
    if (searchText) {
      url = `?search=${searchText}`
    } else {
      url = ``
    }

    try {

      const result = await AssetAPI.searchAssets(url)
      console.log('getTableData ress in table comp', result)
      if (Array.isArray(result) && result.length > 0) {
        // add key in the asset table data
        result.map((item: any, i: number) => {
          item.key = i
        })
        setTableData(result)
      } else {
        message.info('No asset found')
        setTableData([])
      }
    } catch (err) {
      console.log('initializeMap fail...', err)

    }


  }

  useEffect(() => {
    getTableData()
    getCategories()
  }, [doSearch]);

  const handleEditClick = async (record: any, viewOnly: boolean = false) => {
    console.log("onRowClick record", record);

    const assetId = record.asset_ID

    setIsGlobalLoading(true)
    // api to get alert single asset detail and api call to get alerts history
    try {
      const result = await AssetDataAPI.getAssetDataById(assetId)
      let finalData = {
        ...result,
        masterData_refID: result.master_refID

      }
      const alertsResults = await AlertAPI.getAlertByAssetId(assetId)
      finalData = {
        ...finalData,
        alerts_history: [...alertsResults]
      }

      console.log('onRowClick finalData', viewOnly, finalData)
      openModal && openModal(finalData, viewOnly)
      setIsGlobalLoading(false)
    } catch (err) {
      console.log('get assets or alerts hist fail...', err)
      setIsGlobalLoading(false)
    }

  };

  const handleDelete = async (record: any) => {

    const { asset_ID } = record
    const url = `${BIZ_CODE}/assets?asset_ID=${asset_ID}`

    const moduleCode = AWSFile.S3_MODULE_CODE.am
    const s3KeyDir = AWSFile.constructS3Key(moduleCode, `custom_fields/asset/${asset_ID}`)

    setIsGlobalLoading(true)
    // delete files from custom fields - delete the whole directory associated to asset id
    await AWSFile.deleteFromS3(s3KeyDir, true)
      .catch(e => {
        console.log('deleteFromS3 fail...', e)
        message.error('Failed to delete the asset custom field files.')
      })

    ASSET_MANAGEMENT_API({
      url,
      method: 'DELETE',
    }).then(res => {
      message.success(`Asset deleted successfully!`)
      initialize && initialize()

    }).catch(err => {

      console.log('Delete asset master data fail...', err)
      message.error(err.message)

    }).finally(() => {
      setIsGlobalLoading(false)
    })

  }

  const menu = (record: any) => (
    <Menu
      className='dropdownMenu'
      onMouseEnter={(e: React.SyntheticEvent) => {
        e.stopPropagation()
        open(record.asset_ID);
      }}
      onMouseLeave={() => close()}
    >
      {record.status === ASSET_STATUS.DISPOSED ? (
        <Menu.Item key="1">
          <div
            onClick={
              async (e: MouseEvent) => {
                e.preventDefault()
                e.stopPropagation();
                close();
                console.log('onClick view record', record)
                // handleModalOpen(e, 'isRequestEdit', record)
                handleEditClick(record, true)
              }
            }
          >View</div>
        </Menu.Item>
      ) : (
        <>
          <Menu.Item key="1">
            <div
              onClick={
                async (e: MouseEvent) => {
                  e.preventDefault()
                  e.stopPropagation();
                  close();
                  console.log('onClick record', record)
                  // handleModalOpen(e, 'isRequestEdit', record)
                  handleEditClick(record, false)
                }
              }
            >Edit</div>
          </Menu.Item>

          {/* <Menu.Item key="2">
        <div
          onClick={
            (e: MouseEvent) => {
              e.preventDefault()
              e.stopPropagation();
              ADI_CONFIRM({
                customizeTitle: 'Are you sure?',
                isdelete: true,
                onConfirm: () => {
                  console.log('Delete', record)
                  handleDelete(record)
                }
              })

            }
          }
        >Delete</div>
      </Menu.Item> */}
          {
            record.status === 'unallocated' ? (
              <Menu.Item key="3">
                <div
                  onClick={
                    (e: MouseEvent) => {
                      e.preventDefault()
                      e.stopPropagation();
                      close();
                      // handleModalOpen(e, 'isRetrieveOpen', record)
                      setSelectedAssets([record])
                      handleDisposalModalOpen(record)
                    }
                  }>Dispose</div>
              </Menu.Item>
            ) : null
          }</>
      )}


    </Menu >
  );


  const columns: any = [
    {
      title: formatTitle("Asset ID"),
      dataIndex: "asset_ID",
      align: "left",
      key: "asset_ID",
      width: TABLE_CELL_WIDTH_250,
    },
    {
      title: formatTitle("Asset Name"),
      dataIndex: "asset_name",
      align: "left",
      key: "asset_name",
      width: TABLE_CELL_WIDTH_250,
    },

    {
      title: formatTitle("Asset Category"),
      dataIndex: "asset_category",
      align: "left",
      key: "asset_category",
      width: TABLE_CELL_WIDTH_250,
      filters: cateDataList.map((item: any) => ({ text: item.category_name, value: item.category_name })),
      onFilter: (value: string, record: any) => record.asset_category === value
    },
    {
      title: formatTitle("Asset Type"),
      dataIndex: "asset_type",
      align: "left",
      key: "asset_type",
      width: TABLE_CELL_WIDTH_250,
      filters: cateDataList.map((item: any) => {
        const types = item.category_types.map((type: any) => ({ text: type, value: type }))
        return types
      }).flat(),
      onFilter: (value: string | number | boolean, record: any) => record.asset_type === value,
    },
    // phase two dev
    {
      title: formatTitle("Asset Value"),
      dataIndex: "asset_price",
      align: "left",
      key: "asset_price",
      width: TABLE_CELL_WIDTH_250,
      render: initialCellValue
    },
    {
      title: formatTitle("Home Location"),
      dataIndex: "home_location",
      align: "left",
      key: "home_location",
      width: TABLE_CELL_WIDTH_250,
      render: initialCellValue
    },
    {
      title: formatTitle("ACQUISITION DATE"),
      dataIndex: "asset_acquisition_date",
      align: "left",
      key: "asset_acquisition_date",
      width: TABLE_CELL_WIDTH_250,
      render: initialCellValue
    },
    {
      title: formatTitle("PREDICTED END OF LIFE"),
      dataIndex: "predicted_end_of_life",
      align: "left",
      key: "predicted_end_of_life",
      width: TABLE_CELL_WIDTH_250,
      render: initialCellValue
    },
    {
      title: formatTitle("Sensor ID"),
      dataIndex: "current_deviceID",
      align: "left",
      key: "current_deviceID",
      width: TABLE_CELL_WIDTH_250,
      render: initialCellValue
    },
    {
      title: formatTitle("Status"),
      dataIndex: "status",
      align: "left",
      key: "status",
      width: TABLE_CELL_WIDTH_250,
      render: (text: any) => text ? capitaliseFirst(text) : initialCellValue,
      filters: STATUS,
      onFilter: (value: any, record: any) => record?.status === value,
    },
    {
      title: formatTitle("Actions"),
      dataIndex: "actions",
      align: "left",
      key: "actions",
      fixed: 'right',
      width: 150,
      render: (_text: any, record: any) => (
        <div onMouseLeave={() => close()}>
          <Dropdown
            overlay={menu(record)}
            visible={dropDownOpen && dropDownId === record.asset_ID}
            placement="bottomRight">
            <div
              onMouseEnter={(e: any) => {
                e.stopPropagation()
                open(record.asset_ID);
              }}
            >
              <ActionIcon
                style={{ color: 'white', fontSize: '20px' }}

              />
            </div>
          </Dropdown>
        </div>
      ),
    },
  ];

  isAmReadOnly && columns.pop()

  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows, 'rowSelectionlength', selectedRows.length);
      setSelectedAssets(selectedRows)
      setSelectedRowKeys(selectedRowKeys)
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
    },
    getCheckboxProps: (record) => ({
      disabled: !(record.status == ASSET_STATUS.UNALLOCATED), // Column configuration not to be checked
      name: record.status,
    }),
  };

  return !isLoading && Array.isArray(tableData) && tableData.length == 0 ? (
    <DisplayEmptyTextPrimary style={{ marginTop: '200px' }}>
      You don’t have any Assets to display or track
      <DisplayEmptyTextSecondary style={{ width: 'auto', marginTop: '30px' }}>
        Before you add your assets, please create and manage Asset Presets  from Master Data &gt; Asset Preset
      </DisplayEmptyTextSecondary>
    </DisplayEmptyTextPrimary >
  ) : (
    <AdiTable
      loading={isLoading}
      tableData={tableData}
      fullWidth
      columns={columns}
      marginTop='0px'
      rowKey={(record: any) => record.asset_ID}
      scroll={{ y: FIXED_TABLE_HEIGHT, x: '100%' }}
      rowSelection={{ ...rowSelection }}
      onRowClick={async (record: any, index: number, event: any) => {
        event.preventDefault()
        event.stopPropagation()
        console.log('onRowClick recorddddddddddd')
        await handleEditClick(record, true)
      }}
      extraPaginationPadding
    />

  );
};

export default AssetTable;
