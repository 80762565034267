import React from 'react'
import { DisplayEmptyText } from 'statics/styles/StyledComponents'
import styled from 'styled-components';

type Props = {
  data: {
    title: string;
    text: string;
    buttons?: React.ReactNode[];
  };
};


const EmptyDisplay = (props: Props) => {
  const { title, text, buttons } = props.data;
  return (
    <EmptyDiv>
      <DisplayEmptyText style={{ fontWeight: 'bold', fontSize: '22px' }}>
        {title}
      </DisplayEmptyText>
      <DisplayEmptyText style={{ marginTop: '20px' }}>
        {text}
      </DisplayEmptyText>
      <Buttons buttons={buttons ? buttons : []} />
    </EmptyDiv>
  )
}


const Buttons = ({ buttons }: { buttons: React.ReactNode[] }) => {
  return (
    <>
      {buttons.map((button: React.ReactNode, index: number) => {
        return <React.Fragment key={index}>{button}</React.Fragment>;
      })}
    </>
  );
};


export default EmptyDisplay

const EmptyDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

