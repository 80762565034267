import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Divider, Dropdown, Form, Menu, Row, message } from 'antd';
import AdiTable from 'components/AdiTable';
import ExportButton from 'components/GlobalButtons/ExportButton';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import Input from 'components/Input';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { FlexBox, FlexWrapper } from 'statics/styles/StyledComponents';
import styled from 'styled-components';
import { TABLE_CELL_CONFIG } from 'utilities/CONSTANTS';
import { capitaliseFirst, formatTitle, initialCellValue } from 'utilities/Functions/FormatFunctions';
import { convertDateAndTime } from 'utilities/Functions/GlobalHelperFunctions';
import AssignFoB from '../../components/AssignFoB';
import GlobalModal from 'components/GlobalModal';


import FoBAPI from '../../apis/FoBAPI';
import { FOB_CATEGORY } from '../../statics/constants';
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';
import TableCard from 'components/AdiTable/TableCard';
import EmptyDisplay from 'components/AdiTable/EmptyDisplay';

const { ModalLayout } = GlobalModal;

type Props = {
  tabValue: string,
  active: boolean,
}

const InUse = (props: Props) => {
  const { tabValue, active } = props

  const [tableData, setTableData] = useState<any>([])

  const [filters, setFilters] = useState<any>({
    searchText: '',
    filteredData: [],
  });
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<any[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
  const [exportedData, setExportedData] = React.useState<any[]>([]);

  const [modal, setModal] = useState<any>({
    assignModalOpen: false,
    markAsReturnedModalOpen: false,
    data: {},
  });

  useEffect(() => {
    if (active) {
      getTableData()
    }
  }, [active])

  const getTableData = async () => {
    setIsLoading(true)
    await FoBAPI.getAllFoB().then((Fob: any) => {
      console.log('getTableData', Fob)
      let tempTableData: any[] = []

      // get items that are "business"
      tempTableData = Fob.filter((item: any) => item?.fob_status === "assigned")

      // add key to each row
      tempTableData = tempTableData.map((item: any, index: number) => ({
        ...item,
        key: index,
      }))

      console.log('InUsetempTableData', tempTableData)
      handleSearch(filters.searchText, tempTableData)
      setTableData(tempTableData)

    }).catch((err: any) => {
      console.log('err', err)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  // search by name, company name
  const handleSearch = (text: string, data?: any) => {
    console.log('handleSearch', data)
    const searchText = text.toLowerCase() || ''
    const dataSource = data || tableData
    const filtered: any = Array.isArray(dataSource) && dataSource.filter((item: any) => {
      const fobNumber = item?.fob_number?.toLowerCase() || ''
      return fobNumber.includes(searchText)
    })

    console.log('filteredData', filtered)

    setFilters((prev: any) => ({
      ...prev,
      filteredData: filtered,
    }))
  }


  const handleAssignFob = () => {
    setModal({ ...modal, assignModalOpen: true })
  }
  const closeModal = () => {
    setModal({ ...modal, assignModalOpen: false, markAsReturnedModalOpen: false, data: {} })
  }
  const addAssignFobOnFinish = async () => {
    closeModal()
    await getTableData()
  }

  const handleModalOpen = (record: any) => {
    setModal({ ...modal, markAsReturnedModalOpen: true, data: record })
  }

  // const renderMenu = (record: any) => {

  //   const handleMenuClick = (e: any) => {
  //     e.domEvent.stopPropagation()
  //     handleModalOpen( record)
  //   }

  //   return (
  //     <Menu className='dropdownMenu' onClick={handleMenuClick}>
  //       <Menu.Item key="mark_as_returned">
  //         Mark as Returned
  //       </Menu.Item>
  //     </Menu>
  //   )
  // }

  const finalTableColumns = [
    ...tableColumns,
    // {
    //   title: formatTitle('Action'),
    //   dataIndex: 'action',
    //   key: 'action',
    //   ...TABLE_CELL_CONFIG,
    //   width: 130,
    //   align: 'center',
    //   render: (text: any, record: any) => (
    //     <>
    //       <Dropdown overlay={renderMenu(record)}>
    //         <MoreIcon onClick={(e: any) => e.stopPropagation()} />
    //       </Dropdown>
    //     </>
    //   ),
    // },
    {
      title: formatTitle('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (text: any, record: any) => {

        const items = [
          {
            label: 'Mark as Returned',
            onClick: () => handleModalOpen(record),
          },
        ];

        return <AdiTable.ActionDropdown menuItems={items} />;
      },

    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedRows(selectedRows)
      setSelectedRowKeys(selectedRowKeys)
      constructExportData(selectedRows)
    },
  };

  const constructExportData = (selectedRows: any) => {
    const tempExportData: any[] = []
    selectedRows.forEach((item: any) => {
      const tempObj: any = {}
      tempObj["FoB Number"] = item?.fob_number
      tempObj["FoB Category"] = item?.fob_category
      tempObj["FoB Description"] = item?.fob_description
      tempObj["Assigned To"] = item?.first_name && item?.last_name && capitaliseFirst(`${item?.first_name} ${item?.last_name}`)
      tempObj["Issued By"] = item?.assigned_by
      tempObj["Issued Date"] = item?.date && moment(item?.date).format('DD/MM/YYYY')
      tempObj["Check - In"] = item?.time && moment(item?.time).format('HH:mm')
      tempExportData.push(tempObj)
    })
    setExportedData(tempExportData)
  };


  return (
    <>
      {!isLoading && Array.isArray(tableData) && tableData.length === 0 ?
        (
          <EmptyDisplay data={{
            title: 'You don’t have FoB to display', text: 'Please create and manage your FoB.',
            buttons: [<SimpleButton
              text='+ Assign FoB'
              onClick={handleAssignFob}
            />],
          }} />
        ) : (
          <TableCard>
            <Row gutter={[0, 15]} align="middle" justify='space-between'
              style={{
                margin: '0 10px'
              }}>
              <Col style={{ marginRight: "30px" }} >
                <Row gutter={[15, 15]} align="bottom">
                  <Col>
                    <Input
                      placeholder="Search by FoB Number"
                      type='search'
                      value={filters.searchText}
                      onSearch={() => handleSearch(filters.searchText)}
                      onChange={(e: any) => {
                        setFilters({
                          ...filters,
                          searchText: e.target.value,
                        })

                        if (!e.target.value) {

                          handleSearch('')
                        }

                      }}
                      style={{ width: '400px' }}
                      allowClear
                    />
                  </Col>
                </Row>
              </Col>
              <Col style={{ display: 'flex' }}>
                <Row align="bottom">
                  <Row style={{ marginRight: '1.5rem', display: 'flex', alignItems: 'center' }}>
                    <ExportButton
                      fileName='FoB In-Use Data'
                      exportData={exportedData}
                    />
                  </Row>
                  <SimpleButton
                    text={<BtnText><PlusOutlined />&nbsp;&nbsp;Assign FoB</BtnText>}
                    onClick={handleAssignFob}
                    style={{ padding: '0px 15px' }}
                  />
                </Row>
              </Col>
            </Row>
            <AdiTable
              scroll={{ x: '100%', y: '50vh' }}
              loading={isLoading}
              columns={finalTableColumns}
              tableData={filters.filteredData}
              rowSelection={{ ...rowSelection }}
              fullWidth
              marginTop='20px'
              extraPaginationPadding
            />
          </TableCard>
        )}
      <AssignFoB
        formName={'assign-fob'}
        open={modal.assignModalOpen}
        onClose={closeModal}
        reload={addAssignFobOnFinish}
      />
      <MarkAsModal
        formName={'mark-as-required'}
        open={modal.markAsReturnedModalOpen}
        onClose={closeModal}
        selectedItem={modal.data}
        reload={addAssignFobOnFinish}
      />
    </>
  )
}

export default InUse

const MarkAsModal = (props: { formName: string, open: boolean, onClose: Function, selectedItem: any, reload: Function }) => {
  const { formName, open, onClose, selectedItem, reload } = props
  const [form] = Form.useForm();

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  useEffect(() => {
    if (!open) return;

    if (selectedItem) {
      form.setFieldsValue({
        fob_status: selectedItem?.fob_status,
        return_time: moment(new Date),
      });
    }
  }, [selectedItem, open]);


  const formOnFinish = (values: any) => {

    const { return_time } = values
    const returnTime = return_time ? convertDateAndTime(return_time, 'datetime') : ''

    console.log('formOnFinish', values, returnTime)

    const { key, ...rest } = selectedItem

    const payload = {
      ...rest,
      fob_status: values.fob_status,
      note: values?.note || '',
      return_time: returnTime,
    }

    console.log('update fob payload', payload)

    setIsGlobalLoading(true)
    FoBAPI.updateAssignFoB(payload).then((res: any) => {
      message.success('Record updated successfully!')
      reload()
      closeForm()
    }).catch((err: any) => {
      message.error('Failed to update Record.')
    }).finally(() => {
      setIsGlobalLoading(false)
    })
  }

  const closeForm = () => {
    onClose()
    form.resetFields()
  }


  return (
    <GlobalModal
      title={`FoB Number : ${selectedItem?.fob_number}`}
      open={open}
      onCancel={onClose}
      width='700px'
      footer={[
        <FlexBox style={{ justifyContent: 'flex-end', margin: '0' }} key='0'>
          <SimpleButton
            id='modal-btn-width-regular'
            isCancel={true}
            text="Cancel"
            onClick={onClose}
            style={{ marginRight: '1rem' }}
          />
          <SimpleButton
            id='modal-btn-width-regular'
            htmlType="submit"
            text="Save"
            form={formName}
          />
        </FlexBox>
      ]}
    >
      <Form
        form={form}
        name={formName}
        onFinish={(values: any) => formOnFinish(values)}
      >
        <ModalLayout>

          <ModalLayout.ModalSection title="Return Details">
            <Row gutter={[15, 0]}>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="fob_status"
                  rules={[
                    {
                      required: true,
                      message: 'Please select Status.',
                    }
                  ]}
                >
                  <Input
                    type='select'
                    label='FoB Status'
                    placeholder="Select Status"
                    options={
                      [
                        { label: 'Assigned', value: 'assigned' },
                        { label: 'Returned', value: 'not_assigned' },
                      ]
                    }
                    required
                  />
                </Form.Item>
              </Col>
              <Col md={{ span: '12' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="return_time"
                  rules={[
                    {
                      required: true,
                      message: 'Please select Return Time.',
                    }
                  ]}
                >
                  <Input
                    type='time'
                    label='Return Time'
                    placeholder="Enter Return Time"
                    format='HH:mm'
                    showNow
                    required
                  />
                </Form.Item>
              </Col>
              <Col md={{ span: '24' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="note"
                >
                  <Input
                    type='textarea'
                    label='Notes'
                    placeholder="Add Notes"
                  />
                </Form.Item>
              </Col>
            </Row>
          </ModalLayout.ModalSection>

        </ModalLayout>
      </Form>
    </GlobalModal>
  )
}

const BtnText = styled.span`
  color: var(--white-text-color);
  display: flex;
  align-items: center;
`;

const MoreIcon = styled(MoreOutlined)`
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  top: 13px;
  font-size: 20px;
  font-weight: bold;
  padding: 3px;
  &:hover {
    cursor: pointer;
    // background-color: #33334d;
    // filter: brightness(1.2);
    // border-radius:50%;
  }
`;

const tableColumns = [
  {
    title: 'FoB NUMBER',
    dataIndex: 'fob_number',
    key: 'fob_number',
    ...TABLE_CELL_CONFIG,
    sorter: (a: any, b: any) => a.fob_number?.localeCompare(b.fob_number),
    render: initialCellValue,
  },
  {
    title: 'FoB CATEGORY',
    dataIndex: 'fob_category',
    key: 'fob_category',
    ...TABLE_CELL_CONFIG,
    filters: [...FOB_CATEGORY],
    onFilter: (value: string, record: any) => record.fob_category === value,
    render: initialCellValue,
  },
  {
    title: 'FoB DESCRIPTION',
    dataIndex: 'fob_description',
    key: 'fob_description',
    ...TABLE_CELL_CONFIG,
    render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),
  },
  {
    title: formatTitle('Assigned To'),
    dataIndex: 'first_name',
    key: 'first_name',
    ...TABLE_CELL_CONFIG,
    render: (text: string, record: any) => {
      const { first_name, last_name } = record
      return first_name && last_name ? capitaliseFirst(`${first_name} ${last_name}`) : initialCellValue(text)
    },
  },
  {
    title: formatTitle('Issued By'),
    dataIndex: 'assigned_by',
    key: 'assigned_by',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle('Issued Date'),
    dataIndex: 'date',
    key: 'date',
    ...TABLE_CELL_CONFIG,
    render: (text: string) => text ? moment(text).format('DD/MM/YYYY') : initialCellValue(text),
  },
  {
    title: formatTitle('Check-In'),
    dataIndex: 'time',
    key: 'time',
    ...TABLE_CELL_CONFIG,
    width: 120,
    render: (text: string) => text ? moment(text).format('HH:mm') : initialCellValue(text),
  },
];

