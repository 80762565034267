/**
 * Reusable styled components
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { Tabs, Tab, Box, Typography } from "@material-ui/core";
import Dragger from "antd/lib/upload/Dragger";
import { MoreOutlined } from "@ant-design/icons";

export const theme = {
  main: "#3E7BFA",
  primary: "#545454",
  maxHeight: "1500px",
  maxWidth: "2500px",
  sideWidth: "27em",
  collapseWidth: "5em",
  footerHeight: "3rem",
  headerHeight: "3rem",
  mobile: isMobile,
  myScrollbar: `
     /* Firefox */
    //  scrollbar-color: #E5F0FF #6698FA;
     scrollbar-color: #6698FA #E5F0FF ;
     scrollbar-width: thin;
 
     /* chrome */
     ::-webkit-scrollbar {
       display: block;
     }
 
     /* width */
     ::-webkit-scrollbar {
       width: 10px;
       height: 10px;
     }
 
     /* Track */
     ::-webkit-scrollbar-track {
       background: #E5F0FF;
       border-radius: 10px;
     }
 
     /* Handle */
     ::-webkit-scrollbar-thumb {
       background: #6698FA;
       border-radius: 10px;
     }
 
     /* Handle on hover */
     ::-webkit-scrollbar-thumb:hover {
       background: #5865f2;
     }
     
     // /* ie/Edge */
     -ms-overflow-style: scrollbar;
     scrollbar-face-color: #E5F0FF;
     scrollbar-track-color: #6698FA;
   `,
};

export const primaryRed = "#3E7BFA";

export const FixedHeightScrollableBox = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;
  max-height: 40vh;
  overflow-y: auto;
  padding-right: 5px;
  flex: 0.5;
  ${(props) => {
    if (!props.theme.mobile) {
      return `
      overflow-y: auto;
      ${props.theme.myScrollbar}
    `;
    }
    return "";
  }}

  @media(min-width: 1150px) {
    max-height: 70vh;
    margin-right: 10px;
  }
`;
export const ModalFixedHeightScrollableBox = styled.div`
  height: 50vh;
  overflow-y: auto;
  // padding-right: 5px;

  ${(props) => {
    if (!props.theme.mobile) {
      return `
      overflow-y: auto;
      ${props.theme.myScrollbar}
    `;
    }
    return "";
  }}
`;

export const Button = styled.button`
  display: block;
  background: none;
  border: none;

  &,
  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
`;

export const ButtonGreen = styled(Button)`
  background: var(--unnamed-color-008000) 0% 0% no-repeat padding-box;
  background: #008000 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  color: white;
`;
export const ButtonRed = styled(Button)`
  background: #e22929 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  color: white;
`;

// the icon with green button
export const IconContainer = styled.span`
  margin-right: 5px;
`;

export const TableButton = styled(Button)`
  background: #383838;
  padding: 0.8rem;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
`;

export const CloseButton = styled(Button)`
  color: white;
  border: 1px solid white;
  border-radius: 5px;
  height: 36px;
`;

export const StyledLabel = styled.label`
  text-align: center;
  cursor: pointer;
  font-size: 1.1rem;
  display: inline-block;
  width: 20rem;
  height: 2.5rem;
  font-weight: 500;
  margin: 0 1rem 0 0;
  padding: 0.5rem 1rem;
  border-radius: 2px;
  background: linear-gradient(rgb(76, 76, 76), rgb(42, 42, 42));
  text-transform: uppercase;

  &&& {
    &,
    * {
      color: rgb(246, 246, 246);
    }
  }
`;

export const WhiteButton = styled(Button)`
  display: inline-block;
  // width: 20rem;
  width: 28rem;
  height: 2.5rem;
  // font-size: 1.1rem;
  font-size: 11px;
  font-weight: 500;
  margin: 0 1rem 0 0;
  padding: 0.5rem 1rem;
  border-radius: 2px;
  background: linear-gradient(rgb(76, 76, 76), rgb(42, 42, 42));
  // text-transform: uppercase;

  :disabled {
    opacity: 0.4;
  }

  &&& {
    &,
    * {
      color: rgb(246, 246, 246);
    }
  }
`;

export const OSMTable = styled.table`
  width: 100%;
  font-size: 14px;
  text-align: left;
  * {
    color: white;
  }

  td {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
    max-width: 3rem;
    font-family: var(--font-normal);
    font-weight: 400;
    cursor: pointer;
  }
  thead {
    tr {
      th {
        padding: 0px 16px 12px 16px !important;
        font-weight: 400;
        font-weight: 500;
      }

      th:first-child {
        padding: 0px 16px 12px 0px !important;
      }

      th:last-child {
        padding: 0px 0px 12px 16px !important;
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 0px 16px 12px 16px !important;
      }

      td:first-child {
        padding: 0px 16px 0px 0px !important;
      }

      td:last-child {
        padding: 0px 0px 0px 16px !important;
      }
    }
  }
  tbody {
    td {
      padding: 6px 16px 0px 16px !important;
    }
  }
`;
export const PurchaseProductInfoTable = styled.table`
  width: 100%;
  font-size: 14px;
  text-align: left;
  border: 1px solid var(--input-border-color);
  position: relative;
  height: 170px;
  // overflow-y: auto;
  display: inline-block;
  overflow-x: auto;
  ${theme.myScrollbar}

  * {
    color: white;
  }

  td {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
    min-width: 20rem;
    max-width: 100%;
    font-family: var(--font-normal);
    font-weight: 400;
    cursor: pointer;
  }
  thead {
    tr {
      th {
        padding: 12px 16px 12px 16px !important;
        background: var(--table-header-bg-color);
        font-weight: 500;
        position: sticky;
        top: 0;
        z-index: 9;
        min-width: 20rem;
        max-width: 100%;
      }
      th:last-child {
        width: 100% !important;
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 12px 16px 12px 16px !important;
      }
      td:last-child {
        text-overflow: unset;
        white-space: unset;
        word-break: unset;
        min-width: 100% !important;
      }
    }
  }
  @media (min-width: 1350px) {
    thead {
      tr {
        th {
          min-width: 24rem;
          max-width: 100%;
        }
        th:last-child {
          min-width: 10rem;
          max-width: 100%;
        }
      }
    }
    td {
      min-width: 24rem;
      max-width: 100%;
    }
    tbody {
      tr {
        td:last-child {
          min-width: 10rem;
          max-width: 100%;
        }
      }
    }
  }
`;
export const PurchaseProductInfoTable1 = styled.table`
  width: 100%;
  font-size: 14px;
  text-align: left;
  position: relative;
  display: inline-block;

  * {
    color: white;
  }
  thead {
    tr {
      th {
        padding: 12px 16px 12px 16px !important;
        background: #2a2a2a;
        font-weight: 400;
        font-weight: 500;
        position: sticky;
        top: 0;
        z-index: 9;
        width: 100rem;
      }
    }
  }

  td {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
    max-width: 4rem;
    font-family: var(--font-normal);
    font-weight: 400;
  }
`;
export const SupplierPriceTable = styled.table`
  background: #2c2d42;
  width: fit-content;
  border-collapse: collapse;
  font-size: 14px;
  text-align: left;
  * {
    color: white;
  }

  td {
    padding: 0.5rem 1rem;

    span {
      color: #c3c3c3;
    }
  }

  tr,
  td {
    min-width: 164px;
  }

  td,
  th {
    border: 1px solid rgba(85, 87, 112, 0.6);
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  tfoot {
    tr:last-child {
      background: var(--btn-disabled-color);
    }
  }
`;

export const Table = styled.table`
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  font-size: 1.2rem;
  text-align: left;
  * {
    color: white;
  }

  tr:nth-child(even) {
    background: #333333;
  }
  tr:nth-child(odd) {
    background: #383838;
  }

  td {
    padding: 2rem;
  }

  tr,
  td {
    // min-width: 10rem;
  }

  td,
  th {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 68px;
  }

  tr:hover {
    td,
    th {
      white-space: normal;
      word-break: break-all;
    }
  }

  thead {
    border-radius: 5px 5px 0px 0px;
    tr:first-child {
      th {
        padding: 1rem 2rem;
        text-transform: uppercase;
        height: 68px;
        background: #1a1a1a;
      }
    }
  }
`;

export const TableWrapper = styled.div`
   // when have scroll bar, scoll bar is right next to table
  //  width: fit-content;
   width: 99.5%;
   overflow-y: scroll;
  //  border: 1px solid #C5C5C5;
    border-radius: 10px;
    opacity: 1;
   
   ${(props) => {
     if (!props.theme.mobile) {
       return `
       overflow-y: auto;
       ${props.theme.myScrollbar}
     `;
     }
     return "";
   }}

   @media(max-width: 1000px) {
     max-width: 99%;     
     overflow-x: auto;
     ${(props) => {
       if (!props.theme.mobile) {
         return `${props.theme.myScrollBar}`;
       }
       return "";
     }}
 `;
export const TableWrapperSmall = styled.div`
  // when have scroll bar, scoll bar is right next to table
  width: fit-content;
  overflow-y: scroll;
  // margin-left: 10px;
  max-width: 63vw;
  ${(props) => {
    if (!props.theme.mobile) {
      return `
     overflow-y: auto;
     ${props.theme.myScrollbar}
   `;
    }
    return "";
  }}

  @media(min-width: 700px) {
    max-width: 65vw;
    overflow-x: auto;
    ${(props) => {
      if (!props.theme.mobile) {
        return `${props.theme.myScrollBar}`;
      }
      return "";
    }}
  }

  // @media(min-width: 800px) {
  //   max-width: 70vw;
  // }
  // @media(min-width: 1000px) {
  // max-width:95%;
  // }
  @media (min-width: 1150px) {
    max-width: 48vw;
  }
  @media (min-width: 1350px) {
    max-width: 100%;
  }
`;
export const PanelTableWrapperSmall = styled.div`
  // when have scroll bar, scoll bar is right next to table
  // width: fit-content;
  overflow-y: scroll;
  // margin-left: 10px;
  // max-width: 63vw;
  max-width: 100%;
  ${(props) => {
    if (!props.theme.mobile) {
      return `
     overflow-y: auto;
     ${props.theme.myScrollbar}
   `;
    }
    return "";
  }}

  @media(min-width: 700px) {
    // max-width: 65vw;
    overflow-x: auto;
    ${(props) => {
      if (!props.theme.mobile) {
        return `${props.theme.myScrollBar}`;
      }
      return "";
    }}
  }

  // @media(min-width: 800px) {
  //   max-width: 70vw;
  // }
  // @media(min-width: 1000px) {
  // max-width:95%;
  // }
  @media (min-width: 1150px) {
    // max-width: 48vw;
  }
  @media (min-width: 1350px) {
    // max-width: 100%;
  }
`;

export const TableView = styled.table`
  position: relative;
  // width:100%;
  background: rgba(0, 0, 0, 0.5);
  // border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  margin-bottom: 10px;
  // margin-left:10px;
  font-size: 11px;
  text-align: left;
  min-width: 100%;
  // max-width:100%;
  // margin-left:10px;
  // margin-left:5px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 16%);
  border: 1px solid rgb(255, 255, 255, 0.3);
  border-top: none;
  * {
    color: white;
  }

  tr:nth-child(even) {
    background: #333333;
  }
  tr:nth-child(odd) {
    background: #383838;
  }

  td {
    padding: 2rem;
    // width:200px;
    // width:100px;
    max-width: 128px;
    // border-top: 1px solid rgb(255, 255, 255, .2);
    border-bottom: 1px solid rgb(255, 255, 255, 0.2);
    // border-left: 1px solid rgb(255, 255, 255, .2);
    border-right: 1px solid rgb(255, 255, 255, 0.2);
    // border-collapse: collapse;
  }

  tr,
  td {
    // min-width: 10rem;
    flex: 1;
    overflow: visible;
    white-space: nowrap;
    // width: 115px;
    // max-width: 270px;
    height: 30px;
    padding: 5px 7px;

    font-style: normal;
    font-weight: normal;
    line-height: 0.3;
    overflow: visible;
  }

  th {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 30px;
    border-top: 1px solid rgb(255, 255, 255, 0.2);
    border-bottom: 1px solid rgb(255, 255, 255, 0.2);
    // border-left: 1px solid rgb(255, 255, 255, .2);
    border-right: 1px solid rgb(255, 255, 255, 0.2);
    border-collapse: collapse;
  }

  tr:hover {
    td,
    th {
      // word-break: break-all;
    }
  }

  thead {
    transition: width 0.3s;
    border-radius: 5px 5px 0px 0px;
    // when table set certain height, this works for sticky table header
    position: sticky;
    top: 0;
    z-index: 2;
    tr:first-child {
      th {
        padding: 5px 10px;
        text-transform: Capitalize;
        // height:50px;
        // width: 115px;
        min-height: 40px;
        background: #1a1a1a;
        position: relative;
        flex: 1;
        overflow: visible;
        font-size: 11px;

        // color: rgba(255,69,0,0.8);
        color: white;
        white-space: nowrap;
        line-height: 16.2px;
        text-align: center;
        // when table set certain height, this works for sticky table header
        position: sticky;
        top: 0;
        z-index: 2;
      }
      & th:first-child {
        padding: 5px 5px;
      }
      & th:last-child {
        border-right: none;
      }
    }
  }

  tbody {
    position: relative;
    max-height: 1000px;
    // transition: all 0.5s;
    opacity: 1;
    overflow: scroll;
  }

  tbody > tr td:first-child {
    padding-left: 10px;
  }

  tbody tr:last-child td {
    border-bottom: none;
  }

  tr td:last-child {
    border-right: none;
  }
`;

export const Input = styled.input`
  outline: none;
  border: none;
  width: 100%;
  text-overflow: ellipsis;
  color: white;
  background: rgb(75, 75, 75);
  padding: 0.5rem;
  border: 1px solid transparent;

  &::placeholder {
    color: rgb(126, 126, 126);
  }

  &:focus {
    border-color: ${(props) => props.theme.main};
  }
`;

export const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  flex: 3rem;
  color: white;
  display: ${(props) => {
    if (props.theme.mobile) return "none";
    return "block";
  }};
`;

export const Body = styled.div`
  flex: auto;
  position: relative; //  for sticky page header
  overflow-x: hidden;
  padding-left: 11px;
  padding-right: 20px;
  padding-top: 20px;
  // margin-bottom: ${isMobile ? "0px" : "35px"};
  ${(props) => {
    if (!props.theme.mobile) {
      return `
       overflow-y: auto;
       ${props.theme.myScrollbar}
     `;
    }
    return "";
  }}
  // hide scrollbar
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const ADIKeyPage = styled.div`
  width: 98%;
  flex: 14;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;
export const FlexBoxVertical = styled.div`
  display: flex;
  flex-direction: column;
`;
export const FlexBox = styled.div`
  display: flex;
  margin-top: 30px;
  @media (min-width: 440px) {
    // grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1150px) {
    // grid-template-columns: repeat(5, 1fr);
  }
`;
type FlexProps = {
  flexEnd?: boolean;
  flexStart?: boolean;
  flexBetween?: boolean;
  flexAround?: boolean;
  flexEvenly?: boolean;
  alignStart?: boolean;
  alignEnd?: boolean;
  column?: boolean;
  gap?: string;
};
export const FlexWrapper = styled.div`
  display: flex;
  gap: ${(props: FlexProps) => props.gap || "0px"};
  align-items: ${(props: FlexProps) => {
    if (props.alignEnd) {
      return "end";
    } else if (props.alignStart) {
      return "start";
    } else {
      return "center";
    }
  }};
  justify-content: ${(props: FlexProps) => {
    if (props.flexEnd) {
      return "flex-end";
    } else if (props.flexStart) {
      return "flex-start";
    } else if (props.flexBetween) {
      return "space-between";
    } else if (props.flexAround) {
      return "space-around";
    } else if (props.flexEvenly) {
      return "space-evenly";
    } else {
      return "center";
    }
  }};
  flex-direction: ${(props: FlexProps) => {
    if (props.column) {
      return "column";
    } else {
      return "row";
    }
  }};
`;

export const Grid = styled.div`
  display: grid;
  grid-gap: 1rem;
  @media (min-width: 440px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1150px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;
export const GridTwo = styled.div`
  display: grid;
  grid-gap: 1rem;
  @media (min-width: 400px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1150px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
export const GridTwoTemp = styled.div`
  display: grid;
  grid-gap: 1rem;
  @media (min-width: 400px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1150px) {
    grid-template-columns: 1fr 60px;
  }
`;

export const GridThree = styled.div`
  display: grid;
  //  grid-gap: 1rem;
  margin-top: 30px;
  @media (min-width: 440px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1150px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const GridThreeT = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin-top: 30px;
  margin-left: 10px;
  @media (min-width: 440px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (min-width: 1150px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
export const GridFour = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 10px;
  @media (min-width: 440px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 915px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;
export const GridFive = styled.div`
  display: grid;
  grid-gap: 1rem;
  @media (min-width: 490px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 830px) {
    // grid-template-columns: repeat(5, 1fr)
    grid-template-columns: repeat(3, 1fr);
  }
  // @media (min-width: 1200px) {
  //   grid-template-columns: repeat(5, 1fr);
  // }
  @media (min-width: 1355px) {
    grid-template-columns: repeat(6, 1fr);
  }
`;
export const GridSix = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  margin-bottom: 10px;
  @media (min-width: 440px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1150px) {
    grid-template-columns: repeat(6, 1fr);
  }
`;
export const GridSeven = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  margin-bottom: 10px;
  @media (min-width: 440px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1150px) {
    grid-template-columns: repeat(7, 1fr);
  }
`;
export const DateTimeContainer = styled.div`
  position: relative;
`;

export const TrackGridTwo = styled.div`
  display: grid;
  // margin-top:30px;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 5px;
  margin-bottom: 10px;

  @media (min-width: 1150px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
    margin-right: 20px;
  }
`;
export const TrackGridThree = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 5px;
  @media (min-width: 618px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1150px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
export const TrackGridFour = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 5px;
  @media (min-width: 618px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1150px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;
export const TrackGridFive = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 5px;
  @media (min-width: 618px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1150px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;
export const TrackFlexTwo = styled.div`
  display: flex;
  flex-direction: column-reverse;

  margin-bottom: 10px;

  @media (min-width: 1320px) {
    flex-direction: row;
  }
`;

export const DateFilterGridThree = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 5px;
  @media (min-width: 440px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1150px) {
    // display: flex;
    // justify-content: flex-end;
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const DatePickerBox = styled.div`
  width: 100%;
  @media (min-width: 440px) {
    margin-bottom: 0px;
  }
  @media (min-width: 1150px) {
    width: 33%;
  }
`;

export const TextHead = styled.h1`
  font-size: 14px;
  color: #fff;
  font-weight: 800;
`;
export const TextHeadCr = styled.h1`
  font-size: 18px;
  color: #fff;
  font-weight: 800;
  padding-top: 20px;
  margin-left: 15px;
  @media (max-width: 780px) {
    margin-left: 30px;
  }
`;

export const AssetGridFive = styled.div`
  display: grid;
  grid-gap: 1rem;
  @media (min-width: 490px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 830px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;
export const StyledSelect = styled.select`
  display: inline-block;
  margin-top: 0;
  background: #383838;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 16%);
  border-radius: 5px;
  height: 2.625rem;
  font-size: 1.125rem;
  -webkit-appearance: menulist-button;
  color: white;
  font-family: var(--font-normal);
  text-align: center;
  width: 98%;
  border: 1px solid rgba(255, 255, 255, 0.3);
  & option {
    color: white;
  }
  &:invalid,
  & option[value=""] {
    color: gray;
  }
`;

export const ModalBtn = styled.button`
  // background: #e93f33;
  border: 0px solid transparent;
  // border-radius: 5px;
  padding: 7px 0px;
  font-family: var(--font-normal);
  font-size: 12px;
  width: 130px;
  color: white;
  margin: 0 auato;
  margin-top: 10px;
  background: var(--primary-color) 0% 0% no-repeat padding-box;
  background: #3e7bfa 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  cursor: pointer;
`;
export const ModalBtnDisabled = styled.button`
  // background: #e93f33;
  border: 0px solid transparent;
  // border-radius: 5px;
  padding: 7px 0px;
  font-family: var(--font-normal);
  font-size: 12px;
  width: 130px;
  color: white;
  margin: 0 auato;
  margin-top: 10px;
  background: var(--primary-color) 0% 0% no-repeat padding-box;
  background: #ff0000 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
`;

export const ChainPageContainer = styled.div`
  // margin-left: 10px;
`;

// page tab navigation header styled components -> Modified, page tabs are styled in the PageTabs component
type Propsa = {
  border?: boolean;
};

export const StyledButton = styled.button`
  font-family: var(--font-normal);
  // height: 45px;
  padding-bottom: 15px;
  background: transparent;
  border-style: solid;
  text-align: left;
  margin-right: 1rem;
  border-color: ${({ border }: Propsa) => {
    if (border) {
      return ` transparent transparent ${primaryRed} transparent`;
    }
    return ` transparent`;
  }};
  &:hover {
    ${({ border }: Propsa) => {
      if (!border) {
        return `
          border-bottom: 2px solid #545454
          p {
            color: #D1D1D1;
          }
        `;
      }
    }};
  }
`;
export const StyledBackButton = styled.button`
  background: forestgreen;
  border-style: solid;
  text-align: left;
  color: white;
  padding: 5px 10px;
  border-radius: 12px;
  border: none;
  font-size: 1.2rem;
`;
// font-weight: ${({ border }: Propsa) => {
//   if (border) {
//     return "bold !important";
//   }
//   return "normal !important";
// }};
export const StyledText = styled.p`
  padding: 5px 0;
  font-style: normal;
  font-weight: ${({ border }: Propsa) => {
    if (border) {
      return "bold";
    }
    return "normal";
  }};
  font-size: 18px;
  line-height: 1.5rem;
  // text-transform: uppercase;
  color: #ffffff;
  background: transparent;
  // border-style: solid;
  text-align: left;
  width: fit-content;
  margin: 3px 0;
`;
export const StyledPlaceholderText = styled(StyledText)`
  font-size: 1.25rem; // 1.5rem before
`;

export const ShowHeader = styled.div`
  display:flex
  margin-right:20px;
  align-items: center;
  margin-bottom: 5px;
  @media (min-width: 440px) {
    margin: 0 10px 0;
    // margin-right: 0;
  }
`;

export const PageHeader = styled.div`
*{
  color: white;
}

&>p{
  font-size: 1.2rem;
  margin: 0;
  padding: 0;
}
display:flex
align-item:flex-end;
justify-content: ${(props) => {
  if (props.theme.mobile) return "flex-end";
  return "none";
}}
`;

// popup modal styled component
export const PopupContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 3;
`;
export const PopupWrapper = styled.div`
  box-sizing: border-box;
  position: absolute;
  left: ${(props) => {
    if (props.theme.mobile) return 0;
    return "30%";
  }};
  top: 43px;
  height: auto;
  width: ${(props) => {
    if (props.theme.mobile) return "100%";
    return "40%";
  }};
  margin: auto;
  z-index: 1;
  border-radius: 5px;
  border: 0px solid rgb(210, 210, 210);
  padding: 1rem 1rem 2rem;
  background: #191919;
  display: ${({ show }: { show: boolean }) => {
    if (show) return "block";
    return "none";
  }};
`;

export const PopupFormWrapper = styled.div`
  padding: 5rem 0;
`;

export const PopupFormItem = styled.div`
  padding: 0 1rem;
  width: 100%;
  h1 {
    font-size: 1.5rem;
    padding: 1rem;
    margin: 0;
    color: white;
  }

  & > *:not(h1) {
    background: #454545;
    font-size: 1.3rem;
    border-radius: 5px;
    min-height: 4rem;
    padding: 1rem;

    &::placeholder {
      color: rgb(210, 210, 210);
    }
  }
`;

export const PopupCenterSpan = styled.span`
  width: 200px;
  position: absolute;
  bottom: 90%;
  left: 50%;
  margin-top: -100px;
  margin-left: -100px;
  font-weight: bold;
  font-size: 16px;
  color: white;
  text-align: center;
`;
export const PopupCloseButton = styled.span`
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
`;

export const PopupStyledButton = styled(WhiteButton)`
  margin-left: 1rem;
  background: #3e7bfa;
  border-radius: 5px;
  padding: 0 1rem;
  width: 95%;
  height: 40px;
  color: #fff !important;
  font-family: var(--font-normal) !important;
  font-style: normal !important;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 15px;
`;

export const StyledInputLabel = styled.label`
  display: flex;
  font-size: 10px;
  margin: 0.5em 0;
  letter-spacing: 0.075em;
  line-height: 1.5;
  cursor: pointer;
  margin-right: 5px;
  input {
    // margin: 5px 6px 8px 0;
  }
`;

export const StyledInput = styled.input`
  appearance: none;
  width: 12px;
  height: 12px;
  background-color: #383838;
  border: 1px solid white;
  border-radius: 2px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin: 0px;
  :checked {
    background-color: white;
    color: #383838;
  }

  :checked::after {
    content: "\\2714";
    font-size: 13px;
    position: absolute;
    top: -4px;
    left: 0;
    color: black;
  }
`;
export const StyledTextInput = styled.input`
  display: inline-block;
  margin-top: 0;
  background: #383838;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 16%);
  border-radius: 5px;
  height: 26px;
  font-size: 11px;
  -webkit-appearance: menulist-button;
  color: white;
  font-family: var(--font-normal);
  width: 98%;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 5px;
`;

export const ChainListItemCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 16%);
  border-radius: 10px;
  width: 98%;
  font-family: var(--font-normal);
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 10px;
  padding: 10px 24px;
  background: #383838;
  cursor: pointer;
`;

export const ChainCardLeftBox = styled.div`
  font-size: 1.5rem;
  color: ${primaryRed};
  font-weight: bold;
`;
export const ChainCardRightBox = styled.div`
  font-size: 14px;
  p {
    margin-bottom: 0px;
  }
`;
export const ChainCardLabel = styled.span``;
export const ChainCardValue = styled.span`
  opacity: 0.6;
`;

export const TabsBox = styled(Box)`
  padding: 0 0px;
`;

// issue modal component
export const CenterSpan = styled.span`
  width: 200px;
  position: relative;
  top: 51%;
  left: 50%;
  margin-top: -100px;
  margin-left: -50px;
  font-family: var(--font-normal);
  font-size: 14px;
  color: white;
  text-align: center;
`;

export const DataRow = styled.div`
  display: flex;

  font-size: 11px;
  font-weight: 500;
  color: white;
  padding: 6px 0 0 0;
`;

export const LeftDesc = styled.p`
  width: 30%;
  float: left;
  margin: 0;
`;
export const RightDesc = styled.div`
  width: 70%;
  float: right;
  margin: 0;
`;

export const CommentArea = styled.textarea`
  background: #383838;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 16%);
  border-radius: 5px;
  height: 150px;
  font-size: 11px;
  color: white;

  padding: 5px;
  width: 100%;
  overflow: scroll;
  border: 1px solid rgba(255, 255, 255, 0.2);
`;

export const MenuTitle = styled.label`
  display: flex;
  font-size: 10px;
  margin: 0.5em 0;
  letter-spacing: 0.075em;
  line-height: 1.5;
  cursor: pointer;
  margin-right: 10px;
  input {
    // margin: 5px 6px 8px 0;
  }
`;

export const MenuInput = styled.input`
  appearance: none;
  width: 10px;
  height: 10px;
  background-color: #383838;
  border: 1px solid white;
  border-radius: 2px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-top: 2px;
  margin-right: 5px;
  :checked {
    background-color: white;
    color: #383838;
  }

  :checked::after {
    content: "\\2714";
    font-size: 11px;
    position: absolute;
    top: -4px;
    left: 0;
    color: black;
  }
`;

export const SelectMenuContainer = styled.div`
  width: 100%;
  display: flex;
  background: #383838;
  padding: 0 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  flex-wrap: wrap;
  // @media (max-width: 1200px) {
  //   padding: 15px ;
  // }
  // @media (max-width: 700px) {
  //   padding: 10px;
  // }
`;

export const AccItemContainer = styled.div`
  display: flex;
  // align-items: center;
`;

export const TableWrapP = styled.p`
  padding: 3px 0;
  white-space: break-spaces !important;
  line-height: 1.4rem;
  margin: 0;
  word-break: break-word;
`;

// Inventory Management
export const RawMaterialContainer = styled.div`
  // margin-left: 10px;
`;

export const ActionButton = styled.span`
  padding: 3px 10px; // changed from 3px 6px
  border-radius: 3px;
  cursor: pointer;
`;

export const FormWrapper = styled.div``;

export const Title = styled.h3`
  opacity: 0.8;
  font-size: 11px;
  color: tomato;
  margin-bottom: 0 !important;
  border-bottom: 1px solid gray;
`;
export const Desc = styled.p`
  font-family: var(--font-normal);
  font-size: 11px;
  font-weight: 500;
  color: white;
  margin-left: 20px;
`;

export const LeftDiv = styled.div`
  width: 46%;
  float: left;
`;
export const RightDiv = styled.div`
  width: 46%;
  float: right;
  margin-left: 20px;
`;

export const RightDescP = styled.p`
  margin: 0;
`;

export const TableP = styled.p`
  padding: 5px 0;
  margin: 0;
  // white-space: break-spaces !important;
  // line-height: 1.5rem;
`;

export const TableLocationWrapP = styled.p`
  padding: 5px 0;
  white-space: break-spaces !important;
  line-height: 1.5rem;
  margin: 0;
  padding-top: 24px;
`;

export const TableLongTD = styled.td`
  width: 296px !important;
  display: inline-block;
  line-height: 31px !important;
`;

export const TableLongTH = styled.th`
  width: 296px;
  display: inline-block;
  line-height: 31px !important;
`;

export const StyledModal = styled.div`
  background-color: var(--panel-color);
  border-radius: var(--border-radius);
  border: 1px solid
    ${({ borderGrey }: { borderGrey?: boolean }) =>
      borderGrey ? "var(--grey-text-color)" : "white"};
  color: var(--white-text-color);
  padding: 15px;
  width: 100%;
`;

export const LogFaintText = styled.p`
  color: var(--white-text-color);
  font-size: 1.25rem;
  margin: 0;
  opacity: 0.7;
`;

export const MonthTitle = styled.h3`
  color: var(--white-text-color);
  margin-top: 20px;
  font-size: 1.75rem;
`;
export const Label = styled.div`
  // width: "100rem";
  margintop: "5px";
  font-size: 1.5rem;
  color: #e4e4eb;
`;

export const IntroText = styled.p`
  opacity: 0.8;
  padding: 5px 0;
  font-style: normal;
  font-weight: normal !important;
  font-size: 14px;
  // font-size: 0.88rem;
  color: #ffffff;
  background: transparent;
  text-align: left;
  margin: 3px 0;
  margin-right: 1rem;
  line-height: 2.5rem;
`;
export const CollapseDots = styled.div`
  margin: 0 -10px 0 10px;
  :hover {
    background-color: rgb(107, 107, 107, 50%);
  }
  height: 150%;
  padding: 10px 0;
  border-radius: var(--border-radius);
`;

export const LogContents = styled.div`
  color: white !important;
  p,
  tr,
  td,
  th,
  span,
  div,
  li,
  ul,
  ol,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  input,
  textarea,
  select,
  button,
  label,
  option,
  optgroup,
  fieldset,
  legend,
  form,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  menu,
  nav,
  section,
  summary,
  time,
  mark,
  font,
  b {
    color: white !important;
    background-color: transparent !important;
  }

  p {
    margin-bottom: 0;
  }
`;

export const RulesInfoDiv = styled.div`
  background-color: #494949;
  padding: 20px;
  border: 1px solid #707070;
  border-radius: 4px;
  // width: 900px;
  margin-right: 5px;
  margin-bottom: 1rem;
`;

export const AddDelHintText = styled.span`
  opacity: 0.8;
  padding: 5px;
  font-style: normal;
  font-weight: normal !important;
  font-style: italic;
  font-size: 1rem;
  color: #ffffff;
  background: transparent;
  text-align: right;
  margin: 3px 0;

  line-height: 1.0625rem;
`;
export const PicBox = styled.div`
  display: flex;
  padding: 0.8rem;
  border-radius: 4px;
  border: 1px solid var(--input-border-color);
  background: inherit !important;
  align-items: center;
`;
export const PreviewBox = styled.div`
  display: flex;
  width: 7rem;
  height: 7rem;
  // background: #262626;
  background: #2c2d42;
  border-radius: 4px;
  margin-right: 1rem;
  position: relative;
  // padding: 0.5rem;
  span {
    display: none;
  }

  :hover {
    > span {
      display: block;
    }
    img {
      opacity: 0.55;
    }
  }
`;

export const CloseSpan = styled.span`
  position: absolute;
  top: 0;
  right: 2px;
  color: white;
  cursor: pointer;
  z-index: 5;
`;

export const UploadLabel = styled.label`
  font-size: 2rem;
  color: #e4e4eb;
  margin-bottom: 0;
  opacity: 0.65;
  cursor: pointer;
`;

export const MergeableTableCellP = styled.p`
  padding: 16px;
`;

export const UploadLabelButton = styled.label`
  color: #e4e4eb;
  background-color: var(--grey-text-color);
  border-radius: 4px !important;
  padding: 0.5rem;
  width: 11rem;
  margin-bottom: 1rem;
  text-align: center;
`;

export const DetailsContainer = styled.div`
  ${theme.myScrollbar}
  overflow-y: auto;
  background-color: #1a1a1a;
  padding: 25px;
  width: 100%;
  border-radius: var(--border-radius);
  box-shadow: 0px 4px 2px 0px #00000040;
`;
export const RelativeContainer = styled.div`
  position: relative;
`;

export const DisplayEmptyText = styled.p`
  color: var(--white-text-color);
  font-size: 16px;
  text-align: center;
  margin-top: 50px;
`;
export const DisplayEmptyTextPrimary = styled.p`
  color: var(--white-text-color);
  font-size: 24px;
  text-align: center;
  margin-top: 50px;
`;
export const DisplaySecondaryText = styled.p`
  color: var(--white-text-color);
  font-size: 16px;
  text-align: center;
  margin-top: 18px;
  width: 25%;
  margin-left: 50%;
  transform: translateX(-50%);
`;
export const DisplayEmptyTextSecondary = styled.p`
  color: var(--white-text-color);
  font-size: 16px;
  text-align: center;
  margin-top: 50px;
  width: 25%;
  margin-left: 50%;
  transform: translateX(-50%);
`;

export const ColumnSelectLabel = styled.label`
  font-size: 1.5rem;
  color: var(--white-text-color);
  margin-bottom: 0;
  opacity: 0.65;
  margin-top: 5px;
`;

export const StyledFileDraggerContainer = styled.div`
  border: 1px dashed rgba(94, 94, 94, 1) !important;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  border-radius: var(--border-radius);
  p.ant-uploaded-image-text {
    color: white !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    span:first-child {
      margin-right: 20px;
    }
    a {
      color: white;
    }
  }
`;
export const StyledFileDragger = styled(Dragger)`
  background-color: transparent !important;
  p {
    color: var(--grey-background-color) !important;
  }
  .ant-upload-text {
    font-weight: bold !important;
  }
`;
export const FormRequireText = styled.p`
  color: var(--white-text-color);
  opacity: 0.8;
  margin-bottom: 30px;
  margin-top: 30px;
  span {
    color: var(--itrazo-red-color);
  }
`;

type LabelProps = {
  backgroundColor?: string;
};
export const StyledStatusLabel = styled.div`
  width: auto;
  padding: 4px 6px !important;
  border-radius: 50px;
  display: inline-block;
  font-size: 12px;
  background-color: ${(props: LabelProps) => {
    if (props.backgroundColor && props.backgroundColor != "")
      return props.backgroundColor;
    return "#22BB33";
  }};
  span {
    color: var(--primary-color);
  }
`;

export const ToggleDiv = styled.div`
  display: flex;
  width: 150px;
  border: 1px solid var(--input-border-color);
  border-radius: 50px;
  justify-content: space-between;
  background-color: var(--main-background-color);
  font-size: 14px;
  // box-shadow: 0px 4px black;
`;

export const Toggle = styled.span`
  width: 100px;
  padding: 5px 5px;
  cursor: pointer;
  text-align: center;
  color: ${(props: { border: boolean }) => {
    if (props.border) return "var(--white-text-color);";
    return "#8f90a6";
  }}
  background: ${(props: { border: boolean }) => {
    if (props.border) return "var(--primary-color);";
    // return "linear-gradient(180deg, #E33622 51.39%, #BA1200 100%);";
    return "transparent;";
  }}
  border-radius:  ${(props: { border: boolean }) => {
    if (props.border) return "50px;";
  }};
  
`;

export const HeadingLabel = styled.p`
  font-size: 16px;
  color: #ffffff;
`;
export const SubHeadingLabel = styled.p`
  font-size: 12px;
  color: #ffffff;
`;

export const TableStatusTag = styled.span`
  color: ${(props: { color: string }) => props.color};
  background: transparent;
  border: 1px solid ${(props: { color: string }) => props.color};
  border-radius: 20px;
  padding: 4px 10px;
  font-size: 10px;
  text-align: center;
`;

export const DisplayPage = styled.div`
  display: ${({ active }: { active: boolean }) => {
    return active ? "block" : "none";
  }};
`;

export const BtnText = styled.span`
  color: var(--white-text-color);
  display: flex;
  align-items: center;
`;

export const MoreIcon = styled(MoreOutlined)`
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  top: 13px;
  font-size: 20px;
  font-weight: bold;
  padding: 3px;
  &:hover {
    cursor: pointer;
    // background-color: #33334d;
    // filter: brightness(1.2);
    // border-radius:50%;
  }
`;

export default null;
