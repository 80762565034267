import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Lineage from "containers/Pages/ProductTracking/Lineage";
import VCTRoutes from "containers/Pages/ProductTracking/VCTRoutes";
import SupplyChain from "containers/Pages/ProductTracking/SupplyChain.tsx";
import Track from "containers/Pages/ProductTracking/Track";
import AllItems from "containers/Pages/ProductTracking/Items/AllItems";
import TraceBy from "containers/Pages/ProductTracking/TraceBY";
import Alerts from "containers/Pages/ProductTracking/Alerts";
import Issues from "containers/Pages/ProductTracking/Issues";
import Recalls from "containers/Pages/ProductTracking/Recall/Recalls";
import Recall from "containers/Pages/ProductTracking/Recall";
import ShelfLifeMonitoring from "containers/Pages/ProductTracking/ShelfLifeMonitoring.tsx";
import Documents from "containers/Pages/ProductTracking/Documents";
import Scans from "containers/Pages/ProductTracking/Scans";
import ProductTracking from "containers/Pages/ProductTracking";
import { PageWrapper, Body } from "../../../statics/styles/StyledComponents";
import { VCT_ROOT_PATH } from "containers/Pages/ProductTracking/VCTRoutes";
import styled from "styled-components";

const Right = styled.div`
  height: 100%;
  padding: 0 0.5em 0.5em 0.5em;
  border-radius: 5px;
  margin-right: 1em;
  margin-left: 1em;
`;

const ValueChainTrackingRoutes = () => (
  <Routes>
    <Route
      path={VCT_ROOT_PATH}
      element={<Navigate to={`${VCT_ROOT_PATH}/origin`} />}
    />
    <Route
      path={`${VCT_ROOT_PATH}/origin`}
      element={
        <>
          <ProductTracking />
          <div>
            <Lineage />
          </div>
        </>
      }
    />
    <Route
      path={`${VCT_ROOT_PATH}/supplyChain`}
      element={
        <>
          <ProductTracking />
          <Right>
            <SupplyChain />
          </Right>
        </>
      }
    />
    <Route
      path={`${VCT_ROOT_PATH}/events`}
      element={
        <>
          <ProductTracking />
          <Right>
            <Track />
          </Right>
        </>
      }
    />
    <Route
      path={`${VCT_ROOT_PATH}/items`}
      element={
        <>
          <ProductTracking />
          <Right>
            <AllItems />
          </Right>
        </>
      }
    />
    <Route
      path={`${VCT_ROOT_PATH}/trace`}
      element={
        <>
          <ProductTracking />
          <Right>
            <TraceBy />
          </Right>
        </>
      }
    />
    <Route
      path={`${VCT_ROOT_PATH}/alerts`}
      element={
        <>
          <ProductTracking />
          <Right>
            <Alerts />
          </Right>
        </>
      }
    />
    <Route
      path={`${VCT_ROOT_PATH}/issues`}
      element={
        <>
          <ProductTracking />
          <Right>
            <Issues />
          </Right>
        </>
      }
    />
    <Route
      path={`${VCT_ROOT_PATH}/recalls`}
      element={
        <>
          <ProductTracking />
          <Right>
            <Recalls />
          </Right>
        </>
      }
    />
    <Route
      path={`${VCT_ROOT_PATH}/productLife`}
      element={
        <>
          <ProductTracking />
          <Right>
            <ShelfLifeMonitoring />
          </Right>
        </>
      }
    />
    <Route
      path={`${VCT_ROOT_PATH}/compliance`}
      element={
        <>
          <ProductTracking />
          <Right>
            <Documents />
          </Right>
        </>
      }
    />
    <Route
      path={`${VCT_ROOT_PATH}/scans`}
      element={
        <>
          <ProductTracking />
          <Right>
            <Scans />
          </Right>
        </>
      }
    />
  </Routes>
);

export default ValueChainTrackingRoutes;