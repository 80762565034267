/**
 * Types used within SOM modules
 */


export type SOMTab = 'Dashboard' | 'Sales' | 'Purchases' | 'Procurement' | 'Inventory'
export type OsmInventoryTab = 'items' | 'inventory' | 'QA Check'
export type OsmSalesTab = 'open' | 'closed' | 'cancelled' | 'picklist_generated' | 'picklist' | 'picked' | 'packed' | 'confirmed' | 'delivery_scheduled' | 'dispatched' | 'reports'
export enum SOMPageView {
  TableView = "TableView",
  CreateView = "CreateView",
  ReceiveView = "ReceiveView",
  RejectOrderView = "RejectOrderView",
}
export enum SOMPageType {
  Customer = "customer",
  Sales = "sales",
  Purchases = "purchases",
  Inventory = "inventory",
}

export type PurchaseType = {
  order_ID: string,
  order_date: string,
  supplier_name: string,
  supplier_id: string | undefined,
  email?: string,
  phoneNo?: string,
  supplier_email?: string,
  supplier_phone?: string,
  delivery_date: string,
  order_status: string,
  delivery_address: string,
  productitemsList: any[],
  billing_address: string,
  created_by: string,
  created_date: any,
  product_info: any[],
  total_price: string,
  gst_price?: string,
  mrp_price?: string,
  comments: string,
}

export type RejectType = {
  rejection_ID: string,
  product_info: any[],
  selectedAction: string,
  order_ID: string,
  supplier_id: string,
  supplier_name: string,
  reported_by: string,
  description_of_rejection: string,
  images: any[],
}

export type InventoryItem = {
  item_category: string,
  item_sub_category?: string,
  item_name: string,
  sku: string,
  measurement_unit: string,
  item_image: any,
  assign_to?: string,
  item_description: string,
  supplier_id: any[],
  customer_id?: any[],
  supplier_name: string,
  lead_time_for_replenishement: any,
  cost_price: string,
  safety_stock_count: string,
  reorder_level: string,
  selling_price: string,
  comments: string,
  voided?: string,
  sub_category_voided?: string,
}

export type InspectionData = {
  "inspection_ID": string, // mandatory
  "inspected_on": any,
  "inspected_by": string,
  "picklist_ID": string,
  "total_orders": string,
  "inspection_outcome": string,
  "comments": string,


}