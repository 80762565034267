import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Company, Contact, Dashboard, Task } from './pages';
import CompanyDetails from './pages/Company/DetailsView';
import ContactDetails from './pages/Contact/DetailsView';

export const CRM_ROOT_PATH = '/app/relationship-management';

export const CRM_ROUTES = {
  DASHBOARD: `${CRM_ROOT_PATH}/dashboard`,
  COMPANY: `${CRM_ROOT_PATH}/company`,
  CONTACT: `${CRM_ROOT_PATH}/contact`,
  TASK: `${CRM_ROOT_PATH}/task`,
  COMPANY_DETAILS: `${CRM_ROOT_PATH}/company-details`,
  CONTACT_DETAILS: `${CRM_ROOT_PATH}/contact-details`,
};

const CRMRoutes = () => {
  return (
    <Routes>
      <Route path={`${CRM_ROOT_PATH}`} element={<Navigate to={`${CRM_ROOT_PATH}/dashboard`} />} />
      <Route path={CRM_ROUTES.DASHBOARD} element={<Dashboard />} />
      <Route path={CRM_ROUTES.COMPANY} element={<Company />} />
      <Route path={CRM_ROUTES.CONTACT} element={<Contact />} />
      <Route path={CRM_ROUTES.TASK} element={<Task />} />
      <Route path={`${CRM_ROUTES.COMPANY_DETAILS}/:businessId`} element={<CompanyDetails />} />
      <Route path={`${CRM_ROUTES.CONTACT_DETAILS}/:contactId`} element={<ContactDetails />} />
    </Routes>
  );
};

export default CRMRoutes;
