import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components';
import Input from 'components/Input';
import { ColumnSelectLabel, Toggle, ToggleDiv } from 'statics/styles/StyledComponents';
import { Row, Col } from 'antd'
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getSelectorOptions } from 'utilities/Functions/GlobalHelperFunctions';
import { capitaliseFirst, formatTitle } from 'utilities/Functions/FormatFunctions';
import ColumnSelectButton, { ColumnOptionsType } from 'components/GlobalButtons/ColumnSelectButton';
import { useAuthStore } from 'zustand-stores';
import { AuthStore } from 'zustand-stores/useAuthStore';



type Props = {
  btnOnClick: Function;
  handleToggle?: Function;
  onTextSearch: Function;
  onDateChange: Function;
  onTableColumnSelect: Function;
  selectedTablesColumns: { due_table: any[], completed_table: any[] };
  columnSelectOptions: { due: ColumnOptionsType, completed: ColumnOptionsType };
}

const TaskHeader = (props: Props) => {
  const { btnOnClick, handleToggle, onTextSearch, onDateChange, columnSelectOptions, selectedTablesColumns, onTableColumnSelect } = props
  const isCrmReadonly = useAuthStore((state: AuthStore) => state.moduleAccess.crm.isReadOnly)
  const [toggle, setToggle] = useState<'Due' | 'Completed'>('Due')
  const [searchText, setSearchText] = useState<string>('')
  const [date, setDate] = useState<any>(null)

  const columnOptions = useMemo(() => {
    const key = toggle === 'Due' ? 'due' : 'completed'
    return columnSelectOptions[key]
  }, [toggle])

  const selectedColumns = useMemo(() => {
    const key = toggle === 'Due' ? 'due_table' : 'completed_table'
    return selectedTablesColumns[key]
  }, [toggle, selectedTablesColumns])

  useEffect(() => {
    setSearchText('')
    setDate(null)
  }, [toggle])

  const updateSelectedColumns = (selectedColumns: any[]) => {
    onTableColumnSelect(selectedColumns, toggle.toLocaleLowerCase() + '_table')
  }


  return (
    <div style={{ color: 'white', margin: '0 10px' }}>
      <Row gutter={[0, 15]} align="middle" justify="space-between">
        <Col>
          <Row gutter={[15, 15]} align="bottom">
            <Col>
              <ToggleDiv style={{ width: '200px' }}>
                <Toggle border={toggle === 'Due' ? true : false}
                  onClick={() => {
                    if (handleToggle) handleToggle('Due')
                    setToggle('Due')
                  }}
                >
                  Due
                </Toggle>
                <Toggle
                  border={toggle === 'Completed' ? true : false}
                  onClick={() => {
                    if (handleToggle) handleToggle('Completed')
                    setToggle('Completed')
                  }}
                >
                  Completed
                </Toggle>
              </ToggleDiv>
            </Col>
            <Col>
              <Input
                type="search"
                placeholder="Search by Task Title"
                onSearch={() => onTextSearch(searchText)}
                onChange={(e: any) => {
                  const value = e.target.value
                  setSearchText(value)
                  if (!value) {
                    onTextSearch('')
                  }
                }}
                style={{ width: '250px' }}
                value={searchText}
                allowClear
              />
            </Col>
            <Col>
              <Input
                type="daterange"
                allowClear
                onChange={(_e: any, date: [string, string]) => {
                  if (date[0] === "" || date[1] === "" || !date[0] || !date[1]) {
                    onDateChange(date);
                    setDate(["", ""]);
                    return;
                  }
                  onDateChange(date)
                  setDate([moment(date[0], "DD/MM/YYYY"), moment(date[1], "DD/MM/YYYY")])
                }}
                value={date}
                disabledDate={() => {
                  // don't disable any date
                  return false;
                }}
              >
              </Input>
            </Col>
          </Row>
        </Col>

        <Col style={{ display: 'flex' }}>
          <Row align='bottom'>
            <Row style={{ marginRight: '1.5rem', display: 'flex', alignItems: 'center' }}>
              <ColumnSelectButton
                selectedColumns={selectedColumns}
                columnOptions={columnOptions}
                updateSelectedColumns={updateSelectedColumns}
              />
            </Row>
            {
              !isCrmReadonly &&
              <SimpleButton
                text={<BtnText><PlusOutlined />&nbsp;&nbsp;Add Task</BtnText>}
                onClick={btnOnClick}
                style={{ marginTop: '5px', padding: '0px 15px' }}
              />
            }

          </Row>
        </Col>
      </Row>
    </div>

  )
}

export default TaskHeader


const BtnText = styled.span`
  color: var(--white-text-color);
  display: flex;
  align-items: center;
`