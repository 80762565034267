import React from "react";
import { isMobile } from "react-device-detect";
import {
  PageWrapper,
  Body,
} from "../../../statics/styles/StyledComponents";
import Header from '../../Headers/DesktopHeader';
import { getSessionStorage } from "utilities/Functions/GlobalHelperFunctions";
import PageTabs from "../../../components/PageTabs";
import { Orders } from "../InventoryManagement/pages";
import { ScanDisplayModal } from "../InventoryManagement/components";
import SPMRoutes, { SPM_ROUTES } from "./SPMRoutes";


export const ADMIN_TABS = {
  DASHBOARD: "Dashboard",
  PURCHASES: 'Supplier Orders',
  QUALITY_MANAGEMENT: 'Quality Management',
  INVENTORY: 'Inventory',
  ACG_PRODUCT_ORDERS: 'ACG Product Orders',
  DISTRIBUTION_ORDERS: '3PL Distribution Orders',
  ECOMMERCE_ORDERS: 'E-Commerce Orders',
  CUSTOMER_PORTAL_ORDERS: 'Customer Portal Orders',
  CROSSDOCKINGLAYOUT: 'Cross Docking Layout',
};

const tabsData = [
  {
    label: ADMIN_TABS.DASHBOARD,
    key: ADMIN_TABS.DASHBOARD,
    path: SPM_ROUTES.dashboard
  },
  {
    label: ADMIN_TABS.PURCHASES,
    key: ADMIN_TABS.PURCHASES,
    path: SPM_ROUTES.procurement
  },
  {
    label: ADMIN_TABS.QUALITY_MANAGEMENT,
    key: ADMIN_TABS.QUALITY_MANAGEMENT,
    path: SPM_ROUTES.qualityManagement
  },
  {
    label: ADMIN_TABS.INVENTORY,
    key: ADMIN_TABS.INVENTORY,
    path: SPM_ROUTES.inventory
  },
  {
    label: ADMIN_TABS.ACG_PRODUCT_ORDERS,
    key: ADMIN_TABS.ACG_PRODUCT_ORDERS,
    path: SPM_ROUTES.acgProductOrders
  },
  {
    label: ADMIN_TABS.DISTRIBUTION_ORDERS,
    key: ADMIN_TABS.DISTRIBUTION_ORDERS,
    path: SPM_ROUTES.distributionOrders
  },
  {
    label: ADMIN_TABS.ECOMMERCE_ORDERS,
    key: ADMIN_TABS.ECOMMERCE_ORDERS,
    path: SPM_ROUTES.eCommerceOrders
  },
  {
    label: ADMIN_TABS.CUSTOMER_PORTAL_ORDERS,
    key: ADMIN_TABS.CUSTOMER_PORTAL_ORDERS,
    path: SPM_ROUTES.customerPortalOrders
  }, {
    label: ADMIN_TABS.CROSSDOCKINGLAYOUT,
    key: ADMIN_TABS.CROSSDOCKINGLAYOUT,
    path: SPM_ROUTES.crossDocking
  }
]

const StockAndProductionManagement = () => {
  const level = getSessionStorage('ACCESS_LEVEL', true) || {};


  const tabs = tabsData.map((item: any) => {
    return {
      text: item.label,
      path: item.path
    };
  });



  return (
    <PageWrapper>
      {!isMobile && (
        (level?.osm === 'admin' ||
          level?.osm === 'marketing' ||
          level?.osm === 'ops' ||
          level?.osm === 'quality' ||
          level?.osm === 'production') ? (
          <Header pageTitle="Stock & Production Management" />
        ) : (
          <Header pageTitle="Order Management" />

        )
      )}
      <Body>
        {level?.osm == 'admin' || level?.osm == 'marketing' || level?.osm == 'ops' || level?.osm == 'quality' || level?.osm == 'production' ?
          <>
            <PageTabs tabs={tabs} rowStyle={{ marginBottom: "20px", }} />
            <SPMRoutes />
          </>
          :
          level.osm == 'customer' &&
          <Orders />
        }

      </Body>
      <ScanDisplayModal />
    </PageWrapper>
  );
};


export default StockAndProductionManagement;
