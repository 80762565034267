/**
 * @description Company details view page, displays the details of a company
 * @version 1.0.0
 * @author Bruce Zhu
 * @date 2023-03-09
 */
import React from "react";
import { DetailsPageContainer } from "../../components";
import { CRM_ROUTES } from "../../CRMRoutes";
import { DetailsPageContext } from "../../context";
import { useNavigate } from "react-router-dom";

const DetailsView = () => {
  const navigate = useNavigate();

  const switchToTableView = () => {
    navigate(CRM_ROUTES.COMPANY);
  };

  return (
    <DetailsPageContext>
      <DetailsPageContainer
      // onBackClick={switchToTableView}
      />
    </DetailsPageContext>
  );
};

export default DetailsView;
