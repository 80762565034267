import { DeleteOutlined } from '@ant-design/icons';
import { Form, message, Button as AntdButton, Row, Col, } from 'antd';
import AdiTable from 'components/AdiTable';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import GlobalModal from 'components/GlobalModal';
import Input from 'components/Input';
import AdiLabel from 'components/Label';
import moment from 'moment';
import React, { ChangeEvent, useEffect, useState, MouseEvent } from 'react'
import styled from 'styled-components';
import { DATE_TIME_FORMAT, VALIDATE_MESSAGES, NO_DEVICE_OR_DATE } from 'utilities/CONSTANTS';
import { disabledDateBeforeTodayFunction } from 'utilities/Functions/GlobalHelperFunctions';
import {
    DataRow,
    FlexBox,
    FlexBoxVertical,
    LeftDesc,
    RightDesc, UploadLabelButton
} from "../../../../../statics/styles/StyledComponents";
import { useFileUpload } from 'utilities/hooks';
import AllocationAPI from '../../apis/AllocationAPI';
import { ALLOCATION_REQUEST_STATUS } from '../../statics/constants';


import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';

type Props = {
    isModalOpen: any;
    setIsModalOpen: Function;
    modalData: any;
    setModalData: Function;
    refreshPage: Function;
}

type AllocationData = {
    master_data_refID: string;
    datetime: string;
    expected_return_date: string;
    asset_IDs: string[];
    assets: { [key: string]: string }[];
    dispatch_date: string;
    dispatch_comments: string;

    csv_s3url: string;

}

const AllocationModal = (props: Props) => {

    const { isModalOpen, setIsModalOpen, modalData, setModalData, refreshPage } = props



    const [allocationForm] = Form.useForm();
    const [fileHandler] = useFileUpload();

    const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

    const emptyModalData: AllocationData = {
        master_data_refID: "",
        datetime: "",
        expected_return_date: "",
        asset_IDs: [],
        assets: [{
            asset_ID: '',
            asset_name: ''
        }],
        dispatch_date: "",
        dispatch_comments: "",
        csv_s3url: ""

    }


    const [idNameFile, setIdNameFile] = useState<any>(null);

    const isModalOpenInit = {
        isAllocateOpen: false,
        isRetrieveOpen: false,
        isNewRequest: false,
        isRequestEdit: false,
        isView: false,
    }



    const initialize = () => {
        console.log('passed modalData', modalData)

        const finalData = modalData
        allocationForm.setFieldsValue({ ...finalData })

    }



    useEffect(initialize, [isModalOpen.isAllocateOpen, isModalOpen.isRetrieveOpen, modalData])


    const assetColumns: any = [
        {
            title: "Asset ID",
            dataIndex: "asset_ID",
            align: "left",
            key: "asset_ID",
            render: (text: any, record: any, index: number) => ({
                props: { style: { padding: '0.5rem', } },
                children: record.asset_ID == '' ? (
                    <span style={{ visibility: 'hidden' }}>
                        Empty
                    </span>
                ) : (
                    <>{record.asset_ID}</>
                )

            }),

        },

        // {
        //     title: "Asset Name",
        //     dataIndex: "asset_name",
        //     align: "center",
        //     key: "asset_name",
        //     render: (text: any, record: any, index: number) => ({
        //         props: { style: { padding: '0.5rem', } },
        //         children: (
        //             <>
        //                 {record.asset_name}
        //             </>
        //         )

        //     }),
        // },

    ];



    const handleAllocateOrRetrieveConfirm = async (values: any) => {
        // setIsModalOpen(false);

        if (!idNameFile) {
            message.info('Please upload files')
            return
        }
        console.log("modalData", modalData);
        console.log("values", values);

        const { dispatch_comments, dispatch_date, expected_return_date, returned_comments, returned_date } = values
        const { datetime, master_data_refID, type } = modalData



        const csv_s3url = `${idNameFile.name}`

        const curkey = fileHandler.constructS3Key(fileHandler.S3_MODULE_CODE.am, `allocation/${master_data_refID}/${datetime}/${csv_s3url}`)
        console.log('fileName', curkey, csv_s3url)
        setIsGlobalLoading(true)

        try {

            await fileHandler.uploadFileToS3(idNameFile, curkey)
            let payload: any = {}
            if (isModalOpen.isRetrieveOpen) {
                const formatReturnedDate = returned_date ? (typeof (returned_date) == 'string' ? returned_date : returned_date.format()) : NO_DEVICE_OR_DATE

                payload = {
                    master_data_refID,
                    datetime,
                    csv_s3url: curkey,
                    asset_IDs: [], // need to send empty 
                    returned_date: formatReturnedDate,
                    returned_comments: returned_comments || '',
                    type
                }

            } else {
                const formatDispatchDate = dispatch_date ? (typeof (dispatch_date) == 'string' ? dispatch_date : dispatch_date.format()) : NO_DEVICE_OR_DATE
                const formatReturnDate = expected_return_date ? (typeof (dispatch_date) == 'string' ? dispatch_date : expected_return_date.format()) : NO_DEVICE_OR_DATE
                payload = {
                    master_data_refID,
                    datetime,
                    csv_s3url: curkey,
                    asset_IDs: [], // need to send empty 
                    expected_return_date: formatReturnDate,
                    dispatch_date: formatDispatchDate,
                    dispatch_comments,
                    type
                }
            }

            console.log("handleAllocateOrRetrieveConfirm sensor res payload", payload);

            // return

            await AllocationAPI.allocateAsset(payload)
            message.success(`Asset allocated successfully, thank you`)
            refreshPage()
            handleModalClose()

        } catch (err: any) {
            console.log('upload id file fail...', err.response, err.message)
            message.error(`Asset allocated fail: ${err.response.data}`)
        } finally {
            setIsGlobalLoading(false)
        }

    }





    const handleModalClose = (e?: MouseEvent) => {
        if (e) {
            e.preventDefault();
        }
        setIsModalOpen(isModalOpenInit);

        // reset fields for accordion
        allocationForm.resetFields()

        setModalData(emptyModalData)

        allocationForm.setFieldsValue({ ...emptyModalData })

        // const finalData = { allocations: [emptyModalData] }
        // setModalData({ ...finalData })
        // allocationForm.setFieldsValue({ ...finalData })
        setIdNameFile(null)

    };






    const renderFooter = () => (
        <Row className='justify-content-end'>
            <Col>
                <Form.Item style={{ marginBottom: '0' }}>
                    <SimpleButton
                        text='Cancel'
                        id='modal-btn-width-small'
                        onClick={(e: MouseEvent) => {
                            handleModalClose(e)
                        }}
                        isCancel
                    />
                    {isModalOpen.isView ? null : (
                        <SimpleButton
                            id='modal-btn-width-regular'
                            className="ms-3"
                            htmlType="submit"
                            // text="Save"
                            text={isModalOpen.isAllocateOpen ? 'Allocate' : isModalOpen.isNewRequest ? 'Confirm' : isModalOpen.isRequestEdit ? 'Save' : 'Retrieve'}
                            form="nest-messages"
                        />
                    )}

                </Form.Item>
            </Col>
        </Row>
    )


    return (

        <GlobalModal
            // title={`${isModalOpen.isNewRequest ? `New Request` : `Order Number: ${modalData.order_number}`}`}
            title={`Order Number: ${modalData.order_number}`}
            open={isModalOpen.isAllocateOpen || isModalOpen.isRetrieveOpen}
            onCancel={(e: MouseEvent) => handleModalClose(e)}
            style={{ maxHeight: '50vh', overflowY: 'auto' }}
            // dark
            footer={[renderFooter()]}
        >
            <DataRow1>
                <LeftDesc1>
                    ORDER NUMBER
                </LeftDesc1>
                <RightDesc1>
                    {modalData.order_number}
                </RightDesc1>
            </DataRow1>
            <DataRow1>
                <LeftDesc1>
                    DATE & TIME
                </LeftDesc1>
                <RightDesc1>
                    {moment(modalData.datetime).format(DATE_TIME_FORMAT)}
                </RightDesc1>
            </DataRow1>
            <DataRow1>
                <LeftDesc1>
                    REQUEST TYPE
                </LeftDesc1>
                <RightDesc1>
                    {modalData.type}
                </RightDesc1>
            </DataRow1>
            <DataRow1>
                <LeftDesc1>
                    CUSTOMER ID
                </LeftDesc1>
                <RightDesc1>
                    {modalData.customer_ID}
                </RightDesc1>
            </DataRow1>
            <DataRow1>
                <LeftDesc1>
                    CUSTOMER NAME
                </LeftDesc1>
                <RightDesc1>
                    {modalData.customer}
                </RightDesc1>
            </DataRow1>
            <DataRow1>
                <LeftDesc1>
                    ASSET PRESET
                </LeftDesc1>
                <RightDesc1>
                    {modalData.master_asset_name}
                </RightDesc1>
            </DataRow1>
            <DataRow1>
                <LeftDesc1>
                    QUANTITY
                </LeftDesc1>
                <RightDesc1>
                    {modalData.quantity}
                </RightDesc1>
            </DataRow1>
            <DataRow1>
                <LeftDesc1>
                    DELIVERY STORE
                </LeftDesc1>
                <RightDesc1>
                    {modalData.store}
                </RightDesc1>
            </DataRow1>
            <DataRow1>
                <LeftDesc1>
                    DELIVERY ADDRESS
                </LeftDesc1>
                <RightDesc1>
                    {`${modalData.store_address}`}
                </RightDesc1>
            </DataRow1>
            <DataRow1>
                <LeftDesc1>
                    COMMENTS
                </LeftDesc1>
                <RightDesc1>
                    {modalData.request_comments || '--'}
                </RightDesc1>
            </DataRow1>
            {modalData.status == ALLOCATION_REQUEST_STATUS.PARTIALLY_ALLOCATED && (
                <DataRow1>
                    <LeftDesc1>
                        Allocated Asset IDs
                    </LeftDesc1>
                    <RightDesc1>
                        {modalData.asset_IDs.reduce((acc: any, curEle: any) => acc + ',' + curEle) || '--'}
                    </RightDesc1>
                </DataRow1>
            )}


            <Form
                form={allocationForm}
                name="nest-messages"
                onFinish={(values: any) => handleAllocateOrRetrieveConfirm(values)}
                // style={{ maxWidth: 600 }}
                validateMessages={VALIDATE_MESSAGES}
            // initialValues={{ ...modalData }}
            >

                <Form.Item
                    name="master_data_refID"
                    initialValue={modalData.master_data_refID}
                    style={{ width: '71%' }}
                >

                    <Input
                        label='Asset Preset'
                        type="text"
                        value={modalData.master_data_refID}
                        disabled={true}
                    />


                </Form.Item>
                {isModalOpen.isAllocateOpen && (
                    <Form.Item
                        name="dispatch_date"
                        style={{ width: '71%' }}
                        rules={[{ required: true, message: 'Please select a Date' }]}
                    >

                        <Input
                            label='Dispatch Date'
                            type="date"
                            disabledDate={disabledDateBeforeTodayFunction}
                            required
                        // value={modalData.dispatch_date}
                        />


                    </Form.Item>
                )}

                <Form.Item
                    name={isModalOpen.isRetrieveOpen ? "returned_date" : "expected_return_date"}
                    // initialValue={moment()}
                    style={{ width: '71%' }}
                    rules={[{ required: isModalOpen.isRetrieveOpen ? true : false, message: 'Please select a Date' }]}
                >

                    <Input
                        label={isModalOpen.isRetrieveOpen ? 'Returned Date' : 'Expected Return Date'}
                        type="date"
                        required={isModalOpen.isRetrieveOpen ? true : false}
                    // value={modalData.dispatch_date}
                    />


                </Form.Item>
                <Form.Item
                    name={isModalOpen.isRetrieveOpen ? "returned_comments" : "dispatch_comments"}
                    // initialValue={modalData.dispatch_comments}
                    style={{ width: '71%' }}
                >

                    <Input
                        label='Comment'
                        type="textarea"
                    // value={modalData.dispatch_comments}
                    />


                </Form.Item>

                {/* DO NOT DELETE UNTIL SCAN FUNCTION IS FINALIZED */}
                {/* {isModalOpen.isAllocateOpen && (
                                    <>
                                        <FlexBox style={{ color: 'white', marginTop: 0 }}>
                                            <FlexBoxVertical style={{ marginRight: '4rem', color: 'white', width: '50%' }}>
                                                <AdiLabel required={true}>Assets ID</AdiLabel>
                                                <AdiTable
                                                    columns={assetColumns}
                                                    tableData={[{ asset_ID: '' }]}
                                                    noPagination={true}
                                                    tableWrapperStyle={{ marginTop: '1rem', marginBottom: '1rem' }}
                                                    className='small-padding-table'

                                                />
                                            </FlexBoxVertical>
                                            <FlexBoxVertical style={{ width: '45%' }}>
                                                <AdiLabel style={{ visibility: 'hidden' }} >Scan</AdiLabel>
                                                <div style={{ width: '30%', marginTop: '1rem' }}>
                                                    <SimpleButton style={{ width: '30%' }} text='Scan' isCancel />
                                                </div>


                                                <p style={{ fontSize: '1.15rem', width: '55%', marginTop: '1rem' }}>
                                                    Use the scanner to record the Asset ID’s
                                                </p>
                                            </FlexBoxVertical>

                                        </FlexBox>
                                    </>
                                )} */}
                {/* DO NOT DELETE UNTIL SCAN FUNCTION IS FINALIZED */}
                {/* {isModalOpen.isRetrieveOpen && (
                                    <>

                                        <FlexBox style={{ marginTop: '1rem', color: 'white' }}>
                                            <FlexBoxVertical style={{ width: '50%', marginRight: '1.5rem' }}>
                                                <AdiLabel>Required Assets</AdiLabel>

                                                <AdiTable
                                                    columns={assetColumns}
                                                    tableData={modalData.asset_IDs.map((item: any) => ({ asset_ID: item }))}
                                                    noPagination={true}
                                                    tableWrapperStyle={{ marginTop: '1rem', marginBottom: '1rem', width: '100%' }}
                                                    className='small-padding-table'

                                                />
                                                <AdiLabel style={{ color: '#00B4DB', marginBottom: '1rem', marginTop: 0 }}>
                                                    View More ......
                                                </AdiLabel>

                                                <FlexBox style={{ justifyContent: 'space-between', alignItems: 'center', marginTop: 0 }}>
                                                    <p style={{ fontSize: '1.15rem', width: '60%', marginBottom: 0 }}>
                                                        Export Asset Retrieval list to CSV for a comfortable view.
                                                    </p>

                                                    <a target="_blank" href="https://portal.staging.itrazoadi.com/#/app/insights" rel="noreferrer">
                                                        <SimpleButton text='Export' isCancel={true} />
                                                    </a>
                                                </FlexBox>


                                            </FlexBoxVertical>

                                            <FlexBoxVertical style={{ width: '45%', justifyContent: 'space-between' }}>
                                                <AdiLabel required={true}>Returned Assets</AdiLabel>

                                                <AdiTable
                                                    columns={assetColumns}
                                                    tableData={modalData.asset_IDs.map((item: any) => ({ asset_ID: '' }))}
                                                    noPagination={true}
                                                    tableWrapperStyle={{ marginTop: '1rem', marginBottom: '1rem', width: '100%' }}
                                                    className='small-padding-table'

                                                />
                                                <AdiLabel style={{ visibility: 'hidden', marginBottom: '1rem', marginTop: 0 }}>
                                                    Empty
                                                </AdiLabel>


                                                <UploadLabelButton style={{ alignSelf: 'end', marginTop: '1rem' }} >Scan</UploadLabelButton>
                                                <p style={{ fontSize: '1.15rem', width: '53%', marginBottom: 0, alignSelf: 'end' }}>
                                                    Use the scanner to record the Asset ID’s
                                                </p>
                                            </FlexBoxVertical>

                                        </FlexBox>
                                    </>
                                )
                                } */}

                {isModalOpen.isAllocateOpen && (
                    <>
                        <AdiLabel required={true}>Assets ID & Name</AdiLabel>
                        <FlexBox style={{ marginTop: '1rem', color: 'white' }}>
                            <FlexBoxVertical style={{ width: '35%', marginRight: '1.5rem' }}>
                                <input
                                    type="file"
                                    name="attachFile"
                                    id="assetIdFile"
                                    style={{ display: "none" }}
                                    // multiple
                                    accept=".csv"

                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        e.preventDefault();

                                        console.log('doc change handleChoseFileChange', e);
                                        const curFiles = e.target.files;
                                        console.log("doc curFile", curFiles);
                                        if (curFiles && curFiles[0]) {
                                            setIdNameFile(curFiles[0])
                                            e.target.value = ''
                                        }




                                    }}
                                />
                                <UploadLabelButton htmlFor="assetIdFile">Upload CSV</UploadLabelButton>
                                {idNameFile && (
                                    <FlexBox style={{ margin: 0, alignItems: 'center', }}>
                                        <span style={{ maxWidth: '75%', color: '#00B4DB' }}>{idNameFile.name}</span>
                                        <DeleteOutlined onClick={() => setIdNameFile(null)} />
                                    </FlexBox>
                                )}
                            </FlexBoxVertical>

                            <FlexBoxVertical style={{ width: '45%' }}>

                                <a target="_blank" href={`${process.env.REACT_APP_CDN_DEV}am/AllocationAssetIDs.csv`} rel="noreferrer">
                                    <AdiLabel style={{ color: '#00B4DB', marginBottom: '1rem', marginTop: 0 }}>
                                        Download CSV Template
                                    </AdiLabel>
                                </a>

                                <p style={{ fontSize: '1.15rem' }}>
                                    Use the CSV Template to fill the Asset Names & existing ID’s that’s been allocated to this order.
                                </p>
                            </FlexBoxVertical>

                        </FlexBox>
                    </>
                )}

                {isModalOpen.isRetrieveOpen && (
                    <>

                        <FlexBox style={{ marginTop: '1rem', color: 'white' }}>
                            <FlexBoxVertical style={{ width: '50%', marginRight: '1.5rem' }}>
                                <AdiLabel>Required Assets</AdiLabel>

                                <AdiTable
                                    columns={assetColumns}
                                    tableData={modalData.asset_IDs.map((item: any) => ({ asset_ID: item }))}
                                    noPagination={true}
                                    tableWrapperStyle={{ marginTop: '1rem', marginBottom: '1rem' }}
                                    className='small-padding-table'

                                />
                                <AdiLabel style={{ color: '#00B4DB', marginBottom: '1rem', marginTop: 0 }}>
                                    View More ......
                                </AdiLabel>

                                <FlexBox style={{ justifyContent: 'space-between', alignItems: 'center', marginTop: 0 }}>
                                    <p style={{ fontSize: '1.15rem', width: '60%', marginBottom: 0 }}>
                                        Export Asset Retrieval list to CSV for a comfortable view.
                                    </p>

                                    <a target="_blank" href={`${process.env.REACT_APP_CDN_DEV}am/AllocationAssetIDs.csv`} rel="noreferrer">
                                        <SimpleButton text='Export' isCancel={true} />
                                    </a>
                                </FlexBox>


                            </FlexBoxVertical>

                            <FlexBoxVertical style={{ width: '45%', justifyContent: 'space-between' }}>
                                <AdiLabel required={true}>Returned Assets</AdiLabel>

                                <FlexBox style={{ margin: 0, alignItems: 'center', }}>
                                    <span style={{ marginRight: '2rem', color: '#00B4DB' }}>{idNameFile ? idNameFile.name : ''}</span>
                                    {idNameFile && <DeleteOutlined onClick={() => setIdNameFile(null)} />}
                                </FlexBox>

                                <input
                                    type="file"
                                    name="attachFile"
                                    id="assetIdFile"
                                    style={{ display: "none" }}
                                    // multiple
                                    accept=".csv"
                                    onClick={(e: MouseEvent) => {
                                        const ele = e.target as HTMLInputElement
                                        ele.value = ''
                                    }}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        e.preventDefault();

                                        console.log('doc change handleChoseFileChange', e);
                                        const curFiles = e.target.files;
                                        console.log("doc curFile", curFiles);
                                        if (curFiles && curFiles[0]) {

                                            setIdNameFile(curFiles[0])

                                        }


                                    }}
                                />
                                <UploadLabelButton style={{ alignSelf: 'end' }} htmlFor="assetIdFile">Upload CSV</UploadLabelButton>

                            </FlexBoxVertical>

                        </FlexBox>
                    </>
                )
                }



            </Form>


        </GlobalModal>

    );
}

export default AllocationModal;

const DataRow1 = styled(DataRow)`
    font-family: var(--font-normal);
    align-items: center;
    padding:0;
    margin-bottom: 2.25rem;
`
const LeftDesc1 = styled(LeftDesc)`
    font-size: 1.3rem;
    text-transform: uppercase;
    font-weight: 900;
    width: 45%;
`
const RightDesc1 = styled(RightDesc)`
    font-size: 1.2rem;
`