import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

export type Page = {
  key: string;
  path: string;
  name: string;
  icon?: React.ReactNode;
  activeIcon?: React.ReactNode;
  component?: React.ComponentType;
  apis?: any[];
  subNav?: Page[];
};

interface Props {
  pages: Page[];
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background: var(--side-bar-color);
  display: flex;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25) !important;
`;

interface MyLinkProps {
  number: number;
  selected: boolean;
}

const MyLink = styled(Link)<MyLinkProps>`
  flex: 0 0 ${({ number }) => `${100 / number}%`};
  text-align: center;
  color: white;
  margin: auto;

  svg {
    font-size: 3rem;
    color: ${({ selected, theme }) => (selected ? theme.main : 'white')};
  }
`;

const MobileNavigation: React.FC<Props> = ({ pages }) => {
  const location = useLocation();
  const number = pages.length > 0 ? pages.length : 1;

  return (
    <Wrapper>
      {pages.map((page) => (
        <MyLink
          key={page.key}
          to={page.path}
          number={number}
          selected={location.pathname.split('/app/').pop() === page.key}
        >
          {page.icon}
        </MyLink>
      ))}
    </Wrapper>
  );
};

export default MobileNavigation;
