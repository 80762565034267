//  Simple button with itrazo red background and white text color

import styled from 'styled-components';
import React, { CSSProperties, MouseEvent } from 'react';
import { Button } from 'antd';


type myProps = {
  onClick?: Function;
  style?: CSSProperties;
  text: React.ReactNode;
  className?: string;
  form?: string;
  htmlType?: "button" | "reset" | "submit";
  isCancel?: boolean;
  id?: string;
  disabled?: boolean;
  title?: string;
  outlined?: boolean;
  // height?: string;
  loading?: boolean;
  isActive?: boolean;
};

const SimpleButton = (props: myProps) => {
  const { onClick, style, text, className, form, htmlType, isCancel, id, disabled, title, outlined, loading, isActive } = props;


  return (
    <StyledButton
      title={title ? title : ''}
      id={id}
      form={form}
      htmlType={htmlType}
      onClick={(e: MouseEvent) => onClick ? onClick(e) : null}
      style={{ borderRadius: 'var(--border-radius)', ...style }}
      className={className}
      disabled={disabled}
      outlined={outlined}
      iscancelbtn={isCancel}
      loading={loading}
      isActive={isActive}
    >
      <StyledText>{text}</StyledText>
    </StyledButton>
  )
};



type BtnProps = {
  iscancelbtn?: boolean;
  outlined?: boolean;
  isActive?: boolean;
}

const generalBtnStyle = `
  font-family: var(--font-normal);
  font-size: 1.25rem;
  font-weight: 500 !important;
  align-items: center;
  opacity: 1;
  display: inline-flex;
  overflow: hidden;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border: none !important;
  color: var(--white-text-color);

  &:disabled {
    background: var(--btn-disabled-color) !important;
    color: #C1C1C1 !important;
    border: none !important;
    box-shadow: none !important;
  }
`

const StyledButton = styled(Button)`
  ${generalBtnStyle}
  
  
  ${(props: BtnProps) => {
    if (props.iscancelbtn) {
      return `
        background: transparent !important;
        color: #C1C1C1 !important;
        border: 1px solid var(--white-text-color) !important;
      `
    } else if (props.outlined) {
      return `
        background: transparent !important;
        color: var(--white-text-color) !important;
        border: 1px solid var(--primary-color) !important;
        :hover:enabled {
          span {
            color: var(--primary-color);
          }
        }
      `
    } /* else if (props.isActive) {
      return `
      background: 'rgb(0, 204, 163);';
      `
    } */

    return `
      background: var(--primary-color) !important;
      color: var(--white-text-color) !important;
    `
  }}

  &.ant-btn-loading {
    &:hover:enabled {
      cursor: not-allowed;
      span {
        color: inherit !important;
      }
    }
    
  }

`


const StyledText = styled.span`
  text-align: right;
  margin: auto;
`;





export default SimpleButton;
