import React from "react";
import { Dropdown, Menu, Tooltip } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { useMemo } from 'react';
import IconButton from "components/GlobalButtons/IconButton";
import styled from "styled-components";


type Props = {
  menuItems: { key?: string | number; icon?: React.ReactNode; disabled?: boolean; onClick?: () => void; label: React.ReactNode }[];
  isButton?: boolean;
  icon?: React.ReactNode;
};

const ActionDropdown = (props: Props) => {
  const { menuItems, isButton, icon } = props;

  // Add default event handlers to menu items
  const menu = useMemo(
    () => (
      <Menu>
        {Array.isArray(menuItems)
          ? menuItems.map((item, index) => (
            <Menu.Item
              key={item.key || index}
              disabled={item.disabled}
              onClick={(info) => {
                console.log('abc info', info);
                info.domEvent.preventDefault(); // Prevent default browser behavior
                info.domEvent.stopPropagation(); // Stop the event from propagating
                item.onClick && item.onClick();
              }}
              icon={item.icon}
            >
              {item.label}
            </Menu.Item>
          ))
          : null}
      </Menu>
    ),
    [menuItems]
  );

  return (
    <Dropdown overlay={menu} placement="bottomCenter" trigger={['click']} >
      <Tooltip title="Actions">
        {isButton ? (
          <IconButton
            icon={icon}
            onClick={(e: React.SyntheticEvent) => {
              e.stopPropagation();
            }}
          />
        ) : (
          <ActionIcon
            onClick={(e: React.SyntheticEvent) => {
              e.stopPropagation();
            }}
          />
        )}
      </Tooltip>
    </Dropdown>
  );
};

export default ActionDropdown;

export const ActionIcon = styled(EllipsisOutlined)`
  padding: 5px;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: var(--icon-hover-bg-color);
  }
`;

// Alternatively, a reusable StyledClickableIcon function
export const StyledClickableIcon = (Icon?: React.ComponentType<any>, style?: string) => {
  const iconStyle = `
    padding: 5px;
    border-radius: 6px;
    font-size: 14px; // Default size same as the text
    cursor: pointer;
    &:hover {
      background-color: var(--icon-hover-bg-color);
    }
    ${style}
  `;
  if (Icon) {
    return styled(Icon)`
      ${iconStyle}
    `;
  } else {
    return styled.div`
      ${iconStyle}
    `;
  }
};
