import React, { useState, useEffect, MouseEvent, useCallback } from "react";
import { getTabsArrayFromTabsObj } from "utilities/Functions/GlobalHelperFunctions";
import {
  ADIKeyPage,
} from "statics/styles/StyledComponents";
import PageTabs from "components/PageTabs";
import { ScanDisplayModal } from "containers/Pages/InventoryManagement/components";
import { isMobile } from "react-device-detect";


import { OsmSalesTab } from "containers/Pages/InventoryManagement/statics/types";
import { PRODUCT_TYPE } from "containers/Pages/InventoryManagement/statics/constants";
import AcgProductOrders from "containers/Pages/InventoryManagement/pages/admin/Sales/AcgProductOrders";

type Props = {};
type SalesPageTypes =
  | OsmSalesTab
  | "pageViewCreateOrder"
  | "orderFulfillment"
  | "picklistDetails";

export const PRODUCT_WORK_TABS: { [key: string]: string } = {
  ACG_PRODUCT_ORDERS: "ACG Product Orders",
  DISTRIBUTION_ORDERS: "3PL Distribution Orders",
  ECOMMERCE_ORDERS: "E-Commerce Orders",
  CUSTOMER_PORTAL_ORDERS: "Customer Portal Orders",
};


const CustomerOrders = (props: Props) => {
  ;
  const [tab, setTab] = useState(PRODUCT_WORK_TABS.CUSTOMER_PORTAL_ORDERS);

  // state will control pages
  const [pageViewSales, setPageViewSales] = useState<SalesPageTypes>("open");

  const renderTabpages = (tab: string) => {

    return (
      <ADIKeyPage
        key={tab}
        style={{ display: tab ? "block" : "none", width: "99.5%" }}
      >
        {<AcgProductOrders
          productType={PRODUCT_TYPE.customerPortalProduct.value}
          pageViewSales={pageViewSales}
          setPageViewSales={setPageViewSales}
        />}
      </ADIKeyPage>
    );
  };

  return (
    <>
      {renderTabpages(tab)}
      <ScanDisplayModal />
    </>
  );
};

export default CustomerOrders;
