import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Input from "components/Input";
import SimpleButton from "components/GlobalButtons/SimpleButton";
import { FlexWrapper } from "statics/styles/StyledComponents";

type SearchInputProps = {
  placeholder?: string;
  style?: React.CSSProperties;
  className?: string;
  search?: {
    dataSource: any[];
    fields: string[];
    onSuccess: (result: any[]) => void;
  };
};

type ButtonProps = {
  text: React.ReactNode;
  onClick: () => void;
};

type Props = {
  searchInput: SearchInputProps;
  button?: ButtonProps;
  className?: string;
  style?: React.CSSProperties;
};

const TableSearchHeader: React.FC<Props> = (props) => {
  const { searchInput, button, className, style } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    const searchValue = searchParams.get("table_search");
    if (searchValue) {
      setSearchValue(searchValue);
      if (searchInput.search) {
        searchOnFields(searchValue || "");
      }
    }
  }, [searchParams.get("table_search"), searchInput.search?.dataSource]);

  const handleSearch = (value: string) => {
    searchOnFields(value);

    if (value) {
      searchParams.set("table_search", value);
    } else {
      searchParams.delete("table_search");
    }
    navigate({
      search: searchParams.toString(),
    });
  };

  const searchOnFields = (value: string) => {
    const searchValue = value.toLowerCase();
    const filtered =
      searchInput.search?.dataSource?.filter((record: any) => {
        let found = false;
        if (searchInput?.search && Array.isArray(searchInput.search.fields)) {
          for (let i = 0; i < searchInput?.search.fields.length; i++) {
            const field = searchInput.search?.fields[i];
            const fieldParts = field?.split(".") ?? [];
            const fieldValue = getNestedProperty(record, fieldParts);
            if (fieldValue?.toLowerCase().includes(searchValue)) {
              found = true;
              break;
            }
          }
        }
        return found;
      }) || [];

    if (searchInput.search?.onSuccess) {
      searchInput.search.onSuccess(filtered);
    }
  };

  return (
    <FlexWrapper
      flexBetween
      className={className}
      style={{ ...style, margin: '0 10px' }}
    >
      <SearchInput {...searchInput} />
      {button && <SimpleButton {...button} />}
    </FlexWrapper>
  );
};

const SearchInput: React.FC<SearchInputProps> = (props) => {
  const { placeholder, className, style, search } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const searchParams = new URLSearchParams(location.search);

  const handleSearch = (value?: string) => {
    const searchVal = value ?? searchValue;
    searchOnFields(searchVal);
    if (searchVal) {
      searchParams.set("table_search", searchVal);
    } else {
      searchParams.delete("table_search");
    }
    navigate({
      search: searchParams.toString(),
    });
  };

  const searchOnFields = (value: string) => {
    const searchValue = value.toLowerCase();
    const filtered =
      search?.dataSource?.filter((record: any) => {
        let found = false;
        if (Array.isArray(search?.fields)) {
          for (let i = 0; i < search?.fields.length; i++) {
            const field = search?.fields[i];
            const fieldValue = getNestedProperty(record, field.split("."));
            if (fieldValue?.toLowerCase().includes(searchValue)) {
              found = true;
              break;
            }
          }
        }
        return found;
      }) || [];

    if (search?.onSuccess) {
      search.onSuccess(filtered);
    }
  };

  return (
    <Input
      className={className}
      style={style}
      value={searchValue}
      placeholder={placeholder || "Search"}
      type="search"
      onSearch={() => handleSearch()}
      allowClear
      onChange={(e: any) => {
        const inputValue = e.target.value;
        setSearchValue(inputValue);
        if (!inputValue) {
          handleSearch("");
        }
      }}
    />
  );
};

const getNestedProperty = (data: any, keys: string[]): any => {
  if (keys.length === 0) {
    return data;
  }

  const [currentKey, ...remainingKeys] = keys;

  if (typeof data !== "object" || data === null) {
    return undefined;
  }

  return getNestedProperty(data[currentKey], remainingKeys);
};

export default TableSearchHeader;
