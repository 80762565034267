/**
 * This is the user creation component
 *
 * @author Preet Kaur
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import { Col, Divider, Form, Row, message, Radio, Space, Checkbox, Steps } from 'antd';
import GlobalModal from 'components/GlobalModal';
import Input from 'components/Input';
import { FlexWrapper, FlexBox, } from 'statics/styles/StyledComponents';
import moment from "moment";
import AddGS1KeyAPI from "../apis/Gs1Key";
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";
import { PRODUCT_TYPE } from "containers/Pages/InventoryManagement/statics/constants";
import { InventoryItemAPI } from "containers/Pages/InventoryManagement/apis";
import { PayPalButton } from 'react-paypal-button-v2';
import GlobalDrawer from "components/GlobalModal/GlobalDrawer";

const { ModalLayout } = GlobalDrawer;


type Props = {
  open: boolean;
  onClose: Function;
  getDataByCustomer: Function;
  productsList?: any[];
};

interface InputFields {
  "key_details": [{
    'product_name': undefined,
    'product_id': '',
    'status': '',
    'quantity': '',
  }],
  'payment_method': '',
}

function getInitialValues(): InputFields {
  return {

    "key_details": [{
      'product_name': undefined,
      'product_id': '',
      'status': '',
      'quantity': '',
    }],
    'payment_method': ''
  }
}
const PurchaseKeys = (props: Props) => {
  const { open, onClose, getDataByCustomer, productsList } = props;
  const [form] = Form.useForm();
  console.log("Form Reference:", form);
  const { Step } = Steps;
  const [step, setStep] = useState(0);
  const initialValues = getInitialValues();
  const [category, setCategory] = useState([])
  const [inventoryType, setInventoryType] = useState(PRODUCT_TYPE.generalProduct.value)
  const [subCategory, setSubCategory] = useState([])
  const [productListing, setProductListing] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [invoiceValue, setInvoiceValue] = useState('');

  useEffect(() => {
    let listingData: any = [];
    console.log('productsList::', productsList)
    listingData = productsList?.map((product: any) => {
      return {
        value: product.product_name,
        label: product.product_name,
      }
    })
    if (BIZ_CODE !== 'acg') {
      setProductListing(listingData)
    }
  }, [productsList]);

  const selectInventory = async (e: any) => {
    setInventoryType(e.target.value)
    let categories: any = [];
    try {
      const items = await InventoryItemAPI.getItemsByMarkFinishedProduct(e.target.value)
      const uniqueCategories = Array.from(new Set(items.map((item: any) => item.item_category)))

      setCategoryList(items);
      categories = uniqueCategories.map((category: any) => {
        return {
          value: category,
          label: category
          // item_category: category,
        }
      })
      console.log(categories)
      categories = categories.sort((a: any, b: any) => {
        return a.value.localeCompare(b.value)
      })
      console.log(categories)
      setCategory(categories)
    }
    catch {
      console.log('error')
    }

  }
  const categoryHandler = (e: any, index: any) => {
    // setCategory(e)
    let subCategories: any = [];
    console.log(e)
    const formValues = form.getFieldsValue();
    formValues.key_details[index].item_sub_category = '';
    const subCategory = categoryList.filter((subCat: any) => subCat.item_category === e)
    console.log(subCategory)
    const uniqueCategories = Array.from(new Set(subCategory.map((item: any) => item.item_sub_category)))

    // setCategoryList(items);
    subCategories = uniqueCategories.map((category: any) => {
      return {
        value: category,
        label: category
        // item_category: category,
      }
    })
    console.log(subCategories)
    subCategories = subCategories.sort((a: any, b: any) => {
      return a.value.localeCompare(b.value)
    })
    console.log(subCategories)
    setSubCategory(subCategories)
  }
  const handleSubCategory = (e: any, index: any) => {
    console.log(e)
    let products: any = [];
    const formValues = form.getFieldsValue();
    formValues.key_details[index].product_name = '';
    const subCategory = categoryList.filter((subCat: any) => subCat.item_sub_category === e)
    console.log(subCategory)
    const uniqueCategories = Array.from(new Set(subCategory.map((item: any) => item.item_name)))
    console.log(uniqueCategories)
    // setCategoryList(items);
    products = uniqueCategories.map((category: any) => {
      return {
        value: category,
        label: category
        // item_category: category,
      }
    })
    console.log(products)
    products = products.sort((a: any, b: any) => {
      return a.value.localeCompare(b.value)
    })
    console.log(products)
    setProductListing(products)
  }
  let BIZ_CODE = getBizCode().toLocaleLowerCase();
  const [payload, setPayload] = useState<any>();

  const formOnFinish = async (values: any) => {
    console.log(values)

    if (step == 0) {
      setStep(1)

      values.key_details.map((rec: any) => {
        rec.status = 'active';
        rec.payment_method = invoiceValue;
        rec.created_date = moment().toISOString();
      })

      const payload1 = {
        product_details: values.key_details,
        customer_code: BIZ_CODE
      }
      console.log(payload1);
      setPayload(payload1)
    }
    else {

      console.log(payload);
      payload.product_details
        .map((rec: any) => {
          rec.status = 'active';
          rec.payment_method = values.payment_method;
          rec.created_date = moment().toISOString();
        })
      try {
        AddGS1KeyAPI.addKeyByCustomer(payload).then((response) => {
          console.log(response);
          onClose();
          getDataByCustomer();
          message.success('Key added successfully!');
        })
      }
      catch {
        console.log('error')
        message.error('Something wrong ');

      }
    }

  }

  const onSuccess = (details: any, data: any) => {
    console.log('Transaction completed by', details.payer.name.given_name);
    console.log(data);
    console.log(details);
    // Handle success
    const formValues = form.getFieldsValue();
    console.log(formValues)
    console.log(payload)
    payload.product_details.map((rec: any) => {
      rec.status = 'active';
      rec.payment_method = formValues.payment_method;
      rec.created_date = moment().toISOString();
      rec.payment_id = data.paymentID;
    })

    console.log('onSuccess payload', payload);
    try {
      AddGS1KeyAPI.addKeyByCustomer(payload).then((response) => {
        console.log(response);
        onClose();
        getDataByCustomer();
        message.success('Key added successfully!');
      })
    }
    catch {
      console.log('error')
      message.error('Something wrong ');

    }
  };

  const onError = (error: any) => {
    console.error('Error:', error);
    // Handle error
  };
  const invoiceCheck = (e: any) => {
    console.log(e.target.value)
    setInvoiceValue(e.target.value)

  }
  const onBack = () => {
    setStep(0)
  }
  const pricePerKey = 0.02;
  const [calculateQuantity, setCalculatedQuantity] = useState<any>(0)

  const calculatePrice = (e: any, index: number) => {
    console.log(e.target.value)
    console.log(index);
    const formValues = form.getFieldsValue();
    const keyDetails = formValues.key_details;

    if (keyDetails && keyDetails[index]) {
      keyDetails[index].quantity = e.target.value;
      form.setFieldsValue({ key_details: [...keyDetails] });
      const newTotalQuantity = keyDetails.reduce((total: any, item: any) => total + parseInt(item.quantity) * pricePerKey, 0);
      setCalculatedQuantity(newTotalQuantity.toFixed(2));
    }

  };

  const updatePrice = (keyDetails: any) => {
    const newTotalQuantity = keyDetails.reduce((total: any, item: any) => total + parseInt(item.quantity) * pricePerKey, 0);
    setCalculatedQuantity(newTotalQuantity.toFixed(2));
    console.log('updatePrice newTotalQuantity::', keyDetails, newTotalQuantity)
  };

  const onModalClose = () => {
    onClose();
    setStep(0);
    form.resetFields();
    setCalculatedQuantity(0);
    setInvoiceValue('');
  };

  return (
    <GlobalDrawer
      title={`Purchase Key`}
      open={open}
      onClose={onModalClose}
      width={700}

      footer={[
        <FlexBox style={{ justifyContent: 'flex-end', margin: '0' }} key='0'>
          {/* <SimpleButton
            id='modal-btn-width-regular'
            isCancel={true}
            text="Cancel"
            onClick={onClose}
            style={{ marginRight: '1rem' }}
          /> */}
          {step == 1 ?
            <SimpleButton
              id='modal-btn-width-regular'
              isCancel={true}
              text="Back"
              onClick={onBack}
              style={{ marginRight: '1rem' }}
            /> : null}
          {/* {invoiceValue ? */}
          {step == 0 ?
            <SimpleButton
              id='modal-btn-width-regular'
              htmlType="submit"
              isCancel={true}
              text={"Next"}
              form='requestKeys'
            /> :
            invoiceValue == 'invoice' ?
              <SimpleButton
                id='modal-btn-width-regular'
                htmlType="submit"
                text={"Save"}
                form='requestKeys'
              /> : null}
          {/* : null} */}
        </FlexBox>
      ]}
    >
      <Form
        form={form}
        name='requestKeys'
        initialValues={initialValues}
        onFinish={(values: any) => { formOnFinish(values) }}
      >
        <div style={{ width: 'fit-content', margin: 'auto', marginBottom: '24px' }}>
          <Steps current={step} labelPlacement="vertical" size="small">
            <Step title={`Key Info`} />
            {/* <Step title={`Delivery Info`} /> */}
            <Step title={`Payment Info`} />
          </Steps>
        </div>
        {
          step == 0 ? (
            <>
              <ModalLayout>
                <ModalLayout.ModalSection title="Product Details">
                  <p style={{ color: '#fff' }}><b>$0.02 </b>per key</p>
                  {BIZ_CODE === 'acg' ?
                    <Form.List name="key_details"  >
                      {(fields, { add, remove }) => (
                        <div>
                          {fields.map((field, index) => (
                            <Row gutter={[15, 0]} key={field.key}>
                              <Col md={{ span: '24' }} sm={{ span: '24' }} xs={{ span: '24' }} >
                                <Form.Item
                                  name={[field.name, "inventory_type"]}>
                                  <Radio.Group onChange={(e: any) => selectInventory(e)} value={inventoryType}>
                                    <Radio style={{ fontSize: '12px', color: '#E4E4EB' }} value={PRODUCT_TYPE.acgProduct.value}>{PRODUCT_TYPE.acgProduct.label}</Radio>
                                    <Radio style={{ fontSize: '12px', color: '#E4E4EB' }} value={PRODUCT_TYPE.eCommerceProduct.value}>{PRODUCT_TYPE.eCommerceProduct.label}</Radio>
                                    <Radio style={{ fontSize: '12px', color: '#E4E4EB' }} value={PRODUCT_TYPE.xplProduct.value}>{PRODUCT_TYPE.xplProduct.label}</Radio>
                                  </Radio.Group>
                                </Form.Item>
                              </Col>
                              <Col md={{ span: '7' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                                <Form.Item
                                  name={[field.name, "item_category"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please Select Product Category',
                                    }
                                  ]}
                                >
                                  <Input
                                    type='select'
                                    label='Product Category'
                                    placeholder="Please Select Product Category"
                                    options={category}
                                    onChange={(e: any) => categoryHandler(e, index)}
                                    required
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={{ span: '7' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                                <Form.Item
                                  name={[field.name, "item_sub_category"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please Select Product Subcategory',
                                    }
                                  ]}
                                >
                                  <Input
                                    type='select'
                                    label='Product Subcategory'
                                    placeholder="Please Select Product Subcategory"
                                    options={subCategory}
                                    onChange={(e: any) => handleSubCategory(e, index)}
                                    required
                                  />
                                </Form.Item>
                              </Col>

                              <Col md={{ span: '7' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                                <Form.Item
                                  name={[field.name, "product_name"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please Select Product',
                                    }
                                  ]}
                                >
                                  <Input
                                    type='select'
                                    label='Product Name'
                                    placeholder="Select Product"
                                    options={productListing}
                                    required
                                    onChange={(e: any) => {
                                      const nameParts = e.split(' ');
                                      const prefixes = nameParts.map((part: any) => part[0]);
                                      const productId = prefixes.join('')
                                      try {
                                        const formValues = form.getFieldsValue();
                                        formValues.key_details[field.name].product_id = productId;
                                        form.setFieldsValue(formValues);
                                        console.log(form.getFieldValue('key_details'),)
                                      } catch (error) {
                                        console.error("Error setting field value:", error);
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={{ span: '7' }} sm={{ span: '24' }} xs={{ span: '24' }} className="productIDKey">
                                <Form.Item
                                  name={[field.name, "product_id"]}
                                >
                                  <Input
                                    type='text'
                                    label='Product ID'
                                    placeholder="Product ID"
                                    disabled

                                    required
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={{ span: '7' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                                <Form.Item
                                  name={[field.name, "quantity"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please Enter Quantity ',
                                    }
                                  ]}
                                >
                                  <Input
                                    type='number'
                                    label='Quantity'
                                    onChange={(e: any) => { calculatePrice(e, index) }}
                                    placeholder="Enter Quantity"
                                    required
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={{ span: '3' }} sm={{ span: '24' }} xs={{ span: '24' }} style={{ display: 'flex', alignItems: 'center' }}>
                                {field.key > 0 ? (
                                  <span onClick={() => {
                                    const formValues = form.getFieldValue('key_details');
                                    updatePrice(formValues)

                                  }} style={{ color: '#fff', cursor: 'pointer' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1024 1024"><path fill="currentColor" d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z" /></svg>
                                  </span>
                                ) : null}
                              </Col>
                            </Row>
                          ))}
                          <div style={{ display: 'flex', justifyContent: 'end' }}>
                            <SimpleButton
                              outlined
                              text="+ Add More"
                              onClick={() => {
                                const formValues = form.getFieldsValue();
                                const previousItem = formValues.key_details[fields.length - 1];
                                if (!previousItem || !previousItem.product_name || !previousItem.product_id || !previousItem.quantity) {
                                  message.error('Please complete the previous product details before adding another one.');
                                  return;
                                }
                                add();

                              }}
                            />
                          </div>
                        </div>
                      )}
                    </Form.List> :
                    <Form.List name="key_details"  >
                      {(fields, { add, remove }) => (
                        <div>
                          {fields.map((field, index) => (
                            <Row gutter={[15, 0]} key={field.key}>

                              <Col md={{ span: '7' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                                <Form.Item
                                  name={[field.name, "product_name"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please Select Product',
                                    }
                                  ]}
                                >
                                  <Input
                                    type='select'
                                    label='Product Name'
                                    placeholder="Select Product"
                                    options={productListing}
                                    required
                                    onChange={(e: any) => {
                                      const nameParts = e.split(' ');
                                      const prefixes = nameParts.map((part: any) => part[0]);
                                      const productId = prefixes.join('')
                                      try {
                                        const formValues = form.getFieldsValue();
                                        formValues.key_details[field.name].product_id = productId;
                                        form.setFieldsValue(formValues);
                                        console.log(form.getFieldValue('key_details'),)
                                      } catch (error) {
                                        console.error("Error setting field value:", error);
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={{ span: '7' }} sm={{ span: '24' }} xs={{ span: '24' }} className="productIDKey">
                                <Form.Item
                                  name={[field.name, "product_id"]}
                                >
                                  <Input
                                    type='text'
                                    label='Product ID'
                                    placeholder="Product ID"
                                    disabled

                                    required
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={{ span: '7' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                                <Form.Item
                                  name={[field.name, "quantity"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please Enter Quantity ',
                                    }
                                  ]}
                                >
                                  <Input
                                    type='number'
                                    label='Quantity'
                                    onChange={(e: any) => { calculatePrice(e, index) }}
                                    placeholder="Enter Quantity"
                                    required
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={{ span: '3' }} sm={{ span: '24' }} xs={{ span: '24' }} style={{ display: 'flex', alignItems: 'center' }}>
                                {field.key > 0 ? (
                                  <span onClick={() => {
                                    remove(field.name)
                                    const formValues = form.getFieldValue('key_details');
                                    updatePrice(formValues)
                                  }} style={{ color: '#fff', cursor: 'pointer' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1024 1024"><path fill="currentColor" d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z" /></svg>
                                  </span>
                                ) : null}
                              </Col>
                            </Row>
                          ))}
                          <div style={{ display: 'flex', justifyContent: 'end' }}>
                            <SimpleButton
                              outlined
                              text="+ Add More"
                              onClick={() => {
                                const formValues = form.getFieldsValue();
                                const previousItem = formValues.key_details[fields.length - 1];
                                if (!previousItem || !previousItem.product_name || !previousItem.product_id || !previousItem.quantity) {
                                  message.error('Please complete the previous product details before adding another one.');
                                  return;
                                }
                                add();
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </Form.List>}
                  <div style={{ background: '#30324A', color: '#fff', padding: '10px', marginTop: '10px' }}>
                    <p>Summary</p>
                    <div>
                      <p style={{ width: '50%', float: 'left', border: '1px solid grey', padding: '5px' }}> Total</p>
                      <p style={{ float: 'right', width: '50%', border: '1px solid grey', borderLeft: '0px solid transparent', padding: '5px', textAlign: 'right' }}>$ {calculateQuantity}</p>
                      <div style={{ clear: 'both' }} />
                    </div>
                  </div>
                </ModalLayout.ModalSection>
              </ModalLayout>
            </>) :
            <>
              <ModalLayout>
                <ModalLayout.ModalSection title="Payment">
                  <Row gutter={[15, 0]}>
                    <Col md={{ span: '24' }} sm={{ span: '24' }} xs={{ span: '24' }} className="radioGroup">
                      <Form.Item
                        name="payment_method"

                        rules={[
                          {
                            required: true,
                            message: 'Please select a payment method',
                          },
                        ]}
                      >
                        {/* <Checkbox onChange={invoiceCheck} /> */}
                        <Radio.Group name='payment_method' onChange={invoiceCheck} >
                          <Radio value='paypal' style={{ fontSize: '12px', color: '#E4E4EB' }}>Paypal Method</Radio>
                          {/* <Radio value='credit' style={{ fontSize: '12px', color: '#E4E4EB' }}>Credit/Debit Card</Radio> */}
                          <Radio value='invoice' style={{ fontSize: '12px', color: '#E4E4EB' }}>Invoice</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  {
                    invoiceValue == 'paypal' ?
                      <PayPalButton
                        amount={calculateQuantity}
                        shippingPreference="NO_SHIPPING" // doesn't show shipping address on the payment page
                        onSuccess={(details: any, data: any) => {
                          console.log('onsuccess', details, data);
                          onSuccess(details, data)
                          // OPTIONAL: Call your server to save the transaction
                          // return onSuccess();
                        }}
                        options={{
                          clientId: 'Afn3Io77hiW7YJXcvuH820xvacpqzL3YcD3k6Zi77u8bTpiKpKB-eJfc_5SSNqzUsHSRN7LpU-sXxheN', // TODO: need to change this
                          currency: 'AUD',
                          disableFunding: 'card',

                        }}
                        onError={(error: any) =>
                          onError(error)

                        }
                      /> : null
                  }
                </ModalLayout.ModalSection>
              </ModalLayout>
            </>}
      </Form>
      {/* <Form
        form={form}
        name='requestKeys'
        initialValues={initialValues}
        onFinish={(values: any) => { formOnFinish(values) }}
      >
        <div style={{ width: 'fit-content', margin: 'auto', marginBottom: '24px' }}>
          <Steps current={step} labelPlacement="vertical" size="small">
            <Step title={`Key Info`} />
            <Step title={`Payment Info`} />
          </Steps>
        </div>
        {
          step == 0 ? (
            <>
              <Divider orientation="left" orientationMargin="0">
                <div style={{ width: '150px', textAlign: 'left', fontSize: '14px', fontWeight: '700' }}>Product Details</div>
              </Divider>
              <FlexWrapper flexEnd >
                <DivWidthForModal>
                  <p style={{ color: '#fff' }}><b>$0.02 </b>per key</p>
                  {BIZ_CODE === 'acg' ?
                    <Form.List name="key_details"  >
                      {(fields, { add, remove }) => (
                        <div>
                          {fields.map((field, index) => (
                            <Row gutter={[15, 0]} key={field.key}>
                              <Col md={{ span: '24' }} sm={{ span: '24' }} xs={{ span: '24' }} >
                                <Form.Item
                                  name={[field.name, "inventory_type"]}>
                                  <Radio.Group onChange={(e: any) => selectInventory(e)} value={inventoryType}>
                                    <Radio style={{ fontSize: '12px', color: '#E4E4EB' }} value={PRODUCT_TYPE.acgProduct.value}>{PRODUCT_TYPE.acgProduct.label}</Radio>
                                    <Radio style={{ fontSize: '12px', color: '#E4E4EB' }} value={PRODUCT_TYPE.eCommerceProduct.value}>{PRODUCT_TYPE.eCommerceProduct.label}</Radio>
                                    <Radio style={{ fontSize: '12px', color: '#E4E4EB' }} value={PRODUCT_TYPE.xplProduct.value}>{PRODUCT_TYPE.xplProduct.label}</Radio>
                                  </Radio.Group>
                                </Form.Item>
                              </Col>
                              <Col md={{ span: '7' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                                <Form.Item
                                  name={[field.name, "item_category"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please Select Product Category',
                                    }
                                  ]}
                                >
                                  <Input
                                    type='select'
                                    label='Product Category'
                                    placeholder="Please Select Product Category"
                                    options={category}
                                    onChange={(e: any) => categoryHandler(e, index)}
                                    required
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={{ span: '7' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                                <Form.Item
                                  name={[field.name, "item_sub_category"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please Select Product Subcategory',
                                    }
                                  ]}
                                >
                                  <Input
                                    type='select'
                                    label='Product Subcategory'
                                    placeholder="Please Select Product Subcategory"
                                    options={subCategory}
                                    onChange={(e: any) => handleSubCategory(e, index)}
                                    required
                                  />
                                </Form.Item>
                              </Col>

                              <Col md={{ span: '7' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                                <Form.Item
                                  name={[field.name, "product_name"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please Select Product',
                                    }
                                  ]}
                                >
                                  <Input
                                    type='select'
                                    label='Product Name'
                                    placeholder="Select Product"
                                    options={productListing}
                                    required
                                    onChange={(e: any) => {
                                      const nameParts = e.split(' ');
                                      const prefixes = nameParts.map((part: any) => part[0]);
                                      const productId = prefixes.join('')
                                      try {
                                        const formValues = form.getFieldsValue();
                                        formValues.key_details[field.name].product_id = productId;
                                        form.setFieldsValue(formValues);
                                        console.log(form.getFieldValue('key_details'),)
                                      } catch (error) {
                                        console.error("Error setting field value:", error);
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={{ span: '7' }} sm={{ span: '24' }} xs={{ span: '24' }} className="productIDKey">
                                <Form.Item
                                  name={[field.name, "product_id"]}
                                >
                                  <Input
                                    type='text'
                                    label='Product ID'
                                    placeholder="Product ID"
                                    disabled

                                    required
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={{ span: '7' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                                <Form.Item
                                  name={[field.name, "quantity"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please Enter Quantity ',
                                    }
                                  ]}
                                >
                                  <Input
                                    type='number'
                                    label='Quantity'
                                    onChange={(e: any) => { calculatePrice(e, index) }}
                                    placeholder="Enter Quantity"
                                    required
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={{ span: '3' }} sm={{ span: '24' }} xs={{ span: '24' }} style={{ display: 'flex', alignItems: 'center' }}>
                                {field.key > 0 ? (
                                  <span onClick={() => {
                                    const formValues = form.getFieldValue('key_details');
                                    updatePrice(formValues)

                                  }} style={{ color: '#fff', cursor: 'pointer' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1024 1024"><path fill="currentColor" d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z" /></svg>
                                  </span>
                                ) : null}
                              </Col>
                            </Row>
                          ))}
                          <div style={{ display: 'flex', justifyContent: 'end' }}>
                            <SimpleButton
                              outlined
                              text="+ Add More"
                              onClick={() => {
                                const formValues = form.getFieldsValue();
                                const previousItem = formValues.key_details[fields.length - 1];
                                if (!previousItem || !previousItem.product_name || !previousItem.product_id || !previousItem.quantity) {
                                  message.error('Please complete the previous product details before adding another one.');
                                  return;
                                }
                                add();

                              }}
                            />
                          </div>
                        </div>
                      )}
                    </Form.List> :
                    <Form.List name="key_details"  >
                      {(fields, { add, remove }) => (
                        <div>
                          {fields.map((field, index) => (
                            <Row gutter={[15, 0]} key={field.key}>

                              <Col md={{ span: '7' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                                <Form.Item
                                  name={[field.name, "product_name"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please Select Product',
                                    }
                                  ]}
                                >
                                  <Input
                                    type='select'
                                    label='Product Name'
                                    placeholder="Select Product"
                                    options={productListing}
                                    required
                                    onChange={(e: any) => {
                                      const nameParts = e.split(' ');
                                      const prefixes = nameParts.map((part: any) => part[0]);
                                      const productId = prefixes.join('')
                                      try {
                                        const formValues = form.getFieldsValue();
                                        formValues.key_details[field.name].product_id = productId;
                                        form.setFieldsValue(formValues);
                                        console.log(form.getFieldValue('key_details'),)
                                      } catch (error) {
                                        console.error("Error setting field value:", error);
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={{ span: '7' }} sm={{ span: '24' }} xs={{ span: '24' }} className="productIDKey">
                                <Form.Item
                                  name={[field.name, "product_id"]}
                                >
                                  <Input
                                    type='text'
                                    label='Product ID'
                                    placeholder="Product ID"
                                    disabled

                                    required
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={{ span: '7' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                                <Form.Item
                                  name={[field.name, "quantity"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please Enter Quantity ',
                                    }
                                  ]}
                                >
                                  <Input
                                    type='number'
                                    label='Quantity'
                                    onChange={(e: any) => { calculatePrice(e, index) }}
                                    placeholder="Enter Quantity"
                                    required
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={{ span: '3' }} sm={{ span: '24' }} xs={{ span: '24' }} style={{ display: 'flex', alignItems: 'center' }}>
                                {field.key > 0 ? (
                                  <span onClick={() => {
                                    remove(field.name)
                                    const formValues = form.getFieldValue('key_details');
                                    updatePrice(formValues)
                                  }} style={{ color: '#fff', cursor: 'pointer' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1024 1024"><path fill="currentColor" d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z" /></svg>
                                  </span>
                                ) : null}
                              </Col>
                            </Row>
                          ))}
                          <div style={{ display: 'flex', justifyContent: 'end' }}>
                            <SimpleButton
                              outlined
                              text="+ Add More"
                              onClick={() => {
                                const formValues = form.getFieldsValue();
                                const previousItem = formValues.key_details[fields.length - 1];
                                if (!previousItem || !previousItem.product_name || !previousItem.product_id || !previousItem.quantity) {
                                  message.error('Please complete the previous product details before adding another one.');
                                  return;
                                }
                                add();
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </Form.List>}
                </DivWidthForModal>
              </FlexWrapper>
              <div style={{ background: '#30324A', color: '#fff', padding: '10px', marginTop: '10px' }}>
                <p>Summary</p>
                <div>
                  <p style={{ width: '50%', float: 'left', border: '1px solid grey', padding: '5px' }}> Total</p>
                  <p style={{ float: 'right', width: '50%', border: '1px solid grey', borderLeft: '0px solid transparent', padding: '5px', textAlign: 'right' }}>$ {calculateQuantity}</p>
                  <div style={{ clear: 'both' }} />
                </div>
              </div>

            </>) :
            <>
              <Divider orientation="left" orientationMargin="0">
                <div style={{ width: '110px', textAlign: 'left', fontSize: '14px', fontWeight: '700' }}>Payment</div>
              </Divider>
              <FlexWrapper flexEnd >
                <DivWidthForModal>

                  <Row gutter={[15, 0]}>
                    <Col md={{ span: '24' }} sm={{ span: '24' }} xs={{ span: '24' }} className="radioGroup">
                      <Form.Item
                        name="payment_method"

                        rules={[
                          {
                            required: true,
                            message: 'Please select a payment method',
                          },
                        ]}
                      >
                        <Radio.Group name='payment_method' onChange={invoiceCheck} >
                          <Radio value='paypal' style={{ fontSize: '12px', color: '#E4E4EB' }}>Paypal Method</Radio>
                          <Radio value='invoice' style={{ fontSize: '12px', color: '#E4E4EB' }}>Invoice</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  {
                    invoiceValue == 'paypal' ?
                      <PayPalButton
                        amount={calculateQuantity}
                        shippingPreference="NO_SHIPPING" // doesn't show shipping address on the payment page
                        onSuccess={(details: any, data: any) => {
                          console.log('onsuccess', details, data);
                          onSuccess(details, data)
                          // OPTIONAL: Call your server to save the transaction
                          // return onSuccess();
                        }}
                        options={{
                          clientId: 'Afn3Io77hiW7YJXcvuH820xvacpqzL3YcD3k6Zi77u8bTpiKpKB-eJfc_5SSNqzUsHSRN7LpU-sXxheN', // TODO: need to change this
                          currency: 'AUD',
                          disableFunding: 'card',

                        }}
                        onError={(error: any) =>
                          onError(error)

                        }

                      /> : null
                  }
                </DivWidthForModal>
              </FlexWrapper>

            </>}
      </Form> */}
    </GlobalDrawer >
  )
};



export default PurchaseKeys;
