import React, { useState, useCallback, useEffect, MouseEvent, useRef } from "react";
import styled from "styled-components";

import { OsmSalesTab } from "containers/Pages/InventoryManagement/statics/types";
import SalesHeader from "containers/Pages/InventoryManagement/components/Widgets/SalesHeader";
import Loading from "components/Loading";
import moment from "moment";
import { OrderAPI, ProdWorkOrderAPI } from "containers/Pages/InventoryManagement/apis";
import { ActionButton, DisplayEmptyTextPrimary, DisplayEmptyTextSecondary, FlexWrapper, StyledStatusLabel, TableStatusTag } from "statics/styles/StyledComponents";
import AdiTable from "components/AdiTable";
import { TABLE_CELL_CONFIG } from "utilities/CONSTANTS";
import { formatNameString, formatTitle, initialCellValue, initialDatellValue, initialEnumCellValue, initialPriceCellValue } from "utilities/Functions/FormatFunctions";


import GeneratePicklist from "./GeneratePicklist";
import { Dropdown, Menu, message } from "antd";
import GeneratePicklistAPI from "containers/Pages/InventoryManagement/apis/GeneratePicklistAPI";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import PrintableComponent from "containers/Pages/InventoryManagement/components/Widgets/PrintableComponent";
import { getOrderStatusTextColor } from '../../../utilities/functions'
import { PRODUCT_TYPE } from "containers/Pages/InventoryManagement/statics/constants";
import { MoreOutlined } from '@ant-design/icons'
import { useGeneralStore } from "zustand-stores";
import { GeneralStore } from "zustand-stores/useGeneralStore";
import TableCard from "components/AdiTable/TableCard";
import { ActionIcon } from "components/AdiTable/ActionDropdown";

type Props = {
  // pageViewSales: 'picklist',
  pageViewSales: OsmSalesTab,
  setPageViewSales: Function,
  setItemData: Function,
  initializeTab: Function,
  componentRef: any,
  productType?: string,

};

const PicklistTableView = (props: Props) => {
  const { pageViewSales, setPageViewSales, setItemData, initializeTab, productType } = props;

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  const [data, setData] = useState<any>()
  const [filterData, setFilterData] = useState<any[]>([])

  const [filters, setFilters] = useState<any>({
    searchText: '',
    dateRange: ['', ''],
  })

  const [isLoading, setIsLoading] = useState(false)

  const defaultTablesColumns = ['ordering_for', 'product_info']

  useEffect(() => {
    getTableData()
  }, [props.productType, props.pageViewSales]);


  const getTableData = () => {
    setIsLoading(true)
    let orderFor = 'acg_product'
    if (props.productType == PRODUCT_TYPE.acgProduct.value) {
      orderFor = 'acg_product'
    }
    if (props.productType == PRODUCT_TYPE.xplProduct.value) {
      orderFor = '3pl_product'
    }
    if (props.productType == PRODUCT_TYPE.eCommerceProduct.value) {
      orderFor = 'ecommerce_product'
    }
    if (props.productType == PRODUCT_TYPE.customerPortalProduct.value) {
      orderFor = PRODUCT_TYPE.customerPortalProduct.value
    }

    console.log('get getAllPicklist data for tab', orderFor, props.productType)
    GeneratePicklistAPI.getAllPicklist(orderFor, props.pageViewSales).then((result: any) => {

      if (Array.isArray(result)) {
        setData(result)
        applyAllFilters(filters.searchText, filters.dateRange, result)
        // setFilterData(result)
      }

    }).catch(err => {
      console.log('getTableData order fail...', err)

    }).finally(() => {
      setIsLoading(false)
    })
  }


  const onTextSearch = (searchText: string, dataSource?: any) => {
    const tempData = dataSource || data
    const filtered = tempData?.filter((item: any) => {
      const itmNo = item.picklist_ID.toLowerCase() || ''
      const search = searchText?.toLowerCase() || ''
      return itmNo.includes(search)
    })
    // setFilterData(filtered)
    return filtered

  }

  const handleFilterByDate = (date: [string, string], dataSource?: any) => {

    const startDate: string = date[0] != "" ? moment(date[0], 'DD/MM/YYYY').format('YYYY-MM-DD') : ''
    const endDate: string = date[1] != "" ? moment(date[1], 'DD/MM/YYYY').format('YYYY-MM-DD') : ''

    const tempData = dataSource || data
    if (startDate != "" && endDate != "") {
      const filtered = tempData.filter((item: any) => {
        const createdDate: string = moment(item?.created_on).format('YYYY-MM-DD') || ''
        // compare dates
        return createdDate >= startDate && createdDate <= endDate
      })
      // setFilterData(filtered)
      return filtered
    } else {
      // setFilterData(tempData)
      return tempData
    }


  }

  const applyAllFilters = (searchText: string, dateRange: [string, string], dataSource?: any) => {
    let result = dataSource || data
    result = onTextSearch(searchText, result)
    result = handleFilterByDate(dateRange, result)
    setFilterData(result)

  }


  const renderPurpose = (record: any) => {
    const docStatus: string = record.supporting_doc.every((item: any) => item.doc_status && item.doc_status === 'verified') ? 'verified' : 'pending'


    return (
      <Box>
        <Label>{record.ordering_for}</Label>
        <StyledStatusLabel backgroundColor={docStatus == 'pending' ? '#F5821F' : ''}>{formatNameString(docStatus)}</StyledStatusLabel>
      </Box>
    )
  }

  const columnWidth160 = 160
  const columnWidth240 = 240

  const fixedColumns = [
    {
      title: formatTitle("Picklist NO."),
      dataIndex: "picklist_ID",
      key: "picklist_ID",
      ...TABLE_CELL_CONFIG,
      width: columnWidth240,
      render: initialCellValue,
      sorter: (a: any, b: any) => a.picklist_ID?.localeCompare(b.picklist_ID)
    },
    {
      title: formatTitle("ORDER ID"),
      dataIndex: "order_ids",
      key: "order_ids",
      ...TABLE_CELL_CONFIG,
      width: columnWidth240,
      render: (text: string, record: any) => {
        const orderIds = record?.orders_for_picklist_generation?.map((item: any) => item?.order_ID) || []
        return (
          orderIds.map((item: any, index: number) => {
            return `${index === 0 ? '' : ', '}${item}`
          })
        )
      }
    },
    {
      title: formatTitle("STATUS"),
      dataIndex: "picklist_status",
      key: "picklist_status",
      ...TABLE_CELL_CONFIG,
      width: columnWidth160,
      // align: 'center',
      render: (text: string) => {
        const textColor = getOrderStatusTextColor(text)
        const value = text ? formatNameString(text) : initialCellValue(text)
        return (
          <FlexWrapper flexStart>
            <TableStatusTag color={textColor} style={{ width: '120px', display: 'block' }}>
              {value}
            </TableStatusTag>
          </FlexWrapper>
        )
      }
      // render: (text: string) => renderStatusLabel(text),
      // filters: getStatusFilterOptions(),
      // onFilter: (value: string, record: any) => {
      //   if (value == 'in_progress') {
      //     return record.order_status === 'picked' || record.order_status === 'packed' || record.order_status === 'inspected'
      //   }
      //   return record.order_status === value
      // },
      // sorter: (a: any, b: any) => a.sku?.localeCompare(b.sku)
    },
    {
      // this is fixed column
      title: formatTitle('TOTAL ORDERS'),
      dataIndex: 'total_orders',
      key: 'total_orders',
      ...TABLE_CELL_CONFIG,
      width: columnWidth240,
      render: initialCellValue,
      // sorter: (a: any, b: any) => a.customer_name?.localeCompare(b.customer_name)
      // filters: [
      //   { text: 'Raw Material', value: "Raw Material" },
      //   { text: 'Packaging Material', value: "Packaging Material" },
      // ],
      // onFilter: (value: string, record: any) => record.item_category === value,

    },
    // {
    //   title: formatTitle('PURPOSE'),
    //   dataIndex: 'ordering_for',
    //   key: 'ordering_for',
    //   ...TABLE_CELL_CONFIG,
    //   render: (text: string, record: any) => renderPurpose(record),
    // },
    {
      title: formatTitle('CREATED ON'),
      dataIndex: 'created_on',
      key: 'created_on',
      ...TABLE_CELL_CONFIG,
      width: columnWidth240,
      render: initialDatellValue,
      // sorter: (a: any, b: any) => parseFloat(a.safety_stock_count) - parseFloat(b.safety_stock_count),
    },
    {
      title: formatTitle('CREATED BY'),
      dataIndex: 'created_by',
      key: 'created_by',
      ...TABLE_CELL_CONFIG,
      width: columnWidth240,
      render: initialCellValue,
      // render: initialEnumCellValue,
      // filters: OrderPackagingList.map((item: any) => ({
      //   text: formatNameString(item),
      //   value: item
      // })),
      // onFilter: (value: string, record: any) => record.packaging === value,
      // sorter: (a: any, b: any) => parseFloat(a.reorder_level) - parseFloat(b.reorder_level),
    },
    {
      title: formatTitle('ASSIGNED TO'),
      dataIndex: 'assigned_to',
      key: 'assigned_to',
      ...TABLE_CELL_CONFIG,
      width: columnWidth240,
      render: initialCellValue,
      // sorter: (a: any, b: any) => parseFloat(a.cost_price) - parseFloat(b.cost_price),
      // sorter: (a: any, b: any) => a.delivery_date?.localeCompare(b.delivery_date),
      // render: (text: string) => text ? moment(text).format('DD/MM/YYYY') : initialCellValue(text)
    },


  ]


  const flexibleColumns = [
    {
      title: formatTitle('PURPOSE'),
      dataIndex: 'ordering_for',
      key: 'ordering_for',
      ...TABLE_CELL_CONFIG,
      width: columnWidth240,
      // render: (text: string, record: any) => renderPurpose(record),
    },
    {
      title: formatTitle('ITEMS'),
      dataIndex: 'product_info',
      key: 'product_info',
      ...TABLE_CELL_CONFIG,
      width: columnWidth240,
      // render: (text: string, record: any, index: number) => `${record.product_info.length} ${(record.product_info.length == 0 || record.product_info.length == 1) ? 'Item' : 'Items'}`
      // sorter: (a: any, b: any) => parseFloat(a.cost_price) - parseFloat(b.cost_price),
      // sorter: (a: any, b: any) => a.creation_date?.localeCompare(b.creation_date),
      // render: (text: string) => text ? moment(text).format('DD/MM/YYYY') : initialCellValue(text)
    },




  ]

  const componentRef = React.useRef(null);

  // const onBeforeGetContentResolve = React.useRef(null);

  // const [loading, setLoading] = React.useState(false);
  // const [text, setText] = React.useState("old boring text");

  // const handleAfterPrint = React.useCallback(() => {
  //   console.log("`onAfterPrint` called"); // tslint:disable-line no-console
  // }, []);

  // const handleBeforePrint = React.useCallback(() => {
  //   console.log("`onBeforePrint` called"); // tslint:disable-line no-console
  // }, []);

  // const handleOnBeforeGetContent = React.useCallback(() => {
  //   console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
  //   setLoading(true);
  //   setText("Loading new text...");

  //   return new Promise((resolve) => {
  //     onBeforeGetContentResolve.current = resolve;

  //     setTimeout(() => {
  //       setLoading(false);
  //       setText("New, Updated Text!");
  //       resolve('done');
  //     }, 2000);
  //   });
  // }, [setLoading, setText]);

  // const reactToPrintContent = React.useCallback(() => {
  //   return componentRef.current;
  // }, [componentRef.current]);

  // const handlePrint = useReactToPrint({
  //   content: reactToPrintContent,
  //   documentTitle: "AwesomeFileName",
  //   onBeforeGetContent: handleOnBeforeGetContent,
  //   onBeforePrint: handleBeforePrint,
  //   onAfterPrint: handleAfterPrint,
  //   removeAfterPrint: true
  // });

  const generateXmlFile = async (record: any) => {
    const batchNum = record?.batch_number || ''

    const processingLine = record?.processing_line || ''
    const manufactureDate = moment(record?.manufacturing_date || undefined).format('DD/MM/YYYY')
    // get products from order record
    const orders = record?.orders_for_picklist_generation || []

    const productsData = orders.map((order: any) => {
      const products = order.allocated_picklist
      const productDetails = products.map((product: any) => {
        // this payload is special 
        return {
          JobID: 'GeneralBEXPQR',  // static,
          QRCode: 'www.markem-imaje.com', // static,
          Batch: batchNum,
          Sku: product.sku || '',
          Expiry: moment(product.expiry_date).format('DD/MM/YYYY'),
          OrderID: order.order_ID,
          ProductName: product.item_name,
          ManufacturingDate: manufactureDate,
          ProcessingLine: processingLine,
        }
      })
      return productDetails
    }).flat()

    const payload = {
      product_details: productsData,
    }
    console.log('generateXmlFile', record, payload)
    // return
    setIsGlobalLoading(true)
    try {
      const res = await ProdWorkOrderAPI.generateXmlFile(payload)
      message.success("XML file generated successfully!")
    } catch (e) {
      const err: any = e
      console.log('Failed to generate XML file.', err, err?.response)
      if (err?.response?.status === 401 && err?.response?.data) {
        message.error(err?.response?.data)
      } else {
        message.error("Failed to generate XML file.")
      }

    } finally {
      setIsGlobalLoading(false)
    }
  }

  const actionColumn = [
    {
      title: formatTitle('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 120,
      align: 'center',
      render: (text: any, record: any, index: number) => {
        const renderMenu = (record: any) => {

          const handleMenuClick = (e: any) => {
            e.domEvent.stopPropagation()
            const key = e.key
            if (key === 'xml') {
              console.log('generateXmlFile', record)
              generateXmlFile(record)
            }

          }

          return (
            <Menu className='dropdownMenu' onClick={handleMenuClick} >
              <Menu.Item key="print" id='print-button'>
                <div style={{ width: '120px' }}>
                  <PrintableComponent
                    data={record}
                    type='picklist'
                    trigger={() => <div style={{ width: '100%' }}>Print</div>}
                  />
                </div>
              </Menu.Item>
              {
                record?.picklist_status === 'picked' &&
                <Menu.Item key="xml">
                  Send XML File
                </Menu.Item>
              }

            </Menu>
          )
        }
        return (
          productType === PRODUCT_TYPE.acgProduct.value ?
            <Dropdown overlay={renderMenu(record)}>
              <ActionIcon style={{ color: 'white', fontSize: '20px' }} onClick={(e: any) => e.stopPropagation()} />
            </Dropdown>
            :
            <span
              onClick={(e: MouseEvent) => {
                e.preventDefault()
                e.stopPropagation()
              }}
              style={{ background: 'transparent' }}
              title="Print"
            >
              <PrintableComponent data={record} type='picklist' />
            </span>
        )
      },
    }
  ]



  const finalTableColumns = [
    ...fixedColumns,
    // ...newColArray,
    ...actionColumn
  ]

  // // insert the column accordingly
  // flexibleColumns.filter((item: any) => {
  //   if (includeColumn(selectedTableColumns, item.key)) {
  //     if (item.key == 'ordering_for') {
  //       finalTableColumns.splice(3, 0, item)
  //     }
  //     if (item.key == 'product_info') {
  //       finalTableColumns.splice(6, 0, item)
  //     }
  //     return item
  //   }

  // })


  // const finalTableColumns = [
  //   ...fixedColumns,
  //   // ...newColArray,
  //   ...actionColumn
  // ]








  // const componentRef = useRef<any>(null);
  // const onBeforeGetContentResolve = useRef<any>(null);

  // const [loading, setLoading] = useState(false);
  // const [text, setText] = useState("old boring text");

  // const reactToPrintContent = useCallback(() => {
  //   return componentRef.current;
  // }, [componentRef.current]);

  // const handleAfterPrint = React.useCallback(() => {
  //   console.log("`onAfterPrint` called");
  // }, []);

  // const handleBeforePrint = React.useCallback(() => {
  //   console.log("`onBeforePrint` called");
  // }, []);

  // const handleOnBeforeGetContent = React.useCallback(() => {
  //   console.log("`onBeforeGetContent` called");
  //   setLoading(true);
  //   setText("Loading new text...");

  //   return new Promise((resolve) => {
  //     onBeforeGetContentResolve.current = resolve;

  //     setTimeout(() => {
  //       setLoading(false);
  //       setText("New, Updated Text!");
  //       resolve('done');
  //     }, 2000);
  //   });
  // }, [setLoading, setText]);

  // const reactToPrintTrigger = React.useCallback(() => {
  //   // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
  //   // to the root node of the returned component as it will be overwritten.

  //   // Bad: the `onClick` here will be overwritten by `react-to-print`
  //   // return <button onClick={() => alert('This will not work')}>Print this out!</button>;

  //   // Good
  //   return (
  //     <button>
  //       Print a Functional Component (using `forwardRef`) using a Functional
  //       Component
  //     </button>
  //   ); // eslint-disable-line max-len
  // }, []);



  return (
    <>
      <TableCard>
        <SalesHeader
          onTextSearch={(text: string) => {
            // onTextSearch(text)
            applyAllFilters(text, filters.dateRange)
            setFilters({ ...filters, searchText: text })
          }}
          onDateChange={(date: [string, string]) => {
            // handleFilterByDate(date)
            applyAllFilters(filters.searchText, date)
            setFilters({ ...filters, dateRange: date })
          }}
          pageView={props.pageViewSales}
          setPageView={props.setPageViewSales}
          searchPlaceholder="Search by Picklist No."
        // onTableColumnSelect={handleColumnSelect}
        // selectedTablesColumns={selectedTableColumns}
        // handleModalOpen={handleModalOpen}

        />
        {/* {selectedItem && (
        <span>
          <ReactToPrint
            trigger={() => <PrinterOutlined style={{ fontSize: '20px' }}

            />}
            content={() => {
              console.log('  content', componentRef.current)
              return componentRef.current
            }}
          />
          <div ref={componentRef}>
            <PrintableComponent data={selectedItem} type='picklist' />
          </div>
        </span>

      )} */}

        {
          !isLoading && filterData?.length === 0 ?
            <DisplayEmptyTextPrimary style={{ marginTop: '200px' }}>
              Start managing your Picklist.
              <DisplayEmptyTextSecondary>
                Create and manage your Picklist
              </DisplayEmptyTextSecondary>
            </DisplayEmptyTextPrimary >
            :
            <AdiTable
              loading={isLoading}
              scroll={{ x: '100%', y: '50vh' }}
              columns={finalTableColumns}
              // columns={props.tableColumns}
              tableData={filterData}
              rowKey={(record: any) => record.order_ID}
              fullWidth
              marginTop='20px'
              extraPaginationPadding
              onRowClick={(record: any, rowIndex: any, e: any) => {
                e.stopPropagation()
                e.preventDefault()
                props.setPageViewSales('picklistDetails')
                props.setItemData(record)
                // props.setPageViewInventory('pageViewCreateItem')
                // props.setDisabledPanels && props.setDisabledPanels({
                //   pageViewCreateItem: true,
                //   pageViewCreateInventory: false

                // })
              }}
            // sticky
            />
        }
      </TableCard>

    </>
  )
};

// export const FunctionalComponentToPrint = React.forwardRef((props, ref) => {
//   // eslint-disable-line max-len
//   return <ComponentToPrint ref={ref} text={props.text} />;
// });
// export class ComponentToPrint extends React.PureComponent {
//   constructor(props: any) {
//     super(props);

//     this.state = { checked: false };
//   }

//   canvasEl;

//   componentDidMount() {
//     const ctx = this.canvasEl.getContext("2d");
//     if (ctx) {
//       ctx.beginPath();
//       ctx.arc(95, 50, 40, 0, 2 * Math.PI);
//       ctx.stroke();
//       ctx.fillStyle = "rgb(200, 0, 0)";
//       ctx.fillRect(85, 40, 20, 20);
//       ctx.save();
//     }
//   }

//   handleCheckboxOnChange = () =>
//     this.setState({ checked: !this.state.checked });

//   setRef = (ref: any) => (this.canvasEl = ref);

//   render() {
//     const { text: any } = this.props;

//     return (
//       <div className="relativeCSS">
//         <<style type="text/css" media="print">
//           {"\
//    @page { size: landscape; }\
// "}
//         </style>
//         <div className="flash" />
//         <table className="testClass">
//           <thead>
//             <tr>
//               <th className="column1">Test Name</th>
//               <th>Test</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>Canvass</td>
//               <td>
//                 <canvas height="100" ref={this.setRef} width="200">
//                   Your browser does not support the HTML5 canvas tag.
//                 </canvas>
//               </td>
//             </tr>
//             <tr>
//               <td>Dynamic Content From Prop</td>
//               <td>{text ?? "Custom Text Here"}</td>
//             </tr>
//             <tr>
//               <td>Fonts</td>
//               <td>
//                 <div className="customFontText">Some Cool Font Text</div>
//               </td>
//             </tr>
//             <tr>
//               <td>Image: Local Import</td>
//               <td>
//                 <img alt="A test" src={image} width="200" />
//               </td>
//             </tr>
//             <tr>
//               <td>Image: URL</td>
//               <td>
//                 <img
//                   alt="Google logo"
//                   src="https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png"
//                   width="200"
//                 />
//               </td>
//             </tr>
//             <tr>
//               <td>Input</td>
//               <td>
//                 <input />
//               </td>
//             </tr>
//             <tr>
//               <td>Input: Checkbox</td>
//               <td>
//                 <input
//                   checked={this.state.checked}
//                   onChange={this.handleCheckboxOnChange}
//                   type="checkbox"
//                 />
//               </td>
//             </tr>
//             <tr>
//               <td>Input: Date</td>
//               <td>
//                 <input type="date" />
//               </td>
//             </tr>
//             <tr>
//               <td>Input: Radio</td>
//               <td>
//                 Blue <input type="radio" id="blue" name="color" value="blue" />
//                 Red <input type="radio" id="red" name="color" value="red" />
//               </td>
//             </tr>
//             <tr>
//               <td>Select</td>
//               <td>
//                 <select name="cars" id="cars">
//                   <option value="volvo">Volvo</option>
//                   <option value="saab">Saab</option>
//                   <option value="mercedes">Mercedes</option>
//                   <option value="audi">Audi</option>
//                 </select>
//               </td>
//             </tr>
//             <tr>
//               <td>TextArea</td>
//               <td>
//                 <textarea />
//               </td>
//             </tr>
//             <tr>
//               <td>SVG</td>
//               <td>
//                 <svg height="100" width="100">
//                   <circle
//                     cx="50"
//                     cy="50"
//                     fill="yellow"
//                     r="40"
//                     stroke="green"
//                     strokeWidth="4"
//                   />
//                 </svg>
//               </td>
//             </tr>
//             <tr>
//               <td>Video</td>
//               <td>
//                 <video
//                   src="https://www.w3schools.com/html/mov_bbb.mp4"
//                   width="200"
//                 />
//               </td>
//             </tr>
//             <tr>
//               <td>Video: With Poster</td>
//               <td>
//                 <video
//                   poster="https://images.freeimages.com/images/large-previews/9a9/tuscany-landscape-4-1500765.jpg"
//                   src="https://www.w3schools.com/html/mov_bbb.mp4"
//                   width="200"
//                 />
//               </td>
//             </tr>
//           </tbody>
//         </table>>
//       </div>
//     );
//   }
// }

export default PicklistTableView;

const BtnText = styled.span`
  color: var(--white-text-color);
  display: flex;
  align-items: center;
`
const Box = styled.div`  
  display: flex;
`
const Label = styled.div`
  flex:1
`

const MoreIcon = styled(MoreOutlined)`
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  top: 13px;
  font-size: 20px;
  font-weight: bold;
  padding: 3px;
  &:hover {
    cursor: pointer;
    // background-color: #33334d;
    // filter: brightness(1.2);
    // border-radius:50%;
  }
`;