import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components';
import Loading from 'components/Loading';
import { Row, Col } from 'antd';
import type { DataNode, TreeProps } from 'antd/es/tree';
import Input from 'components/Input';
import { TaskHeader } from '../../components';


import { useTablePageFilters } from '../../hooks';
import { FilterType } from '../../hooks/useTablePageFilters'
import { TaskAPI } from '../../apis';
import { DueTaskTable, CompletedTaskTable, CreateTask } from '../../components';
import { message } from 'antd'
import { ADI_CONFIRM } from 'utilities/Functions/GlobalHelperFunctions';
import DisplayStats from 'components/Statistics/DisplayStats';
import { PageTab } from '../../statics/types';
import FlowInfoPanel from 'components/FlowInfoPanel';
import { TASK_FLOW_STEPS } from '../../statics/constants';
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';
import setWith from 'lodash/setWith';
import TableCard from 'components/AdiTable/TableCard';

const dueSelectColumns = [
  {
    text: 'Task Type',
    valueKey: 'task_type'
  },
  {
    text: 'Due Date & Time',
    valueKey: 'due_date'
  },
  {
    text: 'Priority',
    valueKey: 'priority'
  }
]

const columnSelectOptions = {
  due: dueSelectColumns,
  completed: [
    ...dueSelectColumns,
    {
      text: 'Completed Date',
      valueKey: 'end_datetime'
    }
  ]

}

const defaultTablesColumns = {
  due_table: columnSelectOptions.due.map(item => item.valueKey),
  completed_table: columnSelectOptions.completed.map(item => item.valueKey),
}


const Task = () => {
  const [modals, setModals] = useState({
    open: false,
    isEdit: false,
    selectedTask: null,
    disabled: false,
  })
  const [showTableColumn, setShowTableColumn] = useState<{ due_table: any[], completed_table: any[] }>(defaultTablesColumns);
  // handles filters
  const [filterState, filterDispatch] = useTablePageFilters('task')
  const api = useAPI()
  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  const [isLoading, setIsLoading] = useState(false)
  const [reload, setReload] = useState(false);
  const [withReload, setWithReload] = useState(false);

  console.log('filterState in tasks', filterState.filteredData);
  useEffect(() => {
    setIsLoading(true)
    // get all task data
    api.getAllTasks().then((data: any) => {
      const dueTasks = data ? data.filter((rec: any) => {
        if (rec.end_datetime == '') {
          return rec
        }
      }) : []

      const completedTasks = data ? data.filter((rec: any) => {
        if (rec.end_datetime != '') {
          return rec
        }
      }) : []

      const fullTaskData = {
        due: dueTasks,
        completed: completedTasks
      }

      filterDispatch({ type: FilterType.APPLY_ALL, payload: fullTaskData })
    }).catch((e) => {
      message.error('Failed to get task data')
    }).finally(() => {
      setIsLoading(false)
      setReload(false)
    })

  }, [withReload])


  const deleteTask = async (data: any) => {
    // console.log('deleteTask', data);

    const id = data.task_ID
    if (!id) {
      message.error({ content: 'Failed to delete task' })
      return;
    }
    ADI_CONFIRM({
      // actionTitle: 'delete the task',
      customizeTitle: `Are you sure to delete task: ${data?.subject || ''}?`,
      isdelete: true,
      onConfirm: () => {
        setIsGlobalLoading(true)
        api.deleteTask(id).then(() => {
          message.success({ content: 'Task deleted successfully!' })
          setReload(true)
          setWithReload(!withReload)
        }).catch((e) => {
          message.error({ content: 'Failed to delete task.' })
        }).finally(() => {
          setIsGlobalLoading(false)
        })
      }
    })
  }


  const allTaskTableActions = {
    convert: (record: any) => null,
    edit: (record: any) => setModals({ ...modals, open: true, isEdit: true, selectedTask: record }),
    delete: (record: any) => deleteTask(record)
  }



  const resetTableColumn = () => {
    setShowTableColumn(defaultTablesColumns)

  }




  const handleColumnSelect = (e: any, type: 'due_table' | 'completed_table') => {
    // console.log('onTableColumnSelect', e)

    setShowTableColumn({
      ...showTableColumn,
      [type]: e
    })



  }

  const handleToggle = (toggleTo: string) => {
    filterDispatch({ type: FilterType.TOGGLE, payload: toggleTo })
    resetTableColumn()
  }

  const statsData = useMemo(() => {
    const dueNum = filterState.tasksData?.due?.length || 0
    const completedNum = filterState.tasksData?.completed?.length || 0
    const totalNum = dueNum + completedNum

    return [
      {
        text: 'Total Tasks',
        number: totalNum,
      },
      {
        text: 'Completed',
        number: completedNum,
      },
      {
        text: 'Due',
        number: dueNum,
      },

    ]
  }, [filterState.tasksData])

  return (
    <div>
      <div style={{ marginTop: '-20px', marginBottom: '40px' }}>
        <FlowInfoPanel
          items={[...TASK_FLOW_STEPS]}
          style={{ maxWidth: '400px', minWidth: '300px', marginLeft: '10px' }}
        />
        <DisplayStats data={statsData} style={{ marginBottom: '20px' }} />
      </div>
      <TableCard>
        <TaskHeader
          btnOnClick={() => setModals({ ...modals, open: true })}
          handleToggle={handleToggle}
          onTextSearch={(text: string) => filterDispatch({ type: FilterType.SEARCH_TEXT, payload: text })}
          onDateChange={(date: [string, string]) => filterDispatch({ type: FilterType.CREATION_DATE, payload: date })}
          onTableColumnSelect={handleColumnSelect}
          selectedTablesColumns={showTableColumn}
          columnSelectOptions={columnSelectOptions}
        />

        {
          reload ? (
            <Loading show />
          ) : (
            filterState.taskToggle === 'Due' ?
              <DueTaskTable
                isLoading={isLoading}
                selectedTableColumns={showTableColumn.due_table}
                data={filterState.filteredData}
                actions={allTaskTableActions}
                onRowClick={(record: any) => {
                  // console.log('record', record)
                  setModals({ ...modals, open: true, isEdit: true, selectedTask: record, disabled: true })
                }}
              />
              :
              <CompletedTaskTable
                isLoading={isLoading}
                selectedTableColumns={showTableColumn.completed_table}
                data={filterState.filteredData}
                actions={allTaskTableActions}
                onRowClick={(record: any) => {
                  // console.log('record', record)
                  setModals({ ...modals, open: true, isEdit: true, selectedTask: record, disabled: true })
                }}
              />
          )
        }
      </TableCard>
      <CreateTask
        open={modals.open}
        isEdit={modals.isEdit}
        onClose={() => setModals({ ...modals, open: false, isEdit: false, selectedTask: null, disabled: false })}
        selectedTask={modals.selectedTask}
        setReload={() => {
          setReload(true)
          setWithReload(!withReload)
        }}
        viewOnly={modals.disabled}
      />

    </div>
  )
}

export default Task;


// handles api requests for Company component above
const useAPI = () => {

  const getAllTasks = async () => {
    try {
      const data = await TaskAPI.getAllTask(['business_ID'])
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const deleteTask = async (task: any) => {
    try {
      const res = await TaskAPI.deleteTask(task)
      return Promise.resolve(res)
    } catch (e) {
      return Promise.reject(e)
    }

  }


  return {
    getAllTasks,
    deleteTask,
  }

}