import Icon, { DeleteOutlined, EditOutlined, } from "@ant-design/icons";
import AdiTable from "components/AdiTable";
import SimpleButton from "components/GlobalButtons/SimpleButton";
import Input from "components/Input";
import React, { useCallback, useEffect, useState, MouseEvent } from "react";
import { TABLE_ACTION_WIDTH, TABLE_CELL_CONFIG } from "utilities/CONSTANTS";
import { ADI_CONFIRM } from "utilities/Functions/GlobalHelperFunctions";
import { getFilterSearchUrl } from "utilities/Functions/GlobalHelperFunctions";
import { ActionButton, FlexWrapper, TrackGridTwo } from "../../../../statics/styles/StyledComponents";

import { formatTitle, initialCellValue } from "utilities/Functions/FormatFunctions";
import SensorAPI from "../apis/SensorAPI";
import SensorModal from "./SensorModal";
import SensorMapModal from "./SensorMapModal";
import { message } from "antd";
import { useAuthStore } from "zustand-stores";
import { AuthStore } from "zustand-stores/useAuthStore";
import DropdownAPI from "../apis/DropdownAPI";
import TableCard from "components/AdiTable/TableCard";

type Props = {};

type AssetSensor = {
  deviceID: string;
  asset_ID: string;
  model: string;
  serial: string;
  sensorType: string;
  location_name: string;
  status: string;
}

export const emptyModalData: AssetSensor = {
  deviceID: '',
  asset_ID: '',
  model: '',
  serial: '',
  sensorType: '',
  location_name: '',
  status: '',
}


const Sensors = (props: Props) => {
  const isAmReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.am.isReadOnly)
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isModalMapOpen, setIsModalMapOpen] = useState<boolean>(false);
  const [currentSensorRecord, setCurrentSensorRecord] = useState<any>([]);

  const [filters, setFilters] = useState<{ [key: string]: string }>({
    sensorType: '',
    status: '',
    search: ''
  });

  const [tableData, setTableData] = useState<any[]>([]);

  const [modalData, setModalData] = useState<any>({
    sensors: [emptyModalData]
  });

  const [sensorTypeList, setSensorTypeList] = useState<any[]>([]);

  const STATUS = [
    { value: false, label: "Off", text: "Off" },
    { value: true, label: "On", text: "On" },
  ]

  const getTableData = useCallback(
    async (searchUrl?: string) => {
      let url = ``
      if (searchUrl) {
        url = `${searchUrl}`
      }
      setIsLoading(true)
      try {
        const result = await SensorAPI.searchSensor(url)
        if (result && result.length > 0) {
          setTableData(result)
        } else {
          setTableData([])
        }

      } catch (err) {
        console.log('getTableData master fail...', err)
      } finally {
        setIsLoading(false)
      }

    },
    [],
  )

  const getSensorTypes = useCallback(
    async () => {
      try {
        const result = await DropdownAPI.getDropdowns({
          sensor_types: true
        })
        const { sensor_types } = result
        setSensorTypeList(sensor_types)
      } catch (err) {
        console.log('getSensorTypes fail...', err)
      }

    },
    [],
  )


  const initialize = useCallback(
    () => {
      getTableData()
      getSensorTypes()
    },
    [],
  )
  useEffect(initialize, [])


  const onSelectType = (e: string, selectorName: string) => {
    console.log("onSelectLevel", e, selectorName);
    const selectedItem = e;
    const latestFilters = {
      ...filters,
      [selectorName]: selectedItem
    }
    setFilters(latestFilters)

    // only search when selector changed  
    if (selectorName !== 'search') {
      handleSearch(latestFilters)
    }
  };

  const handleDelete = async (record: any) => {

    const { deviceID } = record

    const hide = message.loading(`Deleting the sensor, please wait...`);

    try {
      const res = await SensorAPI.deleteSensor(deviceID)
      console.log('handleDelete res', res)
      hide()

      message.success(`Delete sensor successfully, thank you`)
      getTableData()
    } catch (err: any) {
      console.log('Delete asset fail...', err)
      message.error(err.message)
    }

  }

  const actionColumn = isAmReadOnly ? [] : [
    // {
    //   title: formatTitle("Actions"),
    //   dataIndex: "actions",
    //   key: "actions",
    //   fixed: 'right',
    //   width: TABLE_ACTION_WIDTH,
    //   render: (text: any, record: any, index: number) => (
    //     <>
    //       <p style={{ marginBottom: "0" }}>
    //         <ActionButton
    //           title="Edit"
    //           onClick={(e: MouseEvent) => {
    //             handleModalOpen(e, record)
    //           }}
    //         >
    //           <EditOutlined />
    //         </ActionButton>
    //         <ActionButton
    //           title="Delete"
    //           onClick={(e: MouseEvent) => {
    //             e.preventDefault()
    //             ADI_CONFIRM({
    //               customizeTitle: 'Are you sure?',
    //               isdelete: true,
    //               onConfirm: () => {
    //                 console.log('Delete')
    //                 handleDelete(record)
    //               }
    //             })

    //           }}
    //         >
    //           <DeleteOutlined />
    //         </ActionButton>
    //       </p>
    //     </>
    //   ),
    // },
    {
      title: formatTitle('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (text: any, record: any) => {

        const items = [
          {
            label: "Edit",
            icon: <EditOutlined />,
            onClick: () => handleModalOpen(record),
          },
          {
            label: "Delete",
            icon: <DeleteOutlined />,
            onClick: () => {
              ADI_CONFIRM({
                customizeTitle: 'Are you sure?',
                isdelete: true,
                onConfirm: () => {
                  console.log('Delete')
                  handleDelete(record)
                }
              })
            },
          }
        ];

        return <AdiTable.ActionDropdown menuItems={items} />;
      },

    }
  ]

  const columns: any = [
    {
      title: formatTitle("Device ID"),
      dataIndex: "deviceID",
      key: "deviceID",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue
    },

    {
      title: formatTitle("Sensor Type"),
      dataIndex: "sensorType",
      key: "sensorType",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
      filters: sensorTypeList.map((item: any) => ({ text: item, value: item })),
      onFilter: (value: any, record: any) => record?.sensorType === value,
    },
    {
      title: formatTitle("Model"),
      dataIndex: "model",
      key: "model",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue
    },
    {
      title: formatTitle("Serial"),
      dataIndex: "serial",
      key: "serial",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue
      // render: (text: any, record: any, index: number) => (
      //   <>
      //     {record.serial ? record.serial : '---'}
      //   </>
      // ),
    },
    {
      title: formatTitle("Customer ID"),
      dataIndex: "owner",
      key: "owner",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue
    },
    {
      title: formatTitle("Asset ID"),
      dataIndex: "asset_ID",
      key: "asset_ID",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue
    },
    {
      title: formatTitle("Location"),
      dataIndex: "location",
      key: "location",
      ...TABLE_CELL_CONFIG,
      render: (text: any, record: any, index: number) => {
        return <span><div style={{ color: 'var(--primary-color)', cursor: 'pointer' }}
          onClick={(e: MouseEvent) => {
            console.log('view map')
            handleModalMapOpen(e, record)
          }}
        >View in Map</div></span>
      }
    },
    {
      title: formatTitle("Status"),
      dataIndex: "status",
      key: "status",
      ...TABLE_CELL_CONFIG,
      render: (text: any, record: any, index: number) => (
        <>
          {record.status === true ? 'On' : 'Off'}
        </>
      ),
      filters: STATUS,
      onFilter: (value: any, record: any) => record?.status === value,
    },
    ...actionColumn
  ];

  const handleModalOpen = (curRecord?: any) => {
    // edit sensor
    if (curRecord) {
      console.log('handleModalOpen curRecord ', curRecord)
      // status is false, set value as Off, else on
      const status = curRecord?.status === true ? 'On' : 'False'
      curRecord.status = status
      setModalData(curRecord)
      setIsEdit(true)

    }

    setIsModalOpen(true)

  };


  const [deviceID, setDeviceID] = useState<any>();

  const handleModalMapOpen = async (e: any, curRecord?: any) => {
    e.preventDefault();
    console.log('curRecord', curRecord)
    if (curRecord.deviceID != '') {
      const DeviceID = curRecord.deviceID
      setDeviceID(DeviceID);
      try {
        const result = await SensorAPI.getSensorById(DeviceID)
        setCurrentSensorRecord([result]);
        setIsModalMapOpen(true)
      } catch (err) {
        console.log('getTableData master fail...', err)
      }

    } else {
      message.info(`Device ID is missing`)
    }

  }




  const handleSearch = (latestFilters?: any) => {
    console.log('handleSearch', filters)
    let curFilters: any
    // for selector search
    if (latestFilters) {
      curFilters = { ...latestFilters }
    }
    // for input enter search
    else {
      curFilters = { ...filters }
    }
    const searchUrl = getFilterSearchUrl(curFilters)

    if (searchUrl.length > 0) {

      getTableData(searchUrl)

    } else {
      getTableData()

    }


  }



  return (
    <>

      {/* {renderMapModal()} */}
      <TableCard>
        <TrackGridTwo style={{ marginRight: 0, padding: '0 10px' }}>
          <FlexWrapper flexStart>
            <Input
              placeholder="Search by Device ID or Asset ID"
              name=""
              type="search"
              value={filters.search}
              onChange={(e: any) => onSelectType(e.target.value, 'search')}
              onSearch={() => handleSearch()}
              allowClear
            />
          </FlexWrapper>
          {
            !isAmReadOnly &&
            <FlexWrapper flexEnd >
              <SimpleButton text="+ Add Sensor" onClick={handleModalOpen} />
            </FlexWrapper>
          }

        </TrackGridTwo>
        {/* {renderModal()} */}


        <AdiTable
          loading={isLoading}
          marginTop="20px"
          tableData={tableData}
          columns={columns}
          fullWidth
          extraPaginationPadding
        />
      </TableCard>

      {isModalOpen && (
        <SensorModal
          modalData={modalData}
          setModalData={setModalData}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          getTableData={getTableData}
        />
      )}
      {isModalMapOpen && (
        <SensorMapModal
          currentSensorRecord={currentSensorRecord}
          isModalMapOpen={isModalMapOpen}
          setIsModalMapOpen={setIsModalMapOpen}
          deviceID={deviceID}
        />
      )}

    </>
  )
};

export default Sensors;
