import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import SupplyChain from './SupplyChain.tsx';
import Track from './Track';
import TraceBy from './TraceBY';
import Alerts from './Alerts';
import Issues from './Issues';
import Recalls from './Recall/Recalls';
import Lineage from './Lineage';
import ShelfLifeMonitoring from './ShelfLifeMonitoring.tsx';
import Documents from './Documents';
import AllItems from './Items/AllItems';
import Scans from './Scans';

export const VCT_ROOT_PATH = '/app/value-chain-tracking';

const VCTRoutes = () => {
  return (
    <Routes>
      <Route path={`${VCT_ROOT_PATH}`} element={<Navigate to={`${VCT_ROOT_PATH}/origin`} />} />
      <Route path={`${VCT_ROOT_PATH}/origin`} element={<Lineage />} />
      <Route path={`${VCT_ROOT_PATH}/supplyChain`} element={<SupplyChain />} />
      <Route path={`${VCT_ROOT_PATH}/events`} element={<Track />} />
      <Route path={`${VCT_ROOT_PATH}/items`} element={<AllItems />} />
      <Route path={`${VCT_ROOT_PATH}/trace`} element={<TraceBy />} />
      <Route path={`${VCT_ROOT_PATH}/alerts`} element={<Alerts />} />
      <Route path={`${VCT_ROOT_PATH}/issues`} element={<Issues />} />
      <Route path={`${VCT_ROOT_PATH}/recalls`} element={<Recalls />} />
      <Route path={`${VCT_ROOT_PATH}/productLife`} element={<ShelfLifeMonitoring />} />
      <Route path={`${VCT_ROOT_PATH}/compliance`} element={<Documents />} />
      <Route path={`${VCT_ROOT_PATH}/scans`} element={<Scans />} />
    </Routes>
  );
};

export default VCTRoutes;
