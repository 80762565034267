import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Products, EditProduct } from './pages';

export const CMS_ROOT_PATH = '/app/content-management-system';

const CMSRoutes = () => {
  return (
    <Routes>
      <Route path={CMS_ROOT_PATH} element={<Products />} />
      <Route path={`${CMS_ROOT_PATH}/products`} element={<Products />} />
      <Route path={`${CMS_ROOT_PATH}/products/edit-product`} element={<EditProduct />} />
    </Routes>
  );
};

export default CMSRoutes;
