import AdiTable from 'components/AdiTable'
import ColumnSelectButton from 'components/GlobalButtons/ColumnSelectButton'
import ExportButton from 'components/GlobalButtons/ExportButton'
import Input from 'components/Input'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { FlexWrapper } from 'statics/styles/StyledComponents'
import { TABLE_CELL_CONFIG } from 'utilities/CONSTANTS'
import { capitaliseFirst, formatTitle, initialCellValue } from 'utilities/Functions/FormatFunctions'
import { includeColumn } from 'utilities/Functions/GlobalHelperFunctions'
import LogBookAPI from '../../apis/LogBookAPI'
import { Col, Dropdown, Form, Menu, Row, message } from 'antd'
import styled from 'styled-components'
import { MoreOutlined } from '@ant-design/icons'
import GlobalModal from 'components/GlobalModal'
import SimpleButton from 'components/GlobalButtons/SimpleButton'
import AddressBookAPI from '../../apis/AddressBookAPI'
import TableCard from 'components/AdiTable/TableCard'
import { ActionIcon } from 'components/AdiTable/ActionDropdown'

type Props = {
  active: boolean;
}

enum CONTRACTOR_STATUS {
  EXPECTED = 'expected',
  CHECKED_IN = 'checked_in',
  CHECKED_OUT = 'checked_out',
}

const Contractor = (props: Props) => {
  const { active } = props
  const [tableData, setTableData] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [filters, setFilters] = useState<any>({
    searchText: '',
    filteredData: [],
    dateRange: ['', '']
  });

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<any[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
  const [exportedData, setExportedData] = React.useState<any[]>([]);

  const selectColumnOptions: any = [
    {
      text: formatTitle("Contractor Email"),
      valueKey: "email",
    },
    {
      text: formatTitle("Contractor Phone No"),
      valueKey: "phone_no",
    },
    {
      text: formatTitle("MDNA Status"),
      valueKey: "mnda_status",
    },
    {
      text: formatTitle("Induction Status"),
      valueKey: "induction_status",
    },
    {
      text: formatTitle("Background Check"),
      valueKey: "background_check",
    }
  ];

  const defaultTablesColumns = selectColumnOptions.map((item: any) => item.valueKey)
  const [selectedTableColumns, setSelectedTableColumns] = useState<any[]>(defaultTablesColumns);
  const [modal, setModal] = useState<any>({
    modalOpen: false,
    data: {},
  });

  useEffect(() => {
    if (active) {
      getTableData()
    }
  }, [active])

  const getTableData = async () => {
    setIsLoading(true)
    await LogBookAPI.getAllLogs().then((Logs: any) => {
      console.log('LogsgetTableData', Logs)
      let tempTableData: any[] = []

      // get items that are "business"
      tempTableData = Logs.filter((item: any) => item?.purpose_of_visit === "Contractor")

      // add key to each row
      tempTableData = tempTableData.map((item: any, index: number) => ({
        ...item,
        key: index,
      }))

      console.log('tempTableData', tempTableData)
      applyAllFilters(filters.searchText, filters.dateRange, tempTableData)
      setTableData(tempTableData)

      if (Array.isArray(tempTableData) && tempTableData.length == 0) {
        setSelectedTableColumns([])
      }

    }).catch((err: any) => {
      console.log('err', err)
    }).finally(() => {
      setIsLoading(false)
    })

  }

  // search by visitor name and company name
  const handleSearch = (searchText: string, data?: any) => {
    console.log('handleSearch', searchText, data)

    const search = searchText || ''

    const dataSource = data || tableData
    console.log('dataSource', dataSource, search)
    if (!search) {
      return dataSource
    }
    console.log('search', search, dataSource)
    const filteredData = dataSource.filter((item: any) => {
      const full_name = `${item?.first_name} ${item?.last_name}`
      return full_name.toLowerCase().includes(search.toLowerCase()) ||
        item?.company.toLowerCase().includes(search.toLowerCase())
    })
    console.log('filteredData', filteredData)
    return filteredData
  }

  const onDateChange = (date: [string, string], dataSource?: any) => {
    console.log('date==>', date, dataSource)
    const tempData = dataSource || tableData
    if (!date) {
      return tempData
    }
    const startDate: string = date[0] != "" ? moment(date[0], 'DD/MM/YYYY').format('YYYY-MM-DD') : ''
    const endDate: string = date[1] != "" ? moment(date[1], 'DD/MM/YYYY').format('YYYY-MM-DD') : ''

    if (startDate != "" && endDate != "") {
      const filteredData = tempData.filter((rec: any) => {
        const reportedDate: string = moment(rec?.visit_date).format('YYYY-MM-DD') || ''
        // compare dates
        return reportedDate >= startDate && reportedDate <= endDate
      })
      console.log('filtered', filteredData)
      return filteredData
    }
    else {
      console.log('elsefiltered')
      return tempData
    }

  }

  const applyAllFilters = (searchText: string, dateRange: [string, string], dataSource?: any) => {
    console.log('applyAllFilters', searchText, dateRange, dataSource)
    const tempData = dataSource || tableData
    const result: any = handleSearch(searchText, tempData)
    console.log('result', result)
    const filterData = onDateChange(dateRange || filters.dateRange, result)
    console.log('result', result)
    setFilters((prev: any) => ({
      ...prev,
      filteredData: [...filterData],
    }))
  }

  const newVisitorColumns = visitorColumns.filter((item: any) => {
    if (includeColumn(selectedTableColumns, item.key)) {
      return item
    }
  })

  const newExtraColumns = extraColumns.filter((item: any) => {
    if (includeColumn(selectedTableColumns, item.key)) {
      return item
    }
  })

  const closeModal = () => {
    setModal({ ...modal, modalOpen: false, data: {} })
  }

  const handleModalOpen = (record: any) => {
    setModal({ ...modal, modalOpen: true, data: { ...record } })
  }

  const changeStatusOnFinish = async () => {
    closeModal()
    await getTableData()
  }


  // const renderMenu = (record: any) => {

  //   const handleMenuClick = (e: any) => {
  //     e.domEvent.stopPropagation()
  //     handleModalOpen( record)
  //   }

  //   return (
  //     <Menu className='dropdownMenu' onClick={handleMenuClick}>
  //       <Menu.Item key="action">
  //         Change Work Order Status
  //       </Menu.Item>
  //     </Menu>
  //   )
  // }

  const finalTableColumns = [
    ...tableColumns,
    ...newVisitorColumns,
    ...fixedColumns,
    ...newExtraColumns,
    ...workColumns,
    // {
    //   title: formatTitle('Action'),
    //   dataIndex: 'action',
    //   key: 'action',
    //   ...TABLE_CELL_CONFIG,
    //   width: 150,
    //   fixed: 'right',
    //   align: 'center',
    //   render: (text: any, record: any) => (
    //     <>
    //       <Dropdown overlay={renderMenu(record)}>
    //         <ActionIcon onClick={(e: any) => e.stopPropagation()} />
    //       </Dropdown>
    //     </>
    //   ),

    // },
    {
      title: formatTitle('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (text: any, record: any) => {

        const items = [
          {
            label: 'Change Work Order Status',
            onClick: () => handleModalOpen(record),
          },
        ];

        return <AdiTable.ActionDropdown menuItems={items} />;
      },

    }
  ];


  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedRows(selectedRows)
      setSelectedRowKeys(selectedRowKeys)
      constructExportData(selectedRows)
    },
  };

  const constructExportData = (selectedRows: any) => {
    const tempExportData: any[] = []
    selectedRows.forEach((item: any) => {
      const tempObj: any = {}
      tempObj["Status"] = item?.status && capitaliseFirst(item?.status)
      tempObj["Invitation"] = item?.invitation && capitaliseFirst(item?.invitation)
      tempObj["Date"] = item?.visit_date && moment(item?.visit_date).format('DD/MM/YYYY')
      tempObj["Company"] = item?.company && capitaliseFirst(item?.company)
      tempObj["Name"] = item?.first_name && item?.last_name && capitaliseFirst(`${item?.first_name} ${item?.last_name}`)
      tempObj["Email"] = item?.email
      tempObj["Phone No"] = item?.phone_no
      tempObj["MDNA Status"] = item?.mnda_status && capitaliseFirst(item?.mnda_status)
      tempObj["Host"] = item?.host && capitaliseFirst(item?.host)
      tempObj["Check In"] = item?.check_in_time && moment(item?.check_in_time).format('HH:mm')
      tempObj["Check Out"] = item?.check_out_time && moment(item?.check_out_time).format('HH:mm')
      tempObj["Induction Status"] = item?.background_check && capitaliseFirst(item?.background_check)
      tempObj["Background Check Status"] = item?.background_check && capitaliseFirst(item?.background_check)
      tempObj["Work Type"] = item?.work_type && capitaliseFirst(item?.work_type)
      tempObj["Work Order Status"] = item?.work_order_status && capitaliseFirst(item?.work_order_status)
      tempObj["FoB Status"] = item?.fob_status && capitaliseFirst(item?.fob_status)
      tempObj["FoB Number"] = item?.fob_number
      tempExportData.push(tempObj)
    })
    setExportedData(tempExportData)
  };

  return (
    <>
      <TableCard>
        <Row gutter={[0, 15]} align="middle" justify='space-between'
          style={{
            margin: '0 10px'
          }}>
          <Col style={{ marginRight: "30px" }} >
            <Row gutter={[15, 15]} align="bottom">
              <Col>
                <Input
                  placeholder="Search by Contractor's Name or Company Name"
                  type='search'
                  value={filters.searchText}
                  onSearch={() => {
                    applyAllFilters(filters.searchText, filters.dateRange)
                  }}
                  onChange={(e: any) => {
                    setFilters({
                      ...filters,
                      searchText: e.target.value,
                    })

                    if (!e.target.value) {

                      applyAllFilters('', filters.dateRange)
                    }
                  }}
                  style={{ width: '400px' }}
                  allowClear
                />
              </Col>
              <Col>
                <Input
                  type='daterange'
                  value={filters.dateRange}
                  onChange={(e: any, date: [string, string]) => {
                    console.log('date', date, e)
                    applyAllFilters(filters.searchText, date)
                    setFilters((prev: any) => ({
                      ...prev,
                      dateRange: e,
                    }))
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col style={{ display: 'flex' }}>
            <Row align="bottom">
              <Row style={{ marginRight: '1.5rem', display: 'flex', alignItems: 'center' }}>
                <ExportButton
                  fileName='Contractors Logs Data'
                  exportData={exportedData}
                />
              </Row>
              <ColumnSelectButton
                selectedColumns={selectedTableColumns}
                columnOptions={selectColumnOptions}
                updateSelectedColumns={(values: string[]) => setSelectedTableColumns(values)}
              />
            </Row>
          </Col>
        </Row>
        <AdiTable
          scroll={{ x: '100%', y: '50vh' }}
          loading={isLoading}
          columns={finalTableColumns}
          tableData={filters.filteredData}
          rowSelection={{ ...rowSelection }}
          fullWidth
          marginTop='20px'
          extraPaginationPadding
        />
      </TableCard>
      <ChangeWorkStatus
        formName={'work_form'}
        open={modal.modalOpen}
        selectedLog={modal.data}
        onClose={closeModal}
        reload={changeStatusOnFinish}
      />
    </>
  )
}

export default Contractor

const ChangeWorkStatus = (props: { formName: string, open: boolean, onClose: Function, reload: Function, selectedLog: any }) => {
  const { formName, open, onClose, reload, selectedLog } = props

  const [form] = Form.useForm();

  useEffect(() => {
    if (!open) return;

    initializeDetails()

    return () => form.resetFields()
  }, [open])

  const initializeDetails = () => {

    if (!selectedLog) return;
    const values = {
      ...selectedLog,
    }
    console.log('initializeDetails==>', values)
    form.setFieldsValue(values)
  }

  const getAddressBook = async (payload: any) => {
    let finalAddressPayload: any[] = [];

    const email = payload?.email
    const visit_date = payload?.visit_date

    // make Get call to get address book data on teh basis of email and visit_date
    await AddressBookAPI.getAddressBooksItemByEmailAndDate(email, visit_date).then((res: any) => {
      console.log('getAddressBooksItemByEmailAndDate==>', res)
      if (res.status != '' && res.length == 0) {
        message.warning(`${res.status}`)
        return
      } else {
        if (!res) return
        finalAddressPayload = [...res]
      }
    }).catch((err: any) => {
      console.log('getAddressBooksItemByEmailAndDate err==>', err)
    })
    return finalAddressPayload
  }


  const onFormFinish = async (values: any) => {
    console.log('onFormFinish==>', values, selectedLog)

    const logBookPayload = {
      log_id: selectedLog?.log_id,
      work_order_status: values?.work_order_status,
    }

    console.log('logBookPayload==>', logBookPayload)

    // return

    LogBookAPI.updateLogBook(logBookPayload).then((res: any) => {
      console.log('updateLogBook==>', res)
      if (!res) return
      message.success('Work Order Status updated successfully.')
      reload()
      onClose()
    }).catch((err: any) => {
      console.log('updateLogBook err==>', err)
      message.error('Failed to update Status.')
    })


    // let finalAddressBookPayload;

    // let addressPayload: any = await getAddressBook(logBookPayload)

    // if (!addressPayload) {
    //   return
    // } else {
    //   addressPayload = addressPayload[0]

    //   finalAddressBookPayload = {
    //     ...addressPayload,
    //     ...values,
    //   }

    //   console.log('finalAddressBookPayload==>', finalAddressBookPayload)

    // }

    // // make PUT call to update Address book data

    // let promise = null

    // promise = AddressBookAPI.updateAddressBook(finalAddressBookPayload)

    // promise.then((res: any) => {
    //   console.log('updateAddressBook==>', res)
    //   if (!res) return

    //   // update log book data PUT call

    //   LogBookAPI.updateLogBook(logBookPayload).then((res: any) => {
    //     console.log('updateLogBook==>', res)
    //     if (!res) return
    //     message.success('Record updated successfully.')
    //     reload()
    //     onClose()
    //   }).catch((err: any) => {
    //     console.log('updateLogBook err==>', err)
    //     message.error('Failed to update recor.')
    //   })

    // }).catch((err: any) => {
    //   console.log('updateAddressBook err==>', err)
    //   message.error('Failed to update recor.')
    // })
  }

  const closeForm = () => {
    form.resetFields()
    onClose()
  }

  const renderFooter = () => (
    <FlexWrapper flexEnd key='0' gap='10px'>
      <SimpleButton
        text='Cancel'
        id='modal-btn-width-regular'
        onClick={closeForm}
        isCancel
      />
      <SimpleButton
        text={'Save'}
        form={formName}
        id='modal-btn-width-regular'
        htmlType="submit"
      />
    </FlexWrapper>
  );

  return (
    <>
      <GlobalModal
        title={`Work Order Status`}
        open={open}
        onCancel={onClose}
        small
        footer={renderFooter()}
      >
        <Form
          form={form}
          name={formName}
          onFinish={onFormFinish}
        >
          <div style={{ width: '80%' }}>
            <Row gutter={[15, 0]}>
              <Col md={{ span: '24' }} sm={{ span: '24' }} xs={{ span: '24' }}>
                <Form.Item
                  name="work_order_status"
                  rules={[
                    {
                      required: true,
                      message: 'Please select Work Order Status.',
                    }
                  ]}
                >
                  <Input
                    label='Work Order Status'
                    placeholder="Select Status"
                    type="select"
                    options={
                      [
                        { value: 'assigned', label: 'Assigned' },
                        { value: 'unassigned', label: 'Unassigned' },
                        { value: 'completed', label: 'Completed' },
                        { value: 'approved_by_qa', label: 'Approved By QA' },
                      ]
                    }
                    required
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </GlobalModal>
    </>
  )
}


const tableColumns = [
  {
    title: formatTitle('Status'),
    dataIndex: 'status',
    key: 'status',
    ...TABLE_CELL_CONFIG,
    width: 200,
    filters: [
      // { text: 'Expected', value: CONTRACTOR_STATUS.EXPECTED },
      { text: 'Checked-In', value: CONTRACTOR_STATUS.CHECKED_IN },
      { text: 'Checked-Out', value: CONTRACTOR_STATUS.CHECKED_OUT },
    ],
    onFilter: (value: string, record: any) => record.status === value,
    render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),
  },
  {
    title: formatTitle('Invitation'),
    dataIndex: 'invitation',
    key: 'invitation',
    ...TABLE_CELL_CONFIG,
    width: 200,
    render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),
  },
  {
    title: formatTitle('Date'),
    dataIndex: 'visit_date',
    key: 'visit_date',
    ...TABLE_CELL_CONFIG,
    sorter: (a: any, b: any) => a.visit_date?.localeCompare(b.visit_date),
    render: (text: string) => text ? moment(text).format('DD/MM/YYYY') : initialCellValue(text),
  },
  {
    title: formatTitle('Company'),
    dataIndex: 'company',
    key: 'company',
    ...TABLE_CELL_CONFIG,
    render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),
  },
  {
    title: formatTitle('Contractor Name'),
    dataIndex: 'first_name',
    key: 'first_name',
    ...TABLE_CELL_CONFIG,
    render: (text: string, record: any) => {
      const { first_name, last_name } = record
      return first_name && last_name ? capitaliseFirst(`${first_name} ${last_name}`) : initialCellValue(text)
    },
  },
];

const visitorColumns = [
  {
    title: formatTitle('Contractor Email'),
    dataIndex: 'email',
    key: 'email',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle('Contractor Phone No.'),
    dataIndex: 'phone_no',
    key: 'phone_no',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle('Mnda Status'),
    dataIndex: 'mnda_status',
    key: 'mnda_status',
    ...TABLE_CELL_CONFIG,
    render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),
  },
];

const fixedColumns = [
  {
    title: formatTitle('Host'),
    dataIndex: 'host',
    key: 'host',
    ...TABLE_CELL_CONFIG,
    render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),
  },
  {
    title: formatTitle('Check-In'),
    dataIndex: 'check_in_time',
    key: 'check_in_time',
    ...TABLE_CELL_CONFIG,
    width: 200,
    render: (text: string) => text ? moment(text).format('HH:mm') : initialCellValue(text),
  },
  {
    title: formatTitle('Check-Out'),
    dataIndex: 'check_out_time',
    key: 'check_out_time',
    ...TABLE_CELL_CONFIG,
    width: 200,
    render: (text: string) => text ? moment(text).format('HH:mm') : initialCellValue(text),
  },
];

const extraColumns = [
  {
    title: formatTitle('Induction Status'),
    dataIndex: 'induction_status',
    key: 'induction_status',
    ...TABLE_CELL_CONFIG,
    render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),
  },
  {
    title: formatTitle('Background Check'),
    dataIndex: 'background_check',
    key: 'background_check',
    ...TABLE_CELL_CONFIG,
    render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),
  }
];

const renderDocumentName = (text: string) => {
  console.log('renderDocumentNametext==>', text)
  const splitText = text?.split('/')
  if (splitText.length > 1) {
    return splitText[splitText.length - 1]
  }
  return 'N/A'
}

const workColumns = [
  {
    title: formatTitle('Work Type'),
    dataIndex: 'work_type',
    key: 'work_type',
    ...TABLE_CELL_CONFIG,
    render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),
  },
  {
    title: formatTitle('Work Order'),
    dataIndex: 'work_order',
    key: 'work_order',
    ...TABLE_CELL_CONFIG,
    render: (text: string, record: any) => {
      return <span>
        {
          record?.work_order ?
            <a href={`${process.env.REACT_APP_CDN}${record?.work_order}`} target='_blank' rel="noopener noreferrer">
              {renderDocumentName(record?.work_order)}
            </a> : initialCellValue(text)
        }
      </span>
    },
  },
  {
    title: formatTitle('Work Order Status'),
    dataIndex: 'work_order_status',
    key: 'work_order_status',
    ...TABLE_CELL_CONFIG,
    width: 200,
    render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),
  },
  {
    title: formatTitle('Fob Staus'),
    dataIndex: 'fob_status',
    key: 'fob_status',
    ...TABLE_CELL_CONFIG,
    width: 200,
    render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),
  },
  {
    title: formatTitle('Fob Number'),
    dataIndex: 'fob_number',
    key: 'fob_number',
    ...TABLE_CELL_CONFIG,
    width: 200,
    render: initialCellValue,
  },

];

const MoreIcon = styled(MoreOutlined)`
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  top: 13px;
  font-size: 20px;
  font-weight: bold;
  padding: 3px;
  &:hover {
    cursor: pointer;
  }
`;


