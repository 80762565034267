import React, { useEffect, useState, useRef, useCallback, Fragment } from "react";
import AssetDataAPI from "../../../apis/AssetDataAPI";
import AlertAPI from "../../../apis/AlertAPI";
import styles from '../../../../../../statics/styles/GoogleMapThemes/GoogleMapStylesPurple.json';
import GlobalMarkerIcon from 'statics/images/map-marker-green.svg';
import M1 from 'statics/images/markerclusterer/m1.png';

import GeofenceAPI from "containers/Pages/AssetManagement/apis/GeofenceAPI";
import { GoogleMap, Polyline, useJsApiLoader, Polygon, MarkerClusterer, MarkerF } from "@react-google-maps/api";
import { MELBOURNE_LATLONG } from "utilities/Functions/CONSTANTS";


type Props = {
  searchText: string;
  doSearch: boolean;
  openModal: Function
  // assetDetailsModal: any

};

const MAX_ZOOM = 19;
const MIN_ZOOM = 3;
const INITIAL_ZOOM = 5;

const INITIAL_CENTER: google.maps.LatLngLiteral = {
  lat: MELBOURNE_LATLONG[0],
  lng: MELBOURNE_LATLONG[1],
};

const AssetMap = (props: Props) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || "",
    libraries: ["places", "geometry"],
  });

  const { searchText, openModal, doSearch } = props
  // console.log('AMap searchText', searchText)
  const [map, setMap] = React.useState(null)
  const [center, setCenter] = useState<google.maps.LatLngLiteral>(INITIAL_CENTER);
  const [mapZoom, setMapZoom] = useState<number>(INITIAL_ZOOM);

  const [polygonsData, setPolygonsData] = useState<any[]>([]);
  const [clickedMarkerData, setClickedMarkerData] = useState<any>(null);
  const [markersData, setMarkerData] = useState<any[]>([]);


  const mapRef = useRef<google.maps.Map | null>(null)



  const getMarkerPosition = (dataItem: any) => {
    return { lat: parseFloat(dataItem.device_data.Location.lat), lng: parseFloat(dataItem.device_data.Location.long) }
  };

  const getPolygonCoords = (dataItemCoordinats: any) => {
    // if any of the source or destination has same geo.lat and geo.long then remove that polyline
    const path = dataItemCoordinats.map((item: any) => {
      return { lat: parseFloat(item.lat), lng: parseFloat(item.long) }
    })
    return path || []

  };

  const getMapData = async () => {

    console.log('handleSearch getMapData', searchText)
    // const searchUrl = getFilterSearchUrl(filters)
    const url = searchText && searchText != '' ? `?map_search=${searchText}` : ''

    console.log('getMapDatagetMapDatagetMapData', url)

    try {
      const result = await AssetDataAPI.searchAssetsData(url)
      const validAssets = result.filter((item: any) => {

        return !item.device_data.error

      })
      console.log('initializeMap google map validAssets result', validAssets)
      return validAssets
    } catch (err) {
      console.log('initializeMap google map fail...', err)
      return []

    }
  }
  const getGeofenceData = async () => {

    try {
      const result = await GeofenceAPI.getAllGeofence()

      console.log('initializeMap google map result GeofenceAPI', result)
      return result
    } catch (err) {
      console.log('initializeMap google map GeofenceAPI fail...', err)
      return []

    }

  }

  const initMap = async () => {

    const assetData = await getMapData()
    setMarkerData(assetData)

    // init the genfence
    const geofenceData = await getGeofenceData()
    // setPolygons(geofenceData)
    setPolygonsData(geofenceData)

    // reset the polyline when render the component
    setClickedMarkerData(null)
  }

  useEffect(() => {
    initMap();
  }, [doSearch]);

  useEffect(() => {
    // whenever marker clicked , change the bounds based on path
    console.log('whenever marker clicked , change the bounds based on path', markersData)
    if (clickedMarkerData && mapRef.current) {
      if (clickedMarkerData.device_data && mapRef.current) {

        const bounds = new window.google.maps.LatLngBounds();

        clickedMarkerData.device_data.forEach((item: any) => {
          if (item.Location.lat && item.Location.long) {
            bounds.extend({ lat: parseFloat(item.Location.lat), lng: parseFloat(item.Location.long) });
          }
        });

        // Ensure that the map is initialized before calling fitBounds
        if (bounds.isEmpty()) {
          // Handle the case when bounds are empty (no valid marker)
          mapRef.current.setCenter(INITIAL_CENTER);
          mapRef.current.setZoom(INITIAL_ZOOM);
        } else {
          mapRef.current.fitBounds(bounds);
          setMapZoom(mapRef.current.getZoom() || INITIAL_ZOOM);
        }

      }
    }

  }, [clickedMarkerData])


  const onLoad = useCallback((map: google.maps.Map) => {
    // setMap(map)
    console.log('onLoadMap', map)
    mapRef.current = map;
    map.setOptions({
      maxZoom: MAX_ZOOM,
      minZoom: MIN_ZOOM,
    });

  }, [])

  const onUnmount = React.useCallback(function callback(map: google.maps.Map) {
    setMap(null)
  }, [])

  const handleMarkerClick = async (item: any) => {
    // console.log(' marker clicked, do sth girl!', childProps)
    // const { item } = childProps
    if (item) {
      // const assetId = 'A-148'
      const assetId = item.asset_ID

      try {


        const singleAssetDetails = await AssetDataAPI.getAssetDataById(assetId)

        let finalData = {
          ...singleAssetDetails,
        }

        const alertsResults = await AlertAPI.getAlertByAssetId(assetId)

        finalData = {
          ...finalData,
          alerts_history: [...alertsResults]
        }

        console.log('handleMarkerClick finalData', finalData)
        openModal && openModal(finalData)

        setClickedMarkerData(singleAssetDetails)

      } catch (error) {
        console.log('handleMarkerClick fail...', error)
      }
    } else {
      // console.log('setZoom click', map.getZoom())
      // setZoom(zoom + 1)
    }


  }

  function createKey(location: any) {
    return location.lat + location.lng
  }

  const renderPolyline = () => {
    if (clickedMarkerData) {
      const polylineCoords: any[] = []

      clickedMarkerData.device_data.map((item: any) => {

        return item.Location.lat && item.Location.long && polylineCoords.push({ lat: parseFloat(item.Location.lat), lng: parseFloat(item.Location.long) })
        // return ([parseFloat(item.Location.lat), parseFloat(item.Location.long)])
      })

      const finalPaths = [...polylineCoords]
      console.log('final poly path', finalPaths)
      return (
        <Polyline
          path={polylineCoords}
          options={{
            strokeColor: "red",
            strokeWeight: 2,

          }}

        />)

    }

  }

  const renderPolygons = () => {
    return polygonsData.length > 0 && polygonsData.map((polygonData: any, i: number) => {

      const coords = getPolygonCoords(polygonData.coordinates)
      console.log('getPolygonCoords ==> ', coords)
      return (
        <Fragment key={i}>
          <Polygon
            path={coords}
            options={{
              fillColor: 'red',
              fillOpacity: 0.4,
              strokeColor: 'red',
              strokeOpacity: 1,
              strokeWeight: 2,
              // icons: [
              //   {
              //     icon: {
              //       path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              //     },
              //     offset: "50%", // Appear at the middle of the line
              //   },
              // ],
            }}
          />
        </Fragment>
      )
    })
  }

  const clusterStyles = [
    {
      textColor: 'black',
      url: M1, // Relative path to the cluster icon
      height: 60,
      width: 60,
    },
    // Add more styles for other cluster sizes if needed
  ];

  const renderCluster = () => {
    const locations = markersData.map((item: any) => {
      return getMarkerPosition(item)
    })

    console.log('locations for cluster ', locations)

    return (

      <MarkerClusterer options={{
        // imagePath: `../../../../../../statics/images/markerclusterer/m`,
        styles: clusterStyles,
      }}>
        {(clusterer: any) => (
          <>
            {markersData.map((markerItem: any) => {
              const location = getMarkerPosition(markerItem)
              return (<MarkerF
                key={createKey(location)}
                position={location}
                clusterer={clusterer}
                icon={{
                  scaledSize: new google.maps.Size(25, 25),
                  url: GlobalMarkerIcon,

                }}
                onClick={() => handleMarkerClick(markerItem)}

              />)
            })}
          </>
        )}
      </MarkerClusterer>)
  }


  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "60vh", marginTop: '20px' }}
        options={{
          mapTypeId: 'roadmap',
          mapTypeControl: false,
          streetViewControl: false,
          styles,
        }}
        center={center}
        zoom={mapZoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {renderCluster()}
        {renderPolygons()}
        {renderPolyline()}

      </GoogleMap>

    </>
  ) : null;
};

export default AssetMap;